import React from 'react'
import ReactSelect from 'react-select'

const customStyles = {
	control: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			border: isFocused ? '1px solid var(--color-border-hover)' : '1px solid var(--color-border)'
		}
	},
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles
		}
	}
}

const CustomReactSelect = (props) => {
	return <ReactSelect styles={customStyles} noOptionsMessage={() => 'bulunamadı'} {...props} />
}

export default CustomReactSelect
