import React from 'react'

import AddPersonnelFileModal from './components/AddPersonnelFileModal'
import PersonnelFileHistory from './components/PersonnelFileHistory'

const FilesTab = () => {
	return (
		<>
			<AddPersonnelFileModal />
			<PersonnelFileHistory />
		</>
	)
}

export default FilesTab
