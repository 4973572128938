import ReactTimeAgo from 'react-timeago'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import trStrings from 'react-timeago/lib/language-strings/tr'

const formatter = buildFormatter(trStrings)

const TimeAgo = ({ date }) => {
	return (
		<span>
			<ReactTimeAgo date={date} formatter={formatter} title={false} />
		</span>
	)
}

export default TimeAgo
