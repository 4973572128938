import Service from 'global/Service'
import { create } from 'zustand'

const useOrganizationStore = create((set, get) => ({
	isLoading: true,
	organizationId: null,
	isValidOrganization: false,
	setIsLoading: (loading) => set({ isLoading: loading }),
	setOrganizationId: (id) => set({ organizationId: id }),
	setIsValidOrganization: (valid) => set({ isValidOrganization: valid }),
	validateOrganization: async () => {
		const { setOrganizationId, setIsValidOrganization, setIsLoading } = useOrganizationStore.getState()

		const domain = window.location.hostname
		console.log(domain, 'domain')
		if (!isSubdomain(domain)) {
			setIsLoading(false)
			return
		}

		const subdomain = domain.split('.')[0]

		try {
			const response = await Service.post(`/auth/check-slug/${subdomain}`)
			if (response.data.org_id) {
				setOrganizationId(response.data.org_id)
				setIsValidOrganization(true)
			}
		} catch (error) {
			console.error(error)
		}
		setIsLoading(false)
	}
}))

const isSubdomain = (domain) => domain.split('.').length > (process.env.NODE_ENV === 'development' ? 1 : 2)

export default useOrganizationStore
