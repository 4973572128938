import 'react-toastify/dist/ReactToastify.css'

import { MarkdownEditor, Tooltip } from 'components/ui'
import Service from 'global/Service'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import useAuthStore from 'stores/authStore'

import Birthdays from './components/Birthdays'
import CompletedTasksByDaysChart from './components/CompletedTasksByDaysChart'
import ExampleSSE from './components/ExampleSSE'
import LastEvents from './components/LastEvents'
import LastOpenedDocuments from './components/LastOpenedDocuments'
import MyVacations from './components/MyVacations'
import WaitingTasks from './components/WaitingTasks'

const Dashboard = () => {
	const user = useAuthStore((state) => state.user)

	return (
		<div className="container-xxl flex-grow-1 container-p-y">
			<div className="d-flex flex-wrap flex-md-nowrap align-items-center py-4">
				<div className="d-block mb-4 mb-md-0">
					<h1 className="h4">Hoşgeldin, {user.fullName}!</h1>
				</div>
			</div>

			<Row className="d-flex align-items-start">
				<Col md={4} className="mb-4">
					<WaitingTasks />
				</Col>
				<Col md={4} className="mb-4">
					<MyVacations />
				</Col>
				<Col md={4} className="mb-4">
					<LastEvents />
				</Col>
				<Col md={8} className="mb-4">
					<CompletedTasksByDaysChart />
				</Col>
				{/* <Col md={3} className="mb-4">
					<LastOpenedDocuments />
				</Col> */}

				<Col md={4} className="mb-4">
					<Birthdays />
				</Col>
			</Row>
		</div>
	)
}

export default Dashboard
