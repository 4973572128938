import { CreateButton } from 'components/fast-ui'
import { CustomReactTable, FaIcon } from 'components/ui/index.js'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'

const columns = [
	{
		Header: 'Ad',
		accessor: 'name',
		Cell: (cell) => {
			return (
				<span className="fw-semibold">
					{cell.row.original.name}

					{cell.row.original.is_primary && <span className="badge bg-primary ms-2">Birincil</span>}
				</span>
			)
		}
	},
	{
		Header: 'Açıklama',
		accessor: 'description'
	},
	{
		Header: 'Seçenekler',
		accessor: 'options',
		Cell: (cell) => {
			const params = useParams()
			const { module_name } = params
			return (
				<div className="d-flex align-items-center">
					<div className="d-flex align-items-center">
						<Link to={`/vacations/rules/${module_name}/${cell.row.original._id}`} className="text-primary me-3">
							<FaIcon icon="edit" />
						</Link>
					</div>
				</div>
			)
		}
	}
]

const VacationTypesTable = () => {
	const params = useParams()
	const navigate = useNavigate()

	const { tab_name, module_name } = params
	const [data, setData] = useState([])

	const fetchData = async () => {
		try {
			const response = await Service.get(`/vacation-types/parent/${module_name}`)
			setData(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [params])

	const handleNewVacationType = () => {
		navigate(`/vacations/rules/${module_name}/add`)
	}

	return (
		<Card>
			<Card.Header>
				<Card.Title as="h5">İzin Türleri</Card.Title>
				<small>Kural, çalışanların talep edebileceği {data.length} izin türünü içerir.</small>
			</Card.Header>
			<Card.Body>
				<CreateButton text="Yeni İzin Türü" onClick={handleNewVacationType} className="mb-4" />
				<CustomReactTable data={data} columns={columns} pageSizeCount={100} defaultSortBy="name" />
			</Card.Body>
		</Card>
	)
}
export default VacationTypesTable
