import { CloseButton, CreateButton, SaveButton } from 'components/fast-ui'
import { NavButton, NavButtonContainer } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useRoleStore from 'stores/settings/roleStore'

import RoleMembersTab from './tabs/members'
import RolePermissionsTab from './tabs/permissions'
import RoleViewTab from './tabs/view'

const RoleEditor = ({ form, isNew }) => {
	const params = useParams()
	const { tab_name, item_id: role_id } = params
	const [sekmeAdi, setSekmeAdi] = useState('view')
	const fetchRoles = useRoleStore((state) => state.fetchRoles)

	const {
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState,
		watch,
		formState: { errors }
	} = form

	const handleCreate = async (data) => {
		const body = data
		try {
			const response = await Service.post('/roles', body)
			sendToast('Rol başarıyla oluşturuldu', true)
			fetchRoles()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleSave = async (data) => {
		const body = data
		try {
			const response = await Service.patch(`/roles/${data._id}`, body)
			sendToast('Rol başarıyla güncellendi', true)
			fetchRoles()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<>
			<NavButtonContainer className="mb-3">
				<NavButton text="Görünüm" onClick={() => setSekmeAdi('view')} active={sekmeAdi === 'view'} />
				<NavButton text="İzinler" onClick={() => setSekmeAdi('permissions')} active={sekmeAdi === 'permissions'} />
				{/* <NavButton text="Üyeler" onClick={() => setSekmeAdi('users')} active={sekmeAdi === 'users'} /> */}
			</NavButtonContainer>
			<div className="mb-3">
				{['view'].includes(sekmeAdi) && <RoleViewTab form={form} />}
				{['permissions'].includes(sekmeAdi) && <RolePermissionsTab form={form} />}
				{/* {['users'].includes(sekmeAdi) && <RoleMembersTab />} */}
			</div>
			{isNew ? (
				<CreateButton onClick={handleSubmit((data) => handleCreate(data))} />
			) : (
				<SaveButton onClick={handleSubmit((data) => handleSave(data))} />
			)}
		</>
	)
}

export default RoleEditor
