import 'react-popper-tooltip/dist/styles.css'

import React from 'react'
import { Container } from 'react-bootstrap'

import FaIcon from '../FaIcon'

const NoData = ({ title, subTitle }) => {
	return (
		<Container className="d-flex flex-column align-items-center justify-content-center text-center py-5">
			<FaIcon icon="inbox" size="3x" className="mb-2" />
			<h3 className="mb-1">{title}</h3>
			<span>{subTitle}</span>
		</Container>
	)
}

export default NoData
