import Service from 'global/Service'
import { create } from 'zustand'

const useGeneralStore = create((set) => ({
	logoUrl: null,
	iconUrl: null,
	setLogoUrl: (logoUrl) => set({ logoUrl }),
	setIconUrl: (iconUrl) => set({ iconUrl }),
	init: async () => {
		const { setLogoUrl, setIconUrl } = useGeneralStore.getState()

		const response = await Service.get('/company-units/images')
		console.log(response.data)
		setLogoUrl(response.data.logoUrl)
		setIconUrl(response.data.iconUrl)
	}
}))

export default useGeneralStore
