import { CreateButton } from 'components/fast-ui'
import { CustomReactSelect, CustomReactTable } from 'components/ui'
import Service from 'global/Service'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useMemo, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

import COLUMNS from './components/Columns'
import CompetenceModal from './components/CompetenceModal'

const CompetenceDefinition = () => {
	const params = useParams()
	const navigate = useNavigate()
	const { tab_name, module_name, module_id: competenceId } = params

	const [showModal, setShowModal] = useState(false)
	const [searchValue, setSearchValue] = useState('')
	const [typeFilterValue, setTypeFilterValue] = useState('')

	const [competencies, setCompetencies] = useState([])
	const [competenceTypes, setCompetenceTypes] = useState([])
	const [filteredCompetencies, setFilteredCompetencies] = useState([])

	const fetchCompetencies = async () => {
		try {
			const res = await Service.get('/competencies')
			setCompetencies(res.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchCompetenceTypes = async () => {
		try {
			const res = await Service.get('/competence-types')
			setCompetenceTypes(res.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchCompetencies()
		fetchCompetenceTypes()
		if (tab_name === 'definitions' && module_name === 'competencies' && competenceId) {
			setShowModal(true)
		} else {
			setShowModal(false)
		}
	}, [params])

	const handleFilter = (searchValue, typeFilterValue) => {
		const filteredData = competencies.filter((item) => {
			const nameMatch = item.name.toLowerCase().includes(searchValue)
			const typeMatch = typeFilterValue ? item.type._id === typeFilterValue._id : true
			return nameMatch && typeMatch
		})
		setFilteredCompetencies(filteredData)
	}

	useEffect(() => {
		handleFilter(searchValue, typeFilterValue)
	}, [competencies, searchValue, typeFilterValue])

	const handleCreateButton = () => {
		navigate('/ik-competencies/definitions/competencies/add')
	}

	const data = useMemo(() => filteredCompetencies)
	const columns = useMemo(() => COLUMNS, [])

	return (
		<Card className="h-100">
			<Card.Body>
				<div className="d-flex align-items-start justify-content-between mb-4">
					<div className="content-left">
						<div className="d-flex align-items-end mt-2">
							<h4 className="mb-0 me-2">Yetkinlikler</h4>
						</div>
						<small>Toplam: {competencies.length} </small>
					</div>
				</div>
				<Row>
					<Col md={4} lg={6} className="mb-3">
						<CreateButton onClick={handleCreateButton} />
					</Col>
					<Col md={4} lg={3} className="mb-3">
						<Form.Group>
							<Form.Control
								type="text"
								placeholder="Ara.."
								onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
							/>
						</Form.Group>
					</Col>
					<Col md={4} lg={3} className="mb-3">
						<Form.Group>
							<CustomReactSelect
								isClearable
								placeholder="Tür.."
								value={typeFilterValue}
								onChange={(e) => setTypeFilterValue(e)}
								options={competenceTypes}
								getOptionValue={(item) => item._id}
								getOptionLabel={(item) => item.name}
							/>
						</Form.Group>
					</Col>
				</Row>
				<CustomReactTable data={data} columns={columns} defaultSortBy="type.name" />
			</Card.Body>

			{showModal && (
				<CompetenceModal id={competenceId} show={showModal} onHide={() => navigate('/ik-competencies/definitions')} />
			)}
		</Card>
	)
}

export default CompetenceDefinition
