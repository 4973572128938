import { FaIcon } from 'components/ui'
import { AvatarGroup } from 'components/ui'
import { formatDate } from 'global/functions/formatDate'
import PropTypes from 'prop-types'
import React from 'react'
import { Card } from 'react-bootstrap'

const MyCompetenceRatingCard = ({ employee, competencies, createDate, iCompleted }) => {
	return (
		<Card>
			<Card.Body>
				<div className="d-flex align-items-center mb-3">
					<a href="#" className="d-flex align-items-center">
						<div className="avatar avatar-sm me-2">
							{employee.profilePhotoUrl ? (
								<img className="rounded-circle" src={employee.profilePhotoUrl} alt="Avatar" />
							) : (
								<span className="avatar-initial rounded-circle bg-label-primary">
									{`${employee.firstName[0]} ${employee.lastName[0]}`}
								</span>
							)}
						</div>
						<div className="me-2 h5 mb-0">
							<span>{employee.fullName}</span>
						</div>
					</a>
					<div className="ms-auto mb-2">
						{iCompleted ? (
							<FaIcon icon="check" className="text-success" />
						) : (
							<FaIcon icon="arrow-right" className="text-muted" />
						)}
					</div>
				</div>

				<div className="d-flex align-items-center justify-content-between flex-wrap gap-1">
					<div>
						{iCompleted ? (
							<span title="Tamamlanma Durumu" className="badge bg-label-success me-2">
								Tamamlandı <FaIcon icon="check" className="ms-1" />
							</span>
						) : (
							<span title="Tamamlanma Durumu" className="badge bg-label-warning me-2">
								Bekliyor <FaIcon icon="clock" className="ms-1" />
							</span>
						)}
					</div>
					<div>
						<span title="Yetkinlik Sayısı" className="badge bg-label-secondary me-2">
							{competencies.length} <FaIcon icon="star" className="ms-1" />
						</span>
						<span title="Oluşturma Tarihi" className="badge bg-label-secondary">
							{formatDate(createDate)} <FaIcon icon="calendar" className="ms-1" />
						</span>
					</div>
				</div>
			</Card.Body>
		</Card>
	)
}

MyCompetenceRatingCard.propTypes = {
	employee: PropTypes.object.isRequired,
	competencies: PropTypes.array.isRequired,
	createDate: PropTypes.string.isRequired,
	iCompleted: PropTypes.bool.isRequired
}

export default MyCompetenceRatingCard
