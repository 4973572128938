import Service from 'global/Service'

import { createSlice } from '@reduxjs/toolkit'

const ikEmployeeEditSlice = createSlice({
	name: 'ikEmployeeEdit',
	initialState: {
		employee: [],
		employeeEditingPosition_id: null,
		tabOneError: false,
		tabTwoError: false,
		tabThreeError: false,
		tabFourError: false
	},
	reducers: {
		setEmployee: (state, action) => {
			state.employee = action.payload
		},
		setTabOneError: (state, action) => {
			state.tabOneError = action.payload
		},
		setEmployeeEditingPosition_id: (state, action) => {
			state.employeeEditingPosition_id = action.payload
		}
	}
})

export const { setEmployee, setTabOneError, setEmployeeEditingPosition_id } = ikEmployeeEditSlice.actions

export const fetchEmployee = (employee_id) => async (dispatch) => {
	const employee = await Service.get(`/employees/${employee_id}`)
	try {
		dispatch(setEmployee(employee.data))
	} catch (error) {}
}

export default ikEmployeeEditSlice.reducer
