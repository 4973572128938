import { FaIcon } from 'components/ui'
import React, { useState } from 'react'

import { ControlledMenu, MenuItem, useMenuState } from '@szhsin/react-menu'

export const TagElement = ({ data, onClick, selected }) => {
	const [menuProps, toggleMenu] = useMenuState()
	const [isOpen, setOpen] = useState()
	const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })

	const handleContextMenu = (e) => {
		e.preventDefault()
		setAnchorPoint({ x: e.clientX, y: e.clientY })
		toggleMenu(true)
		setOpen(true)
	}

	const handleClose = () => {
		toggleMenu(false)
		setOpen(false)
	}

	const handleOnClick = () => {
		onClick && onClick(data._id)
	}

	return (
		<li
			className={`mb-2 px-2 ${isOpen ? 'border rounded' : ''}`}
			onClick={handleOnClick}
			onContextMenu={handleContextMenu}
		>
			<div className="d-flex align-items-center" type="button" style={{ color: data.hexColor }}>
				<i className={`${selected ? 'fa-solid' : 'fa-regular'} fa-circle-small me-2`}></i>
				<span>{data.name}</span>
			</div>
			<ControlledMenu className="position-absolute" {...menuProps} anchorPoint={anchorPoint} onClose={handleClose}>
				<MenuItem data-bs-toggle="modal" data-bs-target="#newTagModal">
					<span className="text-secondary">
						<FaIcon icon="edit" size="sm" className="me-2" /> Düzenle
					</span>
				</MenuItem>
			</ControlledMenu>
		</li>
	)
}
