const extraSalaryPayments = [
	{
		value: 'road',
		label: 'Yol Yardımı'
	},
	{
		value: 'meal',
		label: 'Yemek Yardımı'
	},
	{
		value: 'fixed_bonus',
		label: 'Sabit Prim'
	},
	{
		value: 'health_insurance',
		label: 'Özel Sağlık Sigortası'
	},
	{
		value: 'pension',
		label: 'Bireysel Emeklilik'
	}
]

export default extraSalaryPayments
