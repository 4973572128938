import anonimProfilePhoto from 'assets/img/avatars/anonim-profile-photo.jpg'
import React from 'react'
import { TreeNode } from 'react-organizational-chart'

const SchemeContainer = (props) => {
	return (
		<>
			{props.data?.map((item) => (
				<TreeNode
					label={
						<button type="button" className="btn btn-sm btn-label-secondary">
							<div className="d-flex align-items-center flex-wrap">
								<div className="avatar avatar-xs me-2">
									{item.employee.profilePhotoUrl ? (
										<img src={`${item.employee?.profilePhotoUrl}`} alt="Avatar" className="rounded-circle" />
									) : (
										<img src={anonimProfilePhoto} alt="Avatar" className="rounded-circle" />
									)}{' '}
								</div>
								<span className="me-0">
									<b>{`${item.employee?.firstName} ${item.employee?.lastName}`}</b>
									<br />
									<small>{item.employee?.position?.department?.name}</small> -{' '}
									<small>{item.employee?.position?.title?.name}</small>
								</span>
							</div>
						</button>
					}
				>
					{item.children ? <SchemeContainer data={item.children} /> : null}
				</TreeNode>
			))}
		</>
	)
}

export default SchemeContainer
