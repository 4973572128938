import { CloseButton } from 'components/fast-ui'
import { FaIcon } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import ProjectModal from '../../ProjectModal'

const ProjectCard = ({ data }) => {
	const [showEditIcon, setShowEditIcon] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [formData, setFormData] = useState(null)

	const navigate = useNavigate()
	const params = useParams()

	const { workspace_id, project_id } = params

	const handleClick = (e, project_id) => {
		e.stopPropagation()
		navigate(`/projects/${workspace_id}/${project_id}`)
	}

	const handleEditClick = (e, project_id) => {
		e.stopPropagation()
		navigate(`/workspaces/${workspace_id}/${project_id}/edit`)
		setFormData(data)
		setShowModal(true)
	}

	return (
		<>
			<div
				className="d-flex flex-column flex-wrap justify-content-center cursor-pointer text-center border rounded project-card position-relative"
				style={{
					height: '110px',
					width: '260px'
				}}
				onClick={(e) => handleClick(e, data._id)}
				onMouseEnter={() => setShowEditIcon(true)}
				onMouseLeave={() => setShowEditIcon(false)}
			>
				<h6 className="fw-bold mb-0 text-white">{data?.name}</h6>
				{showEditIcon && (
					<span className="position-absolute top-0 end-0 p-2" onClick={(e) => handleEditClick(e, data._id)}>
						<FaIcon icon="edit" className="text-white p-2 rounded" style={{ background: 'rgb(0 0 0 / 30%' }} />
					</span>
				)}
			</div>
			{showModal && <ProjectModal show={showModal} onHide={() => setShowModal(false)} formData={formData} />}
		</>
	)
}

export default ProjectCard
