import { ApproveButton, CloseButton } from 'components/fast-ui'
import { ButtonContainer, FaIcon } from 'components/ui'
import { sendToast } from 'global/sendToast'
import React, { useRef, useState } from 'react'
import { Form, Modal, Row } from 'react-bootstrap'
import * as XLSX from 'xlsx'

const ImportEmployees = () => {
	const [data, setData] = useState([])
	const [show, setShow] = useState(false)
	const [isFileUploaded, setIsFileUploaded] = useState(false)

	const importData = (event) => {
		const file = event.target.files[0]
		const reader = new FileReader()
		reader.onload = (event) => {
			const workbook = XLSX.read(event.target.result, { type: 'binary' })
			const sheetName = workbook.SheetNames[0]
			const worksheet = workbook.Sheets[sheetName]
			const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: false })
			setData(sheetData)
			console.log(JSON.stringify(sheetData, null, 2))
			setIsFileUploaded(true)
		}
		reader.readAsBinaryString(file)
	}

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const handleApprove = () => {
		sendToast('Personel yükleme işlemi başarılı')
		handleClose()
	}

	return (
		<>
			<div className="cursor-pointer mt-md-0 mt-3 border rounded-2 px-3 py-2" onClick={handleShow}>
				<FaIcon icon="fa fa-upload" />
			</div>

			<Modal show={show} onHide={handleClose} centered size="md">
				<Modal.Header closeButton>
					<Modal.Title as="h5">Personel Yükle</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Form.Group controlId="formFile">
							<Form.Label className="mb-3">Yüklemek istediğiniz personel excel dosyasını seçiniz</Form.Label>
							<Form.Control type="file" onChange={importData} accept=".xlsx, .xls, .csv" />
						</Form.Group>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<ButtonContainer>
						<CloseButton onClick={handleClose} />
						<ApproveButton onClick={handleApprove} disabled={!isFileUploaded} />
					</ButtonContainer>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ImportEmployees
