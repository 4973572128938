import { ApproveButton } from 'components/fast-ui'
import NotificationItem from 'components/theme/Navbar/components/Notifications/components/NotificationItem'
import { Notification } from 'components/ui'
import Service from 'global/Service'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const MyNotifications = () => {
	const [data, setData] = useState([])

	const fetchData = async () => {
		try {
			const response = await Service.get('/notifications2/me/all')
			console.log(response.data, 'me/all')
			//! url sonuna /me/all eklenecek
			setData(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	const markAllAsRead = async () => {
		try {
			await Service.post('/notifications2/read-all')
			fetchData()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<Container className="d-flex align-items-center container-p-y">
			<Row>
				<Col md={12}>
					<h4>
						<i className="bx bx-bell bx-sm" />
						&nbsp;Bildirimler
					</h4>
					<div className="mb-3">
						<ApproveButton
							type="outline-secondary"
							text="Tümünü Okundu Olarak İşaretle"
							onClick={() => markAllAsRead()}
						/>
					</div>
					<ul className="list-group list-group-flush border rounded">
						<ul className="list-group list-group-flush p-2">
							{data.notifications &&
								data.notifications.map((notification) => <Notification key={notification._id} data={notification} />)}
						</ul>
					</ul>
				</Col>
			</Row>
		</Container>
	)
}

export default MyNotifications
