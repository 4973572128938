import { ProjectButton } from 'components/fast-ui/Buttons'
import { Button, FaIcon } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { forwardRef } from 'react'
import { Card } from 'react-bootstrap'
import { usePopper } from 'react-popper'
import { useParams } from 'react-router-dom'
import useTaskStore from 'stores/projectTaskStore'
import useProjectTaskStore from 'stores/projectTaskStore'

import TaskTagCard from './components/TaskTagCard'

const TaskTagPopper = forwardRef(({ buttonRef, show, onClose }) => {
	const popoverRef = useRef(null)
	const { styles, attributes } = usePopper(buttonRef.current, popoverRef.current, {
		placement: 'bottom-start',
		strategy: 'fixed',
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, 8]
				}
			}
		]
	})

	const handleClose = () => {
		onClose && onClose()
	}

	const params = useParams()
	const { workspace_id, project_id, task_id } = params

	const [tabName, setTabName] = useState('tags')
	const [tags, setTags] = useState([])
	const [selectedTag, setSelectedTag] = useState(null)

	const task = useProjectTaskStore((state) => state.projectTask)
	const refreshTask = useProjectTaskStore((state) => state.refreshProjectTask)

	const fetchTags = async () => {
		try {
			const response = await Service.get(`/projects/${project_id}/tags`)
			setTags(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleTagChange = async (e, tagId) => {
		const isChecked = e.target.checked

		if (isChecked) {
			const body = {
				tag: tagId
			}

			try {
				const response = await Service.post(`/project-tasks/${task_id}/tags`, body)
				sendToast('Etiket eklendi.', true)
				refreshTask()
			} catch (error) {
				sendToast(error.response.data.message, false)
			}
		} else {
			try {
				const response = await Service.delete(`/project-tasks/${task_id}/tags/${tagId}`)
				sendToast('Etiket kaldırıldı.', true)
				refreshTask()
			} catch (error) {
				sendToast(error.response.data.message, false)
			}
		}
	}

	const handleSuccess = () => {
		setTabName('tags')
		fetchTags()
		refreshTask()
	}

	useEffect(() => {
		fetchTags()
	}, [selectedTag])

	return (
		<>
			<div
				ref={popoverRef}
				style={{ ...styles.popper, zIndex: 9999 }}
				{...attributes.popper}
				className={`${show ? '' : 'd-none'}`}
			>
				<Card style={{ minWidth: '300px', maxWidth: '300px' }}>
					<Card.Header>
						<div className="d-flex justify-content-between">
							{tabName !== 'tags' && (
								<Button type="light" className="btn-sm shadow-none" onClick={() => setTabName('tags')}>
									<FaIcon icon="arrow-left" />
								</Button>
							)}
							{tabName === 'tags' && <div>Etiketler</div>}
							{tabName === 'edit-tag' && <div>Etiketleri Düzenle</div>}
							{tabName === 'create-tag' && <div>Etiket Oluştur</div>}
							<Button type="light" className="btn-sm shadow-none" onClick={handleClose}>
								<FaIcon icon="times" />
							</Button>
						</div>
					</Card.Header>
					<Card.Body>
						{tabName === 'create-tag' && <TaskTagCard isNew={true} onSuccess={handleSuccess} />}
						{tabName === 'edit-tag' && <TaskTagCard tagId={selectedTag} isNew={false} onSuccess={handleSuccess} />}
						{tabName === 'tags' && (
							<>
								{tags?.map((tag) => (
									<div key={tag._id} className="mb-2 w-100 d-flex justify-content-between align-items-center gap-2">
										<div>
											<input
												type="checkbox"
												checked={task?.tags?.find((t) => t._id === tag._id)}
												className="form-check-input"
												onChange={(e) => handleTagChange(e, tag._id)}
											/>
										</div>
										<div className="d-flex w-100 gap-2">
											<div className="rounded w-100" style={{ backgroundColor: tag.hexColor }}>
												<div className="text-white text-start p-1 rounded ms-2">{tag.name}</div>
											</div>
											<Button
												className="px-2 py-1 shadow-none border-0"
												type="light"
												onClick={() => {
													setSelectedTag(tag._id)
													setTabName('edit-tag')
												}}
											>
												<FaIcon icon="pen" size="xs" className="text-dark" />
											</Button>
										</div>
									</div>
								))}
								<Button
									type="light"
									className="shadow-none w-100 p-1 mt-2"
									text="Yeni Etiket Oluştur"
									icon={null}
									onClick={() => setTabName('create-tag')}
								/>
							</>
						)}
					</Card.Body>
				</Card>
			</div>
		</>
	)
})

export default TaskTagPopper
