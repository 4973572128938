import { CloseButton, CreateButton } from 'components/fast-ui'
import { ButtonContainer, FaIcon } from 'components/ui'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { createFolder, setTreeNewFolderDigitalName } from 'stores/documentSlice'

const NewFolderModal = () => {
	const dispatch = useDispatch()
	const { newFolder } = useSelector((state) => state.document.tree)
	const selectedFolderID = useSelector((state) => state.document.tree.selectedFolder.content._id)

	const resetFolderName = () => {
		dispatch(setTreeNewFolderDigitalName(''))
	}

	return (
		<div className="modal fade" id="newFolderModal" tabIndex={-1} aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="newFolderModalTitle">
							<FaIcon icon="folder-plus" className="me-2" />
							Yeni Klasör
						</h5>
						<button
							onClick={resetFolderName}
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						/>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col mb-3">
								<label htmlFor="nameWithTitle" className="form-label">
									Klasör Adı
								</label>
								<input
									onChange={(e) => {
										dispatch(setTreeNewFolderDigitalName(e.target.value))
									}}
									value={newFolder.digitalName || ''}
									type="text"
									id="nameWithTitle"
									className="form-control"
									placeholder="Klasor adı"
								/>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<ButtonContainer>
							<CloseButton onClick={resetFolderName} data-bs-dismiss="modal" />
							<CreateButton
								onClick={() => {
									dispatch(createFolder(newFolder.digitalName, selectedFolderID))
								}}
								data-bs-dismiss="modal"
							/>
						</ButtonContainer>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NewFolderModal
