import { ExamineButton } from 'components/fast-ui'
import { FaIcon } from 'components/ui'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'

import ExpensesRequestsModal from './ExpensesRequestsListModal'

const ExpensesRequestsList = () => {
	const [showModal, setShowModal] = useState(false)

	const handleClose = () => {
		setShowModal(false)
	}

	return (
		<>
			<Card>
				<Card.Header>
					<Card.Title as="h5">Onayımı Bekleyen İzin Talepleri</Card.Title>
				</Card.Header>
				<Card.Body>
					<Row className="g-4">
						<Col md={4}>
							<Card>
								<Card.Header>
									<div className="d-flex align-items-start justify-content-between">
										<div>
											<Card.Title as="h5">
												<span className="me-2">Ahmet Yılmaz</span>
											</Card.Title>
										</div>

										<ExamineButton onClick={() => setShowModal(true)} />
									</div>
								</Card.Header>
								<Card.Body>
									<Card.Text>
										<div className="d-flex align-items-center justify-content-between">
											<span>
												<FaIcon icon="calendar" className="me-2" />
												{format(new Date(), 'dd.MM.yyyy')}
											</span>
										</div>
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Card.Body>
			</Card>

			<ExpensesRequestsModal show={showModal} onModalClose={handleClose} />
		</>
	)
}

export default ExpensesRequestsList
