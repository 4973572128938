import anonimProfilePhoto from 'assets/img/avatars/anonim-profile-photo.jpg'
import React from 'react'

import { departmentFilter, locationFilter, titleFilter } from './ColumnFilter'

const COLUMNS = [
	{
		Header: 'Personel',
		accessor: 'fullName',
		Cell: ({ row: { original } }) => {
			return (
				<div className="d-flex justify-content-start align-items-center">
					<div className="avatar-wrapper">
						<div className="avatar avatar-sm me-3">
							{original.profilePhotoUrl == null ? (
								<img src={anonimProfilePhoto} alt="Avatar" className="rounded-circle" />
							) : (
								<img src={`${original.profilePhotoUrl}`} alt="Avatar" className="rounded-circle" />
							)}
						</div>
					</div>
					<div className="d-flex flex-column">
						<a href="#" className="text-body text-truncate">
							<span className="fw-semibold">{original.fullName}</span>
						</a>
						{original.position && (
							<small className="text-muted">
								{original.position.title && `${original.position.title.name} - `}
								{original.position.location && `${original.position.location.name}`}
							</small>
						)}
					</div>
				</div>
			)
		}
	},
	{
		Header: 'Şube',
		accessor: 'position.location.name',
		Filter: locationFilter
	},
	{
		Header: 'Ünvan',
		accessor: 'position.title.name',
		Filter: titleFilter
	},
	{
		Header: 'Departman',
		accessor: 'position.department.name',
		Filter: departmentFilter
	}
]

export default COLUMNS
