import { FaIcon } from 'components/ui'
import React from 'react'
import { Nav } from 'react-bootstrap'

const TabButton = ({ className, icon, text, active = false, ...rest }) => {
	return (
		<Nav.Item>
			<Nav.Link className={`btn btn-tab ${active && 'active'} ${className && className}`} {...rest}>
				{icon && <FaIcon type={active ? 'solid' : 'regular'} icon={icon} className={text && 'me-2'} />}
				<span>{text}</span>
			</Nav.Link>
		</Nav.Item>
	)
}

export default TabButton
