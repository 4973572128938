import { create } from 'zustand'

const useNewEmployeeStore = create((set) => ({
	firstName: 'Yeni',
	lastName: 'Personel',
	setFirstName: (firstName) => set({ firstName }),
	setLastName: (lastName) => set({ lastName })
}))

export default useNewEmployeeStore
