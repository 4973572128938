import { CustomReactTable, TabButton, TabButtonContainer } from 'components/ui'
import { set } from 'date-fns'
import Service from 'global/Service.js'
import { sendToast } from 'global/sendToast.js'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Card, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

import COLUMNS from './Columns.js'

const VacationRequestesTable = () => {
	const params = useParams()
	const { tab_name, module_name, module_id, module_sub_id } = params

	const [vacations, setVacations] = useState([])

	const fetchVacations = async () => {
		try {
			const response = await Service.get('/vacations')
			setVacations(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchVacations()
	}, [params])

	const data = vacations
	const columns = COLUMNS

	const [filterData, setFilterData] = useState('')

	const filteredData = filterData ? data.filter((item) => item.status === filterData) : data

	useEffect(() => {
		setFilterData('')
	}, [params])

	return (
		<Col lg={12} md={12} sm={12} xs={12}>
			<Card>
				<Card.Header>
					<Card.Title as="h5" className="mb-0">
						İzin Talepleri
					</Card.Title>
				</Card.Header>
				<Card.Body>
					<TabButtonContainer className="mb-3">
						<TabButton icon="list" text="Tümü" onClick={() => setFilterData('')} active={filterData === ''} />
						<TabButton icon="clock" onClick={() => setFilterData('pending')} active={filterData === 'pending'} />
						<TabButton icon="check" onClick={() => setFilterData('approved')} active={filterData === 'approved'} />
						<TabButton icon="xmark" onClick={() => setFilterData('rejected')} active={filterData === 'rejected'} />
					</TabButtonContainer>
					<CustomReactTable
						data={filteredData}
						columns={columns}
						defaultSortBy="id"
						defaultSortDesc={true}
						pageSizeCount={10}
					/>
				</Card.Body>
			</Card>
		</Col>
	)
}

export default VacationRequestesTable
