import { CreateButton } from 'components/fast-ui'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

import VacationRuleModal from './components/VacationRuleModal'
import VacationRuleEdit from './components/vacation-rule-edit'
import VacationRulesTable from './components/vacation-rules-table'

const VacationRules = () => {
	const params = useParams()
	const { tab_name, module_name } = params

	const [tab, setTab] = useState('list')

	useEffect(() => {
		if (tab_name === 'rules') {
			if (module_name) {
				if (module_name === 'add') {
					setTab('list')
				} else {
					setTab('edit')
				}
			} else {
				setTab('list')
			}
		}
	}, [params])

	const navigate = useNavigate()

	const handleNewRule = () => {
		navigate('/vacations/rules/add')
	}

	return (
		<>
			{tab === 'list' && (
				<>
					<Row className="mb-4">
						<VacationRulesTable />
					</Row>
				</>
			)}
			{tab === 'edit' && <VacationRuleEdit />}

			<VacationRuleModal />
		</>
	)
}

export default VacationRules
