import { SaveButton } from 'components/fast-ui'
import { CustomReactSelect } from 'components/ui'
import { allCountries } from 'constants/Countries'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import Flatpickr from 'react-flatpickr'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchEmployee } from 'stores/ikEmployeeEditSlice'

import { ErrorMessage } from '@hookform/error-message'

const maritalStatuses = [
	{ value: 'single', label: 'Bekar' },
	{ value: 'unspecified', label: 'Belirtilmemiş' },
	{ value: 'divorced', label: 'Boşanmış' },
	{ value: 'married', label: 'Evli' }
]

const spouseWorkStatuses = [
	{ value: 'working', label: 'Çalışıyor' },
	{ value: 'notworking', label: 'Çalışmıyor' }
]

const genders = [
	{ value: 'unspecified', label: 'Belirtilmemiş' },
	{ value: 'other', label: 'Diğer' },
	{ value: 'male', label: 'Erkek' },
	{ value: 'female', label: 'Kadın' }
]

const degreeOfDisabilities = [
	{ value: 'none', label: 'Yok' },
	{ value: '1', label: '1. derece' },
	{ value: '2', label: '2. derece' },
	{ value: '3', label: '3. derece' }
]

const numberOfChildrens = [
	{ value: 0, label: '0' },
	{ value: 1, label: '1' },
	{ value: 2, label: '2' },
	{ value: 3, label: '3' },
	{ value: 4, label: '4' },
	{ value: 5, label: '5' },
	{ value: 6, label: '6' },
	{ value: 7, label: '7' },
	{ value: 8, label: '8' },
	{ value: 9, label: '9' },
	{ value: 10, label: '10' }
]

const educationStatuses = [
	{ value: 'graduate', label: 'Mezun' },
	{ value: 'student', label: 'Öğrenci' }
]

const bloodTypes = [
	{ value: '0+', label: '0+' },
	{ value: '0-', label: '0-' },
	{ value: 'ab+', label: 'AB+' },
	{ value: 'ab-', label: 'AB-' },
	{ value: 'a+', label: 'A+' },
	{ value: 'a-', label: 'A-' },
	{ value: 'b+', label: 'B+' },
	{ value: 'b-', label: 'B-' }
]

const educationLevels = [
	{ value: 'primarySchool', label: 'İlk Okul' },
	{ value: 'middleSchool', label: 'Ortaokul' },
	{ value: 'highSchool', label: 'Lise' },
	{ value: 'associateDegree', label: 'Ön lisans' },
	{ value: 'licence', label: 'Lisans' },
	{ value: 'degree', label: 'Yüksek Lisans' },
	{ value: 'doctorate', label: 'Doktora' },
	{ value: 'no', label: 'Yok' }
]

const PersonalTab = () => {
	const params = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { employee_id, module_name, module_id } = params
	const { employee } = useSelector((state) => state.ikEmployeeEdit)
	const { workingStatus } = employee

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		formState,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	useEffect(() => {
		if (employee._id !== undefined) {
			reset(employee)
		}
	}, [employee])

	const handleSave = async (data) => {
		const body = data
		try {
			const patchedEmployee = await Service.patch(`/employees/${employee_id}`, body)
			dispatch(fetchEmployee(employee_id))
			sendToast('Personel kaydedildi.')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const maritalStatus = watch('maritalStatus')

	return (
		<Card>
			<Card.Header>
				<Card.Title>Kişisel Bilgiler</Card.Title>
			</Card.Header>
			<Card.Body>
				<Form>
					<Row>
						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Doğum Tarihi</Form.Label>
							<Controller
								name="birthDate"
								control={control}
								render={({ field }) => (
									<Flatpickr
										className="form-control"
										value={field.value}
										onChange={field.onChange}
										ref={field.ref}
										disabled={!workingStatus}
										options={{ dateFormat: 'd-m-Y', locale: { ...Turkish } }}
									/>
								)}
							/>
							<ErrorMessage
								errors={errors}
								name={'birthDate'}
								render={({ message }) => <div className="invalid-feedback">{message}</div>}
							/>
						</Col>

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Kimlik Numarası</Form.Label>
							<Form.Control
								disabled={!workingStatus}
								{...register('identity', {
									pattern: { value: /^[1-9]{1}[0-9]{9}[02468]{1}$/i, message: 'geçersiz kimlik numarası' }
								})}
							/>
							<ErrorMessage
								errors={errors}
								name={'identity'}
								render={({ message }) => <div className="invalid-feedback">{message}</div>}
							/>
						</Col>

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Medeni Hal</Form.Label>
							<Controller
								name="maritalStatus"
								control={control}
								render={({ field }) => (
									<CustomReactSelect
										value={maritalStatuses.filter((obj) => obj.value === field.value)}
										onChange={(d) => field.onChange(d?.value)}
										ref={field.ref}
										placeholder=""
										isDisabled={!workingStatus}
										options={maritalStatuses}
									/>
								)}
							/>
						</Col>

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Cinsiyet</Form.Label>
							<Controller
								name="gender"
								control={control}
								render={({ field }) => (
									<CustomReactSelect
										value={genders.filter((obj) => obj.value === field.value)}
										onChange={(d) => field.onChange(d?.value)}
										ref={field.ref}
										placeholder=""
										isDisabled={!workingStatus}
										options={genders}
									/>
								)}
							/>
						</Col>

						{maritalStatus === 'married' && (
							<Col xs={12} sm={6} className="mb-3">
								<Form.Label>Eş çalışma durumu</Form.Label>
								<Controller
									name="spouseWorkStatus"
									control={control}
									render={({ field }) => (
										<CustomReactSelect
											value={spouseWorkStatuses.filter((obj) => obj.value === field.value)}
											onChange={(d) => field.onChange(d?.value)}
											ref={field.ref}
											placeholder=""
											isDisabled={!workingStatus}
											options={spouseWorkStatuses}
										/>
									)}
								/>
							</Col>
						)}

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Engel Derecesi</Form.Label>
							<Controller
								name="disabilityDegree"
								control={control}
								render={({ field }) => (
									<CustomReactSelect
										value={degreeOfDisabilities.filter((obj) => obj.value === field.value)}
										onChange={(d) => field.onChange(d?.value)}
										ref={field.ref}
										placeholder=""
										isDisabled={!workingStatus}
										options={degreeOfDisabilities}
									/>
								)}
							/>
						</Col>

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Uyruğu</Form.Label>
							<Controller
								name="nationality"
								control={control}
								render={({ field }) => (
									<CustomReactSelect
										value={allCountries.filter((obj) => obj.code === field.value)}
										onChange={(d) => field.onChange(d?.code)}
										ref={field.ref}
										placeholder=""
										isDisabled={!workingStatus}
										options={allCountries}
										getOptionValue={(option) => option.code}
										getOptionLabel={(option) => option.name}
									/>
								)}
							/>
						</Col>

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Çocuk Sayısı</Form.Label>
							<Controller
								name="numberOfChildren"
								control={control}
								render={({ field }) => (
									<CustomReactSelect
										value={numberOfChildrens.filter((obj) => obj.value === field.value)}
										onChange={(d) => field.onChange(d?.value)}
										ref={field.ref}
										placeholder=""
										isDisabled={!workingStatus}
										options={numberOfChildrens}
									/>
								)}
							/>
						</Col>

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Kan Grubu</Form.Label>
							<Controller
								name="bloodType"
								control={control}
								render={({ field }) => (
									<CustomReactSelect
										value={bloodTypes.filter((obj) => obj.value === field.value)}
										onChange={(d) => field.onChange(d?.value)}
										ref={field.ref}
										placeholder=""
										isDisabled={!workingStatus}
										options={bloodTypes}
									/>
								)}
							/>
						</Col>

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Eğitim Durumu</Form.Label>
							<Controller
								name="educationalStatus"
								control={control}
								render={({ field }) => (
									<CustomReactSelect
										value={educationStatuses.filter((obj) => obj.value === field.value)}
										onChange={(d) => field.onChange(d?.value)}
										ref={field.ref}
										placeholder=""
										isDisabled={!workingStatus}
										options={educationStatuses}
									/>
								)}
							/>
						</Col>

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Tamamlanan En Yüksek Eğitim Seviyesi</Form.Label>
							<Controller
								name="educationLevel"
								control={control}
								render={({ field }) => (
									<CustomReactSelect
										value={educationLevels.filter((obj) => obj.value === field.value)}
										onChange={(d) => field.onChange(d?.value)}
										ref={field.ref}
										placeholder=""
										isDisabled={!workingStatus}
										options={educationLevels}
									/>
								)}
							/>
						</Col>

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Son Tamamlanan Eğitim Kurumu</Form.Label>
							<Form.Control disabled={!workingStatus} {...register('lastEducation')} />
						</Col>
					</Row>
				</Form>
			</Card.Body>
			<Card.Footer>{!workingStatus ? null : <SaveButton onClick={handleSubmit((d) => handleSave(d))} />}</Card.Footer>
		</Card>
	)
}

export default PersonalTab
