import React from 'react'

import MyVacationTable from './components/MyVacationTable/index.js'
import VacationCancelModal from './components/VacationCancelModal.js'
import VacationShowModal from './components/VacationShowModal.js'

const MyVacations = () => {
	return (
		<>
			<MyVacationTable />
			<VacationShowModal />
			<VacationCancelModal />
		</>
	)
}

export default MyVacations
