import Service from 'global/Service'
import { formatDate } from 'global/functions/formatDate'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'

export default function Birthdays() {
	const [employees, setEmployees] = useState([])

	const fetchEmployees = async () => {
		try {
			const response = await Service.get('/employees')
			setEmployees(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const getUpcomingBirthdays = () => {
		const today = new Date()

		const upcomingBirthdays = employees
			.map((employee) => {
				const birthDate = new Date(employee.birthDate)
				birthDate.setFullYear(today.getFullYear())
				if (birthDate < today) {
					birthDate.setFullYear(today.getFullYear() + 1)
				}
				const daysUntilBirthday = Math.floor((birthDate - today) / (1000 * 60 * 60 * 24))
				return { ...employee, birthDate, daysUntilBirthday }
			})
			.sort((a, b) => a.birthDate - b.birthDate)
		return upcomingBirthdays
	}

	const renderUpcomingBirthdays = () => {
		const upcomingBirthdays = getUpcomingBirthdays()
		const firstThreeBirthdays = upcomingBirthdays.slice(0, 5)

		return firstThreeBirthdays.map((employee) => (
			<li className="d-flex mb-3" key={employee.id}>
				{employee.daysUntilBirthday === 0 ? (
					<span className="text-primary me-2">
						<i className="bx bx-cake bx-sm" />
					</span>
				) : (
					<span className="text-secondary me-2">
						<i className="bx bx-cake bx-sm" />
					</span>
				)}
				<div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
					<div className="me-2">
						<h6 className="mb-0 lh-1">{employee.fullName}</h6>
						<small className="text-muted">{formatDate(employee.birthDate, false)}</small>
					</div>
					<div className="item-progress">
						{employee.daysUntilBirthday === 0
							? 'Bugün'
							: employee.daysUntilBirthday === 1
							? 'Yarın'
							: `${employee.daysUntilBirthday} gün sonra`}
					</div>
				</div>
			</li>
		))
	}

	useEffect(() => {
		fetchEmployees()
	}, [])

	return (
		<div
			className="card"
			style={{
				height: 300
			}}
		>
			<div className="card-header d-flex align-items-start justify-content-between">
				<div className="card-title mb-0">
					<h5 className="m-0 me-2">Doğum Günleri</h5>
					<small className="card-subtitle text-muted">Yaklaşan doğum günleri</small>
				</div>
			</div>
			<div
				className="card-body"
				style={{
					overflow: 'auto'
				}}
			>
				<div id="salesChart" />
				<ul className="p-0 m-0">{renderUpcomingBirthdays()}</ul>
			</div>
		</div>
	)
}
