import { CloseButton, SendButton } from 'components/fast-ui'
import { ButtonContainer, FaIcon } from 'components/ui'
import { AvatarGroup } from 'components/ui'
import Service from 'global/Service'
import { formatDate } from 'global/functions/formatDate'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedRatingID } from 'stores/ik/competence/ikCompetenceRatingSlice'

const MyCompetenceRatingAnwserCard = ({
	_id: competenceRatingId,
	employee,
	competencies,
	raters,
	createDate,
	ratings,
	averageScore,
	isCompleted
}) => {
	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		formState,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	//array field
	const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
		control,
		name: 'answers'
	})

	const handleAnswerClick = (competence, score) => {
		const prevAnswer = fields.find((answer) => answer.competence._id === competence._id)
		const newAnswer = { competence: competence, score: score }
		if (prevAnswer) {
			const prevAnswerIndex = fields.indexOf(prevAnswer)
			remove(prevAnswerIndex)
		}
		append(newAnswer)
	}

	const handleSend = async (data) => {
		const body = {
			competenceRatingId: competenceRatingId,
			answers: data.answers
		}

		try {
			const response = await Service.post('/competence-ratings/answer', body)
			const { data } = response
			sendToast('Yetkinlik değerlendirmeniz başarıyla kaydedildi.')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<Card className="h-100">
			<Card.Header className="border-bottom">
				<Card.Title as="h5" className="mb-0">
					Değerlendirmeniz gereken yetkinlikler
				</Card.Title>
			</Card.Header>

			<Card.Body>
				<Row className="g-3 mt-3">
					{competencies.map((competence, index) => (
						<Col md={6}>
							<Card>
								<Card.Body>
									<div className="d-flex align-items-center justify-content-between">
										<div>
											<div className="d-flex flex-wrap justify-content-between gap-2 mb-2">
												<h5 className="mb-0">{competence.name}</h5>
												<span className="badge bg-label-info">
													<FaIcon icon="flag" className="me-2" />
													{competence.type.name}
												</span>
											</div>
											<small className="text-muted">{competence.description}</small>
										</div>
									</div>
								</Card.Body>
								<Card.Footer className="border-top">
									<div className="d-flex align-items-center justify-content-between">
										<div className="d-flex flex-wrap gap-1">
											{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((score) => (
												<Button
													key={score}
													variant={
														fields.find((answer) => answer.competence._id === competence._id && answer.score === score)
															? 'primary'
															: 'outline-primary'
													}
													size="sm"
													onClick={() => handleAnswerClick(competence, score)}
												>
													{score}
												</Button>
											))}
										</div>
									</div>
								</Card.Footer>
							</Card>
						</Col>
					))}
				</Row>
			</Card.Body>
			<Card.Footer className="border-top">
				<ButtonContainer>
					<SendButton onClick={handleSubmit((d) => handleSend(d))} disabled={fields.length !== competencies.length} />
				</ButtonContainer>
			</Card.Footer>
		</Card>
	)
}

export default MyCompetenceRatingAnwserCard
