import { TabButton, TabButtonContainer } from 'components/ui'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

import MySettings from './tabs/settings'
import MyVacations from './tabs/vacations'

const Profile = () => {
	const params = useParams()
	const navigate = useNavigate()

	const [tabName, setTabName] = useState('profile')

	const { module_name } = params

	useEffect(() => {
		if (module_name) {
			setTabName(module_name)
		} else {
			navigate('/profile/profile')
		}
	}, [params])

	return (
		<Container fluid="xxl" className="flex-grow-1 container-p-y">
			<Row>
				<Col md={12} className="mb-4">
					<TabButtonContainer>
						<TabButton
							icon="user-circle"
							text="Bilgilerim"
							active={tabName === 'profile'}
							onClick={() => navigate('/profile/profile')}
						/>
						<TabButton
							icon="calendar"
							text="İzinlerim"
							active={tabName === 'vacations'}
							onClick={() => navigate('/profile/vacations')}
						/>
						<TabButton
							icon="cog"
							text="Ayarlar"
							active={tabName === 'settings'}
							onClick={() => navigate('/profile/settings')}
						/>
					</TabButtonContainer>
				</Col>
				<Col md={12}>
					{tabName === 'profile' && <div>Profile</div>}
					{tabName === 'vacations' && <MyVacations />}
					{tabName === 'settings' && <MySettings />}
				</Col>
			</Row>
		</Container>
	)
}

export default Profile
