import { ContinueButton, DangerApproveButton } from 'components/fast-ui'
import Service from 'global/Service'
import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import useAuthStore from 'stores/authStore'

import TwoFactorDisableModal from './components/TwoFactorDisableModal'
import TwoFactorEnableModal from './components/TwoFactorEnableModal'

const TwoFactorSettings = () => {
	const user = useAuthStore((state) => state.user)
	const fetchUser = useAuthStore((state) => state.fetchUser)

	const [showTwoFactorEnableModal, setShowTwoFactorEnableModal] = useState(false)
	const [showTwoFactorDisableModal, setShowTwoFactorDisableModal] = useState(false)

	return (
		<>
			<Card>
				<Card.Header>
					<Card.Title as="h5" className="mb-0">
						İki Adımlı Doğrulama (2FA)
					</Card.Title>
				</Card.Header>
				<Card.Body>
					<p>
						İki Adımlı Doğrulama (2FA) hesabınızı korumak için ekstra bir güvenlik katmanıdır. 2FA etkinleştirildiğinde,
						giriş yapmak için kullanıcı adı ve şifrenize ek olarak, telefonunuzda veya tabletinizde bir uygulama
						kullanarak oluşturulan bir kod girmeniz gerekir.
					</p>
					{user.twoFactor && user.twoFactor.isActive ? (
						<DangerApproveButton
							text="2FA'yı Devre Dışı Bırak"
							icon="unlock"
							onClick={() => setShowTwoFactorDisableModal(true)}
						/>
					) : (
						<ContinueButton text="2FA'yı Etkinleştir" icon="lock" onClick={() => setShowTwoFactorEnableModal(true)} />
					)}
				</Card.Body>
			</Card>

			{showTwoFactorEnableModal && (
				<TwoFactorEnableModal
					show={showTwoFactorEnableModal}
					onHide={() => setShowTwoFactorEnableModal(false)}
					onEnabled={fetchUser}
				/>
			)}

			{showTwoFactorDisableModal && (
				<TwoFactorDisableModal
					show={showTwoFactorDisableModal}
					onHide={() => setShowTwoFactorDisableModal(false)}
					onDisabled={fetchUser}
				/>
			)}
		</>
	)
}

export default TwoFactorSettings
