import anonimProfilePhoto from 'assets/img/avatars/anonim-profile-photo.jpg'
import { Button, CustomReactSelect, FaIcon } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useEffect } from 'react'
import { forwardRef } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { Card } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { usePopper } from 'react-popper'
import { useParams } from 'react-router-dom'
import useProjectTaskStore from 'stores/projectTaskStore'

const TaskMemberPopper = forwardRef(({ buttonRef, show, onClose }) => {
	const params = useParams()
	const { workspace_id, task_id } = params

	const task = useProjectTaskStore((state) => state.projectTask)
	const refreshTask = useProjectTaskStore((state) => state.refreshProjectTask)

	const [members, setMembers] = useState([])

	const popoverRef = useRef(null)

	const { styles, attributes } = usePopper(buttonRef.current, popoverRef.current, {
		placement: 'bottom-start',
		strategy: 'fixed',
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, 8]
				}
			}
		]
	})

	const handleClose = () => {
		onClose && onClose()
	}

	const fetchMembers = async () => {
		try {
			const response = await Service.get(`/workspaces/${workspace_id}/members`)
			setMembers(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const changeMembers = async (data) => {
		const body = {
			members: data
		}
		try {
			const response = await Service.patch(`/project-tasks/${task_id}/change-members`, body)
			refreshTask()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchMembers()
	}, [])

	const {
		control,
		formState: { errors }
	} = useForm()

	return (
		<div
			ref={popoverRef}
			style={{ ...styles.popper, zIndex: 9999, minWidth: '300px', maxWidth: '300px' }}
			{...attributes.popper}
			className={`${show ? '' : 'd-none'}`}
		>
			<Card>
				<Card.Header>
					<div className="d-flex justify-content-between">
						<div>Üyeler</div>
						<div>
							<Button type="light" className="btn-sm shadow-none" onClick={handleClose}>
								<FaIcon icon="times" />
							</Button>
						</div>
					</div>
				</Card.Header>
				<Card.Body>
					<Controller
						name="members"
						control={control}
						render={({ field }) => (
							<>
								<CustomReactSelect
									isMulti
									options={members}
									value={task?.members}
									onChange={(data) => {
										changeMembers(data)
									}}
									placeholder="Üye ekleyin"
									getOptionLabel={(option) => (
										<div>
											<img
												src={option.profilePhotoUrl || anonimProfilePhoto}
												alt="avatar"
												className="rounded-circle me-2"
												width="24"
												height="24"
											/>
											<span className="me-1">{option.fullName}</span>
											<span>
												({option?.position?.department} - {option?.position?.title})
											</span>
										</div>
									)}
									getOptionValue={(option) => option._id}
								/>
							</>
						)}
					/>
				</Card.Body>
			</Card>
		</div>
	)
})

export default TaskMemberPopper
