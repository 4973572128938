import { format } from 'date-fns'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { setEmployeeEditingPosition_id } from 'stores/ikEmployeeEditSlice'

const employmentTypes = [
	{ value: 'full_time', label: 'Tam Zamanlı' },
	{ value: 'part_time', label: 'Yarı Zamanlı' },
	{ value: 'temporary', label: 'Geçici' },
	{ value: 'contract', label: 'Kontratlı' },
	{ value: 'freelance', label: 'Serbest Çalışan' },
	{ value: 'intern', label: 'Stajyer' },
	{ value: 'mobile', label: 'Gezici' }
]

const COLUMNS = [
	{
		Header: 'İşlem',
		Cell: (cell) => {
			const params = useParams()
			return (
				<div className="d-inline-block">
					<Link to={`/employees/${params.employee_id}/career/positions/${cell.row.original._id}`}>
						<button className="btn btn-sm btn-icon hide-arrow">
							<i className="bx bxs-edit" />
						</button>
					</Link>
				</div>
			)
		}
	},
	{
		Header: 'Başlangıç',
		accessor: 'startDate',
		Cell: (cell) => {
			return (
				<>
					<div className="d-flex align-items-center">
						{cell.row.original.startDate !== undefined
							? format(new Date(cell.row.original.startDate), 'dd.MM.yyyy')
							: null}
						{cell.row.original.isDefault && <span className="badge bg-primary ms-2">Varsayılan</span>}
					</div>
				</>
			)
		}
	},
	{
		Header: 'Bitiş',
		accessor: 'finishDate',
		Cell: (cell) => {
			return (
				<>
					{cell.row.original.finishDate !== undefined
						? format(new Date(cell.row.original.finishDate), 'dd.MM.yyyy')
						: null}
				</>
			)
		}
	},
	{
		Header: 'Çalışma Şekli',
		accessor: 'employmentType',
		Cell: (cell) => {
			return (
				<>
					{employmentTypes.map((item) => {
						if (item.value === cell.row.original.employmentType) {
							return item.label
						}
					})}
				</>
			)
		}
	},
	{
		Header: 'Yöneticisi',
		accessor: 'manager',
		Cell: (cell) => {
			return (
				<>
					{cell.row.original.manager !== undefined
						? cell.row.original.manager.firstName + ' ' + cell.row.original.manager.lastName
						: null}
				</>
			)
		}
	}
]

export default COLUMNS
