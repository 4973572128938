import React from 'react'
import { Col, Row } from 'react-bootstrap'

import EmployeeDismissalChart from '../StandartTab/components/EmployeeDismissalChart'
import NumberOfLeavesByAgeGroup from './components/NumberOfLeavesByAgeGroup'
import ReasonsForDismissalChart from './components/ReasonsForDismissalChart'

const DismissalTab = () => {
	return (
		<Row>
			<Col md={4} className="mb-4">
				<NumberOfLeavesByAgeGroup />
			</Col>
			<Col md={8} className="mb-4">
				<ReasonsForDismissalChart />
			</Col>
		</Row>
	)
}

export default DismissalTab
