import { AddButton } from 'components/fast-ui'
import { Button, FaIcon } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { forwardRef } from 'react'
import { useRef } from 'react'
import { Card, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { usePopper } from 'react-popper'
import { useParams } from 'react-router-dom'
import useProjectTaskStore from 'stores/projectTaskStore'

const TaskListPopper = forwardRef(({ buttonRef, show, onClose }) => {
	const popoverRef = useRef(null)
	const { styles, attributes } = usePopper(buttonRef.current, popoverRef.current, {
		placement: 'bottom-start',
		strategy: 'fixed',
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, 8]
				}
			}
		]
	})

	const handleClose = () => {
		onClose && onClose()
	}

	const task = useProjectTaskStore((state) => state.projectTask)
	const refreshTask = useProjectTaskStore((state) => state.refreshProjectTask)

	const params = useParams()
	const { workspace_id, project_id, task_id } = params

	const handleAdd = async () => {
		const body = {
			title: getValues('title')
		}

		try {
			const response = await Service.post(`/project-tasks/${task_id}/check-lists`, body)
			sendToast('Kontrol listesi eklendi.', true)
			refreshTask()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		formState,
		setValue,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	return (
		<span>
			<div
				ref={popoverRef}
				style={{ ...styles.popper, zIndex: 9999 }}
				{...attributes.popper}
				className={`${show ? '' : 'd-none'}`}
			>
				<Card>
					<Card.Header>
						<div className="d-flex justify-content-between">
							<div>Liste Ekle</div>
							<div>
								<Button type="light" className="btn-sm shadow-none" onClick={handleClose}>
									<FaIcon icon="times" />
								</Button>
							</div>
						</div>
					</Card.Header>
					<Card.Body>
						<Form>
							<Form.Group>
								<Form.Label>Liste Adı</Form.Label>
								<Form.Control type="text" name="title" {...register('title')} className="mb-2" />
								<AddButton icon={null} type="primary" onClick={handleSubmit(handleAdd)} />
							</Form.Group>
						</Form>
					</Card.Body>
				</Card>
			</div>
		</span>
	)
})

export default TaskListPopper
