import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { Tree, TreeNode } from 'react-organizational-chart'
import { useSelector } from 'react-redux'

import SchemeContainer from './components/SchemeContainer'

const SchemeBody = () => {
	const { selectedOrganization } = useSelector((state) => state.ikOrganization)

	return (
		<Card>
			<Card.Header>
				<h5>Şema</h5>
			</Card.Header>
			<Card.Body>
				<Tree
					label={
						<Button variant="primary" size="lg">
							<b>{selectedOrganization?.title}</b>
						</Button>
					}
				>
					<SchemeContainer data={selectedOrganization.tempItems} />
				</Tree>
			</Card.Body>
		</Card>
	)
}

export default SchemeBody
