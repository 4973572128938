import { SaveButton } from 'components/fast-ui'
import { ProjectButton } from 'components/fast-ui/Buttons'
import { Button, ButtonContainer, CustomDatePicker, FaIcon } from 'components/ui'
import Service from 'global/Service'
import React from 'react'
import { useRef } from 'react'
import { forwardRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Card, Form, Stack } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { Controller, useForm } from 'react-hook-form'
import { usePopper } from 'react-popper'
import { useNavigate, useParams } from 'react-router-dom'

const TaskDatePopper = forwardRef(({ buttonRef, show, onClose }) => {
	const popoverRef = useRef(null)
	const { styles, attributes } = usePopper(buttonRef.current, popoverRef.current, {
		placement: 'bottom-start',
		strategy: 'fixed',
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, 8]
				}
			}
		]
	})

	const handleClose = () => {
		onClose && onClose()
	}

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		formState,
		setValue,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	const navigate = useNavigate()
	const params = useParams()

	const { workspace_id, project_id, task_id } = params

	const onSubmit = async (data) => {
		const body = {
			startDate: data.startDate,
			endDate: data.endDate
		}

		const response = await Service.patch(`/project-tasks/${task_id}/date`, body)
		navigate(`/projects/${workspace_id}/${project_id}/${task_id}`)
	}

	return (
		<>
			<div
				ref={popoverRef}
				style={{ ...styles.popper, zIndex: 9999 }}
				{...attributes.popper}
				className={`${show ? '' : 'd-none'}`}
			>
				<Card>
					<Card.Header>
						<div className="d-flex justify-content-between">
							<div>Tarihler</div>
							<div>
								<Button type="light" className="btn-sm shadow-none" onClick={handleClose}>
									<FaIcon icon="times" />
								</Button>
							</div>
						</div>
					</Card.Header>
					<Card.Body>
						<Stack className="mb-3">
							<Form.Label>Başlangıç Tarihi</Form.Label>
							<Controller
								name="startDate"
								control={control}
								render={({ field }) => (
									<CustomDatePicker
										{...field}
										selected={field.value}
										onChange={(date) => field.onChange(date)}
										rules={{
											required: 'Bu alan zorunludur.'
										}}
										value={field.value}
									/>
								)}
							/>
							<Form.Label className="mt-2">Bitiş Tarihi</Form.Label>
							<Controller
								name="endDate"
								control={control}
								render={({ field }) => (
									<CustomDatePicker
										{...field}
										selected={field.value}
										onChange={(date) => field.onChange(date)}
										rules={{
											required: 'Bu alan zorunludur.'
										}}
										value={field.value}
									/>
								)}
							/>
						</Stack>
						<ButtonContainer>
							<SaveButton className="w-100" onClick={handleSubmit(onSubmit)} icon={null} />
							<Button
								type="light"
								className="w-100 shadow-none"
								onClick={() => {
									reset()
								}}
							>
								Kaldır
							</Button>
						</ButtonContainer>
					</Card.Body>
				</Card>
			</div>
		</>
	)
})

export default TaskDatePopper
