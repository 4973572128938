import { CreateButton } from 'components/fast-ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

import ApprovalCard from './components/ApprovalCard'
import ApprovalModal from './components/ApprovalModal'

const ApprovalProcesses = () => {
	const navigate = useNavigate()
	const params = useParams()

	const { tab_name, item_id } = params

	const handleCreateButton = () => {
		navigate(`/settings/approval-processes/add`)
	}

	const [approvalProcesses, setApprovalProcesses] = useState([])
	const [filteredApprovalProcesses, setFilteredApprovalProcesses] = useState([])
	const [search, setSearch] = useState('')

	const fetchApprovalProcesses = async () => {
		try {
			const response = await Service.get('/approval-processes')
			setApprovalProcesses(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleSearch = (e) => {
		setSearch(e.target.value)
	}

	useEffect(() => {
		const filtered = approvalProcesses.filter((item) => {
			return item.name.toLowerCase().includes(search.toLowerCase())
		})
		setFilteredApprovalProcesses(filtered)
	}, [search, approvalProcesses])

	useEffect(() => {
		if (tab_name) {
			fetchApprovalProcesses()
		}
	}, [params])

	return (
		<Row>
			<Row className="mb-4">
				<Col md={6}>
					<div className="d-flex align-items-center justify-content-start">
						<div className="me-2">
							<input type="search" onChange={handleSearch} className="form-control" placeholder="Ara" />
						</div>
						<div>
							<CreateButton onClick={handleCreateButton} />
						</div>
					</div>
				</Col>
			</Row>
			{/* 			<Row>
				<Col md={12}>
					<Card>
						<Card.Header>
							<Card.Title as="h5">
								Varsayılan Onay Süreci
								<FaIcon icon="check-circle" className="ms-2" />
							</Card.Title>
						</Card.Header>
						<Card.Body>
							<div className="d-flex align-items-center">
								<ApprovalStepItem icon="user" name="Çalışan" />
								<FaIcon icon="arrow-right" className="ms-3 me-3" />
								<ApprovalStepItem imgSrc="https://picsum.photos/48" name="Çalışan" />
								<FaIcon icon="arrow-right" className="ms-3 me-3" />
								<ApprovalStepItem icon="check-circle" name="Onaylandı" />
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row> */}
			<Row>
				{filteredApprovalProcesses.map((item, index) => (
					<Col md={12} className="mb-4" key={index}>
						<ApprovalCard data={item} />
					</Col>
				))}
			</Row>
			{item_id && <ApprovalModal />}
		</Row>
	)
}

export default ApprovalProcesses
