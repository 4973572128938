import 'react-toastify/dist/ReactToastify.css'

import { default as lightLogo } from 'assets/svg/logos/meno-light.svg'
import { Loader } from 'components/ui'
import AuthService from 'global/AuthService'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import { useEffect, useState } from 'react'
import { Button, Card, Container, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import useOrganizationStore from 'stores/organizationStore'

const ResetPassword = () => {
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(false)
	const [is2FAActive, setIs2FAActive] = useState(false)
	const [searchParams] = useSearchParams()
	const token = searchParams.get('token')

	const organizationId = useOrganizationStore((state) => state.organizationId)

	const check2FA = async () => {
		const body = {
			org_id: organizationId
		}

		try {
			const response = await AuthService.post(`/auth/reset-password/${token}/check-2fa`, body)

			if (response.data.twoFactorIsActive) {
				setIs2FAActive(true)
			} else {
				setIs2FAActive(false)
			}
			setIsLoading(false)
		} catch (error) {
			navigate('/auth/login')

			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		check2FA()
	}, [])

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState,
		watch,
		formState: { errors, isValid }
	} = useForm({ mode: 'onChange' })

	const handleSend = async (data) => {
		const body = {
			password: data.password,
			org_id: organizationId,
			otp_attempt: data.otp_attempt ? data.otp_attempt.replace(/\s/g, '') : null
		}
		try {
			const response = await Service.post(`/auth/reset-password/${token}`, body)
			sendToast('Şifreniz başarıyla sıfırlandı.')
			navigate('/auth/login')
		} catch (error) {
			sendToast(error.response.data.message, false)
			console.error(error)
		}
	}

	const validatePassword = (value) => {
		const password = getValues('password')
		return password === value || 'Şifreler eşleşmiyor'
	}

	if (isLoading) {
		return (
			<div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
				<Loader />
			</div>
		)
	}

	return (
		<div className="authentication-wrapper authentication-basic container-p-y">
			<div className="authentication-inner py-4">
				<Card style={{ padding: '40px 30px' }}>
					<Card.Body>
						<Card.Img className="app-brand" variant="top" src={lightLogo} height={30} />
						<Form onSubmit={handleSubmit(handleSend)}>
							<Form.Group className="mb-3" controlId="formBasicEmail">
								<Form.Label>Şifre</Form.Label>
								<Form.Control
									type="password"
									placeholder="Şifre"
									{...register('password', {
										required: 'Bu alan gerekli.',
										minLength: {
											value: 8,
											message: 'Şifre en az 8 karakter olmalıdır'
										},
										pattern: {
											value: /^(?=.*[A-Z])(?=.*[0-9!@#$%^&*])(?=.*[a-z]).{8,}$/,
											message: 'Şifre en az bir büyük harf, bir sayı veya sembol içermelidir'
										}
									})}
								/>
								{errors.password && <Form.Text className="text-danger">{errors.password.message}</Form.Text>}
							</Form.Group>
							<Form.Group className="mb-3" controlId="formBasicEmail">
								<Form.Label>Şifre Tekrar</Form.Label>
								<Form.Control
									type="password"
									placeholder="Şifre Tekrar"
									{...register('passwordAgain', {
										required: 'Bu alan gerekli.',
										validate: validatePassword || 'Şifreler eşleşmiyor'
									})}
								/>
								{errors.passwordAgain && <Form.Text className="text-danger">{errors.passwordAgain.message}</Form.Text>}
							</Form.Group>
							{is2FAActive && (
								<Form.Group className="mb-3" controlId="formBasicEmail">
									<Form.Label>2FA Kodu</Form.Label>
									<Form.Control
										type="text"
										placeholder="2FA Kodu"
										{...register('otp_attempt', { required: 'Bu alan gerekli.' })}
									/>

									{errors.is2FAActive && <Form.Text className="text-danger">{errors.is2FAActive.message}</Form.Text>}
								</Form.Group>
							)}
							<Button variant="primary" type="submit" className="w-100" disabled={!isValid}>
								Şifremi Sıfırla
							</Button>
						</Form>
					</Card.Body>
				</Card>
			</div>
		</div>
	)
}

export default ResetPassword
