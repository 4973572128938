import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import React from 'react'
import { Card } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: 'top'
		}
	}
}

const labels = ['Satış', 'Pazarlama', 'Muhasebe', 'İnsan Kaynakları', 'Teknik', 'Yönetim']

const data = {
	labels,
	datasets: [
		{
			label: 'Maaş Ortalaması',
			data: [65, 59, 80, 81, 56, 55, 40],
			//#086BD9 0.5
			backgroundColor: 'rgba(8, 107, 217, 0.5)'
		},
		{
			label: 'Çalışan Sayısı',
			data: [28, 48, 40, 19, 86, 27, 90],
			//#FFC107 0.5
			backgroundColor: 'rgba(255, 193, 7, 0.5)'
		}
	]
}

const AverageSalariesChart = () => {
	return (
		<Card>
			<Card.Header>
				<Card.Title as="h5" className="mb-0">
					Departmanlara Göre Maaş Ortalaması ve Çalışan Sayıları
				</Card.Title>
			</Card.Header>
			<Card.Body>
				<Bar options={options} data={data} height={250} />
			</Card.Body>
		</Card>
	)
}

export default AverageSalariesChart
