import React from 'react'
import { useEffect } from 'react'
import { Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { refreshOrganizationList } from 'stores/ikOrganizationSlice'

import CreateScheme from './components/CreateScheme'
import CreateSchemeModal from './components/CreateSchemeModal'
import SchemeCard from './components/SchemeCard'

const SchemeList = () => {
	const params = useParams()
	const dispatch = useDispatch()

	const { organizationList } = useSelector((state) => state.ikOrganization)

	useEffect(() => {
		dispatch(refreshOrganizationList())
	}, [params])

	return (
		<>
			<Col xl={4} lg={6} md={6} className="mb-4">
				<CreateScheme />
			</Col>

			{organizationList.map(({ _id, name, title, createDate, updateDate }) => (
				<Col xl={4} lg={6} md={6} className="mb-4">
					<SchemeCard _id={_id} name={name} createDate={Date()} updateDate={Date()} />
				</Col>
			))}

			<CreateSchemeModal />
		</>
	)
}

export default SchemeList
