import React from 'react'

import DocumentBreadcrumb from '../components/document-breadcrumb'
import DefaultView from './defaultView'

const DocumentContent = () => {
	return (
		<>
			<nav aria-label="breadcrumb">
				<ol className="breadcrumb breadcrumb-style1 ms-1 mb-4">
					<DocumentBreadcrumb />
				</ol>
			</nav>

			<DefaultView />
		</>
	)
}

export default DocumentContent
