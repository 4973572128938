const notificationTypes = [
	{
		type: 'document_approval_needed',
		titleTemplate: (notification) => 'Doküman Onayı Gerekiyor',
		messageTemplate: (notification) =>
			`'<b>${notification.data.documentName}</b>' adlı dokümanı yükledi. Lütfen onaylayınız.`,
		linkTemplate: (notification) => `/document/${notification.data.documentId}`
	}
]

export default notificationTypes
