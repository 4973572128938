import { DeleteButton, SaveButton } from 'components/fast-ui'
import { TabButton, TabButtonContainer } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import HolidayModal from './components/HolidayModal'
import HolidaysTable from './components/holidays-table'

const HolidayCalendarEdit = () => {
	const params = useParams()
	const navigate = useNavigate()
	const { item_id, sub_item_id } = params

	const [showHolidayModal, setShowHolidayModal] = useState(false)
	const [data, setData] = useState([])

	useEffect(() => {
		if (sub_item_id) {
			setShowHolidayModal(true)
		} else {
			setShowHolidayModal(false)
		}
	}, [params])

	const fetchData = async () => {
		try {
			const response = await Service.get(`/holiday-calendars/${item_id}`)
			setData(response.data)
			reset(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		formState,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	const handleSave = async (data) => {
		const body = data
		try {
			const response = await Service.patch(`/holiday-calendars/${item_id}`, body)
			setData(response.data)
			sendToast('Tatil takvimi başarıyla güncellendi.')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDelete = async () => {
		try {
			await Service.delete(`/holiday-calendars/${item_id}`)
			sendToast('Tatil takvimi başarıyla silindi.')
			navigate('/settings/holiday-calendars')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<>
			<Col md={12}>
				<TabButtonContainer>
					<TabButton
						text="Tatil Takvimlerine dön"
						icon="arrow-left"
						onClick={() => navigate('/settings/holiday-calendars')}
					/>
				</TabButtonContainer>
			</Col>
			<Row className="g-4 mt-0">
				<>
					<Col md={12}>
						<Card>
							<Card.Header>
								<Card.Title as="h5">Detaylar</Card.Title>
							</Card.Header>
							<Card.Body>
								<Form>
									<Row>
										<Col md={3} className="mb-3">
											<Form.Label>Ad</Form.Label>
											<Controller
												name="name"
												control={control}
												rules={{ required: { value: true, message: 'Bu alan zorunludur.' } }}
												render={({ field }) => (
													<Form.Control {...field} type="text" placeholder="Ad" isInvalid={errors.name} />
												)}
											/>
											<Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
										</Col>
										<Col md={5} className="mb-3">
											<Form.Label>Açıklama</Form.Label>
											<Controller
												name="description"
												control={control}
												rules={{ required: { value: true, message: 'Bu alan zorunludur.' } }}
												render={({ field }) => (
													<Form.Control {...field} type="text" placeholder="Açıklama" isInvalid={errors.description} />
												)}
											/>
											<Form.Control.Feedback type="invalid">{errors.description?.message}</Form.Control.Feedback>
										</Col>
									</Row>
								</Form>
							</Card.Body>
							<Card.Footer>
								<SaveButton onClick={handleSubmit((data) => handleSave(data))} />
								<DeleteButton onClick={handleSubmit((data) => handleDelete())} />
							</Card.Footer>
						</Card>
					</Col>

					<Col md={12}>
						<HolidaysTable />
					</Col>
				</>
			</Row>
			{showHolidayModal && <HolidayModal />}
		</>
	)
}

export default HolidayCalendarEdit
