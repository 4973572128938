import axios from 'axios'
import apiUrl from 'config'
import useApiStore from 'stores/apiStore'
import useAuthStore from 'stores/authStore'

const AuthService = axios.create({
	baseURL: apiUrl,
	withCredentials: true
})

const setIsLoading = useApiStore.getState().setIsLoading

AuthService.interceptors.request.use(
	(config) => {
		setIsLoading(true)
		console.log(apiUrl, 'apiUrl')
		return config
	},
	(error) => {
		setIsLoading(false)
		return Promise.reject(error)
	}
)

AuthService.interceptors.response.use(
	(response) => {
		setIsLoading(false)
		return response
	},
	async (error) => {
		setIsLoading(false)
		return Promise.reject(error)
	}
)

export default AuthService
