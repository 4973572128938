import { AddButton, DeleteButton, EditButton } from 'components/fast-ui'
import { ButtonContainer, CustomReactSelect, EditableText, EmojiPicker, FaIcon } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Col, FormGroup, FormLabel, Row, Stack } from 'react-bootstrap'
import { Emojione } from 'react-emoji-render'
import { useNavigate, useParams } from 'react-router-dom'

import ProjectCard from './ProjectCard'

const WorkspaceView = () => {
	const [data, setData] = useState({})

	const navigate = useNavigate()
	const params = useParams()

	const { workspace_id } = params

	const fetchData = async () => {
		try {
			const response = await Service.get(`/workspaces/${workspace_id}`)
			setData(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleEditClick = () => {
		navigate(`/workspaces/${workspace_id}/edit`)
	}

	useEffect(() => {
		if (workspace_id) {
			fetchData()
		}
	}, [params])

	const handleNewProjectClick = () => {
		navigate(`/workspaces/${workspace_id}/add`)
	}

	let sortedProjects = []

	if (data.projects) {
		sortedProjects = [...data.projects]
	}

	const handleSort = (sortOption) => {
		if (sortOption === '1') {
			sortedProjects.sort((a, b) => a.name.localeCompare(b.name))
		} else if (sortOption === '2') {
			sortedProjects.sort((a, b) => b.name.localeCompare(a.name))
		}

		setData({ ...data, projects: sortedProjects })
	}

	return (
		<>
			<div className="d-flex justify-content-between align-items-center py-3">
				<div>
					<div className="d-flex align-items-center gap-3">
						<Emojione svg text="❤️" style={{ fontSize: 50 }} />
						<div className="d-flex flex-column gap-2">
							<span>
								<h4 className="mb-0">{data?.name}</h4>
							</span>
							<div className="d-flex gap-3">
								<span>
									<FaIcon icon="fa fa-user" className="text-primary me-1" />
									{data?.members?.length} Üye
								</span>
							</div>
						</div>
					</div>
				</div>

				<ButtonContainer>
					<EditButton size="md" text="Düzenle" onClick={handleEditClick} />
				</ButtonContainer>
			</div>

			<hr />

			<h5 className="mb-3 mt-4">Projeler</h5>

			<Row className="mb-3">
				<Col md={2} className="mb-2">
					<FormLabel>Sıralama ölçütü</FormLabel>
					<CustomReactSelect
						options={[
							{ value: '1', label: 'Alfabetik olarak (A-Z)' },
							{ value: '2', label: 'Alfabetik olarak (Z-A)' }
						]}
						defaultValue={{ value: '1', label: 'Alfabetik olarak (A-Z)' }}
						onChange={(selectedOption) => handleSort(selectedOption.value)}
					/>
				</Col>
			</Row>

			<div className="d-flex flex-wrap gap-3">
				<div
					onClick={handleNewProjectClick}
					className="d-flex flex-column justify-content-center cursor-pointer text-center  border rounded btn btn-outline-secondary"
					style={{
						height: '110px',
						width: '260px'
					}}
				>
					<h6 className="fw-light mb-0" style={{ color: '#748194' }}>
						Yeni proje oluştur
					</h6>
				</div>
				{sortedProjects.map((project) => (
					<ProjectCard data={project} key={project._id} />
				))}
			</div>
		</>
	)
}

export default WorkspaceView
