import { SaveButton } from 'components/fast-ui'
import { VacationViewModal } from 'components/modals'
import { FaIcon } from 'components/ui'
import Service from 'global/Service'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

import VacationNeedApproveList from './components/vacation-need-approve-list'
import VacationRequestesTable from './components/vacation-requestes-table'

const VacationRequestes = () => {
	const params = useParams()
	const { tab_name, module_name: vacationId, module_id: process } = params

	const navigate = useNavigate()
	const [showVacationViewModal, setShowVacationViewModal] = useState(false)

	useEffect(() => {
		if (tab_name === 'requestes' && vacationId) {
			setShowVacationViewModal(true)
		} else {
			setShowVacationViewModal(false)
		}
	}, [params])

	const handleHide = () => {
		navigate(`/vacations/requestes`)
	}

	const handleApprove = async () => {
		try {
			const response = await Service.post(`/vacations/${vacationId}/approve`)
			sendToast('İzin talebi onaylandı.')
			navigate(`/vacations/requestes/${vacationId}`)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleReject = async () => {
		try {
			const response = await Service.post(`/vacations/${vacationId}/reject`)
			sendToast('İzin talebi reddedildi.')
			navigate(`/vacations/requestes/${vacationId}`)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<Row>
			<Col md={12} className="mb-4">
				<VacationRequestesTable />
			</Col>
			<Col md={12}>
				<VacationNeedApproveList />
			</Col>
			{showVacationViewModal && (
				<VacationViewModal
					vacationId={vacationId}
					show={showVacationViewModal}
					showApproveButtons={process === 'approve'}
					showApprovers
					onHide={handleHide}
					onApprove={handleApprove}
					onReject={handleReject}
				/>
			)}
		</Row>
	)
}

export default VacationRequestes
