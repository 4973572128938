import { default as lightLogoText } from 'assets/svg/logos/meno-light-text.svg'
import { default as lightLogo } from 'assets/svg/logos/meno-light.svg'
import { default as logoIcon } from 'assets/svg/logos/meno-logo-icon.svg'
import { FaIcon, MenuItem } from 'components/ui'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAuthStore from 'stores/authStore'
import useGeneralStore from 'stores/generalStore'
import { disableExpanded, enableExpanded, setMiniMenu, setNormalMenu } from 'stores/siteSlice'

const Menu = () => {
	const init = useGeneralStore((state) => state.init)
	const { logoUrl, iconUrl } = useGeneralStore((state) => state)
	const user = useAuthStore((state) => state.user)

	const { dark, miniMenu, expanded } = useSelector((state) => state.site)
	const dispatch = useDispatch()

	const [miniMenuExpanded, setMiniMenuExpanded] = useState(false)

	const defaultMainClass = 'light-style layout-navbar-fixed layout-menu-fixed'
	const miniMenuMainClass = 'light-style layout-navbar-fixed layout-menu-fixed layout-menu-collapsed'
	const expandedMainClass =
		'light-style layout-navbar-fixed layout-menu-fixed layout-transitioning layout-menu-expanded'

	const setMainClass = (className) => {
		document.documentElement.className = className
	}

	useEffect(() => {
		if (miniMenu === true) {
			setMainClass(miniMenuMainClass)
		} else {
			setMainClass(defaultMainClass)
		}
	}, [miniMenu])

	useEffect(() => {
		if (expanded === true) {
			setMainClass(expandedMainClass)
		} else {
			if (miniMenu === true) {
				setMainClass(miniMenuMainClass)
			} else {
				setMainClass(defaultMainClass)
			}
		}
	}, [expanded])

	const handleMenuToggle = () => {
		if (miniMenu === true) {
			dispatch(setNormalMenu())
		} else {
			dispatch(setMiniMenu())
		}
	}

	const handleExitExpanded = () => {
		dispatch(disableExpanded())
	}

	const handleMiniMenuMouseEnter = () => {
		if (miniMenu === true && expanded === false) {
			setMainClass(defaultMainClass)
			setMiniMenuExpanded(true)
		}
	}

	const handleMiniMenuMouseLeave = () => {
		if (miniMenu === true && expanded === false) {
			setMainClass(miniMenuMainClass)
			setMiniMenuExpanded(false)
		}
	}

	useEffect(() => {
		init()
	}, [])

	return (
		<aside
			id="layout-menu"
			className="layout-menu menu-vertical menu bg-menu-theme"
			data-bg-class="bg-menu-theme"
			onMouseLeave={handleMiniMenuMouseLeave}
			onMouseEnter={handleMiniMenuMouseEnter}
			style={{
				touchAction: 'none',
				userSelect: 'none',
				WebkitUserDrag: 'none',
				WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'
			}}
		>
			<div className="app-brand demo ">
				<a href="/" className="app-brand-link">
					{miniMenu === true ? (
						<>
							<span className="app-brand-logo demo">
								<img src={logoIcon} height={28} alt="logo" />
							</span>
							<span className="app-brand-text menu-text fw-bold ms-2">
								<img src={lightLogoText} height={22} alt="logo" />
							</span>
						</>
					) : (
						<span className="app-brand-text menu-text fw-bold ms-2">
							<img src={lightLogo} height={21} alt="logo" />
						</span>
					)}
				</a>
				<a href="#" className="layout-menu-toggle menu-link text-large ms-auto">
					{!expanded && (
						<span onClick={handleMenuToggle}>
							<FaIcon icon={miniMenu === true ? 'circle' : 'circle-dot'} size="lg" className="text-primary" />
						</span>
					)}
					<i onClick={handleExitExpanded} className="bx bx-x d-block d-xl-none bx-sm align-middle" />
				</a>
			</div>
			<div className="menu-divider mt-0  "></div>
			<div className="menu-inner-shadow" />
			<ul className="menu-inner py-1 ps ps--active-y">
				<MenuItem title="Talep" icon="bx bx-send" path="/requests" />
				<MenuItem title="Anasayfa" icon="bx bx-home" path="/home" />
				<MenuItem title="Görevlerim" icon="bx bx-task" path="/tasks/assigned-tasks" />
				<MenuItem title="Proje" icon="bx bx-briefcase" path="/projects" />
				<MenuItem title="Doküman" icon="bx bx-folder" path="/documents" />
				{user.permissions.includes('calendar_access') && (
					<MenuItem title="Takvim" icon="bx bx-calendar" path="/calendar" />
				)}

				<MenuItem title="IK" icon="bx bx-happy-beaming" base="/ik" toggle={true}>
					<MenuItem title="Panel" path="/ik-dashboard" />
					<MenuItem title="Çalışanlar" path="/employees" />
					{/* <li
						className={`menu-item`}
						style={{
							height: 5,
							borderBottom: '1px solid #e5e5e5'
						}}
					></li> */}
					<MenuItem title="İzinler" path="/vacations/requestes" />
					<MenuItem title="Masraflar" path="/expenses" />
					<MenuItem title="Yetkinlik" path="/ik-competencies/definitions" />
					<MenuItem title="Şemalar" path="/ik-organization-schemes" />
				</MenuItem>
				<MenuItem title="Ayarlar" icon="bx bx-cog" path="/settings/company-structure" />
				<MenuItem title="Hesabım" icon="bx bx-user-circle" path="/profile" />

				<div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
					<div className="ps__thumb-x" tabIndex={0} style={{ left: 0, width: 0 }} />
				</div>
				<div className="ps__rail-y" style={{ top: 0, height: 862, right: 4 }}>
					<div className="ps__thumb-y" tabIndex={0} style={{ top: 0, height: 413 }} />
				</div>
			</ul>
			<div className="app-brand demo ">
				<a href="/" className="app-brand-link">
					{miniMenu === true ? (
						<>
							<span className="app-brand-logo demo">
								<img src={iconUrl} height={28} alt="logo" />
							</span>
							<span className="app-brand-text menu-text fw-bold ms-2">
								<img src={dark ? logoUrl : logoUrl} height={22} alt="logo" />
							</span>
						</>
					) : (
						<span className="app-brand-text menu-text fw-bold ms-2">
							<img src={dark ? logoUrl : logoUrl} height={21} alt="logo" />
						</span>
					)}
				</a>
			</div>
		</aside>
	)
}

export default Menu
