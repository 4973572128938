import { format } from 'date-fns'
import React from 'react'
import { Card, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const SchemeCard = ({ _id, name, createDate, updateDate }) => {
	const navigate = useNavigate()
	const handleClickEdit = () => {
		navigate(`/ik-organization-schemes/${_id}`)
	}
	return (
		<Card>
			<div className="card-body">
				<div className="d-flex justify-content-start mb-0">
					<h6 className="fw-normal me-4">
						<i className="fa-regular fa-pen-circle me-2" />
						{format(new Date(createDate), 'dd.MM.yyyy')}
					</h6>
					{updateDate !== null ? (
						<h6 className="fw-normal">
							<i className="fa-regular fa-clock me-2" />
							{format(new Date(updateDate), 'dd.MM.yyyy')}
						</h6>
					) : null}
				</div>
				<div className="d-flex justify-content-between align-items-end">
					<div className="role-heading">
						<h4 className="mb-1">{name}</h4>
						<a onClick={handleClickEdit} href="#" className="role-edit-modal">
							<small>Düzenle</small>
						</a>
					</div>
					<a onClick={handleClickEdit} href="#" className="text-muted">
						<i className="bx bx-show" />
					</a>
				</div>
			</div>
		</Card>
	)
}

export default SchemeCard
