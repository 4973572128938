import { CreateButton } from 'components/fast-ui'
import Service from 'global/Service'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { setSelectedAssessors, setSelectedEmployees } from 'stores/ik/competence/ikCompetenceAssessmentSlice'

import { CompetenceAssessors } from './assessor-selection'
import { CompetenceEmployees } from './employee-selection'

const CompetenceRatingsTab = () => {
	const dispatch = useDispatch()
	const { selectedAssessors, selectedEmployees } = useSelector((state) => state.ikCompetenceAssessment)

	const handleClick = async () => {
		const body = {
			employees: selectedEmployees,
			raters: selectedAssessors
		}

		try {
			const response = Service.post(`/competence-ratings/bulk`, body)
			sendToast('Yetkinlik değerlendirme talepleri oluşturuldu.')
			setSelectedAssessors([])
			setSelectedEmployees([])
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<>
			<Col xl={6} className="mb-4">
				<CompetenceEmployees />
			</Col>
			<Col xl={6} className="mb-4">
				<CompetenceAssessors />
			</Col>
			<Col xl={12} className="d-flex align-items-center justify-content-center">
				<CreateButton
					text="Yetkinlik Değerlendirme Taleplerini Oluştur"
					onClick={handleClick}
					disabled={selectedAssessors.length === 0 || selectedEmployees.length === 0}
				/>
			</Col>
			<p>
				<pre>
					{JSON.stringify(
						{
							selectedAssessors,
							selectedEmployees
						},
						null,
						2
					)}
				</pre>
			</p>
		</>
	)
}

export default CompetenceRatingsTab
