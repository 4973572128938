import { ApproveButton } from 'components/fast-ui'
import { ButtonContainer, FaIcon } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import useProjectStore from 'stores/projectStore'

const NewTaskButton = ({ columnId }) => {
	const params = useParams()
	const { project_id } = params

	const refreshProject = useProjectStore((state) => state.refreshProject)

	const [showInput, setShowInput] = useState(false)
	const [title, setTitle] = useState('')

	const handleTitleChange = (e) => {
		setTitle(e.target.value)
	}

	const toggleAddTask = () => {
		setShowInput(!showInput)
	}

	const handleSend = async () => {
		const body = {
			title: title,
			project: project_id,
			column: columnId
		}

		try {
			const response = await Service.post('/project-tasks', body)
			sendToast('Görev oluşturuldu.', true)
			refreshProject()
			setTitle('')
			setShowInput(false)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<div>
			{showInput ? (
				<Card className="bg-light border-0 cursor-pointer px-4 mb-4">
					<Form.Control
						type="text"
						placeholder="Görev başlığı girin"
						className="mb-2"
						value={title}
						onChange={handleTitleChange}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleSend()
							}
						}}
					/>
					<ButtonContainer className="d-flex justify-content-start align-items-center">
						<ApproveButton onClick={handleSend} size="md" />
						<FaIcon icon="times" className="float-end cursor-pointer" size="lg" onClick={() => setShowInput(false)} />
					</ButtonContainer>
				</Card>
			) : (
				<Card className="bg-light border-0 cursor-pointer px-4 mb-4" onClick={toggleAddTask}>
					<Card.Text>
						<FaIcon icon="plus" /> Görev ekle
					</Card.Text>
				</Card>
			)}
		</div>
	)
}

export default NewTaskButton
