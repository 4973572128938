import React from 'react'

import FaIcon from '../FaIcon'

const Approvers = ({ data }) => {
	return (
		<div className="d-flex align-items-center justify-content-start w-100 flex-wrap">
			{data?.map((approver, index) => (
				<div className="d-flex align-items-center p-3">
					<FaIcon icon="arrow-right" className="me-3" />
					<div className="me-2">
						<img src={'https://via.placeholder.com/150'} alt="" className="rounded-circle" width="40" height="40" />
					</div>
					<div>
						<h6 className="mb-0">{approver?.employee?.fullName}</h6>
						<small className="text-muted">
							{approver?.status === 'pending' && (
								<>
									<FaIcon icon="clock" className="text-warning me-1" /> Beklemede
								</>
							)}
							{approver?.status === 'approved' && (
								<>
									<FaIcon icon="check" className="text-success me-1" /> Onayladı
								</>
							)}
							{approver?.status === 'rejected' && (
								<>
									<FaIcon icon="xmark" className="text-danger me-1" /> Reddetti
								</>
							)}
						</small>
					</div>
				</div>
			))}
		</div>
	)
}

export default Approvers
