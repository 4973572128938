import './index.css'

import React, { useEffect } from 'react'
import { useState } from 'react'
import { Card, Container, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router'

import CalendarAddEvent from './components/EventAddModal'
import AddEventModal from './components/EventAddModal'
import EventAddModal from './components/EventAddModal'
import EventViewModal from './components/EventViewModal'
import CalendarContent from './components/calendar-content'
import CalendarSidebar from './components/calendar-sidebar'

const Calendar = () => {
	const params = useParams()
	const navigate = useNavigate()
	const { event_id } = params

	const [showModal, setShowModal] = useState('')

	useEffect(() => {
		if (event_id) {
			if (event_id === 'add') {
				setShowModal('add')
			} else {
				setShowModal('view')
			}
		} else {
			setShowModal('')
		}
	}, [params])

	const handleModalClose = () => {
		navigate('/calendar')
	}

	return (
		<>
			<Container fluid="xxl" className="flex-grow-1 container-p-y">
				<Card className="app-calendar-wrapper">
					<div className="row g-0">
						<CalendarSidebar />
						<CalendarContent />
					</div>
				</Card>
			</Container>
			{showModal === 'add' && <EventAddModal onClose={handleModalClose} />}
			{showModal === 'view' && <EventViewModal onClose={handleModalClose} />}
		</>
	)
}

export default Calendar
