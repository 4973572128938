import { FaIcon } from 'components/ui'
import statuses from 'constants/statuses'
import { format } from 'date-fns'
import { formatDate } from 'global/functions/formatDate'
import { Link, useNavigate } from 'react-router-dom'

const vacationTypes = [
	{ value: 'military', label: 'Askerlik İzni' },
	{ value: 'paternity', label: 'Babalık İzni' },
	{ value: 'birth', label: 'Doğum İzni' },
	{ value: 'postpartum', label: 'Doğum Sonrası İzni' },
	{ value: 'marriage', label: 'Evlilik İzni' },
	{ value: 'sick', label: 'Hastalık İzni' },
	{ value: 'jobSearch', label: 'İş Arama İzni' },
	{ value: 'reason', label: 'Mazeret İzni' },
	{ value: 'breastfeeding', label: 'Süt İzni' },
	{ value: 'unpaid', label: 'Ücretsiz İzin' },
	{ value: 'death', label: 'Vefat İzni' },
	{ value: 'annual', label: 'Yıllık İzni' },
	{ value: 'road', label: 'Yol İzni' }
]

const COLUMNS = [
	{
		Header: 'Talep Eden',
		accessor: 'requester',
		Cell: ({ row }) => {
			return <span>{`${row.original.requester.firstName} ${row.original.requester.lastName}`}</span>
		}
	},
	{
		Header: 'Tür',
		accessor: 'type.name'
	},
	{
		Header: 'Başlangıç Tarihi',
		accessor: 'startDate',
		Cell: (cell) => {
			return <span>{formatDate(cell.row.original.startDate)}</span>
		}
	},
	{
		Header: 'Bitiş Tarihi',
		accessor: 'endDate',
		Cell: (cell) => {
			return <span>{formatDate(cell.row.original.endDate)}</span>
		}
	},
	{
		Header: 'İzin Süresi',
		accessor: 'days',
		Cell: ({ row }) => {
			return <span>{row.original.days} gün</span>
		}
	},
	{
		Header: 'Yerine Bakacak Kişi',
		accessor: 'substitute',
		Cell: ({ row }) => {
			return (
				<span>
					{row.original.substitute && row.original.substitute.fullName ? row.original.substitute.fullName : '-'}
				</span>
			)
		}
	},
	{
		Header: 'Açıklama',
		accessor: 'description',
		Cell: (cell) => {
			return <span className="fw-semibold">{cell.row.original.description}</span>
		}
	},
	{
		Header: 'Durum',
		accessor: 'status',
		Cell: ({ row }) => {
			const badgeStatus =
				row.original.status === 'pending'
					? 'bg-warning'
					: row.original.status === 'approved'
					? 'bg-success'
					: row.original.status === 'rejected'
					? 'bg-danger'
					: 'bg-secondary'

			return (
				<span className={`badge ${badgeStatus}`}>
					{statuses.find((status) => status.value === row.original.status)?.label}
				</span>
			)
		}
	},
	{
		Header: 'Seçenekler',
		accessor: 'options',
		Cell: (cell) => {
			return (
				<div className="d-flex align-items-center">
					<div className="d-flex align-items-center">
						<Link to={`/vacations/requestes/${cell.row.original._id}`} className="text-primary me-3">
							<FaIcon icon="eye" />
						</Link>
					</div>
				</div>
			)
		}
	}
]

export default COLUMNS
