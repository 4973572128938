import anonimProfilePhoto from 'assets/img/avatars/anonim-profile-photo.jpg'
import { CloseButton, ResetButton, SaveButton } from 'components/fast-ui'
import { FaIcon } from 'components/ui'
import Service from 'global/Service'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React, { createRef, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { fetchEmployee } from 'stores/ikEmployeeEditSlice'

import { ControlledMenu, MenuDivider, MenuItem, SubMenu, useMenuState } from '@szhsin/react-menu'

const Avatar = () => {
	const dispatch = useDispatch()
	const params = useParams()

	const { employee_id } = params
	const { employee } = useSelector((state) => state.ikEmployeeEdit)

	const [show, setShow] = useState(false)

	const handleClick = () => {
		if (employee_id === 'new') return
		setShow(true)
	}
	const handleClose = () => {
		setShow(false)
	}

	const handleClickNewPhoto = () => {
		uploadImageRef.current.click()
	}

	const handleClickDeletePhoto = async () => {
		try {
			const response = await Service.delete(`/employees/${employee_id}/delete-profile-photo`)
			sendToast('Personel fotoğrafı silindi.')
			setShow(false)
		} catch (error) {
			sendToast(error.response.data.message, 'error')
		}

		dispatch(fetchEmployee(employee_id))
	}

	const handleFileSelect = async (e) => {
		const formData = new FormData()
		const file = e.target.files[0]
		formData.append('file', file, file.name)

		try {
			const response = await Service.post(`/employees/${employee_id}/upload-profile-photo`, formData)

			dispatch(fetchEmployee(employee_id))
			sendToast('Personel fotoğrafı yüklendi.')
			setShow(false)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const uploadImageRef = createRef()
	const profilePhotoURL = employee.profilePhotoUrl

	return (
		<>
			<div type="button" onClick={handleClick}>
				<img
					className="img-fluid rounded my-4"
					src={profilePhotoURL || anonimProfilePhoto}
					height={110}
					width={110}
					alt="User avatar"
				/>
			</div>

			<Modal show={show} onHide={handleClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<div className="content-left">
						<Modal.Title as={'h5'}>
							<FaIcon icon={'circle-user'} className="me-2" /> Profil Fotoğrafı
						</Modal.Title>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="p-3 py-0">
						<input className="d-none" ref={uploadImageRef} onChange={handleFileSelect} type="file" />
						<Row>
							<button onClick={handleClickNewPhoto} type="button" className={`btn btn-primary mb-2`}>
								<FaIcon icon="upload" className="me-2" /> Yeni Fotoğraf Yükle
							</button>
						</Row>
						<Row>
							<button onClick={handleClickDeletePhoto} type="button" className={`btn btn-outline-secondary`}>
								<FaIcon icon="trash" className="me-2" /> Mevcut Fotoğrafı Kaldır
							</button>
						</Row>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default Avatar
