import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import React from 'react'
import { Card } from 'react-bootstrap'
import { Doughnut } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: 'bottom'
		}
	}
}

const data = {
	labels: ['Tam Zamanlı', 'Yarı Zamanlı', 'Geçici', 'Kontratlı', 'Serbest Çalışan', 'Stajyer', 'Gezici'],
	datasets: [
		{
			label: '# of Votes',
			data: [12, 19, 3, 5, 2, 3],
			backgroundColor: [
				'rgba(255, 99, 132, 0.2)',
				'rgba(54, 162, 235, 0.2)',
				'rgba(255, 206, 86, 0.2)',
				'rgba(75, 192, 192, 0.2)',
				'rgba(153, 102, 255, 0.2)',
				'rgba(255, 159, 64, 0.2)'
			],
			borderColor: [
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 206, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 159, 64, 1)'
			],
			borderWidth: 1
		}
	]
}

const WorkingTypeChart = () => {
	return (
		<Card>
			<Card.Header>
				<Card.Title as="h5" className="mb-0">
					Çalışma Şekli
				</Card.Title>
			</Card.Header>
			<Card.Body>
				<Doughnut data={data} options={options} height={250} />
			</Card.Body>
		</Card>
	)
}

export default WorkingTypeChart
