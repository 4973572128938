import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip
} from 'chart.js'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Card } from 'react-bootstrap'
import { Line } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const options = {
	responsive: true,
	maintainAspectRatio: false,

	plugins: {
		legend: {
			position: 'top'
		}
	}
}

const labels = [...Array(30).keys()]
	.map((i) => {
		const date = new Date()
		date.setDate(date.getDate() - i)
		return date.toLocaleDateString('tr-TR', {
			//yıl yok
			year: 'numeric',
			month: 'numeric',
			day: 'numeric'
		})
	})
	.reverse()

const CompletedTasksByDaysChart = () => {
	const [receivedTasksByDays, setReceivedTasksByDays] = useState()
	const [completedTaskByDays, setCompletedTaskByDays] = useState()

	const fetchReceivedTasksByDays = async () => {
		try {
			const response = await Service.get('/charts/received-tasks-by-days')
			setReceivedTasksByDays(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchCompletedTaskByDays = async () => {
		try {
			const response = await Service.get('/charts/completed-tasks-by-days')
			setCompletedTaskByDays(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchReceivedTasksByDays()
		fetchCompletedTaskByDays()
	}, [])

	return (
		<Card style={{ maxHeight: 300, overflow: 'auto' }}>
			<Card.Header>
				<Card.Title as="h5">Günlere Göre Tamamlanan Görev Sayısı</Card.Title>
			</Card.Header>

			<Card.Body>
				{receivedTasksByDays && completedTaskByDays && (
					<Line
						data={{
							labels: labels,
							datasets: [
								{
									label: 'Tamamlanan Görev Sayısı',
									data: completedTaskByDays,
									borderColor: 'rgb(8, 107, 217)',
									backgroundColor: 'rgba(8, 107, 217, 0.5)'
								},
								{
									label: 'Alınan Görev Sayısı',
									data: receivedTasksByDays,
									borderColor: 'rgb(0, 207, 221)',
									backgroundColor: 'rgba(0, 207, 221, 0.5)'
								}
							]
						}}
						options={options}
						height={190}
					/>
				)}
			</Card.Body>
		</Card>
	)
}

export default CompletedTasksByDaysChart
