import { CreateButton } from 'components/fast-ui'
import { CustomReactTable, FaIcon } from 'components/ui'
import Service from 'global/Service'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useMemo, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'

import CompetencePacketModal from './components/CompetencePacketModal'

const columns = [
	{
		Header: 'Yetkinlik Paketi',
		accessor: 'name'
	},
	{
		Header: 'Yetkinlikler',
		accessor: 'competencies',
		Cell: ({ value }) => {
			return value.map((item) => item.name).join(', ')
		}
	},
	{
		Header: 'İşlem',
		Cell: ({ row }) => {
			return (
				<div>
					<Link to={`/ik-competencies/definitions/packets/${row.original._id}`}>
						<FaIcon icon="edit" />
					</Link>
				</div>
			)
		}
	}
]

const CompetencePacketDefinition = () => {
	const params = useParams()
	const navigate = useNavigate()

	const { tab_name, module_name, module_id: competencePacketId } = params

	const [showModal, setShowModal] = useState(false)
	const [competencePackets, setCompetencePackets] = useState([])

	const fetchCompetencePackets = async () => {
		try {
			const res = await Service.get('/competence-packets')
			setCompetencePackets(res.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchCompetencePackets()
		if (tab_name === 'definitions' && module_name === 'packets' && competencePacketId) {
			setShowModal(true)
		} else {
			setShowModal(false)
		}
	}, [params])

	const handleCreateButton = () => {
		navigate('/ik-competencies/definitions/packets/add')
	}

	const data = useMemo(() => competencePackets)

	return (
		<Card className="h-100">
			<Card.Body>
				<div className="d-flex align-items-start justify-content-between mb-4">
					<div className="content-left">
						<div className="d-flex align-items-end mt-2">
							<h4 className="mb-0 me-2">Yetkinlik Paketleri</h4>
						</div>
						<small>Toplam: {competencePackets.length} </small>
					</div>
				</div>
				<Row>
					<Col md={12} className="mb-3">
						<CreateButton onClick={handleCreateButton} />
					</Col>
				</Row>
				<CustomReactTable data={data} columns={columns} defaultSortBy="name" />
			</Card.Body>

			{showModal && (
				<CompetencePacketModal
					id={competencePacketId}
					show={showModal}
					onHide={() => navigate('/ik-competencies/definitions')}
				/>
			)}
		</Card>
	)
}

export default CompetencePacketDefinition
