import React, { useEffect, useRef, useState } from 'react'

const EditableText = ({ value, onBlur }) => {
	const [onEdit, setOnEdit] = useState(false)
	const [stateValue, setStateValue] = useState(value)
	const [clicked, setClicked] = useState(false)
	const [isActive, setIsActive] = useState(false)
	const containerRef = useRef(null)

	useEffect(() => {
		console.log('useEffect çalıştı')
	}, [value])

	const handleBlur = () => {
		onBlur && onBlur(stateValue)
		setOnEdit(false)
	}

	const handleClick = () => {
		setClicked(true)
	}

	return (
		<>
			{onEdit ? (
				<input
					type="text"
					defaultValue={stateValue}
					ref={containerRef}
					contentEditable={isActive}
					onChange={(e) => setStateValue(e.target.value)}
					onBlur={handleBlur}
					onClick={handleClick}
					onMouseEnter={() => setIsActive(true)}
					onMouseLeave={() => !clicked && setIsActive(false)}
					className={`${isActive ? 'border  bg-light' : ''}`}
				/>
			) : (
				<span onClick={() => setOnEdit(true)}>{stateValue}</span>
			)}
		</>
	)
}

export default EditableText
