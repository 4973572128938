import { ExamineButton } from 'components/fast-ui'
import { FaIcon } from 'components/ui'
import { format } from 'date-fns'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

const VacationNeedApproveList = () => {
	const params = useParams()
	const navigate = useNavigate()

	const [data, setData] = useState([])

	const fetchData = async () => {
		try {
			const response = await Service.get(`/vacations/pending`)
			setData(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [params])

	const handleClick = (id) => {
		navigate(`/vacations/requestes/${id}/approve`)
	}

	return (
		<Card>
			<Card.Header>
				<Card.Title as="h5">Onayımı Bekleyen İzin Talepleri</Card.Title>
			</Card.Header>
			<Card.Body>
				<Row className="g-4">
					{data.length === 0 && (
						<div className="d-flex align-items-center justify-content-start">
							<h6 className="text-muted">Onayınızı bekleyen izin talebi bulunmamaktadır.</h6>
						</div>
					)}
					{data.map((item, index) => (
						<Col md={4}>
							<Card>
								<Card.Header>
									<div className="d-flex align-items-start justify-content-between">
										<div>
											<Card.Title as="h5">
												{item?.requester?.firstName} {item?.requester?.lastName}
											</Card.Title>
											<span className="me-4">
												<FaIcon icon="door-open" /> {item.type.name}
											</span>
											<span>
												<FaIcon icon="clock" className="me-2" />
												{item.days} gün
											</span>
										</div>

										<ExamineButton onClick={() => handleClick(item._id)} />
									</div>
								</Card.Header>
								<Card.Body>
									<Card.Text>
										<div className="d-flex align-items-center justify-content-between">
											<span>
												<FaIcon icon="calendar" className="me-2" />

												{item.startDate && format(new Date(item.startDate), 'dd.MM.yyyy HH:mm')}
											</span>
											<span>
												<FaIcon icon="arrow-right" />
											</span>

											<span>
												<FaIcon icon="calendar" className="me-2" />
												{item.endDate && format(new Date(item.endDate), 'dd.MM.yyyy HH:mm')}
											</span>
										</div>
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					))}
				</Row>
			</Card.Body>
		</Card>
	)
}

export default VacationNeedApproveList
