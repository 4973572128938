import Service from 'global/Service'
import { sendToast } from 'global/sendToast'

import { createSlice } from '@reduxjs/toolkit'

export const ikOrganizationSlice = createSlice({
	name: 'ikOrganization',
	initialState: {
		employeeList: [],
		employeeFilter: {
			departmentList: [],
			selectedDepartment: [],
			positionList: [],
			selectedPosition: []
		},
		organizationList: [],
		selectedOrganization: {
			_id: null,
			name: null,
			title: null,
			items: [],
			tempItems: []
		},
		readonly: false
	},
	reducers: {
		setOrganizationList: (state, action) => {
			state.organizationList = action.payload
		},
		setSelectedOrganization: (state, action) => {
			state.selectedOrganization = action.payload
		},
		setSelectedOrganizationName: (state, action) => {
			state.selectedOrganization.name = action.payload
		},
		setSelectedOrganizationTitle: (state, action) => {
			state.selectedOrganization.title = action.payload
		},
		setReadonlyTrue: (state) => {
			state.readonly = true
		},
		setReadonlyFalse: (state) => {
			state.readonly = false
		},
		setEmployeeList: (state, action) => {
			state.employeeList = action.payload
		},
		setSelectedOrganizationItems: (state, action) => {
			state.selectedOrganization.items = action.payload
		},
		setSelectedOrganizationTempItems: (state, action) => {
			state.selectedOrganization.tempItems = action.payload
		},
		setSelectedOrganizationFilterIDs: (state, action) => {
			state.selectedOrganization.filterIDs = action.payload
		},
		setEmployeeFilterDepartmentList: (state, action) => {
			state.employeeFilter.departmentList = action.payload
		},
		setEmployeeFilterPositionList: (state, action) => {
			state.employeeFilter.positionList = action.payload
		},
		setEmployeeFilterSelectedDepartment: (state, action) => {
			state.employeeFilter.selectedDepartment = action.payload
		},
		setEmployeeFilterSelectedPosition: (state, action) => {
			state.employeeFilter.selectedPosition = action.payload
		}
	}
})

export const {
	setEmployeeList,
	setOrganizationList,
	setSelectedOrganization,
	setSelectedOrganizationName,
	setSelectedOrganizationTitle,
	setReadonlyTrue,
	setReadonlyFalse,
	setSelectedOrganizationItems,
	setSelectedOrganizationTempItems,
	setSelectedOrganizationFilterIDs,
	setEmployeeFilterDepartmentList,
	setEmployeeFilterPositionList,
	setEmployeeFilterSelectedDepartment,
	setEmployeeFilterSelectedPosition
} = ikOrganizationSlice.actions

export const refreshEmployeeList = () => (dispatch) => {
	let promise = Service.get('/employees')
	promise.then((response) => {
		dispatch(setEmployeeList(response.data))
	})
}

export const refreshDepartmentList = () => (dispatch) => {
	let promise = Service.get('/company-units/departments')
	promise.then((response) => {
		dispatch(setEmployeeFilterDepartmentList(response.data))
	})
}

export const refreshPositionList = () => (dispatch) => {
	let promise = Service.get('/company-units/positions')
	promise.then((response) => {
		dispatch(setEmployeeFilterPositionList(response.data))
	})
}

export const refreshOrganizationList = () => (dispatch) => {
	let promise = Service.get('/organization-schemes')
	promise.then((response) => {
		dispatch(setOrganizationList(response.data))
	})
}

export const selectOrganization = (id) => {}

export const fetchOrganizationScheme = (id) => async (dispatch) => {
	try {
		const scheme = await Service.get(`/organization-schemes/${id}`)
		if (scheme.data.items.length > 0) {
			scheme.data.tempItems = unflatten(scheme.data.items)
		} else {
			scheme.data.tempItems = []
		}
		dispatch(setSelectedOrganization(scheme.data))
	} catch (error) {}
}

const unflatten = (data) => {
	const tree = data
		.map((e) => ({ ...e }))
		.sort((a, b) => a.employee._id - b.employee._id)
		.reduce((a, e) => {
			a[e.employee._id] = a[e.employee._id] || e
			a[e.parentEmployee_id] = a[e.parentEmployee_id] || {}
			const parent = a[e.parentEmployee_id]
			parent.children = parent.children || []
			parent.children.push(e)
			return a
		}, {})
	return Object.values(tree).find((e) => e.employee === undefined).children
}

export const newDefinition = () => (dispatch) => {}

export const saveOrganization = (organizationDetails) => async (dispatch, getState) => {
	const body = { ...organizationDetails }
	try {
		await Service.post(`/organization-schemes`, body)
		sendToast('Şema başarıyla oluşturuldu.', true)
		dispatch(refreshOrganizationList())
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

export const insertEmployeeToScheme = (employee) => (dispatch, getState) => {
	const tempItems = getState().ikOrganization.selectedOrganization.tempItems || []
	const newTempItems = [...tempItems, employee]
	dispatch(setSelectedOrganizationTempItems(newTempItems))
}

export const deleteEmployeeFromScheme = (employee) => (dispatch, getState) => {
	const tempItems = getState().ikOrganization.selectedOrganization.tempItems || []
	const newTempItems = tempItems.filter((item) => item.employee._id !== employee._id)
	dispatch(setSelectedOrganizationTempItems(newTempItems))
}

export const addEmployeeToDetail = (list, employee) => (dispatch) => {}

export const removeEmployeeFromDetail = (list, employee) => (dispatch) => {
	const newList = list.filter((item) => item.id !== employee.employeeID)
}

export const addItemToFilterIDs = (filterList, employeeID) => (dispatch) => {
	const newFilterList = [...filterList, employeeID]
	dispatch(setSelectedOrganizationFilterIDs(newFilterList))
}

export const removeItemFromFilterIDs = (filterList, employeeID) => (dispatch) => {
	const newFilterList = filterList.filter((item) => item !== employeeID)
	dispatch(setSelectedOrganizationFilterIDs(newFilterList))
}
export default ikOrganizationSlice.reducer
