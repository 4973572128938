import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import BreadcrumbContainer from './BreadcrumbContainer'
import BreadcrumbItem from './BreadcrumbItem'

const DocumentBreadcrumb = () => {
	const { search, itemsFrom } = useSelector((state) => state.document.main)
	const selectedFolderInfo = useSelector((state) => state.document.tree.selectedFolder.info)
	const selectedTag = useSelector((state) => state.document.tags.selectedTag.content)

	const navigate = useNavigate()

	const breadcrumbItemHandleClick = (id) => {
		if (id === 'cloud') {
			navigate(`/documents`)
		} else {
			navigate(`/documents/folder/${id}`)
		}
	}

	return (
		<>
			<BreadcrumbItem id={'cloud'} name="Cloud" onClick={breadcrumbItemHandleClick} />
			{search.showStatus === true ? (
				<BreadcrumbItem id={-1} name={`'${search.value}' için sonuçlar`} />
			) : (
				<>
					{itemsFrom === 'trash' && <BreadcrumbItem id={'trash'} name="Çöp Kutusu" />}
					{itemsFrom === 'folder' && (
						<BreadcrumbContainer data={selectedFolderInfo.breadcrumb} breadcrumbItemClick={breadcrumbItemHandleClick} />
					)}
					{itemsFrom === 'tag' && <BreadcrumbItem id={selectedTag._id} name={selectedTag.name} />}
				</>
			)}
		</>
	)
}

export default DocumentBreadcrumb
