import anonimProfilePhoto from 'assets/img/avatars/anonim-profile-photo.jpg'
import { SaveButton } from 'components/fast-ui'
import { Timeago } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Form, Image } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import useAuthStore from 'stores/authStore'
import useTaskStore from 'stores/taskStore'

const TasksComment = ({ data, onEditClick, isEditing, onSuccess }) => {
	const user = useAuthStore((state) => state.user)
	const task = useTaskStore((state) => state.task)

	const handleEditClick = () => {
		onEditClick && onEditClick(data._id)
	}

	const handleUpdate = async (formData) => {
		const body = formData
		try {
			const response = await Service.patch(`/tasks/${task._id}/comments/${data._id}`, body)
			onSuccess && onSuccess()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDelete = async () => {
		try {
			const response = await Service.delete(`/tasks/${task._id}/comments/${data._id}`)
			onSuccess && onSuccess()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleSubmit(handleUpdate)()
		}
	}

	useEffect(() => {
		/* reset({ content: data.content }) */
	}, [])

	const { register, handleSubmit, reset } = useForm({ mode: 'onChange' })

	return (
		<div className="d-flex align-items-start gap-2 mb-3">
			<Image
				src={data.employee.profilePhotoUrl || anonimProfilePhoto}
				width={32}
				height={32}
				roundedCircle
				className="mb-5"
			/>
			<div className="d-flex flex-column w-100 gap-2 px-2">
				<div className="d-flex align-items-center gap-2">
					<b>{data.employee.fullName}</b>
					<span className="text-muted">
						<small>
							<Timeago date={data.createDate} />
						</small>
					</span>
				</div>
				<div className="d-flex flex-column gap-1">
					{isEditing ? (
						<div className="d-flex align-items-center gap-2">
							<Form.Control
								type="text"
								{...register('content', { required: 'bu alan gerekli.' })}
								onKeyDown={handleKeyPress}
							/>
							<SaveButton icon={null} onClick={handleSubmit(handleUpdate)} />
						</div>
					) : (
						<span className="border rounded p-2">{data.content}</span>
					)}
					{user._id === data.employee._id && (
						<div className="d-flex align-items-center gap-2 text-muted ">
							<>
								<span className="text-decoration-underline cursor-pointer" onClick={() => handleEditClick()}>
									<small>Düzenle</small>
								</span>
								<span className="text-decoration-underline cursor-pointer" onClick={() => handleDelete()}>
									<small>Sil</small>
								</span>
							</>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default TasksComment
