import { CustomPopperTooltip, EditableInput, FaIcon } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect } from 'react'
import { Card, Col, Dropdown, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { refreshCompanyStructures } from 'stores/settings/companyStructureSlice'

import { Menu, MenuItem } from '@szhsin/react-menu'

import CompanyStructureCard from './components/CompanyStructureCard'

const companyStructureTypes = [
	{ value: 'location', icon: 'location-pin', label: 'Şube' },
	{ value: 'department', icon: 'apartment', label: 'Departman' },
	{ value: 'title', icon: 'user-tie', label: 'Ünvan' }
]

const CompanyStructures = () => {
	const params = useParams()
	const { tab_name, item_id, sub_item_id } = params

	const dispatch = useDispatch()
	const navigate = useNavigate()

	//redux state
	const { companyStructures } = useSelector((state) => state.companyStructure)

	useEffect(() => {
		dispatch(refreshCompanyStructures())
	}, [])

	const createCompanyStructure = async (sequence) => {
		const body = {
			name: 'Yeni Birim',
			sequence: sequence
		}
		try {
			const response = await Service.post('/company-structures', body)
			dispatch(refreshCompanyStructures())
			sendToast('Birim oluşturuldu.')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const updateCompanyStructure = async (id, name) => {
		const body = {
			name: name
		}
		try {
			const response = await Service.patch(`/company-structures/${id}`, body)
			dispatch(refreshCompanyStructures())
			sendToast('Birim güncellendi.')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleChangeType = async (id, type) => {
		const body = {
			type: type
		}
		try {
			const response = await Service.patch(`/company-structures/${id}/set-type`, body)
			dispatch(refreshCompanyStructures())
			sendToast('Birim güncellendi.')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<a
			href=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault()
				onClick(e)
			}}
			className="px-2"
		>
			{children}
		</a>
	))

	const handleColorChange = (companyStructure_id) => {
		navigate(`/settings/company-structure/${companyStructure_id}/color`)
	}

	const handleDelete = (companyStructure_id) => {
		navigate(`/settings/company-structure/${companyStructure_id}/delete`)
	}

	return (
		<>
			<Col md={4}>
				<p className="mb-4">
					<strong>Şirket Yapısı</strong>
					<p>Kullanmak istediğiniz şirket yapısını belirleyin.</p>
				</p>

				{companyStructures.map((companyStructure) => (
					<>
						{!companyStructure.isLocked && (
							<div className="d-flex align-items-center justify-content-center mb-2">
								<span
									className="cursor-pointer text-center text-secondary"
									onClick={() => createCompanyStructure(companyStructure.sequence)}
								>
									<FaIcon icon="plus" size="xs" />
								</span>
							</div>
						)}

						<CompanyStructureCard
							companyStructure={companyStructure}
							updateCompanyStructure={updateCompanyStructure}
							companyStructureTypes={companyStructureTypes}
							handleChangeType={handleChangeType}
							handleColorChange={handleColorChange}
							handleDelete={handleDelete}
						/>
					</>
				))}
				<div className="d-flex align-items-center justify-content-center mb-0">
					<span
						className="cursor-pointer text-center text-secondary"
						onClick={() => createCompanyStructure(companyStructures.length)}
					>
						<FaIcon icon="plus" size="xs" />
					</span>
				</div>
			</Col>
		</>
	)
}

export default CompanyStructures
