import React from 'react'

import BreadcrumbItem from './BreadcrumbItem'

const BreadcrumbContainer = ({ data, breadcrumbItemClick }) => {
	return (
		<>
			{data?.map((item, index) => (
				<BreadcrumbItem
					key={item._id}
					id={item._id}
					data={item}
					name={item.digitalName}
					onClick={breadcrumbItemClick}
					isActive={index === data.length - 1}
				/>
			))}
		</>
	)
}

export default BreadcrumbContainer
