import { CloseButton, ContinueButton, CreateButton, DeleteButton, SaveButton } from 'components/fast-ui'
import { ButtonContainer, FaIcon } from 'components/ui'
import CustomReactSelect from 'components/ui/CustomReactSelect'
import { modules } from 'constants/Modules'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Button, Modal, Row, Stack } from 'react-bootstrap'
import { Col, Form } from 'react-bootstrap'
import ReactDOM from 'react-dom'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { ErrorMessage } from '@hookform/error-message'

const ApprovalModal = () => {
	const params = useParams()
	const navigate = useNavigate()

	const { item_id } = params
	const [selectedApprovalProcess, setSelectedApprovalProcess] = useState(null)

	const [isNew, setIsNew] = useState(true)
	const [show, setShow] = useState(false)
	const [employees, setEmployees] = useState([])
	const [filteredEmployees, setFilteredEmployees] = useState([])
	const [selectedEmployees, setSelectedEmployees] = useState([])

	const fetchApprovalProcess = async () => {
		try {
			const response = await Service.get(`/approval-processes/${item_id}`)
			setSelectedApprovalProcess(response.data)
			setSelectedEmployees(response.data.items)
			reset(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (item_id) {
			setShow(true)
			if (item_id === 'add') {
				setIsNew(true)
			} else {
				setIsNew(false)
				fetchApprovalProcess()
			}
		} else {
			setShow(false)
		}
	}, [item_id])

	const fetchEmployees = async () => {
		try {
			const response = await Service.get('/employees')
			setEmployees(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	//add employe with order
	const handleAddEmployee = (employee) => {
		const newEmployee = { employee: employee, order: selectedEmployees.length + 1 }
		setSelectedEmployees([...selectedEmployees, newEmployee])
	}

	const getUnselectedEmployees = (employees) => {
		return employees.filter((employee) => {
			return !selectedEmployees.find((selectedEmployee) => selectedEmployee.employee._id === employee._id)
		})
	}

	useEffect(() => {
		fetchEmployees()
	}, [])

	useEffect(() => {
		const unSelectedEmployees = getUnselectedEmployees(employees)
		setFilteredEmployees(unSelectedEmployees)
	}, [employees, selectedEmployees])

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState,
		watch,
		formState: { errors }
	} = useForm({ mode: 'onChange' })

	const handleCreate = async (data) => {
		const body = data
		try {
			const response = await Service.post('/approval-processes', body)
			navigate(`/settings/approval-processes`)
			sendToast('Onay süreci başarıyla oluşturuldu.', true)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleSave = async (data) => {
		const body = data
		try {
			const response = await Service.patch(`/approval-processes/${item_id}`, body)
			navigate(`/settings/approval-processes`)
			sendToast('Onay süreci başarıyla güncellendi.', true)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDelete = async () => {
		try {
			const response = await Service.delete(`/approval-processes/${item_id}`)
			navigate(`/settings/approval-processes`)
			sendToast('Onay süreci başarıyla silindi.', true)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (show) {
			setValue('items', selectedEmployees)
		}
	}, [selectedEmployees])

	const onDragEnd = (result) => {
		if (!result.destination) return
		const items = Array.from(selectedEmployees)

		//reorder with order
		const [reorderedItem] = items.splice(result.source.index, 1)
		items.splice(result.destination.index, 0, reorderedItem)

		//change order
		items.forEach((item, index) => {
			item.order = index + 1
		})

		setSelectedEmployees(items)
	}

	const handleDeleteEmployee = (employee) => {
		const filteredEmployees = selectedEmployees.filter((selectedEmployee) => {
			return selectedEmployee._id !== employee._id
		})

		//change order
		filteredEmployees.forEach((item, index) => {
			item.order = index + 1
		})

		setSelectedEmployees(filteredEmployees)
	}

	const portal = document.createElement('div')
	document.body.appendChild(portal)

	function PortalAwareItem({ provided, snapshot, item }) {
		const usePortal = snapshot.isDragging

		const child = (
			<div
				className="d-flex align-items-center justify-content-between"
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
			>
				<div className="border rounded p-2 mb-1 w-100 me-3">
					{item.employee.firstName + ' ' + item.employee.lastName}
				</div>
				<FaIcon onClick={() => handleDeleteEmployee(item)} icon="times" className="text-danger" />
			</div>
		)

		if (!usePortal) {
			return child
		}

		// if dragging - put the item in a portal
		return ReactDOM.createPortal(child, portal)
	}

	const handleClose = () => {
		navigate(`/settings/approval-processes`)
		setShow(false)
	}

	return (
		<Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'}>
					<FaIcon icon="table-rows" className="me-2" />
					{isNew ? 'Yeni Onay Süreci' : 'Onay Süreci Düzenle'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Stack gap={3}>
						<Controller
							name="name"
							control={control}
							defaultValue=""
							rules={{ required: 'Gerekli alan.' }}
							render={({ field }) => (
								<Form.Group>
									<Form.Label>Adı</Form.Label>
									<Form.Control type="text" {...field} />
									<Form.Control.Feedback type="invalid">
										<ErrorMessage errors={errors} name="name" />
									</Form.Control.Feedback>
								</Form.Group>
							)}
						/>

						<Form.Group>
							<Form.Label>Personel Ekle</Form.Label>
							<CustomReactSelect
								placeholder="Ara..."
								value={null}
								onChange={(e) => {
									handleAddEmployee(e)
								}}
								options={filteredEmployees}
								getOptionValue={(option) => option._id}
								getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>Sıralama</Form.Label>
							{selectedEmployees.length > 0 ? (
								<DragDropContext onDragEnd={onDragEnd}>
									<Droppable droppableId="droppable">
										{(provided) => (
											<div {...provided.droppableProps} ref={provided.innerRef}>
												{selectedEmployees.map((item, index) => (
													<>
														<Draggable key={item.employee._id} draggableId={item.employee._id} index={index}>
															{(provided, snapshot) => (
																<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
																	<PortalAwareItem item={item} provided={provided} snapshot={snapshot} />
																</div>
															)}
														</Draggable>
													</>
												))}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</DragDropContext>
							) : (
								<p className="text-muted">Henüz personel eklenmedi.</p>
							)}
						</Form.Group>
					</Stack>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<ButtonContainer>
					{isNew ? (
						<>
							<CloseButton onClick={handleClose} />
							<CreateButton onClick={handleSubmit((d) => handleCreate(d))} />
						</>
					) : (
						<>
							<DeleteButton onClick={handleDelete} />
							<SaveButton onClick={handleSubmit((d) => handleSave(d))} />
						</>
					)}
				</ButtonContainer>

				<button onClick={() => console.log(selectedEmployees)} className="btn btn-primary">
					console log employe
				</button>
			</Modal.Footer>
		</Modal>
	)
}

export default ApprovalModal
