import React from 'react'

import CleanTrashModal from './CleanTrashModal'
import DeleteModal from './DeleteModal'
import DeletePermanentModal from './DeletePermanentModal'
import DeleteTreeItemModal from './DeleteTreeItemModal'
import EditTagModal from './EditTagModal'
import InfoModal from './InfoModal'
import MoveModal from './MoveModal'
import NewFolderModal from './NewFolderModal'
import RenameModal from './RenameModal'
import ReviseModal from './ReviseModal'
import UndoTrashModal from './UndoTrashModal'
import UploadModal from './upload-modal'

export const DocumentModals = () => {
	return (
		<>
			<ReviseModal />
			<RenameModal />
			<UploadModal />
			<DeleteModal />
			<DeleteTreeItemModal />
			<MoveModal />
			<InfoModal />
			<NewFolderModal />
			<CleanTrashModal />
			<DeletePermanentModal />
			<UndoTrashModal />
			<EditTagModal />
		</>
	)
}
