import { CustomPopperTooltip, EditableInput, FaIcon } from 'components/ui'
import React, { useState } from 'react'
import { Card, Dropdown, Form } from 'react-bootstrap'

import { Menu, MenuItem } from '@szhsin/react-menu'

import ColorModal from '../../company-structure-modals/structures-modals/CompanyStructureColorModal'

const CompanyStructureCard = ({
	companyStructure,
	updateCompanyStructure,
	companyStructureTypes,
	handleChangeType,
	handleColorChange,
	handleDelete
}) => {
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<a
			href=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault()
				onClick(e)
			}}
			className="px-2"
		>
			{children}
		</a>
	))

	return (
		<Card className={`icon-card cursor-pointer text-center mb-2`} key={companyStructure._id}>
			<Card.Body className="px-4 py-2">
				<div className="d-flex align-items-center justify-content-between">
					<p className="icon-name text-capitalize text-truncate mb-0">
						<EditableInput
							type="text"
							value={companyStructure.name}
							placeholder="Birim adı girin..."
							onBlur={(e) => updateCompanyStructure(companyStructure._id, e.target.value)}
						/>
					</p>
					<div className="d-flex align-items-center justify-content-center">
						<p className="text-truncate mb-0 me-2">{companyStructure.isLocked && <FaIcon icon="lock" />}</p>
						{companyStructureTypes.map((companyStructureType) => (
							<React.Fragment key={companyStructureType.value}>
								{companyStructure.type === companyStructureType.value && (
									<>
										<FaIcon icon="circle-dot" style={{ color: companyStructure.hexColor }} className={'me-2'} />
										<CustomPopperTooltip text={companyStructureType.label}>
											<p className="text-truncate mb-0 me-2">
												<FaIcon icon={companyStructureType.icon} />
											</p>
										</CustomPopperTooltip>
									</>
								)}
							</React.Fragment>
						))}
						{!companyStructure.isLocked && (
							<Menu
								menuButton={
									<Dropdown.Toggle id="dropdown-custom-components" as={CustomToggle} className="btn-icon">
										<FaIcon icon="ellipsis-v" />
									</Dropdown.Toggle>
								}
							>
								<MenuItem onClick={() => handleChangeType(companyStructure._id, 'location')}>
									<FaIcon icon="location-pin" className="me-2" />
									Şube olarak belirle
								</MenuItem>
								<MenuItem onClick={() => handleChangeType(companyStructure._id, 'department')}>
									<FaIcon icon="apartment" className="me-2" />
									Departman olarak belirle
								</MenuItem>
								<MenuItem onClick={() => handleChangeType(companyStructure._id, 'title')}>
									<FaIcon icon="user-tie" className="me-2" />
									Ünvan olarak belirle
								</MenuItem>
								<MenuItem onClick={() => handleColorChange(companyStructure._id)}>
									<FaIcon icon="palette" className="me-2" />
									Renk belirle
								</MenuItem>
								<MenuItem onClick={() => handleDelete(companyStructure._id)}>
									<span className="text-danger">
										<FaIcon icon="trash" className="me-2" />
										Sil
									</span>
								</MenuItem>
							</Menu>
						)}
					</div>
				</div>
			</Card.Body>
		</Card>
	)
}

export default CompanyStructureCard
