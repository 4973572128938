import { CloseButton } from 'components/fast-ui'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectFolder, setMainItemsFromCloud, setMainSelectedItems } from 'stores/documentSlice'

import DocumentContainer from './DocumentContainer'
import DocumentItem from './DocumentItem'

const DefaultView = () => {
	const dispatch = useDispatch()

	const {
		items: mainItems,
		tagItems,
		selectedItems,
		trashItems,
		itemsFrom,
		detailedView
	} = useSelector((state) => state.document.main)
	const search = useSelector((state) => state.document.main.search)
	const tagList = useSelector((state) => state.document.tags.items)

	useEffect(() => {
		dispatch(setMainItemsFromCloud())
	}, [dispatch])

	const handleClickDocumentItem = (e, documentData) => {
		if (e.ctrlKey) {
			dispatch(setMainSelectedItems([...selectedItems, documentData]))
		} else {
			selectionResetDedector(documentData)
			dispatch(setMainSelectedItems([documentData]))
		}
	}

	const navigate = useNavigate()

	const handleDoubleClickDocumentItem = (e, documentData) => {
		if (itemsFrom === 'folder' || itemsFrom === 'tag') {
			if (documentData.isFolder) {
				navigate(`/documents/folder/${documentData._id}`)
				//dispatch(selectFolder(props._id))
			}
		}
	}

	const selectionResetDedector = (documentData) => {
		if (selectedItems.filter((item) => item._id === documentData._id).length === 0) {
			dispatch(setMainSelectedItems([documentData]))
		}
	}

	const renderList = (constList) => {
		const sortedList = [...constList].sort((a, b) => b.isFolder - a.isFolder || b.digitalName - a.digitalName)
		return sortedList.map(({ id, uuid, digitalName, extension, isFolder, tags, size, uploadDate }) => {
			const itemData = sortedList.filter((item) => item.id === id)[0]
			const selectedStatus = selectedItems.some((item) => item.id === id)
			return (
				<DocumentItem
					data={itemData}
					tagList={tagList}
					size={size}
					uploadDate={uploadDate}
					tags={tags}
					selectionResetDedector={selectionResetDedector}
					onClick={handleClickDocumentItem}
					onDoubleClick={handleDoubleClickDocumentItem}
					id={id}
					uuid={uuid}
					selected={selectedStatus}
					extension={extension}
					isFolder={isFolder}
					name={digitalName}
				/>
			)
		})
	}

	const documentState = useSelector((state) => state.document)

	return (
		<div>
			{detailedView && (
				<table className="table table-striped table-borderless">
					<thead>
						<tr>
							<th scope="row">Ad</th>
							<th scope="col">Tür</th>
							<th scope="col">Boyut</th>
							<th scope="col">Etiket</th>
							<th scope="col">Oluşturma Tarihi</th>
						</tr>
					</thead>
					<tbody>
						<>
							{!search.showStatus && itemsFrom === 'folder' && (
								<DocumentContainer
									data={mainItems}
									tagList={tagList}
									selectedItems={selectedItems}
									selectionResetDedector={selectionResetDedector}
									handleClickDocumentItem={handleClickDocumentItem}
									handleDoubleClickDocumentItem={handleDoubleClickDocumentItem}
								/>
							)}
							{!search.showStatus && itemsFrom === 'trash' && (
								<DocumentContainer
									data={trashItems}
									tagList={tagList}
									selectedItems={selectedItems}
									selectionResetDedector={selectionResetDedector}
									handleClickDocumentItem={handleClickDocumentItem}
									handleDoubleClickDocumentItem={handleDoubleClickDocumentItem}
								/>
							)}
							{!search.showStatus && itemsFrom === 'tag' && (
								<DocumentContainer
									data={tagItems}
									tagList={tagList}
									selectedItems={selectedItems}
									selectionResetDedector={selectionResetDedector}
									handleClickDocumentItem={handleClickDocumentItem}
									handleDoubleClickDocumentItem={handleDoubleClickDocumentItem}
								/>
							)}
							{search.showStatus && (
								<DocumentContainer
									data={search.items}
									tagList={tagList}
									selectedItems={selectedItems}
									selectionResetDedector={selectionResetDedector}
									handleClickDocumentItem={handleClickDocumentItem}
									handleDoubleClickDocumentItem={handleDoubleClickDocumentItem}
								/>
							)}
							{/* {!search.showStatus && itemsFrom === 'folder' && renderList(items)}
					{!search.showStatus && itemsFrom === 'tag' && renderList(tagItems)}
					{search.showStatus && renderList(search.items)} */}
						</>
					</tbody>
				</table>
			)}
			{!detailedView && (
				<>
					{!search.showStatus && itemsFrom === 'folder' && (
						<DocumentContainer
							data={mainItems}
							tagList={tagList}
							selectedItems={selectedItems}
							selectionResetDedector={selectionResetDedector}
							handleClickDocumentItem={handleClickDocumentItem}
							handleDoubleClickDocumentItem={handleDoubleClickDocumentItem}
						/>
					)}
					{!search.showStatus && itemsFrom === 'trash' && (
						<DocumentContainer
							data={trashItems}
							tagList={tagList}
							selectedItems={selectedItems}
							selectionResetDedector={selectionResetDedector}
							handleClickDocumentItem={handleClickDocumentItem}
							handleDoubleClickDocumentItem={handleDoubleClickDocumentItem}
						/>
					)}
					{!search.showStatus && itemsFrom === 'tag' && (
						<DocumentContainer
							data={tagItems}
							tagList={tagList}
							selectedItems={selectedItems}
							selectionResetDedector={selectionResetDedector}
							handleClickDocumentItem={handleClickDocumentItem}
							handleDoubleClickDocumentItem={handleDoubleClickDocumentItem}
						/>
					)}
					{search.showStatus && (
						<DocumentContainer
							data={search.items}
							tagList={tagList}
							selectedItems={selectedItems}
							selectionResetDedector={selectionResetDedector}
							handleClickDocumentItem={handleClickDocumentItem}
							handleDoubleClickDocumentItem={handleDoubleClickDocumentItem}
						/>
					)}
					{/* {!search.showStatus && itemsFrom === 'folder' && renderList(items)}
					{!search.showStatus && itemsFrom === 'tag' && renderList(tagItems)}
					{search.showStatus && renderList(search.items)} */}
				</>
			)}
		</div>
	)
}

export default DefaultView
