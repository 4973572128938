import anonimProfilePhoto from 'assets/img/avatars/anonim-profile-photo.jpg'
import CustomReactSelect from 'components/ui/CustomReactSelect'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { insertEmployeeToScheme } from 'stores/ikOrganizationSlice'

const EmployeeList = () => {
	const dispatch = useDispatch()

	const [employees, setEmployees] = useState([])
	const [departments, setDepartments] = useState([])
	const [titles, setTitles] = useState([])
	const [locations, setLocations] = useState([])

	const [selectedDepartment, setSelectedDepartment] = useState(null)
	const [selectedTitle, setSelectedTitle] = useState(null)
	const [selectedLocation, setSelectedLocation] = useState(null)
	const [nameFilter, setNameFilter] = useState('')

	const [filteredEmployees, setFilteredEmployees] = useState([])

	const { selectedOrganization } = useSelector((state) => state.ikOrganization)

	useEffect(() => {}, [selectedOrganization])

	const fetchEmployees = async () => {
		try {
			const employees = await Service.get('/employees')
			setEmployees(employees.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchDepartments = async () => {
		try {
			const departments = await Service.get('/company-units/departments')
			setDepartments(departments.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchTitles = async () => {
		try {
			const titles = await Service.get('/company-units/titles')
			setTitles(titles.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchLocations = async () => {
		try {
			const locations = await Service.get('/company-units/locations')
			setLocations(locations.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchEmployees()
		fetchDepartments()
		fetchTitles()
		fetchLocations()
	}, [])

	const filterEmployees = () => {
		// Compute flatTempItems only once
		const flatTempItems = getChilds(null, selectedOrganization.tempItems)

		// Get filteredEmployees with a single filter function
		const filteredEmployees = employees.filter((employee) => {
			// Get employee._id only once
			const { _id } = employee

			// Check isEmployeeInScheme with some method
			const isEmployeeInScheme = flatTempItems.some((item) => item.employee === _id)

			// Destructure employee.position
			const { department, title, location } = employee?.position || {}

			// Write filtering conditions with logical operators
			return (
				!isEmployeeInScheme &&
				(!selectedDepartment || department?._id === selectedDepartment) &&
				(!selectedTitle || title?._id === selectedTitle) &&
				(!selectedLocation || location?._id === selectedLocation) &&
				(!nameFilter || `${employee.firstName} ${employee.lastName}`.toLowerCase().includes(nameFilter.toLowerCase()))
			)
		})

		setFilteredEmployees(filteredEmployees)
	}

	useEffect(() => {
		filterEmployees()
	}, [employees, selectedOrganization.tempItems, selectedDepartment, selectedTitle, nameFilter, selectedLocation])

	const handleClick = (item) => {
		const newItem = { _id: item._id, employee: item }
		dispatch(insertEmployeeToScheme(newItem))
	}

	const getChilds = (parent_id, data) => {
		let array = []
		data.forEach((element) => {
			const item = { employee: element.employee._id, parentEmployee_id: parent_id }
			array = [...array, item]

			if (element.children) {
				const childs = getChilds(element.employee._id, element.children)
				array = [...array, ...childs]
			}
		})
		return array
	}

	return (
		<Card>
			<Card.Header>
				<h5>Personeller</h5>
			</Card.Header>
			<Card.Body>
				<Row className="mb-3">
					<Col md={3}>
						<input
							type="text"
							onChange={(e) => setNameFilter(e.target.value)}
							className="form-control"
							placeholder="Ad"
						/>
					</Col>
					<Col md={3}>
						<CustomReactSelect
							isClearable
							onChange={(d) => setSelectedLocation(d ? d._id : null)}
							placeholder="Şube.."
							options={locations}
							getOptionValue={(option) => option._id}
							getOptionLabel={(option) => option.name}
						/>
					</Col>
					<Col md={3}>
						<CustomReactSelect
							isClearable
							onChange={(d) => setSelectedDepartment(d ? d._id : null)}
							placeholder="Departman.."
							options={departments}
							getOptionValue={(option) => option._id}
							getOptionLabel={(option) => option.name}
						/>
					</Col>

					<Col md={3}>
						<CustomReactSelect
							isClearable
							onChange={(d) => setSelectedTitle(d ? d._id : null)}
							placeholder="Ünvan.."
							options={titles}
							getOptionValue={(option) => option._id}
							getOptionLabel={(option) => option.name}
						/>
					</Col>
				</Row>

				<Row>
					{filteredEmployees?.map((item) => (
						<div className="col-xl-6 col-lg-12">
							<div className="row mt-2 d-flex justify-content-start align-items-center">
								<div className="col-md-11 pe-2">
									<button type="button" className="btn btn-sm btn-label-secondary w-100">
										<div className="d-flex align-items-center flex-wrap">
											<div className="avatar avatar-xs me-2">
												{item?.profilePhotoUrl ? (
													<img src={item?.profilePhotoUrl} alt="Avatar" className="rounded-circle" />
												) : (
													<img src={anonimProfilePhoto} alt="Avatar" className="rounded-circle" />
												)}
											</div>
											<span className="me-3 fw-bold">{`${item.firstName} ${item.lastName}`}</span>
											<span className="me-0">
												<small>{item.position?.title?.name}</small> - <small>{item.position?.department?.name}</small>
											</span>
										</div>
									</button>
								</div>
								<div onClick={() => handleClick(item)} className="col-md-1 px-0">
									<i type="button" className="bx bx-plus text-success"></i>
								</div>
							</div>
						</div>
					))}
				</Row>
			</Card.Body>
		</Card>
	)
}

export default EmployeeList
