import Service from 'global/Service'
import DocumentIcon from 'pages/document/components/document-icon'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFolder } from 'stores/documentSlice'

const UploadItem = (props) => {
	const dispatch = useDispatch()
	const selectedFolderID = useSelector((state) => state.document.tree.selectedFolder.content._id)

	const deleteFile = () => {
		Service.delete(`/documents/${props.id}`)
			.then((res) => {
				props.file.deleted = true
				const filteredPreviewFiles = props.previewFiles.filter((item) => item !== props.file)
				props.setPreviewFiles([...filteredPreviewFiles, props.file])
				dispatch(selectFolder(selectedFolderID))
			})
			.catch((err) => {
				props.file.error = true
				const filteredPreviewFiles = props.previewFiles.filter((item) => item !== props.file)
				props.setPreviewFiles([...filteredPreviewFiles, props.file])
			})
	}

	return (
		<div className="row border rounded d-flex align-items-center justify-content-center py-3 mb-2">
			<div className="col-1">
				{['png', 'jpeg', 'jpg', 'gif'].includes(props.extension) ? (
					<img src={props.preview} style={{ width: 30, height: 30, objectFit: 'cover' }} alt="" />
				) : (
					<DocumentIcon className="me-2" style={{ width: 'auto', height: 30 }} extension={props.extension} />
				)}
			</div>
			<div className="col-9  text-truncate">
				<span className={props.error && 'text-danger'}>{props.name}</span>
			</div>
			<div className="col-2 d-flex align-items-center justify-content-center">
				{props.uploaded ? (
					<i onClick={deleteFile} className="fa-regular fa-trash" type="button"></i>
				) : props.error ? (
					<i className="fa-regular fa-triangle-exclamation text-danger" type="button"></i>
				) : (
					<i className="fa-regular fa-spinner fa-spin" type="button"></i>
				)}
			</div>
		</div>
	)
}

export default UploadItem
