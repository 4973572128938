import './FilePond.css'

import { NewDropzone } from 'components/ui'
import filePondTrTr from 'filepond/locale/tr-tr.js'
import Service from 'global/Service'
import DocumentBreadcrumb from 'pages/document/components/document-breadcrumb'
import React, { useEffect, useState } from 'react'
import { File, FilePond, registerPlugin } from 'react-filepond'
import { useDispatch, useSelector } from 'react-redux'
import { selectFolder } from 'stores/documentSlice'

import MyDropzone from './MyDropzone'
import UploadItem from './UploadItem'

//tr-tr.js

const UploadModal = () => {
	const dispatch = useDispatch()

	const [files, setFiles] = useState([])
	const [files2, setFiles2] = useState([])
	const [previewFiles, setPreviewFiles] = useState([])

	const selectedFolderID = useSelector((state) => state.document.tree.selectedFolder.content._id)

	useEffect(() => {
		setFiles([])
	}, [selectedFolderID])

	useEffect(() => {
		setPreviewFiles([...previewFiles, ...files])
	}, [files])

	useEffect(() => {
		previewFiles.map((item) => (item.uploaded === false ? uploadFile(item) : null))
	}, [previewFiles])

	const uploadFile = (file) => {
		const formData = new FormData()

		formData.append('file', file, file.name)

		Service.post(`/documents/folders/${selectedFolderID}`, formData)
			.then((response) => {
				file.uploaded = true
				const filteredPreviewFiles = files.filter((item) => item !== file)
				file = Object.assign(file, { id: response.data._id })
				setPreviewFiles([...filteredPreviewFiles, file])
				dispatch(selectFolder(selectedFolderID))
			})
			.catch((err) => {
				file.error = true
				const filteredPreviewFiles = files.filter((item) => item !== file)
				setPreviewFiles([...filteredPreviewFiles, file])
			})
	}

	return (
		<div className="modal fade" id="fileUploadModal" tabIndex={-1} aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="fileUploadModalTitle">
							<i className="fa-regular fa-upload me-2"></i>Dosya Yükle
						</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
					</div>
					<div className="modal-body px-5">
						<FilePond
							{...filePondTrTr}
							//yapımcı adını gizleme

							credits={false}
							files={files2}
							allowMultiple={true}
							allowReorder={true}
							maxFiles={50}
							maxParallelUploads={2}
							server={{
								process: (fieldName, file, metadata, load, error, progress, abort) => {
									// Burada Axios kullanarak API sorgusunu özelleştirebilirsiniz
									const formData = new FormData()
									formData.append(fieldName, file, file.name)
									console.log(fieldName)

									Service.post(`/documents/folders/${selectedFolderID}`, formData, {
										onUploadProgress: (e) => {
											progress(e.lengthComputable, e.loaded, e.total)
										}
									})
										.then((response) => {
											// Başarılı yanıt geldiğinde yapılacak işlemler
											dispatch(selectFolder(selectedFolderID))

											load(response.data._id)
										})
										.catch((err) => {
											// Hata durumunda yapılacak işlemler
											error('API hatası')
											/* abort() */
										})
								},

								revert: (uniqueFileId, load, error, abort) => {
									console.log(uniqueFileId)
									Service.delete(`/documents/${uniqueFileId}`)
										.then((response) => {
											// Başarılı yanıt geldiğinde yapılacak işlemler
											dispatch(selectFolder(selectedFolderID))

											load(response.data._id)
										})
										.catch((error) => {
											// Hata durumunda yapılacak işlemler
											error('API hatası')
											abort()
										})
								}
							}}
							name="file"
							oninit={() => this.handleInit()}
							onupdatefiles={(fileItems) => {
								// Set currently active file objects to this.state
								setFiles2(fileItems.map((fileItem) => fileItem.file))
							}}
						/>

						{previewFiles
							.filter((item) => item.deleted === false)
							.map((item) => (
								<UploadItem
									file={item}
									id={item.id}
									setPreviewFiles={setPreviewFiles}
									previewFiles={previewFiles}
									name={item.name}
									extension={item.name.split('.').pop()}
									preview={item.preview}
									uploaded={item.uploaded}
									error={item.error}
									deleted={item.deleted}
								/>
							))}
					</div>
					<div className="modal-footer d-flex justify-content-center">
						<div>
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb breadcrumb-style1 ms-1 mb-4">
									<DocumentBreadcrumb />
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default UploadModal
