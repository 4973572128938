import { CloseButton, CreateButton, DeleteButton, SaveButton } from 'components/fast-ui'
import Service from 'global/Service'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

const CompetenceTypeModal = ({ id, show, onHide }) => {
	const [isEditing, setIsEditing] = useState(false)

	const fetchData = async () => {
		try {
			const res = await Service.get(`/competence-types/${id}`)
			reset(res.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (id) {
			if (id === 'add') {
				setIsEditing(false)
			} else {
				fetchData()
				setIsEditing(true)
			}
		}
	}, [id])

	const handleClose = () => {
		onHide && onHide()
	}

	const handleCreate = async (data) => {
		try {
			const res = await Service.post('/competence-types', data)
			sendToast('Yetkinlik türü başarıyla oluşturuldu')
			onHide && onHide()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDelete = async (data) => {
		try {
			const res = await Service.delete(`/competence-types/${id}`)
			sendToast('Yetkinlik türü başarıyla silindi')
			onHide && onHide()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleSave = async (data) => {
		try {
			const res = await Service.patch(`/competence-types/${id}`, data)
			sendToast('Yetkinlik türü başarıyla güncellendi')
			onHide && onHide()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		formState,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	return (
		<Modal show={show} onHide={handleClose} area-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title as="h5">{isEditing ? 'Yetkinlik Türü Düzenle' : 'Yetkinlik Türü Ekle'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-3">
						<Form.Label>Yetkinlik Türü Adı</Form.Label>
						<Form.Control
							type="text"
							placeholder="Yetkinlik Türü Adı"
							{...register('name', { required: 'Bu alan gerekli.' })}
						/>
						{errors.name && <Form.Text className="text-danger">{errors.name.message}</Form.Text>}
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<CloseButton onClick={handleClose} />
				{isEditing ? (
					<>
						<DeleteButton onClick={handleSubmit((d) => handleDelete(d))} />
						<SaveButton onClick={handleSubmit((d) => handleSave(d))} />
					</>
				) : (
					<>
						<CreateButton onClick={handleSubmit((d) => handleCreate(d))} />
					</>
				)}
			</Modal.Footer>
		</Modal>
	)
}

export default CompetenceTypeModal
