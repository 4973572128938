import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import { toast } from 'react-toastify'

import { createSlice } from '@reduxjs/toolkit'

export const documentSlice = createSlice({
	name: 'document',
	initialState: {
		main: {
			pathID: null,
			items: [],
			trashItems: [],
			tagItems: [],
			itemsFrom: 'folder', //folder-tag-trash
			selectedItems: [],
			detailedView: false,
			search: {
				value: null,
				showStatus: false,
				items: []
			},
			preview: {
				showStatus: false,
				isReviseTab: false
			}
		},
		context: {
			info: {
				name: null,
				totalSize: null,
				totalChildFiles: null,
				totalChildFolders: null,
				breadcrumb: [],
				createDate: null,
				updateDate: null
			},
			rename: {
				id: null,
				value: null
			},
			move: {
				targetFolderID: null
			}
		},
		tree: {
			items: [],
			newFolder: {
				digitalName: null
			},
			selectedFolder: {
				content: {
					_id: null,
					id: 0,
					uuid: null,
					isFolder: null,
					orginalName: null,
					digitalName: null,
					extension: null,
					type: null,
					size: null,
					parentID: null,
					colorTagID: null,
					uploaderID: null,
					uploadDate: null
				},
				info: {}
			},
			focusedFolder: {
				content: {
					id: 0,
					uuid: null,
					isFolder: null,
					orginalName: null,
					digitalName: null,
					extension: null,
					type: null,
					size: null,
					parentID: null,
					colorTagID: null,
					uploaderID: null,
					uploadDate: null
				}
			}
		},
		tags: {
			items: [],
			selectedTag: {
				content: {
					id: 0,
					name: null,
					hexColor: null
				}
			}
		}
	},
	reducers: {
		setContextMoveTargetFolderID: (state, action) => {
			state.context.move.targetFolderID = action.payload
		},
		setMainSearchValue: (state, action) => {
			state.main.search.value = action.payload
		},
		setMainSearchItems: (state, action) => {
			state.main.search.items = action.payload
		},
		setMainSearchShowStatus: (state, action) => {
			state.main.search.showStatus = action.payload
		},
		setMainTrashItems: (state, action) => {
			state.main.trashItems = action.payload
		},
		setMainItemsFrom: (state, action) => {
			state.main.itemsFrom = action.payload
		},
		setContextInfo: (state, action) => {
			state.context.info = action.payload
		},
		setContextRenameID: (state, action) => {
			state.context.rename.id = action.payload
		},
		setContextRenameValue: (state, action) => {
			state.context.rename.value = action.payload
		},
		setMainSelectedItems: (state, action) => {
			state.main.selectedItems = action.payload
		},
		setTreeItems: (state, action) => {
			state.tree.items = action.payload
		},
		setMainItems: (state, action) => {
			state.main.items = action.payload
		},
		setMainTagItems: (state, action) => {
			state.main.tagItems = action.payload
		},
		setSelectedFolderInfo: (state, action) => {
			state.tree.selectedFolder.info = action.payload
		},
		setSelectedFolderContent: (state, action) => {
			state.tree.selectedFolder.content = action.payload
		},
		setFocusedFolderContent: (state, action) => {
			state.tree.focusedFolder.content = action.payload
		},
		setSelectedFolderStatus: (state, action) => {
			state.tree.selectedFolder.status = action.payload
		},
		setSelectedFolderDigitalName: (state, action) => {
			state.tree.selectedFolder.content.digitalName = action.payload
		},
		setTreeNewFolderDigitalName: (state, action) => {
			state.tree.newFolder.digitalName = action.payload
		},
		setTagItems: (state, action) => {
			state.tags.items = action.payload
		},
		setSelectedTag: (state, action) => {
			state.tags.selectedTag = action.payload
		},
		setSelectedTagContent: (state, action) => {
			state.tags.selectedTag.content = action.payload
		},
		setSelectedTagStatus: (state, action) => {
			state.tags.selectedTag.status = action.payload
		},
		setSelectedTagID: (state, action) => {
			state.tags.selectedTag.content.id = action.payload
		},
		setSelectedTagName: (state, action) => {
			state.tags.selectedTag.content.name = action.payload
		},
		setSelectedTagHexColor: (state, action) => {
			state.tags.selectedTag.content.hexColor = action.payload
		},
		setMainDetailedView: (state, action) => {
			state.main.detailedView = action.payload
		},
		setMainPreviewStatus: (state, action) => {
			state.main.preview.status = action.payload
		},
		setMainPreviewIsReviseTab: (state, action) => {
			state.main.preview.isReviseTab = action.payload
		}
	}
})

export const {
	setContextInfo,
	setContextMoveTargetFolderID,
	setContextRenameID,
	setContextRenameValue,
	setFocusedFolderContent,
	setMainDetailedView,
	setMainItems,
	setMainItemsFrom,
	setMainPreviewIsReviseTab,
	setMainPreviewStatus,
	setMainSearchItems,
	setMainSearchShowStatus,
	setMainSearchValue,
	setMainSelectedItems,
	setMainTagItems,
	setMainTrashItems,
	setSelectedFolderContent,
	setSelectedFolderDigitalName,
	setSelectedFolderInfo,
	setSelectedFolderStatus,
	setSelectedTag,
	setSelectedTagContent,
	setSelectedTagHexColor,
	setSelectedTagID,
	setSelectedTagName,
	setSelectedTagStatus,
	setTagItems,
	setTreeItems,
	setTreeNewFolderDigitalName
} = documentSlice.actions

//General
export const updateAllWithItemsFrom =
	(resetSelection = true) =>
	(dispatch, getState) => {
		const documentState = getState().document
		const itemsFrom = documentState.main.itemsFrom
		const selectedFolderID = documentState.tree.selectedFolder.content._id
		const selectedTagID = documentState.tags.selectedTag.content._id
		if (itemsFrom === 'folder') {
			dispatch(selectFolder(selectedFolderID, resetSelection))
		}
		if (itemsFrom === 'tag') {
			dispatch(selectTag(selectedTagID, resetSelection))
		}
		if (itemsFrom === 'trash') {
			//dispatch(selectTrash())
			dispatch(refreshTrashItems())
		}
		dispatch(refreshTreeList())
	}

export const refreshTreeList = () => (dispatch) => {
	let promise = Service.get('/documents/folders/cloud')
	promise.then((response) => {
		const filteredItems = response.data.filter((item) => item.isFolder === true)
		dispatch(setTreeItems(filteredItems))
	})
}

export const setMainItemsFromCloud = () => (dispatch) => {
	let promise = Service.get('/documents/folders/cloud')
	promise.then((response) => {
		const filteredItems = response.data.filter((item) => item.parent_id === undefined || item.parent_id === null)
		dispatch(setMainItems(filteredItems))
	})
}

export const setMainItemsFromFolder = (folderID) => (dispatch) => {
	let promise = Service.get(`/documents/folders/${folderID}`)
	promise.then((response) => {
		dispatch(setMainItems(response.data))
	})
}

export const refreshMainTagItems = (tagID) => async (dispatch) => {
	try {
		const tagItems = await Service.get(`/documents/tag/${tagID}`)
		dispatch(setMainTagItems(tagItems.data))
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

//Folder

export const fetchSelectedFolderInfo = (folderID) => async (dispatch) => {
	try {
		const info = await Service.get(`/documents/${folderID}/info`)
		dispatch(setSelectedFolderInfo(info.data))
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

export const selectFolder =
	(folderID, resetSelections = true) =>
	(dispatch) => {
		let promise = Service.get('/documents/folders/cloud')
		promise.then((response) => {
			resetSelections && dispatch(setMainSelectedItems([]))
			dispatch(setMainItemsFrom('folder'))
			//! dispatch(resetSelectedTag())
			dispatch(setMainSearchShowStatus(false))
			if (folderID === 'cloud' || folderID === null) {
				dispatch(
					setSelectedFolderContent({
						_id: 'cloud'
					})
				)
				dispatch(setMainItemsFromCloud())
				dispatch(setSelectedFolderInfo({}))
			} else {
				const filteredFolder = response.data.find((item) => item._id === folderID)
				dispatch(setSelectedFolderContent(filteredFolder))
				dispatch(fetchSelectedFolderInfo(folderID))
				dispatch(setMainItemsFromFolder(folderID))
			}
		})
	}

export const createFolder = (digitalName, parentID) => async (dispatch) => {
	const body = { parent_id: parentID, digitalName: digitalName, isFolder: true }

	try {
		const newFolder = await Service.post(`/documents`, body)
		sendToast('Klasör oluşturuldu.', true)
		dispatch(selectFolder(parentID))
		dispatch(updateAllWithItemsFrom())
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

// Tags

export const refreshTagList = () => async (dispatch) => {
	try {
		const tagList = await Service.get('/document-tags')
		dispatch(setTagItems(tagList.data))
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

export const selectTag =
	(tagID, resetSelects = true) =>
	async (dispatch) => {
		try {
			const selectedTag = await Service.get(`/document-tags/${tagID}`)
			dispatch(setSelectedTagContent(selectedTag.data))

			resetSelects && dispatch(setMainSelectedItems([]))
			//dispatch(resetSelectedTag())

			dispatch(refreshMainTagItems(tagID))
			dispatch(setMainItemsFrom('tag'))
			dispatch(setMainSearchShowStatus(false))
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

export const createTag = (selectedTagContent) => async (dispatch, getState) => {
	const documentState = getState().document
	const selectedTag = documentState.tags.selectedTag.content
	const body = selectedTag
	try {
		await Service.post(`/document-tags`, body)
		dispatch(refreshTagList())
		sendToast('Etiket oluşturuldu.', true)
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

export const patchSelectedTag = () => async (dispatch, getState) => {
	const documentState = getState().document
	const selectedTag = documentState.tags.selectedTag.content
	const body = selectedTag

	try {
		await Service.patch(`/document-tags/${selectedTag._id}`, body)
		dispatch(refreshTagList())
		sendToast('Etiket güncellendi.', true)
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

export const deleteTag = () => async (dispatch, getState) => {
	const documentState = getState().document
	const selectedTag = documentState.tags.selectedTag.content
	const body = selectedTag
	try {
		await Service.delete(`/document-tags/${selectedTag._id}`)
		dispatch(refreshTagList())
		sendToast('Etiket silindi.', true)
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

export const resetSelectedTag = () => (dispatch) => {
	dispatch(
		setSelectedTag({
			content: {
				_id: null,
				name: null,
				colorCode: null
			}
		})
	)
}

export const addTagToDocuments = (tag) => async (dispatch, getState) => {
	const documentState = getState().document
	const selectedItems = documentState.main.selectedItems
	const body = { documents: selectedItems, tagID: tag._id }
	try {
		await Service.patch(`/documents/bulk-tag/add`, body)
		dispatch(setMainSelectedItems(selectedItems.map((item) => ({ ...item, tags: [...item.tags, tag] }))))
		dispatch(updateAllWithItemsFrom(false))
		sendToast('Etiket ekleme başarılı.', true)
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

export const removeTagFromDocuments = (tag) => async (dispatch, getState) => {
	const documentState = getState().document
	const selectedItems = documentState.main.selectedItems
	const body = { documents: selectedItems, tagID: tag._id }
	try {
		await Service.patch(`/documents/bulk-tag/remove`, body)
		dispatch(
			setMainSelectedItems(
				selectedItems.map((item) => ({ ...item, tags: [...item.tags.filter((x) => x._id !== tag._id)] }))
			)
		)
		dispatch(updateAllWithItemsFrom(false))
		sendToast('Etiket ekleme başarılı.', true)
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

//Modals

export const changeDocumentName = (documentID, newName) => async (dispatch) => {
	const body = { documents: [{ _id: documentID }], newName: newName }

	try {
		const newNameDocument = await Service.patch(`/documents/bulk`, body)
		sendToast('Nesne yeniden adlandırıldı.', true)
		dispatch(updateAllWithItemsFrom())
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

export const setContextInfoFromID = (documentID) => async (dispatch) => {
	try {
		const info = await Service.get(`/documents/${documentID}/info`)
		dispatch(setContextInfo(info.data))
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

export const moveSelectedDocuments = () => async (dispatch, getState) => {
	const documentState = getState().document
	const selectedItems = documentState.main.selectedItems
	const moveTargetFolderID = documentState.context.move.targetFolderID
	const body = { documents: selectedItems, targetFolder_id: moveTargetFolderID }

	try {
		await Service.patch(`/documents/bulk`, body)
		dispatch(updateAllWithItemsFrom())

		if (selectedItems.length === 1) {
			sendToast('Nesne taşındı.', true)
		} else {
			sendToast('Nesneler taşındı.', true)
		}
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

export const moveFocusedFolder = () => async (dispatch, getState) => {
	const documentState = getState().document
	const focusedFolderData = documentState.tree.focusedFolder.content
	const moveTargetFolderID = documentState.context.move.targetFolderID
	const body = { documents: [focusedFolderData], targetFolder_id: moveTargetFolderID }

	try {
		await Service.patch(`/documents/bulk`, body)
		dispatch(updateAllWithItemsFrom())

		sendToast('Nesne taşındı.', true)
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

//Trash

export const refreshTrashItems = () => async (dispatch) => {
	try {
		const trashItems = await Service.get(`/documents/trash`)
		dispatch(setMainTrashItems(trashItems.data))
		dispatch(setMainItemsFrom('trash'))
		dispatch(setMainSelectedItems([]))
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

export const selectTrash = () => (dispatch) => {
	dispatch(setMainItemsFrom('trash'))
	dispatch(setMainSearchShowStatus(false))
	dispatch(
		setSelectedFolderContent({
			_id: 'trash'
		})
	)
	dispatch(refreshTrashItems())
}

export const sendTrashSelectedDocuments = () => async (dispatch, getState) => {
	const currentState = getState().document
	const selectedItems = currentState.main.selectedItems
	const body = { documents: selectedItems, inTrash: true }
	try {
		await Service.patch(`/documents/bulk`, body)
		dispatch(updateAllWithItemsFrom())
		if (selectedItems.length === 1) {
			sendToast('Nesne çöp kutusuna taşındı.', true)
		} else {
			sendToast('Nesneler çöp kutusuna taşındı', true)
		}
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

export const sendTrashFocusedTreeItem = () => async (dispatch, getState) => {
	const currentState = getState().document
	const focusedFolderContent = currentState.tree.focusedFolder.content
	const body = { documents: [focusedFolderContent], inTrash: true }

	dispatch(selectFolder(focusedFolderContent.parent_id))

	try {
		await Service.patch(`/documents/bulk`, body)
		dispatch(updateAllWithItemsFrom())
		sendToast('Nesne çöp kutusuna taşındı.', true)
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

export const undoFromTrashSelectedDocuments = () => async (dispatch, getState) => {
	const currentState = getState().document
	const selectedItems = currentState.main.selectedItems
	const body = { documents: selectedItems, inTrash: false }
	try {
		await Service.patch(`/documents/bulk`, body)
		dispatch(updateAllWithItemsFrom())
		if (selectedItems.length === 1) {
			sendToast('Nesne geri yüklendi.', true)
		} else {
			sendToast('Nesneler geri yüklendi', true)
		}
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

export const deleteFromTrashSelectedDocuments = () => async (dispatch, getState) => {
	const currentState = getState().document
	const body = { documents: currentState.main.selectedItems }
	try {
		await Service.post(`/documents/bulk-delete`, body)
		dispatch(updateAllWithItemsFrom())
		sendToast('Nesneler kalıcı olarak silindi.', true)
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

export const cleanTrash = () => async (dispatch, getState) => {
	try {
		await Service.delete(`/documents/trash/clean`)
		dispatch(updateAllWithItemsFrom())
		sendToast('Çöp kutusu boşaltıldı.', true)
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

//Search

export const searchItem = (keyword) => async (dispatch) => {
	try {
		if (keyword.length > 0) {
			const searchItems = await Service.get(`/documents/search/${keyword}`)
			dispatch(setMainSearchItems(searchItems.data))
		}
	} catch (error) {
		sendToast(error.response.data.message, false)
	}
}

export default documentSlice.reducer
