import { FaIcon } from 'components/ui'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectCompetence } from 'stores/ik/competence/ikCompetenceDefinitionSlice'

import { competenceTypeFilter } from './ColumnFilter'

const COLUMNS = [
	{
		Header: 'Ad',
		accessor: 'name'
	},
	{
		Header: 'Açıklama',
		accessor: 'description'
	},
	{
		Header: 'Tür',
		accessor: 'type.name',
		Filter: competenceTypeFilter
	},
	{
		Header: 'İşlem',
		Cell: ({ row }) => {
			return (
				<div>
					<Link to={`/ik-competencies/definitions/competencies/${row.original._id}`}>
						<FaIcon icon="edit" />
					</Link>
				</div>
			)
		}
	}
]

export default COLUMNS
