import { CloseButton, CreateButton, DeleteButton, SaveButton } from 'components/fast-ui'
import { CustomReactSelect } from 'components/ui'
import Service from 'global/Service'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'

const CompetenceModal = ({ id, show, onHide }) => {
	const [isEditing, setIsEditing] = useState(false)
	const [competenceTypes, setCompetenceTypes] = useState([])

	const fetchCompetenceTypes = async () => {
		try {
			const res = await Service.get('/competence-types')
			setCompetenceTypes(res.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchData = async () => {
		try {
			const res = await Service.get(`/competencies/${id}`)
			reset(res.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (id) {
			if (id === 'add') {
				setIsEditing(false)
			} else {
				fetchData()
				setIsEditing(true)
			}
		}
	}, [id])

	useEffect(() => {
		fetchCompetenceTypes()
	}, [])

	const handleClose = () => {
		onHide && onHide()
	}

	const handleCreate = async (data) => {
		try {
			const res = await Service.post('/competencies', data)
			sendToast('Yetkinlik başarıyla oluşturuldu')
			onHide && onHide()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDelete = async () => {
		try {
			const res = await Service.delete(`/competencies/${id}`)
			sendToast('Yetkinlik başarıyla silindi')
			onHide && onHide()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleSave = async (data) => {
		try {
			const res = await Service.patch(`/competencies/${id}`, data)
			sendToast('Yetkinlik başarıyla güncellendi')
			onHide && onHide()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		formState,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	return (
		<Modal show={show} onHide={handleClose} area-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title as="h5">{isEditing ? 'Yetkinlik Düzenle' : 'Yetkinlik Ekle'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-3">
						<Form.Label>Yetkinlik Adı</Form.Label>
						<Form.Control
							type="text"
							placeholder="Yetkinlik Adı"
							{...register('name', { required: 'Bu alan gerekli.' })}
						/>
						{errors.name && <Form.Text className="text-danger">{errors.name.message}</Form.Text>}
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Yetkinlik Açıklaması</Form.Label>
						<Form.Control
							as={'textarea'}
							rows={3}
							type="text"
							placeholder="Yetkinlik Açıklaması"
							{...register('description', { required: 'Bu alan gerekli.' })}
						/>
						{errors.description && <Form.Text className="text-danger">{errors.description.message}</Form.Text>}
					</Form.Group>
					<Form.Group>
						<Form.Label>Yetkinlik Türü</Form.Label>
						<Controller
							name="type"
							control={control}
							rules={{ required: 'Bu alan gerekli.' }}
							render={({ field }) => (
								<CustomReactSelect
									options={competenceTypes}
									placeholder="Yetkinlik Türü.."
									value={field.value}
									onChange={field.onChange}
									ref={field.ref}
									getOptionValue={(option) => option._id}
									getOptionLabel={(option) => option.name}
								/>
							)}
						/>
						{errors.type && <Form.Text className="text-danger">{errors.type.message}</Form.Text>}
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<CloseButton onClick={handleClose} />
				{isEditing ? (
					<>
						<DeleteButton onClick={handleDelete} />
						<SaveButton onClick={handleSubmit((d) => handleSave(d))} />
					</>
				) : (
					<>
						<CreateButton onClick={handleSubmit((d) => handleCreate(d))} />
					</>
				)}
			</Modal.Footer>
		</Modal>
	)
}

export default CompetenceModal
