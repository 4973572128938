import closedFolderIcon from 'assets/img/icons/documents/general/folder-closed.png'
import openedFolderIcon from 'assets/img/icons/documents/general/folder-opened.png'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	selectFolder,
	setContextInfoFromID,
	setContextRenameID,
	setContextRenameValue,
	setFocusedFolderContent,
	setMainSelectedItems
} from 'stores/documentSlice'

import Folder from './Folder'

const FolderTree = ({ folderList, parent_id, itemOnClick, disableSelectionsAndParents = false, forMove = false }) => {
	const dispatch = useDispatch()

	const handleClick = (folderID) => {
		itemOnClick && itemOnClick(folderID)
	}

	const folderHandleContextMenu = (folderData) => {
		dispatch(setFocusedFolderContent(folderData))
		dispatch(setMainSelectedItems([]))
	}

	const infoHandleClick = (folderID) => {
		dispatch(setContextInfoFromID(folderID))
	}

	const renameHandleClick = (folderID, folderName) => {
		dispatch(setContextRenameID(folderID))
		dispatch(setContextRenameValue(folderName))
	}
	// 🙂 "parent_id" === "cloud" ise parentID'si null veya undefined olanları getir
	if (parent_id === 'cloud') {
		parent_id = null
	}
	const newList = folderList.filter((item) => item.parent_id === parent_id)
	return newList.map((item) => {
		return (
			<>
				<Folder
					data={item}
					_id={item._id}
					name={item.digitalName}
					isHaveChild={item.isHaveChild}
					onClick={handleClick}
					onContextMenu={folderHandleContextMenu}
					openIcon={openedFolderIcon}
					closeIcon={closedFolderIcon}
					renameClick={renameHandleClick}
					infoClick={infoHandleClick}
					disableSelectionsAndParents={disableSelectionsAndParents}
					forMove={forMove}
				>
					<FolderTree
						folderList={folderList}
						parent_id={item._id}
						itemOnClick={itemOnClick}
						disableSelectionsAndParents={disableSelectionsAndParents}
						onContextMenu={folderHandleContextMenu}
						forMove={forMove}
					/>
				</Folder>
			</>
		)
	})
}

export default FolderTree
