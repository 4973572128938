import { CustomDatePicker, CustomReactSelect, FaIcon, TabButton, TabButtonContainer } from 'components/ui'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import React, { useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import Flatpickr from 'react-flatpickr'

import DismissalTab from './tabs/DismissalTab'
import StandartTab from './tabs/StandartTab'

const HRDashboard = () => {
	const [activeTab, setActiveTab] = useState('standart')
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)

	return (
		<Container fluid="xxl" className="flex-grow-1 container-p-y">
			<Row className="d-flex justify-content-between align-items-center">
				<Col md={8} className="mb-4">
					<TabButtonContainer>
						<TabButton
							text="Genel"
							icon="chart-line"
							active={activeTab === 'standart'}
							onClick={() => setActiveTab('standart')}
						/>
						<TabButton
							text="Ayrılma"
							icon="sign-out-alt"
							active={activeTab === 'dismissal'}
							onClick={() => setActiveTab('dismissal')}
						/>
						<CustomReactSelect
							options={[
								{ value: 'last7days', label: 'Son 7 Gün' },
								{ value: 'last30days', label: 'Son 30 Gün' },
								{ value: 'last1Month', label: 'Son 1 Ay' },
								{ value: 'last6Months', label: 'Son 6 Ay' },
								{ value: 'last1Year', label: 'Son 1 Yıl' }
							]}
							onChange={(option) => {
								switch (option.value) {
									case 'last7days':
										setStartDate(new Date(new Date().setDate(new Date().getDate() - 7)))
										setEndDate(new Date())
										break
									case 'last30days':
										setStartDate(new Date(new Date().setDate(new Date().getDate() - 30)))
										setEndDate(new Date())
										break
									case 'last1Month':
										setStartDate(new Date(new Date().setMonth(new Date().getMonth() - 1)))
										setEndDate(new Date())
										break
									case 'last6Months':
										setStartDate(new Date(new Date().setMonth(new Date().getMonth() - 6)))
										setEndDate(new Date())
										break
									case 'last1Year':
										setStartDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)))
										setEndDate(new Date())
										break
									default:
										break
								}
							}}
							components={{
								IndicatorSeparator: () => null
							}}
							placeholder="Tarih Aralığı"
							className="me-2"
						/>
					</TabButtonContainer>
				</Col>
				<Col md={4} className="d-flex align-items-center justify-content-between mb-4">
					<div className="me-2">
						<Flatpickr
							className="form-control"
							placeholder="Başlangıç Tarihi"
							options={{
								allowInput: true,
								dateFormat: 'd-m-Y',
								locale: { ...Turkish }
							}}
						/>
					</div>
					<div className="me-2">
						<FaIcon icon="arrow-right" />
					</div>
					<div className="me-2">
						<Flatpickr
							className="form-control"
							placeholder="Bitiş Tarihi"
							options={{
								allowInput: true,
								dateFormat: 'd-m-Y',
								locale: { ...Turkish }
							}}
						/>
					</div>
				</Col>
			</Row>
			{activeTab === 'standart' && <StandartTab />}
			{activeTab === 'dismissal' && <DismissalTab />}
		</Container>
	)
}

export default HRDashboard
