import anonimProfilePhoto from 'assets/img/avatars/anonim-profile-photo.jpg'
import { CloseButton, CreateButton, DeleteButton, SaveButton } from 'components/fast-ui'
import { ButtonContainer, CustomReactSelect, EmojiPicker } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import { useState } from 'react'
import { useEffect } from 'react'
import { Form, Modal, Stack } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

const WorkspaceModal = ({ onHide, isNew }) => {
	const params = useParams()
	const { workspace_id } = params
	const navigate = useNavigate()

	const [employees, setEmployees] = useState([])

	const fetchData = async () => {
		try {
			const response = await Service.get(`/workspaces/${workspace_id}`)
			reset(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchEmployees = async () => {
		try {
			const response = await Service.get('/employees')
			const activeEmployees = response.data.filter((employee) => employee.workingStatus === true)
			setEmployees(activeEmployees)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchEmployees()

		if (!isNew) {
			fetchData()
		}
	}, [])

	const handleClose = () => {
		onHide && onHide()
	}

	const handleCreate = async (data) => {
		const body = data
		try {
			const response = await Service.post('/workspaces', body)
			sendToast('Çalışma alanı oluşturuldu.')
			handleClose()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleSave = async (data) => {
		const body = data
		try {
			const response = await Service.patch(`/workspaces/${workspace_id}`, body)
			sendToast('Çalışma alanı güncellendi.')
			handleClose()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDelete = async (data) => {
		const body = data
		try {
			const response = await Service.delete(`/workspaces/${workspace_id}`, body)
			sendToast('Çalışma alanı silindi.')
			navigate('/workspaces')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState,
		watch,
		formState: { errors }
	} = useForm({ mode: 'onChange' })

	return (
		<>
			<Modal show={true} onHide={handleClose} centered>
				<Modal.Header closeButton>
					<Modal.Title as="h5">Çalışma Alanı {isNew ? 'Oluştur' : 'Düzenle'}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Stack gap={3}>
							<Form.Group>
								<Form.Label>Çalışma Alanı Adı</Form.Label>
								<Form.Control type="text" placeholder="Çalışma alanı adı" {...register('name', { required: true })} />
								<Form.Text className="text-muted">
									Bu, şirketinizin, takımınızın veya organizasyonunuzun adıdır.
								</Form.Text>
							</Form.Group>
							<Form.Group>
								<Form.Label>Çalışma Alanı Açıklaması</Form.Label>
								<Form.Control
									as="textarea"
									rows={3}
									placeholder="Takımımız burada her şeyi organize ediyor."
									{...register('description', { required: true })}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Üyeler</Form.Label>
								<Controller
									name="members"
									control={control}
									render={({ field }) => (
										<CustomReactSelect
											isMulti
											options={employees}
											value={field.value}
											onChange={field.onChange}
											placeholder="Üyeler..."
											getOptionLabel={(option) => (
												<div>
													<img
														src={option?.profilePhotoUrl || anonimProfilePhoto}
														alt="avatar"
														className="rounded-circle me-2"
														width="24"
														height="24"
													/>
													<span className="me-1">{option?.fullName}</span>
													<span>
														({option?.position?.department?.name} - {option?.position?.title?.name})
													</span>
												</div>
											)}
											getOptionValue={(option) => option._id}
										/>
									)}
								/>
								<Form.Text className="text-muted">Çalışma alanınıza dahil etmek istediğiniz üyeleri seçin.</Form.Text>
							</Form.Group>
						</Stack>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<ButtonContainer>
						<CloseButton onClick={handleClose} />
						{isNew ? (
							<CreateButton onClick={handleSubmit((data) => handleCreate(data))} />
						) : (
							<ButtonContainer>
								<DeleteButton onClick={handleSubmit((data) => handleDelete(data))} />
								<SaveButton onClick={handleSubmit((data) => handleSave(data))} />
							</ButtonContainer>
						)}
					</ButtonContainer>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default WorkspaceModal
