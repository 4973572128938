import { CloseButton, CreateButton, DeleteButton, SaveButton, SendButton } from 'components/fast-ui'
import { CustomFormCheck, CustomReactSelect, FaIcon, TabButton, TabButtonContainer } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Card, Col, Form, InputGroup, Modal, Row, Table } from 'react-bootstrap'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { ErrorMessage } from '@hookform/error-message'

const isPaidOptions = [
	{ value: false, label: 'Ücretsiz' },
	{ value: true, label: 'Ücretli' }
]
const limitationOptions = [
	{ value: 'unlimited', label: 'Limitsiz' },
	{ value: 'every_request_limited', label: 'Her Talep İçin Limitli' },
	{ value: 'yearly_limited', label: 'Yıl İçinde Limitli' }
]
const frequencyOptions = [
	{ value: 'daily', label: 'Günlük' },
	{ value: 'monthly', label: 'Aylık' },
	{ value: 'yearly', label: 'Yıllık' }
]

const calculationOptions = [
	{ value: 'half_day', label: 'Yarım Gün / Tam Gün' },
	{ value: 'hourly', label: 'Saat' }
]

const periodOptions = [
	{ value: 'end_of_period', label: 'Dönemin Sonunda' },
	{ value: 'start_of_period', label: 'Dönemin Başında' }
]

const VacationType = () => {
	const params = useParams()
	const navigate = useNavigate()
	const { tab_name, module_name, module_id } = params

	const [isNew, setIsNew] = useState(false)
	const [page, setPage] = useState(1)

	useEffect(() => {
		if (module_id) {
			if (module_id === 'add') {
				setIsNew(true)
			} else {
				setIsNew(false)
				fetchData()
			}
		}
	}, [params])

	const fetchData = async () => {
		try {
			const response = await Service.get(`/vacation-types/${module_id}`)
			reset(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		formState,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
		control,
		name: 'seniority'
	})

	const is_paid = watch('is_paid')
	const limitation = watch('limitation')

	const goPage = (page) => {
		handleSubmit((data) => setPage(page))()
	}

	const handleCreate = async (data) => {
		const body = { parent_rule: module_name, ...data }
		try {
			const response = await Service.post(`/vacation-types`, body)
			sendToast('İzin Türü Başarıyla Eklendi')
			navigate(`/vacations/rules/${module_name}/${response.data._id}`)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleSave = async (data) => {
		const body = data
		try {
			const response = await Service.patch(`/vacation-types/${module_id}`, body)
			sendToast('İzin Türü Başarıyla Güncellendi')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDelete = async (data) => {
		const body = data
		try {
			const response = await Service.delete(`/vacation-types/${module_id}`)
			sendToast('İzin Türü Başarıyla Silindi')
			navigate(`/vacations/rules/${module_name}`)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleSetPrimary = async (data) => {
		const body = data
		try {
			const response = await Service.patch(`/vacation-types/${module_id}/set-primary`)
			sendToast('İzin Türü Başarıyla Birincil Olarak Ayarlandı')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<Card>
			<Card.Header>
				<Card.Title as={'h5'}>{isNew ? 'Yeni İzin Türü' : 'İzin Türü Düzenle'}</Card.Title>
				<Row>
					<Col md={12}>
						{limitation === 'yearly_limited' ? (
							<TabButtonContainer>
								<TabButton text="Genel" icon="1" active={page === 1} onClick={() => goPage(1)} />
								<TabButton text="Hak Ediş" icon="2" active={page === 2} onClick={() => goPage(2)} />
								<TabButton text="Hak Ediş Sıklığı" icon="3" active={page === 3} onClick={() => goPage(3)} />
								<TabButton text="Devretme" icon="4" active={page === 4} onClick={() => goPage(4)} />
								<TabButton text="Kıdem" icon="5" active={page === 5} onClick={() => goPage(5)} />
								<TabButton text="Talep" icon="6" active={page === 6} onClick={() => goPage(6)} />
							</TabButtonContainer>
						) : (
							<TabButtonContainer>
								<TabButton text="Genel" icon="1" active={page === 1} onClick={() => goPage(1)} />
								<TabButton text="Hak Ediş" icon="2" active={page === 2} onClick={() => goPage(2)} />
								<TabButton text="Talep" icon="3" active={page === 6} onClick={() => goPage(6)} />
							</TabButtonContainer>
						)}
					</Col>
				</Row>
			</Card.Header>
			<Card.Body>
				<Row>
					<Form>
						{page === 1 && (
							<>
								<Col md={4} className="mb-4">
									<Controller
										name="name"
										control={control}
										defaultValue=""
										rules={{ required: 'Gerekli alan.' }}
										render={({ field }) => (
											<Form.Group>
												<Form.Label>İzin Türü Adı</Form.Label>
												<Form.Control type="text" {...field} isInvalid={errors.name} />
												<Form.Control.Feedback type="invalid">
													<ErrorMessage errors={errors} name="name" />
												</Form.Control.Feedback>
												<Form.Text>Bu izin türünün adı ne olmalı?</Form.Text>
											</Form.Group>
										)}
									/>
								</Col>
								<Col md={4} className="mb-4">
									<Controller
										name="description"
										control={control}
										defaultValue=""
										rules={{ required: 'Gerekli alan.' }}
										render={({ field }) => (
											<Form.Group>
												<Form.Label>İzin Türü Açıklaması</Form.Label>
												<Form.Control type="text" {...field} isInvalid={errors.description} />
												<Form.Control.Feedback type="invalid">
													<ErrorMessage errors={errors} name="description" />
												</Form.Control.Feedback>
												<Form.Text>Çalışanlar bu türü ne için kullanabilir?</Form.Text>
											</Form.Group>
										)}
									/>
								</Col>
								<Col md={4} className="mb-4">
									<Controller
										name="internal_code"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<Form.Group>
												<Form.Label>Dahili Kod</Form.Label>
												<InputGroup>
													<Form.Control type="text" {...field} isInvalid={errors.internal_code} />
													<InputGroup.Text>opsiyonel</InputGroup.Text>
												</InputGroup>
												<Form.Control.Feedback type="invalid">
													<ErrorMessage errors={errors} name="internal_code" />
												</Form.Control.Feedback>
												<Form.Text>Uygulamanızdaki aynı tür ile eşleştirmek için bu alanı kullanabilirsiniz.</Form.Text>
											</Form.Group>
										)}
									/>
								</Col>
							</>
						)}
						{page === 2 && (
							<>
								<Col md={4} className="mb-4">
									<Controller
										name="is_paid"
										control={control}
										defaultValue={isPaidOptions[0].value}
										render={({ field }) => (
											<Form.Group>
												<Form.Label>Ücretli / Ücretsiz</Form.Label>
												<CustomReactSelect
													value={isPaidOptions.filter((obj) => obj.value === field.value)}
													onChange={(d) => field.onChange(d?.value)}
													ref={field.ref}
													placeholder=""
													options={isPaidOptions}
												/>
												<Form.Text>Bu izin türü ücretli mi ücretsiz mi?</Form.Text>
											</Form.Group>
										)}
									/>
								</Col>

								{is_paid === false && (
									<>
										<Col md={4} className="mb-4">
											<Card>
												<Card.Header>
													<Card.Title as={'h6'}>Hak Ediş Tarihi</Card.Title>
													<Form.Text>Bu izin türü çalışanların izin hak ediş tarihlerini etkilesin mi?</Form.Text>
												</Card.Header>
												<Card.Body>
													<Controller
														name="should_affect_seniority"
														control={control}
														defaultValue={false}
														render={({ field }) => (
															<Form.Group>
																<CustomFormCheck field={field} type="checkbox" label="Hak Ediş Tarihi Öteleme">
																	<Form.Text>
																		Bir sonraki izin hak edişi tarihi bu iznin kullanılan gün sayısı kadar ötelenir.
																	</Form.Text>
																</CustomFormCheck>
															</Form.Group>
														)}
													/>
												</Card.Body>
											</Card>
										</Col>
									</>
								)}
								<Col md={4} className="mb-4">
									<Controller
										name="limitation"
										control={control}
										defaultValue={limitationOptions[0].value}
										render={({ field }) => (
											<Form.Group>
												<Form.Label>Limit</Form.Label>
												<CustomReactSelect
													value={limitationOptions.filter((obj) => obj.value === field.value)}
													onChange={(d) => field.onChange(d?.value)}
													ref={field.ref}
													placeholder=""
													options={limitationOptions}
												/>
												<Form.Text>Bu izin türü için limit nasıl olmalı?</Form.Text>
											</Form.Group>
										)}
									/>
								</Col>

								{['yearly_limited', 'every_request_limited'].includes(limitation) && (
									<Col md={4} className="mb-4">
										<Controller
											name="day_limit"
											control={control}
											defaultValue={14}
											rules={{
												required: { message: 'Gerekli alan.', value: limitation !== 'unlimited' },
												min: { message: 'Minimum 1 gün olmalı.', value: 1 },
												max: { message: 'Maksimum 365 gün olmalı.', value: 365 }
											}}
											render={({ field }) => (
												<Form.Group>
													<Form.Label>Gün</Form.Label>
													<Form.Control type="number" {...field} isInvalid={errors.day_limit} />

													{limitation === 'yearly_limited' && (
														<Form.Text>Yıllık izin hak edişleri kaç gün olacak?</Form.Text>
													)}

													{limitation === 'every_request_limited' && (
														<Form.Text>Tek seferde en fazla kaç gün izin talep edebilirler?</Form.Text>
													)}
													<Form.Control.Feedback type="invalid">
														<ErrorMessage errors={errors} name="day_limit" />
													</Form.Control.Feedback>
												</Form.Group>
											)}
										/>
									</Col>
								)}

								{['yearly_limited'].includes(limitation) && (
									<>
										<Col md={4} className="mb-4">
											<Controller
												name="age_exception.status"
												control={control}
												defaultValue={false}
												render={({ field }) => (
													<>
														<CustomFormCheck field={field} label="Yaş İstisnası" id="age_exception.status">
															<Form.Text>
																18 yaşından küçük 50 yaşından büyük çalışanlar için hak ediş alt limiti.
															</Form.Text>
															{field.value && (
																<Col md={12} className="mt-2">
																	<Controller
																		name="age_exception.minimum_days"
																		control={control}
																		defaultValue={20}
																		rules={{
																			required: { message: 'Gerekli alan.', value: field.value },
																			min: { message: 'Minimum 1 gün olmalı.', value: 1 },
																			max: { message: 'Maksimum 365 gün olmalı.', value: 365 }
																		}}
																		render={({ field }) => (
																			<Form.Group>
																				<Form.Label>Alt Limit</Form.Label>
																				<InputGroup>
																					<Form.Control
																						type="number"
																						{...field}
																						isInvalid={errors.age_exception?.minimum_days}
																					/>
																					<InputGroup.Text>gün</InputGroup.Text>
																				</InputGroup>
																				<Form.Control.Feedback type="invalid">
																					<ErrorMessage errors={errors} name="age_exception.minimum_days" />
																				</Form.Control.Feedback>
																			</Form.Group>
																		)}
																	/>
																</Col>
															)}
														</CustomFormCheck>
													</>
												)}
											/>
										</Col>

										<Col md={4} className="mb-4">
											<Card>
												<Card.Header>
													<Card.Title as="h6">Eksi Bakiye</Card.Title>
													<Form.Text>
														Çalışanların izin hak edişlerinin olmadığı durumda talep oluşturulursa ne olmalı?
													</Form.Text>
												</Card.Header>
												<Card.Body>
													<Controller
														name="overdrawn"
														control={control}
														render={({ field }) => (
															<>
																<Form.Group className="mb-3">
																	<CustomFormCheck
																		field={field}
																		type="radio"
																		id="not_allowed"
																		value="not_allowed"
																		label="Bakiye Eksiye Düşmez"
																	>
																		<Form.Text>Çalışanlar, izin haklarından fazla izin talep edemezler.</Form.Text>
																	</CustomFormCheck>
																</Form.Group>
																<Form.Group className="mb-3">
																	<CustomFormCheck
																		field={field}
																		type="radio"
																		label="Limitsiz Eksi Bakiye"
																		value="allowed"
																		id="allowed"
																	>
																		<Form.Text>
																			Çalışanlar, izin haklarından fazla izin talep ederek eksi bakiyeye düşebilir.
																		</Form.Text>
																	</CustomFormCheck>
																</Form.Group>
																<Form.Group>
																	<CustomFormCheck
																		field={field}
																		type="radio"
																		label="Limitli Eksi Bakiye"
																		value="allowed_with_limit"
																		id="allowed_with_limit"
																	>
																		<Form.Text>
																			Çalışanlar, izin haklarından fazla izin talep ederek, belirlenen alt limite kadar
																			eksi bakiyeye düşebilir.
																		</Form.Text>

																		{field.value === 'allowed_with_limit' && (
																			<Col md={12}>
																				<Controller
																					name="overdrawn_limit"
																					control={control}
																					defaultValue={0}
																					rules={{
																						required: { message: 'Gerekli alan.', value: field.value },
																						min: { message: 'Minimum 1 gün olmalı.', value: 1 },
																						max: { message: 'Maksimum 365 gün olmalı.', value: 365 }
																					}}
																					render={({ field }) => (
																						<>
																							<Form.Group className="mt-2">
																								<Form.Label>Eksi Bakiye Limiti</Form.Label>

																								<InputGroup>
																									<Form.Control
																										type="number"
																										{...field}
																										isInvalid={errors.overdrawn_limit}
																									/>
																									<InputGroup.Text>gün</InputGroup.Text>
																								</InputGroup>

																								<Form.Control.Feedback type="invalid">
																									<ErrorMessage errors={errors} name={field.name} />
																								</Form.Control.Feedback>
																							</Form.Group>
																						</>
																					)}
																				/>
																			</Col>
																		)}
																	</CustomFormCheck>
																</Form.Group>
															</>
														)}
													/>
												</Card.Body>
											</Card>
										</Col>
									</>
								)}
							</>
						)}
						{page === 3 && (
							<>
								<Col md={4} className="mb-4">
									<Controller
										name="accrual_frequency"
										control={control}
										defaultValue={frequencyOptions[0].value}
										render={({ field }) => (
											<Form.Group className="mb-2">
												<Form.Label>Hak Ediş Sıklığı</Form.Label>
												<div className="mb-2">
													<Form.Text>Çalışanların hangi sıklıkta izin hak edeceğini belirleyin.</Form.Text>
												</div>
												<CustomReactSelect
													value={frequencyOptions.filter((obj) => obj.value === field.value)}
													onChange={(d) => field.onChange(d?.value)}
													ref={field.ref}
													placeholder=""
													options={frequencyOptions}
												/>

												{field.value === 'yearly' && (
													<Form.Text>Çalışanlar her yıl {watch('day_limit')} gün izin hak edecek.</Form.Text>
												)}

												{field.value === 'monthly' && (
													<Form.Text>
														Çalışanlar her ay {(watch('day_limit') / 12).toFixed(2)} gün izin hak edecek.
													</Form.Text>
												)}

												{field.value === 'daily' && (
													<Form.Text>
														Çalışanlar her gün {(watch('day_limit') / 365).toFixed(2)} gün izin hak edecek.
													</Form.Text>
												)}
											</Form.Group>
										)}
									/>
								</Col>

								<Col md={4} className="mb-4">
									<Controller
										name="accrual_schedule"
										control={control}
										defaultValue={periodOptions[0].value}
										render={({ field }) => (
											<Form.Group className="mb-4">
												<Form.Label>Dönem</Form.Label>
												<CustomReactSelect
													value={periodOptions.filter((obj) => obj.value === field.value)}
													onChange={(d) => field.onChange(d?.value)}
													ref={field.ref}
													placeholder=""
													options={periodOptions}
												/>
												<Form.Text>Çalışanların izin hak edişleri ne zaman tanımlanacak?</Form.Text>
											</Form.Group>
										)}
									/>
								</Col>

								<Col md={4}>
									<Card>
										<Card.Header>
											<Card.Title as="h6">Başlangıç Tarihi</Card.Title>
											<Form.Text>Çalışanların izin hak edişlerinin tanımlanma tarihini belirleyin.</Form.Text>
										</Card.Header>
										<Card.Body>
											<Controller
												name="accrual_start_date"
												control={control}
												defaultValue={0}
												render={({ field }) => (
													<>
														<Form.Group className="mb-3">
															<CustomFormCheck
																field={field}
																type="radio"
																label="Yılın İlk Günü"
																value="first_day_of_year"
																id="first_day_of_year"
															>
																<Form.Text>
																	Çalışanların izin hak edişleri, yılın ilk gününden bu yana geçen gün oranıyla
																	hesaplanır.
																</Form.Text>
															</CustomFormCheck>
														</Form.Group>
														<Form.Group>
															<CustomFormCheck
																field={field}
																type="radio"
																label="İzne Esas Hak Ediş Tarihi"
																value="custom_date"
																id="custom_date"
															>
																<Form.Text>
																	Çalışanların izin hakedişleri izne esas hak ediş tarihi kullanılarak hesaplanır.
																</Form.Text>
															</CustomFormCheck>
														</Form.Group>
													</>
												)}
											/>
										</Card.Body>
									</Card>
								</Col>
							</>
						)}
						{page === 4 && (
							<>
								<Col md={4} className="mb-4">
									<Card>
										<Card.Header>
											<Card.Title as="h6">Devretme</Card.Title>
											<Form.Text>Çalışanlar yıllık hakedişlerini kullanmadıklarında ne olur?</Form.Text>
										</Card.Header>
										<Card.Body>
											<Controller
												name="carryover_limitation"
												control={control}
												defaultValue={0}
												render={({ field }) => (
													<>
														<Form.Group className="mb-3">
															<CustomFormCheck
																field={field}
																type="radio"
																label="İzin Hakkı Sıfırlama"
																value="balance_reset"
																id="balance_reset"
															>
																<Form.Text>
																	Çalışanların kullanmadıkları izin hakları bir sonraki yıla devretmez. (Eksi bakiye
																	olsa bile.)
																</Form.Text>
															</CustomFormCheck>
														</Form.Group>

														<Form.Group className="mb-3">
															<CustomFormCheck
																field={field}
																type="radio"
																label="Limitsiz Devretme"
																value="unlimited_carryover"
																id="unlimited_carryover"
															>
																<Form.Text>
																	Çalışanların kullanmadıkları izin hakları bir sonraki yıla devredilir. (Eksi bakiye
																	olsa bile.)
																</Form.Text>
															</CustomFormCheck>
														</Form.Group>
														<Form.Group className="mb-3">
															<CustomFormCheck
																field={field}
																type="radio"
																label="Limitli İzin Hakkı"
																value="limited_balance"
																id="limited_balance"
															>
																<Form.Text>Çalışanların izin hakkı belirlediğiniz limitin üstüne çıkamaz.</Form.Text>

																{field.value === 'limited_balance' && (
																	<Controller
																		control={control}
																		name="carryover_limit.days"
																		rules={{
																			required: 'Gerekli alan.',
																			min: { message: 'Minimum 1 gün olmalı.', value: 1 },
																			max: { message: 'Maksimum 365 gün olmalı.', value: 365 }
																		}}
																		render={({ field }) => (
																			<Form.Group className="mt-2">
																				<Form.Label>İzin Hakkı Limiti</Form.Label>
																				<InputGroup>
																					<Form.Control
																						{...field}
																						type="number"
																						isInvalid={errors.carryover_limit?.days}
																					/>
																					<InputGroup.Text>gün</InputGroup.Text>
																				</InputGroup>
																				<Form.Control.Feedback type="invalid">
																					<ErrorMessage errors={errors} name={field.name} />
																				</Form.Control.Feedback>
																			</Form.Group>
																		)}
																	/>
																)}
															</CustomFormCheck>
														</Form.Group>
														<Form.Group className="mb-3">
															<CustomFormCheck
																field={field}
																type="radio"
																label="Limitli Devretme"
																value="limited_carryover"
																id="limited_carryover"
															>
																<Form.Text>
																	Çalışanların kullanmadıkları izin hakları belirlediğiniz miktar kadar bir sonraki yıla
																	aktarılır.
																</Form.Text>

																{field.value === 'limited_carryover' && (
																	<Controller
																		control={control}
																		name="carryover_limit.days"
																		rules={{
																			required: 'Gerekli alan.',
																			min: { message: 'Minimum 1 gün olmalı.', value: 1 },
																			max: { message: 'Maksimum 365 gün olmalı.', value: 365 }
																		}}
																		render={({ field }) => (
																			<Form.Group className="mt-2">
																				<Form.Label>İzin Hakkı Limiti</Form.Label>
																				<InputGroup>
																					<Form.Control
																						{...field}
																						type="number"
																						isInvalid={errors.carryover_limit?.days}
																					/>
																					<InputGroup.Text>gün</InputGroup.Text>
																				</InputGroup>
																				<Form.Control.Feedback type="invalid">
																					<ErrorMessage errors={errors} name={field.name} />
																				</Form.Control.Feedback>
																			</Form.Group>
																		)}
																	/>
																)}
															</CustomFormCheck>
														</Form.Group>
														<Form.Group>
															<CustomFormCheck
																field={field}
																type="radio"
																label="Geçerlilik Tarihi Olan Limitli Devretme"
																value="limited_carryover_with_deadline"
																id="limited_carryover_with_deadline"
															>
																<Form.Text>
																	Çalışanların kullanmadıkları izin hakları belirlediğiniz miktar kadar bir sonraki yıla
																	aktarılır ve belirlediğiniz süre içinde kullanılmadığında sıfırlanır.
																</Form.Text>

																{field.value === 'limited_carryover_with_deadline' && (
																	<>
																		<Controller
																			control={control}
																			name="carryover_limit.days"
																			rules={{
																				required: 'Gerekli alan.',
																				min: { message: 'Minimum 1 gün olmalı.', value: 1 },
																				max: { message: 'Maksimum 365 gün olmalı.', value: 365 }
																			}}
																			render={({ field }) => (
																				<Form.Group className="mt-2">
																					<Form.Label>İzin Hakkı Limiti</Form.Label>
																					<InputGroup>
																						<Form.Control
																							{...field}
																							type="number"
																							isInvalid={errors.carryover_limit?.days}
																						/>
																						<InputGroup.Text>gün</InputGroup.Text>
																					</InputGroup>
																					<Form.Control.Feedback type="invalid">
																						<ErrorMessage errors={errors} name={field.name} />
																					</Form.Control.Feedback>
																				</Form.Group>
																			)}
																		/>
																		<Controller
																			control={control}
																			name="carryover_limit.deadline"
																			rules={{
																				required: 'Gerekli alan.',
																				min: { message: 'Minimum 1 gün olmalı.', value: 1 },
																				max: { message: 'Maksimum 364 gün olmalı.', value: 364 }
																			}}
																			render={({ field }) => (
																				<Form.Group className="mt-2">
																					<Form.Label>Sıfırlanma Süresi</Form.Label>
																					<InputGroup>
																						<Form.Control
																							{...field}
																							type="number"
																							isInvalid={errors.carryover_limit?.deadline}
																						/>
																						<InputGroup.Text>gün</InputGroup.Text>
																					</InputGroup>
																					<Form.Control.Feedback type="invalid">
																						<ErrorMessage errors={errors} name={field.name} />
																					</Form.Control.Feedback>
																				</Form.Group>
																			)}
																		/>
																	</>
																)}
															</CustomFormCheck>
														</Form.Group>
													</>
												)}
											/>
										</Card.Body>
									</Card>
								</Col>
							</>
						)}
						{page === 5 && (
							<>
								<Col md={6} className="mb-4">
									<Form.Group className="mb-4">
										<h5>Kıdem</h5>
										<Form.Text>Çalışanlarınız çalışma sürelerine göre kaç gün ek izin hakkı elde edebilir?</Form.Text>
									</Form.Group>

									{fields.map((field, index) => (
										<section key={field.id}>
											<Row className="d-flex align-items-end">
												<Col md={4} className="mb-4">
													<Form.Group>
														<Form.Label>Çalışma Süresi</Form.Label>
														<InputGroup>
															<Form.Control type="number" {...register(`seniority.${index}.year`)} />
															<InputGroup.Text>yıl</InputGroup.Text>
														</InputGroup>
													</Form.Group>
												</Col>
												<Col md={4} className="mb-4">
													<Form.Group>
														<Form.Label>Ek İzin Hakkı</Form.Label>
														<InputGroup>
															<Form.Control type="number" {...register(`seniority.${index}.additional_days`)} />
															<InputGroup.Text>gün</InputGroup.Text>
														</InputGroup>
													</Form.Group>
												</Col>
												<Col md={2} className="mb-4">
													<CloseButton text="Sil" onClick={() => remove(index)} />
												</Col>
											</Row>
										</section>
									))}
									<CreateButton text="Kıdem Hakkı Ekle" onClick={() => append({ year: '2', additional_days: 3 })} />
								</Col>
							</>
						)}
						{page === 6 && (
							<>
								{watch('limitation') === 'yearly_limited' && (
									<>
										<Col md={4} className="mb-4">
											<Form.Group className="mb-3">
												<Form.Label>En az</Form.Label>
												<div>
													<Form.Text>Çalışanlar tek seferde en az kaç gün izin talep edebilir?</Form.Text>
												</div>
											</Form.Group>
											<Col md={12} className="mb-4">
												<Controller
													name="least_day_count"
													control={control}
													rules={{
														min: {
															value: 1,
															message: 'Girilen değer en az 1 olabilir.'
														},
														max: {
															value: watch('day_limit'),
															message: `Girilen değer en fazla ${watch('day_limit')} olabilir.`
														}
													}}
													render={({ field }) => (
														<Form.Group>
															<InputGroup>
																<Form.Control type="number" {...field} isInvalid={errors.least_day_count} />
																<InputGroup.Text>gün</InputGroup.Text>
															</InputGroup>
															<Form.Control.Feedback type="invalid">
																<ErrorMessage errors={errors} name="least_day_count" />
															</Form.Control.Feedback>
															<Form.Text>tek seferde</Form.Text>
														</Form.Group>
													)}
												/>
											</Col>
										</Col>

										<Col md={4} className="mb-4">
											<Form.Group className="mb-3">
												<Form.Label>En çok</Form.Label>
												<div>
													<Form.Text>Çalışanlar tek seferde en fazla kaç gün izin talep edebilir?</Form.Text>
												</div>
											</Form.Group>
											<Col md={12} className="mb-4">
												<Controller
													name="most_day_count"
													control={control}
													rules={{
														min: {
															value: 1,
															message: 'Girilen değer en az 1 olabilir.'
														},
														max: {
															value: watch('day_limit'),
															message: `Girilen değer en fazla ${watch('day_limit')} olabilir.`
														}
													}}
													render={({ field }) => (
														<Form.Group>
															<InputGroup>
																<Form.Control type="number" {...field} isInvalid={errors.most_day_count} />
																<InputGroup.Text>gün</InputGroup.Text>
															</InputGroup>
															<Form.Control.Feedback type="invalid">
																<ErrorMessage errors={errors} name="most_day_count" />
															</Form.Control.Feedback>
															<Form.Text>tek seferde</Form.Text>
														</Form.Group>
													)}
												/>
											</Col>
										</Col>
									</>
								)}

								<Col md={4} className="mb-4">
									<Form.Group className="mb-3">
										<Form.Label>Hesaplanma Şekli</Form.Label>
										<div>
											<Form.Text>Çalışanlar saatlik izin talep ettiğinde nasıl hesaplanır?</Form.Text>
										</div>
									</Form.Group>
									<Controller
										name="calculation_type"
										control={control}
										defaultValue={calculationOptions[0].value}
										render={({ field }) => (
											<Form.Group>
												<CustomReactSelect
													value={calculationOptions.filter((obj) => obj.value === field.value)}
													onChange={(d) => field.onChange(d?.value)}
													ref={field.ref}
													placeholder=""
													options={calculationOptions}
												/>
												<Form.Control.Feedback type="invalid">
													<ErrorMessage errors={errors} name="calculation_type" />
												</Form.Control.Feedback>
											</Form.Group>
										)}
									/>
								</Col>

								<Col md={4} className="mb-4">
									<Card>
										<Card.Header>
											<Card.Title as="h5">Ek Seçenekler</Card.Title>
										</Card.Header>
										<Card.Body>
											<Col md={12} className="mb-4">
												<Controller
													name="is_message_required"
													control={control}
													defaultValue={false}
													render={({ field }) => (
														<Form.Group>
															<CustomFormCheck
																field={field}
																type="checkbox"
																label="Açıklama Yazma Zorunluluğu"
																id="is_message_required"
															>
																<Form.Text>Çalışanlar izin taleplerinde açıklama yazmak zorunda olacak mı?</Form.Text>
															</CustomFormCheck>
														</Form.Group>
													)}
												/>
											</Col>

											<Col md={12} className="mb-4">
												<Controller
													name="is_substitute_required"
													control={control}
													defaultValue={false}
													render={({ field }) => (
														<Form.Group>
															<CustomFormCheck
																field={field}
																type="checkbox"
																label="Yerine Bakacak Kişi Zorunluluğu"
																id="is_substitute_required"
															>
																<Form.Text>
																	Çalışanlar izin taleplerinde yerine bakacak kişi yazmak zorunda olacak mı?
																</Form.Text>
															</CustomFormCheck>
														</Form.Group>
													)}
												/>
											</Col>

											<Col md={12} className="mb-4">
												<Controller
													name="waiting_period.status"
													control={control}
													defaultValue={false}
													render={({ field }) => (
														<Form.Group>
															<CustomFormCheck
																field={field}
																type="checkbox"
																label="Bekleme Süresi"
																id="waiting_period.status"
															>
																<Form.Text>
																	Yeni işe giren çalışanlar belirlediğiniz süre sonrasında izin talep edebilir.
																</Form.Text>
																{field.value && (
																	<Controller
																		name="waiting_period.months"
																		control={control}
																		rules={{
																			required: 'Bu alan gerekli.',
																			min: { value: 1, message: 'Minimum 1 ay olabilir.' },
																			max: { value: 12, message: 'Maksimum 12 ay olabilir.' }
																		}}
																		render={({ field }) => (
																			<Form.Group>
																				<Form.Label>Süre</Form.Label>
																				<InputGroup>
																					<Form.Control
																						type="number"
																						{...field}
																						isInvalid={errors.waiting_period?.months}
																					/>
																					<InputGroup.Text>ay</InputGroup.Text>
																				</InputGroup>
																				<Form.Control.Feedback type="invalid">
																					<ErrorMessage errors={errors} name="waiting_period.months" />
																				</Form.Control.Feedback>
																			</Form.Group>
																		)}
																	/>
																)}
															</CustomFormCheck>
														</Form.Group>
													)}
												/>
											</Col>

											<Col md={12} className="mb-4">
												<Controller
													name="is_overridden_holidays"
													control={control}
													defaultValue={false}
													render={({ field }) => (
														<Form.Group>
															<CustomFormCheck
																field={field}
																type="checkbox"
																label="Çalışma Takvimini Geçersiz Kılma"
																id="is_overridden_holidays"
															>
																<Form.Text>
																	Çalışanlara tanımlı çalışma takvimi yok sayılır ve takvim günü olarak hesaplanır.
																</Form.Text>
															</CustomFormCheck>
														</Form.Group>
													)}
												/>
											</Col>

											<Col md={12}>
												<Controller
													name="is_shown_in_calendar"
													control={control}
													defaultValue={false}
													render={({ field }) => (
														<Form.Group>
															<CustomFormCheck
																field={field}
																type="checkbox"
																label="Takvim Üzerinde İzin Türü Gösterme"
																id="is_shown_in_calendar"
															>
																<Form.Text>
																	Onaylanan izinler için takvim üzerinde, bu izin türünün bilgisi gözükür.
																</Form.Text>
															</CustomFormCheck>
														</Form.Group>
													)}
												/>
											</Col>
										</Card.Body>
									</Card>
								</Col>
							</>
						)}
					</Form>
				</Row>
			</Card.Body>
			<Card.Footer>
				{isNew ? (
					<CreateButton onClick={handleSubmit((d) => handleCreate(d))} />
				) : (
					<>
						<SaveButton onClick={handleSubmit((d) => handleSave(d))} />
						<DeleteButton onClick={handleSubmit((d) => handleDelete(d))} />
						<CloseButton
							text={
								<>
									<FaIcon icon="check" /> Bu izin türünü birincil olarak belirle.
								</>
							}
							onClick={handleSubmit((d) => handleSetPrimary(d))}
						/>
					</>
				)}
			</Card.Footer>
		</Card>
	)
}

export default VacationType
