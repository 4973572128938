import { AddButton } from 'components/fast-ui'
import { TabButton, TabButtonContainer } from 'components/ui'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

import AddTaskModal from './modals/addTaskModal'
import DetailTaskModal from './modals/detailTaskModal'
import AssignedTasks from './tabs/assignedTasks'
import MyTasks from './tabs/createdTasks'

const Tasks = () => {
	const params = useParams()
	const { task_id } = params

	const navigate = useNavigate()

	const [tabName, setTabName] = useState('created-tasks')
	const [showModal, setShowModal] = useState('')

	useEffect(() => {
		if (task_id) {
			if (task_id === 'created-tasks') {
				setTabName('created-tasks')
				setShowModal('')
			} else if (task_id === 'assigned-tasks') {
				setTabName('assigned-tasks')
				setShowModal('')
			} else if (task_id === 'add') {
				setShowModal('add-task')
			} else {
				setShowModal('detail-task')
			}
		}
	}, [params])
	return (
		<Container fluid="xxl" className="flex-grow-1 container-p-y">
			<Row>
				<Col md={12} className="mb-4">
					<TabButtonContainer>
						<TabButton
							active={tabName === 'created-tasks'}
							onClick={() => navigate('/tasks/created-tasks')}
							text="Oluşturduklarım"
							icon="fa-sharp fa-regular fa-circle-plus"
						/>
						<TabButton
							active={tabName === 'assigned-tasks'}
							onClick={() => navigate('/tasks/assigned-tasks')}
							text="Atandıklarım"
							icon="fa-sharp fa-light fa-list-check"
						/>

						{tabName === 'created-tasks' && (
							<AddButton
								text="Yeni Görev"
								type="outline-secondary"
								className="ms-auto"
								onClick={() => navigate('/tasks/add')}
							/>
						)}
					</TabButtonContainer>
				</Col>
			</Row>

			<Row>
				<Col md={12}>
					{tabName === 'created-tasks' && <MyTasks />}
					{tabName === 'assigned-tasks' && <AssignedTasks />}
				</Col>
			</Row>

			{showModal === 'add-task' && <AddTaskModal onHide={() => navigate('/tasks/created-tasks')} />}
			{showModal === 'detail-task' && <DetailTaskModal onHide={() => navigate(`/tasks/${tabName}`)} />}
		</Container>
	)
}

export default Tasks
