import { CreateButton } from 'components/fast-ui'
import { FaIcon } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

const days = [
	{ value: 'monday', label: 'Pazartesi' },
	{ value: 'tuesday', label: 'Salı' },
	{ value: 'wednesday', label: 'Çarşamba' },
	{ value: 'thursday', label: 'Perşembe' },
	{ value: 'friday', label: 'Cuma' },
	{ value: 'saturday', label: 'Cumartesi' },
	{ value: 'sunday', label: 'Pazar' }
]
const WorkCalendarList = () => {
	const params = useParams()
	const navigate = useNavigate()
	const [data, setData] = useState([])

	const fetchData = async () => {
		try {
			const response = await Service.get('/work-calendars')
			setData(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [params])

	const handleClick = (id) => {
		navigate(`/settings/work-calendars/${id}`)
	}

	//çalışma takvimi listesi
	return (
		<>
			<Row className="mb-4">
				<Col md={12}>
					<CreateButton text="Yeni Çalışma Takvimi" onClick={() => navigate('/settings/work-calendars/add')} />
				</Col>
			</Row>
			{data.map((item) => (
				<Col key={item._id} md={4}>
					<Card className="mb-4">
						<Card.Header>
							<Card.Title as="h5">{item.name}</Card.Title>
							<Card.Text>{item.description}</Card.Text>
						</Card.Header>
						<Card.Body>
							<Table size="sm">
								<thead>
									<tr>
										<th>Gün</th>
										<th>Mesai Aralığı</th>
										<th>Mola Aralığı</th>
									</tr>
								</thead>
								<tbody>
									{item.days.map((day) => (
										<tr key={day.id}>
											<td>{days.find((d) => d.value === day.dayName).label}</td>
											<td>
												{day.isWorkingDay ? (
													<>
														{day.startTime} - {day.endTime}
													</>
												) : (
													'-'
												)}
											</td>
											<td>
												{day.isWorkingDay ? (
													<>
														{day.lunchStartTime} - {day.lunchEndTime}
													</>
												) : (
													'-'
												)}
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Card.Body>
						<Card.Footer>
							<Button variant="primary" size="sm" className="float-right" onClick={() => handleClick(item._id)}>
								<FaIcon icon="edit" className={'me-2'} /> Düzenle
							</Button>
						</Card.Footer>
					</Card>
				</Col>
			))}
		</>
	)
}

export default WorkCalendarList
