import RolePermissions from 'constants/RolePermissions'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Col, Container, Form, Row, Stack } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'

const RolePermissionsTab = ({ form }) => {
	const [search, setSearch] = useState('')
	const [filteredRolePermissions, setFilteredRolePermissions] = useState(RolePermissions)

	//filtreleme işlemleri
	const handleSearch = (e) => {
		setSearch(e.target.value)
		const filtered = RolePermissions.filter((item) => {
			return (
				item.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
				item.permissions.some((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase())) ||
				item.permissions.some((item) => item.description.toLowerCase().includes(e.target.value.toLowerCase()))
			)
		})
		setFilteredRolePermissions(filtered)
	}

	const {
		register,
		control,
		handleSubmit,
		getValues,
		formState: { errors }
	} = form

	return (
		<Col className="border rounded p-3" lg={8} md={10} sm={12}>
			<div>
				<div>
					<div className="flex-grow-1 input-group input-group-merge rounded-pill">
						<input
							type="text"
							className="form-control chat-search-input"
							placeholder="İzinleri ara"
							value={search}
							onChange={handleSearch}
						/>
						<span className="input-group-text">
							<i className="bx bx-search fs-4" />
						</span>
					</div>
				</div>
			</div>

			<div className="p-2">
				{filteredRolePermissions.map((groups) => (
					<div className="mt-3">
						<h6 className="mt-4">{groups.title}</h6>
						{groups.permissions.map((item) => (
							<>
								<Row className="py-2">
									<div className="d-flex align-items-center justify-content-between mb-2">
										<h5 className="mb-0">{item.name}</h5>
										<Controller
											name={`permissions.${item.value}`}
											control={control}
											defaultValue={false}
											render={({ field }) => (
												<label className="switch switch-lg">
													<input type="checkbox" class="switch-input" {...field} checked={field.value} />
													<span className="switch-toggle-slider">
														<span className="switch-on">
															<i className="bx bx-check"></i>
														</span>
														<span className="switch-off">
															<i className="bx bx-x"></i>
														</span>
													</span>
												</label>
											)}
										/>
									</div>

									<p className="mb-0">{item.description}</p>
								</Row>
								<hr />
							</>
						))}
					</div>
				))}
			</div>
		</Col>
	)
}

export default RolePermissionsTab
