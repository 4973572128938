import { DocumentButton } from 'components/fast-ui'
import { FaIcon } from 'components/ui'
import React, { useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectFolder, selectTag, selectTrash, setMainPreviewStatus } from 'stores/documentSlice'

import DocumentContent from './document-content'
import DocumentLeft from './document-left'
import DocumentRight from './document-right'
import DocumentTop from './document-top'
import { DocumentModals } from './modals'

const Document = () => {
	const dispatch = useDispatch()
	const { path, path_id } = useParams()

	const { selectedItems, preview } = useSelector((state) => state.document.main)

	useEffect(() => {
		if (path) {
			if (path === 'trash') {
				dispatch(selectTrash())
			}
			if (path === 'folder') {
				if (path_id) {
					dispatch(selectFolder(path_id))
				}
			}
			if (path === 'tag') {
				if (path_id) {
					dispatch(selectTag(path_id))
				}
			}
		} else {
			dispatch(selectFolder('cloud'))
		}
	}, [path, path_id, dispatch])

	useEffect(() => {
		if (selectedItems.length === 1) {
			if (selectedItems[0].isFolder === false) {
				dispatch(setMainPreviewStatus(true))
			} else {
				dispatch(setMainPreviewStatus(false))
			}
		} else {
			dispatch(setMainPreviewStatus(false))
		}
	}, [selectedItems, dispatch])

	return (
		<>
			<div className="container-xxl flex-grow-1 container-p-y user-select-none">
				<Row>
					{/* <Col xl={12} className="d-flex justify-content-between pt-2 rounded-top border flex-wrap">
						<div className="d-flex flex-wrap mb-2" style={{ gap: 8 }}>
							<DocumentButton
								//onClick={handleUndoTrash}
								icon="rotate-left"
								text="Geri Yükle"
								data-bs-toggle="modal"
								data-bs-target="#undoTrashModal"
							/>
							<DocumentButton
								//onClick={handleUndoTrash}
								icon="rotate-left"
								text="Geri Yükle"
								data-bs-toggle="modal"
								data-bs-target="#undoTrashModal"
							/>
							<DocumentButton
								//onClick={handleUndoTrash}
								icon="rotate-left"
								text="Geri Yükle"
								data-bs-toggle="modal"
								data-bs-target="#undoTrashModal"
							/>
							<DocumentButton
								//onClick={handleUndoTrash}
								icon="rotate-left"
								text="Geri Yükle"
								data-bs-toggle="modal"
								data-bs-target="#undoTrashModal"
							/>
							<DocumentButton
								//onClick={handleUndoTrash}
								icon="rotate-left"
								text="Geri Yükle"
								data-bs-toggle="modal"
								data-bs-target="#undoTrashModal"
							/>
						</div>
						<div className="d-flex flex-column justify-content-end mb-2">
							<div className="d-flex align-items-center gap-4">
								<div>
									<Form.Control type="text" placeholder="Ara" className="w-100 me-2" />
								</div>
								<div>
									<FaIcon icon="search" className="me-2" />
									<FaIcon icon="filter" />
								</div>
							</div>
						</div>
					</Col> */}

					<DocumentTop />

					<div
						className="col-xl-2 col-md-3 col-sm-4 border border-top-0 pt-4 px-4"
						style={{ minHeight: 600, maxHeight: 600, overflowY: 'scroll' }}
					>
						<DocumentLeft />
					</div>
					<div
						className={`${
							preview.status ? 'col-xl-7 col-md-6' : 'col-xl-10 col-md-9'
						} col-sm-8 border-bottom border-end ps-5 pt-4 px-4 overflow-auto`}
						style={{ minHeight: 600, maxHeight: 600 }}
					>
						<DocumentContent />
					</div>
					<div
						className={`col-xl-3 col-md-3 border border-start-0 border-top-0 px-4 py-2 ${
							!preview.status ? 'd-none' : null
						}`}
					>
						<DocumentRight />
					</div>
				</Row>
			</div>
			<DocumentModals />
		</>
	)
}

export default Document
