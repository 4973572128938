import { CreateButton, DeleteButton, SaveButton } from 'components/fast-ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useEffect } from 'react'
import { Card, Form } from 'react-bootstrap'
import { CirclePicker } from 'react-color'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

const TaskTagCard = ({ tagId, isNew, onSuccess }) => {
	const params = useParams()
	const { workspace_id, project_id, task_id } = params

	const setColor = (color) => {
		setValue('hexColor', color)
	}

	const handleCreate = async (data) => {
		const body = {
			...data,
			project: project_id
		}

		try {
			const response = await Service.post(`/project-tags`, body)
			onSuccess && onSuccess()
			sendToast('Etiket oluşturuldu.', true)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchData = async () => {
		try {
			const response = await Service.get(`/project-tags/${tagId}`)
			reset(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDelete = async () => {
		try {
			const response = await Service.delete(`/project-tags/${tagId}`)
			onSuccess && onSuccess()
			sendToast('Etiket silindi.', true)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleUpdate = async (data) => {
		const body = {
			...data,
			project: project_id
		}

		try {
			const response = await Service.patch(`/project-tags/${tagId}`, body)
			onSuccess && onSuccess()
			sendToast('Etiket güncellendi.', true)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (tagId) {
			fetchData()
		}
	}, [])

	const {
		register,
		handleSubmit,
		control,
		reset,
		setValue,
		watch,
		formState: { errors }
	} = useForm({ mode: 'onChange' })

	return (
		<Card centered size="sm" className="border-0 w-100">
			<Card.Body>
				<Form>
					<Form.Group className="mb-2">
						<Form.Label>{isNew ? 'Etiket Oluştur' : 'Etiket Düzenle'}</Form.Label>
						<Form.Control type="text" name="name" {...register('name')} />
					</Form.Group>
					<Form.Group>
						<Controller
							name="hexColor"
							control={control}
							defaultValue="#146ddd"
							rules={{ required: 'Gerekli alan.' }}
							render={({ field }) => (
								<Form.Group className="mb-2">
									<Form.Label className="mb-2">Etiket Rengi</Form.Label>
									<div className="d-flex flex-wrap align-items-center">
										<div style={{ maxWidth: 400 }} className="d-flex justify-content-center">
											<CirclePicker
												width="100%"
												circleSize={28}
												color={watch('hexColor')}
												onChangeComplete={(color) => {
													setColor(color.hex)
												}}
												colors={[
													'#F44336',
													'#E91E63',
													'#9C27B0',
													'#9B59B6',
													'#3F51B5',
													'#2196F3',
													'#009688',
													'#4CAF50',
													'#CDDC39',
													'#FFC107',
													'#FF9800',
													'#FF5722',
													'#795548',
													'#607D8B',
													'#969696'
												]}
											/>
										</div>
									</div>
								</Form.Group>
							)}
						/>
						{isNew ? (
							<CreateButton
								type="light shadow-none btn-primary mt-3"
								size="sm"
								onClick={handleSubmit(handleCreate)}
								icon={null}
							/>
						) : (
							<div className="d-flex justify-content-between">
								<SaveButton
									type="light shadow-none btn-primary mt-3"
									size="sm"
									onClick={handleSubmit(handleUpdate)}
									icon={null}
								/>
								<DeleteButton size="sm" className="mt-3" onClick={handleDelete} />
							</div>
						)}
					</Form.Group>
				</Form>
			</Card.Body>
		</Card>
	)
}

export default TaskTagCard
