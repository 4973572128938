import React from 'react'
import { forwardRef } from 'react'
import useApiStore from 'stores/apiStore'

import FaIcon from '../FaIcon'

const Button = forwardRef(({ type, text, icon, size, className, children, disabled, ...rest }, ref) => {
	const isLoading = useApiStore((state) => state.isLoading)

	const btnClass = `btn btn-${type} ${className}`
	const sizeClass = size ? `btn-${size}` : ''
	const buttonIcon = icon ? <FaIcon icon={icon} className={text ? 'me-2' : ''} /> : null

	return (
		<button type="button" className={`${btnClass} ${sizeClass}`} disabled={disabled || isLoading} {...rest} ref={ref}>
			{buttonIcon}
			{text}
			{children}
		</button>
	)
})

Button.defaultProps = {
	type: 'primary',
	icon: null,
	disabled: false
}

export default Button
