import { ReactComponent as LogoIcon } from 'assets/svg/logos/meno-logo-icon.svg'
import { motion } from 'framer-motion'
import React from 'react'

const Loader = () => {
	const opacityTransition = {
		duration: 0.5,
		loop: Infinity,
		ease: 'easeInOut'
	}

	const opacityAnimation = {
		opacity: [0.2, 1, 0.2]
	}

	return (
		<motion.div
			className="loading-animation"
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
				height: '100%'
			}}
		>
			<motion.div transition={opacityTransition} animate={opacityAnimation}>
				<LogoIcon width="50" height="50" />
			</motion.div>
		</motion.div>
	)
}

export default Loader
