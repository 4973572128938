import { FaIcon, Tooltip } from 'components/ui'
import { is } from 'date-fns/locale'
import Service from 'global/Service'
import { hexToColorName } from 'global/functions/hexToColorName'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import useAuthStore from 'stores/authStore'
import useProjectStore from 'stores/projectStore'

import KanbanAssigned from '../KanbanAssigned'
import KanbanTagItem from '../KanbanTagItem'

const ProjectTask = ({ data }) => {
	const navigate = useNavigate()
	const params = useParams()
	const { workspace_id, project_id, task_id } = params

	const user = useAuthStore((state) => state.user)
	const refreshProject = useProjectStore((state) => state.refreshProject)

	const [isHovered, setIsHovered] = useState(false)

	const handleClick = () => {
		const taskId = data._id
		navigate(`/projects/${workspace_id}/${project_id}/${taskId}`)
	}

	const handleCompleted = async (event, itemId) => {
		const isChecked = event.target.checked

		const body = {
			isCompleted: isChecked
		}

		try {
			const response = await Service.patch(`/project-tasks/${itemId}/is-completed`, body)
			refreshProject()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<>
			<Card
				className="kanban-item position-relative"
				style={{ borderBottom: '2px solid #DEDEDE' }}
				onClick={handleClick}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				<Card.Body className="px-1 py-3">
					<div className="d-flex align-items-center justify-content-between mb-2">
						<div className="d-flex gap-1">
							{data?.tags?.map((tag) => (
								<Tooltip content={`Renk: ${hexToColorName(tag?.hexColor)}, başlık: '${tag?.name}'`}>
									<KanbanTagItem tagName={tag?.name} hexColor={tag?.hexColor} />
								</Tooltip>
							))}
						</div>
						<Tooltip content={data?.isCompleted === true ? 'Görev tamamlandı.' : 'Görev tamamlanmadı.'}>
							<Form.Check
								type="checkbox"
								className="cursor-pointer"
								value={data?.isCompleted}
								checked={data?.isCompleted}
								onChange={(e) => {
									handleCompleted(e, data?._id)
								}}
								style={{ opacity: isHovered ? 1 : 0 }}
								onClick={(e) => e.stopPropagation()}
							/>
						</Tooltip>
					</div>
					<Card.Text className="mb-1">{data.title}</Card.Text>
					<div className="d-flex align-items-center justify-content-between">
						<div className="d-flex gap-2">
							{data?.notificationRecipients.some((recipient) => recipient.employee === user._id) && (
								<Tooltip content="Bu kartı takip ediyorsunuz." placement="top">
									<FaIcon icon="eye" />
								</Tooltip>
							)}
							{data?.description && (
								<Tooltip content="Bu kart bir açıklama içeriyor." placement="top">
									<FaIcon icon="align-left" />
								</Tooltip>
							)}
							{data?.comments?.length > 0 && (
								<Tooltip content="Yorumlar" placement="top">
									<FaIcon icon="fa-solid fa-comment-lines" className="me-1" />
									{data?.comments?.length}
								</Tooltip>
							)}
							{data?.checkLists?.length > 0 && (
								<Tooltip content="Yapılacaklar öğeleri" placement="top">
									<span
										className={`${
											data?.checkListData?.isCompleted === true
												? 'badge bg-success text-white'
												: 'badge text-dark border'
										}`}
									>
										<FaIcon icon="fa-light fa-check-double" className="me-1" />
										{data?.checkListData?.completedItemsCount}/{data?.checkListData?.totalItemsCount}
									</span>
								</Tooltip>
							)}
						</div>
						<div>
							<KanbanAssigned members={data?.members} />
						</div>
					</div>
				</Card.Body>
			</Card>
		</>
	)
}

export default ProjectTask
