import NoData from 'components/ui/NoData'
import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import useProjectStore from 'stores/projectStore'

import Project from './components/Project'
import ProjectSelect from './components/ProjectSelect'

const Projects = () => {
	const navigate = useNavigate()
	const params = useParams()
	const { workspace_id, project_id, task_id } = params

	const project = useProjectStore((state) => state.project)
	const fetchProject = useProjectStore((state) => state.fetchProject)
	const resetProject = useProjectStore((state) => state.resetProject)

	useEffect(() => {
		if (project_id) {
			fetchProject(project_id)
		} else {
			resetProject()
		}
	}, [params])

	// card -> task
	// kanban // pano -> project
	// workspace -> workspace

	return (
		<>
			<Container fluid="fluid">
				<Row className="px-5 pt-3 pb-4 bg-light mb-4 border-bottom p-0">
					<Col md={12}>
						<Row className="d-flex gap-4">
							<ProjectSelect />
						</Row>
					</Col>
				</Row>
			</Container>

			<Container fluid="xxl" className="flex-grow-1 container-p-y">
				<Row className="d-flex gap-4">
					<Col md={12}>
						{project ? (
							<Project data={project} />
						) : (
							<NoData title="Proje bulunamadı" subTitle="Henüz bir proje seçmediniz." />
						)}
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default Projects
