import { VacationCreateModal, VacationViewModal } from 'components/modals'
import { Button, CustomReactTable, FaIcon } from 'components/ui'
import statuses from 'constants/statuses'
import { format } from 'date-fns'
import Service from 'global/Service'
import { formatDate } from 'global/functions/formatDate'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

const columns = [
	{
		Header: 'Tür',
		accessor: 'type.name'
	},
	{
		Header: 'Başlangıç Tarihi',
		accessor: 'startDate',
		Cell: (cell) => {
			return <span>{formatDate(cell.row.original.startDate)}</span>
		}
	},
	{
		Header: 'Bitiş Tarihi',
		accessor: 'endDate',
		Cell: (cell) => {
			return <span>{formatDate(cell.row.original.endDate)}</span>
		}
	},
	{
		Header: 'İzin Süresi',
		accessor: 'days',
		Cell: ({ row }) => {
			return <span>{row.original.days} gün</span>
		}
	},
	{
		Header: 'Yerine Bakacak Kişi',
		accessor: 'substitute',
		Cell: ({ row }) => {
			return <span>{`${row.original.substitute.firstName} ${row.original.substitute.lastName}`}</span>
		}
	},
	{
		Header: 'Açıklama',
		accessor: 'description',
		Cell: (cell) => {
			return <span className="fw-semibold">{cell.row.original.description}</span>
		}
	},
	{
		Header: 'Durum',
		accessor: 'status',
		Cell: ({ row }) => {
			const badgeStatus =
				row.original.status === 'pending'
					? 'bg-warning'
					: row.original.status === 'approved'
					? 'bg-success'
					: row.original.status === 'rejected'
					? 'bg-danger'
					: 'bg-secondary'

			return (
				<span className={`badge ${badgeStatus}`}>
					{statuses.find((status) => status.value === row.original.status)?.label}
				</span>
			)
		}
	},
	{
		Header: 'Seçenekler',
		accessor: 'options',
		Cell: ({ row }) => {
			const params = useParams()
			const { employee_id, tab_name, module_name: sub_tab_name, module_id: vacation_id, process } = params

			return (
				<div className="d-flex align-items-center">
					<div className="d-flex align-items-center">
						<Link to={`/employees/${employee_id}/vacation/requests/${row.original._id}`} className="text-primary me-3">
							<FaIcon icon="eye" />
						</Link>
					</div>
				</div>
			)
		}
	}
]

const VacationRequestsTab = () => {
	const navigate = useNavigate()
	const params = useParams()
	const { employee_id, tab_name, module_name: sub_tab_name, module_id: vacation_id, process } = params

	const [data, setData] = useState([])
	const [showAddModal, setShowAddModal] = useState(false)
	const [showViewModal, setShowViewModal] = useState(false)

	useEffect(() => {
		setShowAddModal(tab_name === 'vacation' && sub_tab_name === 'requests' && vacation_id === 'add')
		setShowViewModal(tab_name === 'vacation' && sub_tab_name === 'requests' && vacation_id && vacation_id !== 'add')
	}, [params])

	const fetchData = async () => {
		try {
			const response = await Service.get(`/employee-vacations/${employee_id}/requests`)
			setData(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [params])

	return (
		<Card.Body>
			<Button
				variant="primary"
				text="İzin Ekle"
				className="mb-3"
				icon="plus"
				onClick={() => navigate(`/employees/${employee_id}/vacation/requests/add`)}
			/>
			<Row className="g-4">
				<Col md={12}>
					<Card>
						<Card.Header>
							<Card.Title as="h5">İzin Talepleri</Card.Title>
							<Card.Text>Personelin talep ettiği izinlerin listesi.</Card.Text>
						</Card.Header>
						<Card.Body>
							<CustomReactTable columns={columns} data={data} />
						</Card.Body>
					</Card>
				</Col>
			</Row>
			{showAddModal && (
				<VacationCreateModal
					employeeId={employee_id}
					adminMode={true}
					show={showAddModal}
					onHide={() => navigate(`/employees/${employee_id}/vacation/requests`)}
					onSuccess={() => fetchData()}
				/>
			)}
			{showViewModal && (
				<VacationViewModal
					vacationId={vacation_id}
					show={showViewModal}
					onHide={() => navigate(`/employees/${employee_id}/vacation/requests`)}
				/>
			)}
		</Card.Body>
	)
}

export default VacationRequestsTab
