import axios from 'axios'
import { ButtonContainer, FaIcon } from 'components/ui'
import dateFormat from 'dateformat'
import Service from 'global/Service'
import { formatDate } from 'global/functions/formatDate'
import prettyBytes from 'pretty-bytes'
import React, { useState } from 'react'

export const ReviseItem = ({ data, index }) => {
	const [showMoreStatus, setShowMoreStatus] = useState(false)
	const [hoverStatus, setHoverStatus] = useState(false)

	const numberToFontAwesome = (number) => {
		const str = number.toString()
		return str.split('').map((char) => <i className={`fa-solid fa-${char}`}></i>)
	}

	const handleDownload = () => {
		downloadFile(data.url, data.digitalName)
	}

	const openWindow = (url) => {
		window.open(url)
	}

	const downloadFile = (url, fileName) => {
		//resim ise yeni sekmede aç
		if (data?.file?.mimeType?.includes('image')) {
			openWindow(url)
			return
		}
		axios({
			url: url,
			method: 'GET',
			responseType: 'blob'
		}).then((response) => {
			// create file link in browser's memory
			const href = URL.createObjectURL(response.data)

			// create "a" HTML element with href to file & click
			const link = document.createElement('a')
			link.href = href
			link.setAttribute('download', fileName)
			link.setAttribute('target', '_blank') // Open in a new tab
			document.body.appendChild(link)
			link.click()

			// clean up "a" element & remove ObjectURL
			document.body.removeChild(link)
			URL.revokeObjectURL(href)
		})
	}

	return (
		<div className="row border rounded p-3 m-2 disabled mt-3" style={{ opacity: data.status !== 'approved' ? 0.5 : 1 }}>
			<div className="row">
				<div className="col-3">{1 + index}</div>

				<div className="col-9">
					<span>
						<b>{formatDate(data.specialDate)}</b>
					</span>
					<br />
					<span>
						<small>{data?.creator?.fullName} tarafından,</small>
					</span>
					<div className="row">
						<div className="col-10">
							<p className={`${!showMoreStatus && !hoverStatus && 'text-truncate'} m-0`}>{data.note}</p>
						</div>
						<div className="col-2">
							<span
								onClick={() => {
									setShowMoreStatus(!showMoreStatus)
								}}
								type="button"
							>
								<FaIcon type="solid" icon={showMoreStatus ? 'caret-right' : 'caret-down'} className="p1" />
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="row mt-2">
				<div className="col-12">
					<ButtonContainer>
						<button type="button" onClick={handleDownload} className="btn btn-sm btn-label-primary">
							<FaIcon icon="download" />
						</button>
						<button type="button" className="btn btn-sm btn-label-secondary disabled">
							{data.file.size ? prettyBytes(data.file.size) : ''}
						</button>
					</ButtonContainer>
				</div>
			</div>
		</div>
	)
}
