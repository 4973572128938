import { DeleteButton, ProjectButton } from 'components/fast-ui/Buttons'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const TasksArchive = () => {
	const [show, setShow] = useState(false)
	const [archived, setArchived] = useState(false)

	const navigate = useNavigate()
	const params = useParams()

	const { task_id } = params

	const handleDelete = async () => {
		try {
			const response = await Service.delete(`/tasks/${task_id}`)
			sendToast(response.data.message, true)
			navigate(`/tasks`)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleArchive = async () => {
		try {
			const response = await Service.patch(`/tasks/${task_id}/set-archived`)
			sendToast(response.data.message, true)
			navigate(`/tasks/${task_id}`)
			sendToast('Görev arşivlendi', true)
			setArchived(true)
			setShow(true)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleUnarchive = async () => {
		try {
			const response = await Service.patch(`/tasks/${task_id}/set-unarchived`)
			sendToast(response.data.message, true)
			navigate(`/tasks/${task_id}`)
			sendToast('Görev arşivden çıkarıldı', true)
			setArchived(false)
			setShow(false)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<span>
			{!archived && <ProjectButton text="Arşiv" icon="archive" onClick={handleArchive} />}
			<div className={`${show ? '' : 'd-none'}`}>
				<ProjectButton
					text="Arşivden Çıkar"
					className="w-100 text-start shadow-none mb-2"
					style={{ lineHeight: '1rem' }}
					icon="times"
					onClick={handleUnarchive}
				/>
				<DeleteButton
					text="Kalıcı Sil"
					className="w-100 text-start "
					style={{ lineHeight: '1rem' }}
					onClick={handleDelete}
				/>
			</div>
		</span>
	)
}

export default TasksArchive
