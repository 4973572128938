import React, { useState } from 'react'
import { useEffect } from 'react'
import ReactMarkdown from 'react-markdown'

import MDEditor, { ICommand, TextAreaTextApi, TextState, commands } from '@uiw/react-md-editor'

import Button from '../Button'
import ButtonContainer from '../ButtonContainer'
import FaIcon from '../FaIcon'

const CustomMarkdownEditor = ({ value, onBlur }) => {
	const [isEditing, setIsEditing] = useState(false)

	const handleBlur = (e) => {
		setStateValue(e.target.value)
	}

	const handleOk = () => {
		onBlur && onBlur(stateValue)
		setIsEditing(false)
	}

	const handleCancel = () => {
		setIsEditing(false)
		setStateValue(focusValue)
		setFocusValue('')
	}

	const [stateValue, setStateValue] = useState(value)
	const [focusValue, setFocusValue] = useState('')

	const handleFirstFocus = () => {
		if (focusValue === '') {
			setFocusValue(stateValue)
		}
	}

	return (
		<>
			{isEditing ? (
				<>
					<span>
						<MDEditor
							data-color-mode="light"
							value={stateValue}
							onFocus={handleFirstFocus}
							preview="edit"
							onBlur={handleBlur}
							onChange={setStateValue}
							height={150}
							className="mb-2"
							commands={[
								commands.bold,
								commands.italic,
								commands.strikethrough,
								commands.hr,
								commands.divider,
								commands.title,
								commands.divider,
								commands.link,
								commands.quote,
								commands.code,
								commands.divider,
								commands.unorderedListCommand,
								commands.orderedListCommand,
								commands.checkedListCommand,
								commands.divider,
								commands.codeEdit,
								commands.divider
							]}
						/>
						<div className="d-flex justify-content-end">
							<ButtonContainer>
								<Button
									icon="times"
									type="outline-secondary"
									className="text-danger"
									size="sm"
									onClick={handleCancel}
								/>
								<Button icon="check" type="outline-secondary" className="text-success" size="sm" onClick={handleOk} />
							</ButtonContainer>
						</div>
					</span>
				</>
			) : (
				<span onClick={() => setIsEditing(true)} className="cursor-pointer">
					{stateValue ? <ReactMarkdown>{stateValue}</ReactMarkdown> : <span className="text-muted">Açıklama...</span>}
				</span>
			)}
		</>
	)
}

export default CustomMarkdownEditor
