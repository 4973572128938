import 'assets/vendor/libs/flatpickr/flatpickr.css'

import { CloseButton, CreateButton, SendButton } from 'components/fast-ui'
import { ButtonContainer, CustomDatePicker, CustomDropzone, FaIcon, FileIcon } from 'components/ui'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import Flatpickr from 'react-flatpickr'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { updateAllWithItemsFrom } from 'stores/documentSlice'

import MyDropzone from './upload-modal/MyDropzone'

const ReviseModal = () => {
	const selectedItem = useSelector((state) => state.document.main.selectedItems)[0]
	const dispatch = useDispatch()

	const [files, setFiles] = useState([])

	useEffect(() => {
		setFiles([])
	}, [selectedItem])

	const handleSend = async (data) => {
		console.log(data)
		const file = files[0]
		const formData = new FormData()
		formData.append('file', file, file.name)
		formData.append('specialDate', data.specialDate)
		formData.append('note', data.note)

		try {
			console.log('dosya yüklenmeye başlandı')
			console.log(`/documents/${selectedItem._id}/revisions`, 'url')
			const response = await Service.post(`/documents/${selectedItem._id}/revisions`, formData)
			console.log(response)
			sendToast('Revize yüklendi.', true)
			dispatch(updateAllWithItemsFrom())
			reset({
				specialDate: null,
				note: ''
			})
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		reset
	} = useForm({
		mode: 'onChange'
	})

	return (
		<div className="modal fade" id="reviseModal" tabIndex={-1} aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="reviseModalTitle">
							<FaIcon icon="rectangle-history" className={'me-2'} />
							Revize Yükle
						</h5>
						<button type="button" className="btn-close" aria-label="Close" />
					</div>
					<div className="modal-body">
						<div className="row gx-5 g-3">
							<div className="col-md-6">
								<label className="form-label">Revize edilen dosya</label>
								<p>
									{selectedItem?.digitalName}.{selectedItem?.file?.extension}
								</p>
							</div>
							<div className="col-md-6">
								<label className="form-label">Revize Tarihi</label>
								<Controller
									control={control}
									name="specialDate"
									rules={{ required: 'Bu alan gerekli.' }}
									render={({ field }) => <CustomDatePicker value={field.value} onChange={field.onChange} />}
								/>
								{errors.specialDate && (
									<div className="invalid-feedback">
										<>{errors.specialDate.message}</>
									</div>
								)}
							</div>
							<div className="col-12">
								<label className="form-label">Revize Notu</label>
								<textarea
									rows="6"
									type="text"
									{...register('note', { required: 'Bu alan gerekli.' })}
									className="form-control"
									placeholder="Dosyada yaptığınız değişiklikler.."
								/>
								{errors.note && (
									<div className="invalid-feedback">
										<>{errors.note.message}</>
									</div>
								)}
							</div>
							<div className="col-12">
								<label className="form-label">Revize Dosyası</label>
								<CustomDropzone files={files} setFiles={setFiles} />
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<ButtonContainer>
							<CloseButton />
							<SendButton
								onClick={handleSubmit((data) => handleSend(data))}
								text="Yükle"
								disabled={files.length === 0}
							/>
						</ButtonContainer>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ReviseModal
