import { AddButton, CreateButton, SaveButton } from 'components/fast-ui'
import React from 'react'
import { useState } from 'react'
import { Button, Form, FormControl, Modal } from 'react-bootstrap'

const AddPersonnelFileModal = () => {
	const [show, setShow] = useState(false)

	return (
		<>
			<CreateButton className="mb-3" onClick={() => setShow(true)} text="Ekle" />

			<Modal show={show} onHide={() => setShow(false)} centered>
				<Modal.Header closeButton>
					<Modal.Title as="h5" className="mb-0">
						Özlük Dosyası Ekle
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group>
							<Form.Label>Dosya Adı</Form.Label>
							<FormControl className="mb-3" type="text" placeholder="Dosya Adı" />
							<Form.Label>Dosya Yükle</Form.Label>
							<FormControl className="mb-3" type="file" placeholder="Dosya Yükle" />
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<SaveButton />
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default AddPersonnelFileModal
