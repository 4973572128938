import { ApproveButton, CloseButton } from 'components/fast-ui'
import { ButtonContainer } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import QRCode from 'react-qr-code'

import { ErrorMessage } from '@hookform/error-message'

const TwoFactorEnableModal = ({ show, onHide, onEnabled }) => {
	const [twoFactorData, setTwoFactorData] = useState(null)

	const fetchData = async () => {
		try {
			const response = await Service.get('/two-factor')
			if (response.data.enabled) {
				sendToast('2FA zaten etkinleştirilmiş.', false)
				handleClose()
			} else {
				setTwoFactorData(response.data)
			}
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	const handleEnable = async (data) => {
		const tokenClean = data.token.replace(/\s/g, '')
		const body = {
			secret: twoFactorData.secret,
			token: tokenClean
		}
		try {
			const response = await Service.post('/two-factor/enable', body)
			sendToast(response.data.message, true)
			onEnabled && onEnabled()
			handleClose()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors }
	} = useForm({ mode: 'onBlur' })

	const handleClose = () => {
		onHide()
	}

	return (
		<Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title as="h5">2FA'yı Etkinleştir</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="d-flex flex-column gap-3">
					<div>
						<h5>
							1. <strong>Google Authenticator</strong> uygulamasını indirin
						</h5>
						<p>
							Telefonunuzda veya tabletinizde{' '}
							<strong>
								<a
									href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=tr"
									target="_blank"
									rel="noreferrer"
								>
									Google Authenticator
								</a>
							</strong>{' '}
							uygulamasını indirin.
						</p>
						<hr />
					</div>
					<div>
						<h5>
							2. <strong>Google Authenticator</strong> uygulamasını kullanarak hesabınızı etkinleştirin
						</h5>
						<p>
							<strong>Google Authenticator</strong> uygulamasını açın ve aşağıdaki QR kodunu tarayın.
						</p>

						{twoFactorData && <QRCode value={twoFactorData.url} size={128} className="mb-2" />}
						<hr />
					</div>
					<div>
						<h5>3. 2FA'yı etkinleştir</h5>
						<p>
							<strong>Google Authenticator</strong> uygulamasında oluşturulan kodu girin.
						</p>

						<Form.Label>6 Haneli Kod</Form.Label>
						<Form.Control
							type="text"
							placeholder="000 000"
							{...register('token', {
								required: 'Bu alan gerekli.'
							})}
							onChange={(e) => {
								const input = e.target.value.replace(/\D/g, '').substring(0, 6) // sadece rakamları al ve ilk 6 karakteri al
								const regex = /^([0-9]{0,3})([0-9]{0,3})$/
								const matches = input.match(regex) // iki parçaya bölmek için regex kullan
								const formatted = matches ? `${matches[1]} ${matches[2]}`.trim() : ''
								e.target.value = formatted
							}}
						/>
						<Form.Control.Feedback type="invalid">
							<ErrorMessage errors={errors} name="token" />
						</Form.Control.Feedback>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<ButtonContainer>
					<CloseButton onClick={handleClose} />
					<ApproveButton onClick={handleSubmit((data) => handleEnable(data))} />
				</ButtonContainer>
			</Modal.Footer>
		</Modal>
	)
}

export default TwoFactorEnableModal
