import "./index.css"

import React from 'react'

const error = () => {
    return (
        <div className="container-xxl container-p-y">
            <div className="misc-wrapper">
                <h1 className="mx-2 mb-2">404 Sayfa Bulunamadı :(</h1>
                <p className="mx-2 mb-4">Aradığınız sayfa eskimiş görünüyor</p>
                <a href="/" className="btn btn-primary">Anasayfa'ya dön</a>
                <div className="mt-3">
                    <img src="../../assets/img/illustrations/page-misc-error-light.png" alt="page-misc-error-light" width={500} className="img-fluid" data-app-light-img="illustrations/page-misc-error-light.png" data-app-dark-img="illustrations/page-misc-error-dark.png" />
                </div>
            </div>
        </div>

    )
}

export default error