import { FaIcon } from 'components/ui'
import { format } from 'date-fns'
import { formatDate } from 'global/functions/formatDate'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { ControlledMenu, MenuItem, useMenuState } from '@szhsin/react-menu'

import TerminateEmployeeModal from '../DismissalModal'
import Avatar from './Avatar'
import useNewEmployeeStore from 'stores/newEmployeeStore'

const UserCard = () => {
	const { firstName, lastName } = useNewEmployeeStore((state) => state)
	const params = useParams()
	const navigate = useNavigate()

	const [isNew, setIsNew] = useState(true)
	const { employee } = useSelector((state) => state.ikEmployeeEdit)
	const { employee_id } = params

	const [menuProps, toggleMenu] = useMenuState()
	const [isOpen, setOpen] = useState()
	const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })
	const [terminateModalShow, setTerminateModalShow] = useState(false)

	useEffect(() => {
		if (employee_id === 'new') {
			setIsNew(true)
		} else {
			setIsNew(false)
		}
	}, [employee_id])

	const handleClickMenu = (e) => {
		e.preventDefault()
		setAnchorPoint({ x: e.clientX, y: e.clientY })
		toggleMenu(true)
		setOpen(true)
	}

	const handleCloseMenu = () => {
		toggleMenu(false)
		setOpen(false)
	}

	const handleClickDismissal = () => {
		navigate(`/employees/${employee_id}/general/dismissal`)
	}

	const handleClickBackToWork = () => {
		navigate(`/employees/${employee_id}/general/backtowork`)
	}

	const isActive = employee?.dismissal?.date ? false : true
	const today = new Date()
	const activePosition = employee?.positionHistory
		? employee?.positionHistory.find(
				(item) => new Date(item?.startDate) < new Date() && new Date(item?.finishDate) > new Date()
		  )
		: {}

	const dismissalStatus = employee?.dismissal?.date ? true : false

	return (
		<>
			{isNew ? (
				<div className="card mb-4">
					<div className="card-body">
						<div className="user-avatar-section">
							<div className=" d-flex align-items-center flex-column">
								<Avatar />
								<div className="user-info text-center">
									<h5 className="mb-2">
										{firstName} {lastName}
									</h5>
									{/* <p className="mb-3">Ünvan</p>
								<span className="badge bg-label-secondary">Aktif Çalışan</span> */}
								</div>
							</div>
						</div>

						<div className="pb-2 mb-1 border-bottom" />
					</div>
				</div>
			) : (
				<div className="card mb-4">
					<div onClick={handleClickMenu} className="d-flex justify-content-end px-2 py-2">
						<button className="btn p-0" type="button">
							<i className="bx bx-dots-vertical-rounded" />
						</button>
					</div>
					<ControlledMenu
						className="position-absolute"
						{...menuProps}
						anchorPoint={anchorPoint}
						onClose={handleCloseMenu}
					>
						{dismissalStatus ? (
							<MenuItem onClick={handleClickBackToWork}>
								<span>
									<FaIcon icon="rotate-left" size="sm" className="me-2" /> İşe geri al
								</span>
							</MenuItem>
						) : (
							<MenuItem onClick={handleClickDismissal}>
								<span>
									<FaIcon icon="user-minus" size="sm" className="me-2" /> İşten çıkart
								</span>
							</MenuItem>
						)}
					</ControlledMenu>
					<div className="card-body pt-0">
						<div className="user-avatar-section">
							<div className=" d-flex align-items-center flex-column">
								<Avatar />
								<div className="user-info text-center">
									<h5 className="mb-2">{`${employee?.firstName} ${employee?.lastName}`}</h5>
									<p className="mb-3">{employee?.position?.title?.name}</p>
									{isActive ? (
										<span className="badge bg-label-secondary">Aktif Çalışan</span>
									) : (
										<span className="badge bg-label-danger">Ayrıldı</span>
									)}
								</div>
							</div>
						</div>
						<div className="pb-2 mt-4 mb-4 border-bottom" />
						<div className="info-container">
							<small className="text-muted text-uppercase">Hakkında</small>
							<div>
								<ul className="list-unstyled mb-4 mt-3">
									<li className="d-flex align-items-center mb-3">
										<i className="bx bx-user" />
										<span className="fw-semibold mx-2">İşe Başlama Tarihi:</span>{' '}
										<span>{formatDate(employee.hiredDate, false)}</span>
									</li>
									{isActive ? null : (
										<li className="d-flex align-items-center mb-3">
											<i className="bx bx-door-open" />
											<span className="fw-semibold mx-2">İşten Ayrılma Tarihi:</span>
											<span>{formatDate(employee?.dismissal?.date, false)}</span>
										</li>
									)}

									<li className="d-flex align-items-center mb-3">
										<i className="bx bx-briefcase" />
										<span className="fw-semibold mx-2">Departman:</span>{' '}
										<span>{employee?.position?.department?.name}</span>
									</li>
									<li className="d-flex align-items-center mb-3">
										<i className="bx bx-star" />
										<span className="fw-semibold mx-2">Ünvan:</span> <span>{employee?.position?.title?.name}</span>
									</li>
									<li className="d-flex align-items-center mb-3">
										<i className="bx bx-flag" />
										<span className="fw-semibold mx-2">Lokasyon:</span>{' '}
										<span>{employee?.position?.location?.name}</span>
									</li>
									{/* <li className="d-flex align-items-center mb-3">
										<i className="bx bx-detail" />
										<span className="fw-semibold mx-2">Diller:</span> <span>??</span>
									</li> */}
								</ul>
								<small className="text-muted text-uppercase">İletişim (İŞ)</small>
								<ul className="list-unstyled mb-4 mt-3">
									<li className="d-flex align-items-center mb-3">
										<i className="bx bx-phone" />
										<span className="fw-semibold mx-2">Telefon:</span> <span>{`${employee?.workPhone}`}</span>
									</li>
									<li className="d-flex align-items-center mb-3">
										<i className="bx bx-envelope" />
										<span className="fw-semibold mx-2">Mail:</span> <span>{`${employee?.workMail}`}</span>
									</li>
								</ul>
								{/* <small className="text-muted text-uppercase">Ekipler</small>
								<ul className="list-unstyled mt-3 mb-0">
									<li className="d-flex align-items-center mb-3">
										<i className="bx bxl-github text-primary me-2" />
										<div className="d-flex flex-wrap">
											<span className="fw-semibold me-2">Backend Developer</span>
											<span>(126 Members)</span>
										</div>
									</li>
									<li className="d-flex align-items-center">
										<i className="bx bxl-react text-info me-2" />
										<div className="d-flex flex-wrap">
											<span className="fw-semibold me-2">React Developer</span>
											<span>(98 Members)</span>
										</div>
									</li>
								</ul> */}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default UserCard
