import { SaveButton } from 'components/fast-ui'
import { ProjectButton } from 'components/fast-ui/Buttons'
import { Button, ButtonContainer, CustomDatePicker, FaIcon } from 'components/ui'
import Service from 'global/Service'
import React from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Card, Form, Stack } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { Controller, useForm } from 'react-hook-form'
import { usePopper } from 'react-popper'
import { useNavigate, useParams } from 'react-router-dom'

const TasksDate = () => {
	const buttonRef = useRef(null)
	const popoverRef = useRef(null)
	const [show, setShow] = useState(false)
	const { styles, attributes } = usePopper(buttonRef.current, popoverRef.current, {
		placement: 'bottom-start',
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, 6]
				}
			}
		]
	})

	const handleClick = () => {
		setShow(!show)
	}

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		formState,
		setValue,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	const handleClickOutside = (event) => {
		if (
			popoverRef.current &&
			!popoverRef.current.contains(event.target) &&
			buttonRef.current &&
			!buttonRef.current.contains(event.target)
		) {
			setShow(false)
		}
	}

	useEffect(() => {
		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	const navigate = useNavigate()
	const params = useParams()

	const { task_id } = params

	const onSubmit = async (data) => {
		const body = {
			endDate: data.endDate
		}

		const response = await Service.patch(`/tasks/${task_id}/change-end-date`, body)
		navigate(`/tasks/${task_id}`)
	}

	return (
		<span>
			<ProjectButton text="Takvim" icon="fa-regular fa-calendars" onClick={handleClick} ref={buttonRef} />
			{createPortal(
				<div
					ref={popoverRef}
					style={{ ...styles.popper, zIndex: 9999 }}
					{...attributes.popper}
					className={`${show ? '' : 'd-none'}`}
				>
					<Card>
						<Card.Header>
							<div className="d-flex justify-content-between">
								<div>Tarihler</div>
								<div>
									<Button type="light" className="btn-sm shadow-none" onClick={handleClick}>
										<FaIcon icon="times" />
									</Button>
								</div>
							</div>
						</Card.Header>
						<Card.Body>
							<Stack className="mb-3">
								<Form.Label className="mt-2">Bitiş Tarihi</Form.Label>
								<Controller
									name="endDate"
									control={control}
									render={({ field }) => (
										<CustomDatePicker
											{...field}
											selected={field.value}
											onChange={(date) => field.onChange(date)}
											value={field.value}
										/>
									)}
								/>
							</Stack>
							<SaveButton className="w-100" onClick={handleSubmit(onSubmit)} icon={null} />
						</Card.Body>
					</Card>
				</div>,
				document.body
			)}
		</span>
	)
}

export default TasksDate
