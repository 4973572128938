import React, { useState } from 'react'

const BreadcrumbItem = ({ id, data, name, onClick, isActive }) => {
	const [isHovering, setIsHovering] = useState(false)

	const handleClick = () => {
		onClick && onClick(id)
	}

	const handleMouseEnter = () => {
		setIsHovering(true)
	}

	const handleMouseLeave = () => {
		setIsHovering(false)
	}

	const activeClass = isActive ? 'fw-bold' : ''

	return (
		<li className={`breadcrumb-item ${activeClass}`}>
			<span onClick={handleClick} type="button" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
				{isHovering && <span className="fw-semibold">{name}</span>}
				{!isHovering && <>{name}</>}
			</span>
		</li>
	)
}

export default BreadcrumbItem
