import { TabButton, TabButtonContainer } from 'components/ui'
import React from 'react'
import { Col, Container, Row, Stack } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

import ApprovalProcesses from './approval-processes'
import CompanyStructure from './company-structure'
import HolidayCalendars from './holiday-calendars'
import Roles from './roles'
import WorkCalendars from './work-calendars'

const Settings = () => {
	const params = useParams()
	const navigate = useNavigate()

	const { tab_name } = params

	return (
		<Container fluid="xxl" className="flex-grow-1 container-p-y">
			<Row className="g-4">
				<Col>
					<TabButtonContainer>
						<TabButton
							onClick={() => navigate(`/settings/company-structure`)}
							icon="sitemap"
							text="Şirket Yapısı"
							active={tab_name === 'company-structure'}
						/>
						<TabButton
							onClick={() => navigate(`/settings/approval-processes`)}
							icon="table-rows"
							text="Onay Süreçleri"
							active={tab_name === 'approval-processes'}
						/>
						<TabButton
							onClick={() => navigate(`/settings/roles`)}
							icon="shield-quartered"
							text="Roller"
							active={tab_name === 'roles'}
						/>

						<TabButton
							onClick={() => navigate(`/settings/work-calendars`)}
							icon="calendar"
							text="Çalışma Takvimleri"
							active={tab_name === 'work-calendars'}
						/>

						<TabButton
							onClick={() => navigate(`/settings/holiday-calendars`)}
							icon="calendar"
							text="Tatil Takvimleri"
							active={tab_name === 'holiday-calendars'}
						/>
					</TabButtonContainer>
				</Col>
				<Col md={12}>
					{tab_name === 'company-structure' && <CompanyStructure />}
					{tab_name === 'approval-processes' && <ApprovalProcesses />}
					{tab_name === 'roles' && <Roles />}
					{tab_name === 'work-calendars' && <WorkCalendars />}
					{tab_name === 'holiday-calendars' && <HolidayCalendars />}
				</Col>
			</Row>
		</Container>
	)
}

export default Settings
