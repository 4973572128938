import { CreateButton, DeleteButton, SaveButton } from 'components/fast-ui'
import { ButtonContainer, FaIcon } from 'components/ui'
import React, { useEffect } from 'react'
import { CirclePicker } from 'react-color'
import { useDispatch, useSelector } from 'react-redux'
import {
	createTag,
	deleteTag,
	patchSelectedTag,
	refreshTagList,
	setSelectedTagHexColor,
	setSelectedTagName
} from 'stores/documentSlice'

const EditTagModal = () => {
	const dispatch = useDispatch()

	const { items, selectedTag } = useSelector((state) => state.document.tags)

	useEffect(() => {
		dispatch(refreshTagList())
	}, [])

	const handleDelete = () => {
		dispatch(deleteTag(selectedTag.content.id))
	}

	const handleCreate = () => {
		dispatch(createTag(selectedTag.content))
	}

	const handleSave = () => {
		dispatch(patchSelectedTag())
		//dispatch(createTag(selectedTag.content))
	}

	const isNew = selectedTag.content._id === null

	return (
		<div
			className="modal fade"
			id="newTagModal"
			aria-labelledby="newTagModalLabel"
			tabIndex={-1}
			style={{ display: 'none' }}
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<div className="content-left">
							<h5 className="modal-title">
								<FaIcon icon={isNew ? 'plus' : 'pen-to-square'} className="me-2" />
								{isNew ? 'Etiket Ekle' : 'Etiket Düzenle'}
							</h5>
							<small>
								{isNew
									? 'Yeni etiket tanımlama formu.'
									: selectedTag.content._id + ' numaralı etiket için düzenleme formu.'}
							</small>
						</div>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-6 mb-3">
								<label className="form-label" htmlFor="nameWithTitle">
									Etiket Adı
								</label>
								<input
									style={{ color: selectedTag.content.hexColor }}
									onChange={(e) => dispatch(setSelectedTagName(e.target.value))}
									value={selectedTag.content.name || ''}
									type="text"
									id="nameWithTitle"
									className="form-control"
									placeholder="Etiket adı"
								/>
							</div>
							<div className="col-6 mb-3">
								<label className="form-label" htmlFor="nameWithTitle">
									Renk
								</label>
								<CirclePicker
									className="mt-2"
									onChangeComplete={(color) => dispatch(setSelectedTagHexColor(color.hex))}
								/>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						{isNew ? (
							<CreateButton onClick={handleCreate} data-bs-dismiss="modal" />
						) : (
							<ButtonContainer>
								<DeleteButton onClick={handleDelete} data-bs-dismiss="modal" />
								<SaveButton onClick={handleSave} data-bs-dismiss="modal" />
							</ButtonContainer>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default EditTagModal
