import { CloseButton, SendButton } from 'components/fast-ui'
import { ButtonContainer } from 'components/ui'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

const exampleData = [
	{
		_id: '2',
		name: 'Doküman 12',
		uploader: 'Nihat',
		uploadDate: '02/01/2023',
		status: 'pending',
		location: 'Konum 2'
	},
	{
		_id: '3',
		name: 'Doküman 3',
		uploader: 'Barış',
		uploadDate: '03/01/2023',
		status: 'pending',
		location: 'Konum 3'
	}
]

const DocumentRejectModal = ({ show, onHide }) => {
	const [selectedDocument, setSelectedDocument] = useState(null)
	const [rejectReason, setRejectReason] = useState('')
	const [data, setData] = useState(null)

	const params = useParams()
	const navigate = useNavigate()

	const fetchData = () => {
		try {
			const lineData = exampleData.find((item) => item._id === params.document_id)
			setData(lineData)
			setSelectedDocument(exampleData.find((item) => item._id === params.document_id))
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (params.document_id) {
			fetchData()
		}
	}, [params])

	const handleClose = () => {
		onHide && onHide()
		navigate('/document-approval')
	}

	const handleRejectSubmit = () => {
		sendToast('Doküman reddedildi', true)
		handleClose()
	}

	return (
		<Modal show={show} onHide={onHide} centered>
			<Modal.Header closeButton>
				<Modal.Title className="mb-0" as={'h5'}>
					Doküman Reddetme
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					<b>{selectedDocument?.uploader}</b> tarafından yüklenen <b>{selectedDocument?.name}</b> dokümanını reddetmek
					istediğinize emin misiniz?
				</p>
				<Form>
					<Form.Group controlId="rejectReason">
						<Form.Label className="mb-2">Reddetme Nedeni</Form.Label>
						<Form.Control
							as="textarea"
							rows={3}
							value={rejectReason}
							onChange={(e) => setRejectReason(e.target.value)}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<ButtonContainer>
					<ButtonContainer>
						<CloseButton onClick={handleClose} />
						<SendButton onClick={handleRejectSubmit} disabled={!rejectReason} />
					</ButtonContainer>
				</ButtonContainer>
			</Modal.Footer>
		</Modal>
	)
}

export default DocumentRejectModal
