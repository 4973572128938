import { CloseButton, CreateButton, DeleteButton, SaveButton } from 'components/fast-ui'
import { FaIcon } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { ErrorMessage } from '@hookform/error-message'

const days = [
	{ value: 'monday', label: 'Pazartesi' },
	{ value: 'tuesday', label: 'Salı' },
	{ value: 'wednesday', label: 'Çarşamba' },
	{ value: 'thursday', label: 'Perşembe' },
	{ value: 'friday', label: 'Cuma' },
	{ value: 'saturday', label: 'Cumartesi' },
	{ value: 'sunday', label: 'Pazar' }
]

const WorkCalendarModal = () => {
	const params = useParams()
	const { tab_name, item_id } = params

	const navigate = useNavigate()
	const [show, setShow] = useState(true)
	const [isEdit, setIsEdit] = useState(false)

	const fetchData = async () => {
		try {
			const response = await Service.get(`/work-calendars/${item_id}`)
			reset(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (tab_name === 'work-calendars' && item_id) {
			setShow(true)
			if (item_id === 'add') {
				setIsEdit(false)
				reset({
					days: [
						{ dayName: 'monday' },
						{ dayName: 'tuesday' },
						{ dayName: 'wednesday' },
						{ dayName: 'thursday' },
						{ dayName: 'friday' },
						{ dayName: 'saturday' },
						{ dayName: 'sunday' }
					]
				})
			} else {
				setIsEdit(true)
				fetchData()
			}
		} else {
			setShow(false)
		}
	}, [tab_name, item_id])

	const handleClose = () => {
		setShow(false)
		navigate(`/settings/work-calendars`)
	}

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState,
		watch,
		formState: { errors }
	} = useForm({
		defaultValues: {
			days: [
				{ dayName: 'monday' },
				{ dayName: 'tuesday' },
				{ dayName: 'wednesday' },
				{ dayName: 'thursday' },
				{ dayName: 'friday' },
				{ dayName: 'saturday' },
				{ dayName: 'sunday' }
			]
		},
		mode: 'onChange'
	})

	const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
		control,
		name: 'days'
	})

	const handleCreate = async (data) => {
		try {
			const response = await Service.post('/work-calendars', data)
			sendToast('Çalışma Takvimi Oluşturuldu')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleSave = async (data) => {
		try {
			const response = await Service.patch(`/work-calendars/${item_id}`, data)
			sendToast('Çalışma Takvimi Güncellendi')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDelete = async () => {
		try {
			const response = await Service.delete(`/work-calendars/${item_id}`)
			sendToast('Çalışma Takvimi Silindi')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title as="h5">{isEdit ? 'Çalışma Takvimini Düzenle' : 'Yeni Çalışma Takvimi Oluştur'}</Modal.Title>
			</Modal.Header>
			<Modal.Body
				style={{
					maxHeight: 'calc(100vh - 210px)',
					overflowY: 'auto'
				}}
			>
				<Form>
					<Row>
						<Col md={6} className="mb-3">
							<Controller
								name="name"
								control={control}
								defaultValue=""
								rules={{ required: 'Gerekli alan.' }}
								render={({ field }) => (
									<Form.Group>
										<Form.Label>Adı</Form.Label>
										<Form.Control type="text" {...field} isInvalid={errors.name} />
										<Form.Control.Feedback type="invalid">
											<ErrorMessage errors={errors} name="name" />
										</Form.Control.Feedback>
									</Form.Group>
								)}
							/>
						</Col>

						<Col md={6}>
							<Controller
								name="description"
								control={control}
								defaultValue=""
								render={({ field }) => (
									<Form.Group>
										<Form.Label>Açıklama</Form.Label>
										<Form.Control type="text" {...field} isInvalid={errors.description} />
										<Form.Control.Feedback type="invalid">
											<ErrorMessage errors={errors} name="description" />
										</Form.Control.Feedback>
									</Form.Group>
								)}
							/>
						</Col>

						<Col md={12}>
							<Row>
								{fields.map((item, index) => (
									<>
										<Col md={12} className="mb-3">
											<Controller
												name={`days.${index}.isWorkingDay`}
												control={control}
												defaultValue={false}
												render={({ field }) => (
													<Form.Group>
														<Form.Check
															type="switch"
															{...field}
															id={`days.${index}.isWorkingDay`}
															label={days.find((day) => day.value === item.dayName).label}
															onChange={(e) => {
																field.onChange(e.target.checked)
															}}
															checked={field.value}
														/>
														{field.value && (
															<Form.Group className="mt-3">
																<Row>
																	<Col md={3}>
																		<Controller
																			name={`days.${index}.startTime`}
																			control={control}
																			defaultValue="08:00"
																			rules={{ required: 'Bu alan gerekli.' }}
																			render={({ field }) => (
																				<Form.Group>
																					<Form.Label>
																						<FaIcon icon={'clock'} /> Mesai Başlangıç
																					</Form.Label>

																					<Form.Control
																						type="time"
																						{...field}
																						isInvalid={errors.days?.[index]?.startTime}
																					/>
																					<Form.Control.Feedback type="invalid">
																						<ErrorMessage errors={errors} name={`days.${index}.startTime`} />
																					</Form.Control.Feedback>
																				</Form.Group>
																			)}
																		/>
																	</Col>
																	<Col md={3}>
																		<Controller
																			name={`days.${index}.endTime`}
																			control={control}
																			defaultValue="17:00"
																			rules={{ required: 'Bu alan gerekli.' }}
																			render={({ field }) => (
																				<Form.Group>
																					<Form.Label>
																						<FaIcon icon={'clock'} /> Mesai Bitiş
																					</Form.Label>

																					<Form.Control
																						type="time"
																						{...field}
																						isInvalid={errors.days?.[index]?.endTime}
																					/>
																					<Form.Control.Feedback type="invalid">
																						<ErrorMessage errors={errors} name={`days.${index}.endTime`} />
																					</Form.Control.Feedback>
																				</Form.Group>
																			)}
																		/>
																	</Col>
																	<Col md={3}>
																		<Controller
																			name={`days.${index}.lunchStartTime`}
																			control={control}
																			defaultValue="12:00"
																			rules={{ required: 'Bu alan gerekli.' }}
																			render={({ field }) => (
																				<Form.Group>
																					<Form.Label>
																						<FaIcon icon={'coffee'} /> Mola Başlangıç
																					</Form.Label>

																					<Form.Control
																						type="time"
																						{...field}
																						isInvalid={errors.days?.[index]?.lunchStartTime}
																					/>
																					<Form.Control.Feedback type="invalid">
																						<ErrorMessage errors={errors} name={`days.${index}.lunchStartTime`} />
																					</Form.Control.Feedback>
																				</Form.Group>
																			)}
																		/>
																	</Col>
																	<Col md={3}>
																		<Controller
																			name={`days.${index}.lunchEndTime`}
																			control={control}
																			defaultValue="13:00"
																			rules={{ required: 'Bu alan gerekli.' }}
																			render={({ field }) => (
																				<Form.Group>
																					<Form.Label>
																						<FaIcon icon={'coffee'} /> Mola Bitiş
																					</Form.Label>

																					<Form.Control
																						type="time"
																						{...field}
																						isInvalid={errors.days?.[index]?.lunchEndTime}
																					/>
																					<Form.Control.Feedback type="invalid">
																						<ErrorMessage errors={errors} name={`days.${index}.lunchEndTime`} />
																					</Form.Control.Feedback>
																				</Form.Group>
																			)}
																		/>
																	</Col>
																</Row>
															</Form.Group>
														)}
													</Form.Group>
												)}
											/>
										</Col>
									</>
								))}
							</Row>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{isEdit ? (
					<>
						<SaveButton onClick={handleSubmit((d) => handleSave(d))} />
						<DeleteButton onClick={handleDelete} />
					</>
				) : (
					<>
						<CreateButton onClick={handleSubmit((d) => handleCreate(d))} />
					</>
				)}
			</Modal.Footer>
		</Modal>
	)
}

export default WorkCalendarModal
