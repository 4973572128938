import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import { create } from 'zustand'

const useRoleStore = create((set, get) => ({
	roles: [],
	setRoles: (roles) => set({ roles }),
	fetchRoles: async () => {
		try {
			const response = await Service.get('/roles')
			set({ roles: response.data })
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}
}))

export default useRoleStore
