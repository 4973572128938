import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { refreshCompanyStructures } from 'stores/settings/companyStructureSlice'

import CompanyStructureColorModal from './structures-modals/CompanyStructureColorModal'
import CompanyStructureDeleteModal from './structures-modals/CompanyStructureDeleteModal'
import CompanyUnitDeleteModal from './units-modals/CompanyUnitDeleteModal'
import CompanyUnitModal from './units-modals/CompanyUnitModal'
import CompanyUnitTransferModal from './units-modals/CompanyUnitTransferModal'

const CompanyStructureModals = () => {
	const params = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { tab_name, item_id, sub_item_id, process_name } = params

	const [showingModal, setShowingModal] = useState(null)

	useEffect(() => {
		if (tab_name === 'company-structure' && item_id) {
			if (sub_item_id === 'delete') {
				setShowingModal('structure-delete')
			} else if (sub_item_id === 'color') {
				setShowingModal('structure-color')
			} else if (sub_item_id === 'transfer') {
				setShowingModal('structure-transfer')
			} else if (sub_item_id === 'add') {
				setShowingModal('structure-add')
			} else {
				if (process_name === 'transfer') {
					setShowingModal('unit-transfer')
				} else if (process_name === 'delete') {
					setShowingModal('unit-delete')
				} else {
					setShowingModal('unit-edit')
				}
			}
		} else {
			setShowingModal(null)
		}
	}, [params])

	const handleHide = () => {
		navigate(`/settings/company-structure`)
		dispatch(refreshCompanyStructures())
	}

	return (
		<>
			{showingModal === 'structure-delete' && <CompanyStructureDeleteModal onHide={handleHide} />}
			{showingModal === 'structure-color' && <CompanyStructureColorModal onHide={handleHide} />}
			{showingModal === 'structure-add' && <CompanyUnitModal onHide={handleHide} />}
			{showingModal === 'unit-edit' && <CompanyUnitModal onHide={handleHide} />}
			{showingModal === 'unit-transfer' && <CompanyUnitTransferModal onHide={handleHide} />}
			{showingModal === 'unit-delete' && <CompanyUnitDeleteModal onHide={handleHide} />}
		</>
	)
}

export default CompanyStructureModals
