import { FaIcon } from 'components/ui'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectCompetenceType } from 'stores/ik/competence/ikCompetenceTypeDefinitionSlice'

const COLUMNS = [
	{
		Header: 'Ad',
		accessor: 'name',
		Cell: (cell) => {
			return (
				<div>
					<i class="bx bx-check"></i> {cell.row.original.name}
				</div>
			)
		}
	},
	{
		Header: 'İşlem',
		Cell: ({ row }) => {
			return (
				<div>
					<Link to={`/ik-competencies/definitions/types/${row.original._id}`}>
						<FaIcon icon="edit" />
					</Link>
				</div>
			)
		}
	}
]

export default COLUMNS
