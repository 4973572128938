import React from 'react'

const FormInput = ({ label, placeholder, register, ...rest }) => {
	return (
		<div className="form-input">
			<label className="form-label">{label}</label>
			<input className="form-control" placeholder={placeholder} {...register} {...rest} />
		</div>
	)
}

export default FormInput
