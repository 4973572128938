import axios from 'axios'
import apiUrl from 'config'
import useApiStore from 'stores/apiStore'
import useAuthStore from 'stores/authStore'

import { sendToast } from './sendToast'

const Service = axios.create({
	baseURL: apiUrl,
	withCredentials: true
})

const setIsLoading = useApiStore.getState().setIsLoading

Service.interceptors.request.use(
	(config) => {
		setIsLoading(true)
		const accessToken = useAuthStore.getState().accessToken
		if (accessToken) {
			config.headers = {
				Authorization: `Bearer ${accessToken}`
			}
		}
		return config
	},
	(error) => {
		setIsLoading(false)
		return Promise.reject(error)
	}
)

Service.interceptors.response.use(
	(response) => {
		setIsLoading(false)

		return response
	},
	async (error) => {
		setIsLoading(false)
		const originalRequest = error.config
		if (error.response.status === 401 && !originalRequest.isRetry) {
			console.log('401 error alındı')
			const refreshToken = useAuthStore.getState().refreshToken
			console.log('refreshToken for 401', refreshToken)
			const setAccessToken = useAuthStore.getState().setAccessToken
			const logout = useAuthStore.getState().logout

			originalRequest.isRetry = true

			try {
				const response = await Service.post('/token/refresh', {
					refreshToken: refreshToken
				})

				setAccessToken(response.data.accessToken)
				return Service(originalRequest)
			} catch (error) {
				sendToast(error.response.data.message, false)
			}
		}

		if (error.response.status === 403) {
			console.log('403 error alındı')
			const logout = useAuthStore.getState().logout
			logout()
		}
		return Promise.reject(error)
	}
)

export default Service
