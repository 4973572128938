import Service from 'global/Service'
import { formatDate } from 'global/functions/formatDate'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import useAuthStore from 'stores/authStore'

export default function MyVacations() {
	const [data, setData] = useState([])
	const user = useAuthStore((state) => state.user)

	const fetchData = async () => {
		const employeeId = user._id

		try {
			const response = await Service.get(`/vacations/employee/${employeeId}`)
			setData(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<Card
			style={{
				height: 300
			}}
		>
			<Card.Header>
				<Card.Title className="mb-0">İzinlerim</Card.Title>
				<small className="card-subtitle text-muted">Talep ettiğiniz izinler</small>
			</Card.Header>
			<Card.Body className="d-flex align-items-start">
				<div className="w-100">
					{data.slice(0, 3).map((item, index) => (
						<div key={index} className="item-progress">
							<i className="far fa-check-circle mb-2"></i> {formatDate(item.startDate, false)} ({item.type.name})
						</div>
					))}
					{data.length === 0 && <div className="text-muted">Henüz izin talebinde bulunmadınız.</div>}
				</div>
			</Card.Body>
		</Card>
	)
}
