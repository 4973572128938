import { TabButton, TabButtonContainer } from 'components/ui'
import React, { useState } from 'react'
import { Card } from 'react-bootstrap'

import VacationRequestsTab from './requests-tab'
import VacationSummaryTab from './summary-tab'

const VacationTab = () => {
	const [activeTab, setActiveTab] = useState('summary')

	return (
		<Card>
			<Card.Header>
				<Card.Title as="h5">İzinler</Card.Title>
				<TabButtonContainer>
					<TabButton
						text="Özet"
						icon="chart-pie"
						active={activeTab === 'summary'}
						onClick={() => setActiveTab('summary')}
					/>
					<TabButton
						text="İzin Talepleri"
						icon="chart-bar"
						active={activeTab === 'requests'}
						onClick={() => setActiveTab('requests')}
					/>
				</TabButtonContainer>
			</Card.Header>
			{activeTab === 'summary' && <VacationSummaryTab />}
			{activeTab === 'requests' && <VacationRequestsTab />}
		</Card>
	)
}

export default VacationTab
