import anonimProfilePhoto from 'assets/img/avatars/anonim-profile-photo.jpg'
import { SaveButton } from 'components/fast-ui'
import Service from 'global/Service'
import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { Row } from 'react-bootstrap'
import Nestable from 'react-nestable'
import { useDispatch, useSelector } from 'react-redux'
import {
	deleteEmployeeFromScheme,
	setSelectedOrganizationItems,
	setSelectedOrganizationTempItems
} from 'stores/ikOrganizationSlice'

const EmployeeStruct = () => {
	const dispatch = useDispatch()
	const { selectedOrganization } = useSelector((state) => state.ikOrganization)

	const handleDelete = (employee) => {
		dispatch(deleteEmployeeFromScheme(employee))
	}

	const renderItem = ({ item, collapseIcon, handlerStyles, depth, isCollapsed, provided }) => {
		const isTopLevel = depth === 0
		return (
			<>
				<button type="button" className="btn btn-sm btn-label-secondary me-2 mw-100">
					<div className="d-flex align-items-center flex-wrap">
						<div className="avatar avatar-xs me-2">
							{item.employee?.profilePhotoUrl ? (
								<img src={item.employee?.profilePhotoUrl} alt="Avatar" className="rounded-circle" />
							) : (
								<img src={anonimProfilePhoto} alt="Avatar" className="rounded-circle" />
							)}
						</div>
						<span className="me-3">{`${item.employee?.firstName} ${item.employee?.lastName}`}</span>
						<span className="me-0">
							<small>{item.employee?.position?.title?.name}</small> -{' '}
							<small>{item.employee?.position?.department?.name}</small>
						</span>
					</div>
				</button>
				{isTopLevel && (
					<i
						onClick={() => {
							handleDelete(item.employee)
						}}
						type="button"
						className="bx bx-x text-danger"
					></i>
				)}
			</>
		)
	}

	const onChangeHandle = () => {
		const items = refNestable.current.state.items
		dispatch(setSelectedOrganizationTempItems(items))
	}

	const refNestable = useRef()

	return (
		<div className="card">
			<h5 className="card-header">Yapı</h5>
			<div className="card-body d-flex justify-content-center align-item-center">
				<Nestable
					idProp="_id"
					onChange={onChangeHandle}
					items={selectedOrganization.tempItems}
					ref={refNestable}
					renderItem={renderItem}
				/>
			</div>
		</div>
	)
}

export default EmployeeStruct
