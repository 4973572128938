import { createSlice } from '@reduxjs/toolkit'

const darkStorage = localStorage.getItem('dark') === 'true' ? true : false
const miniMenuStorage = localStorage.getItem('miniMenu') === 'true' ? true : false

const setDarkLocalStorage = (booleanValue) => {
	localStorage.setItem('dark', booleanValue)
}

const setMiniMenuLocalStorage = (booleanValue) => {
	localStorage.setItem('miniMenu', booleanValue)
}

export const siteSlice = createSlice({
	name: 'site',
	initialState: {
		dark: darkStorage,
		miniMenu: miniMenuStorage,
		expanded: false
	},
	reducers: {
		setDarkMode: (state) => {
			state.dark = true
			setDarkLocalStorage(true)
		},
		setLightMode: (state) => {
			state.dark = false
			setDarkLocalStorage(false)
		},
		setMiniMenu: (state) => {
			state.miniMenu = true
			setMiniMenuLocalStorage(true)
		},
		setNormalMenu: (state) => {
			state.miniMenu = false
			setMiniMenuLocalStorage(false)
		},
		enableExpanded: (state) => {
			state.expanded = true
		},
		disableExpanded: (state) => {
			state.expanded = false
		}
	}
})

export const { setDarkMode, setLightMode, setMiniMenu, setNormalMenu, enableExpanded, disableExpanded } =
	siteSlice.actions
export default siteSlice.reducer
