import React from 'react'

import { ReviseItem } from './ReviseItem'

const ReviseContainer = ({ data }) => {
	return (
		<>
			{data.map((item, index) => (
				<ReviseItem data={item} index={index} />
			))}
		</>
	)
}

export default ReviseContainer
