import Service from 'global/Service'
import { formatDate } from 'global/functions/formatDate'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'

export default function LastEvents() {
	const [data, setData] = useState([])
	const today = new Date()

	const fetchData = async () => {
		try {
			const response = await Service.get('/calendars')

			const filteredData = response.data.filter((event) => {
				const eventStartDate = new Date(event.dates.startDate)
				return eventStartDate >= today
			})

			const sortedData = filteredData.sort((a, b) => {
				const aStartDate = new Date(a.dates.startDate)
				const bStartDate = new Date(b.dates.startDate)
				return aStartDate - bStartDate
			})

			setData(sortedData)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	const renderUpcomingEvents = () => {
		const upcomingEvents = data.slice(0, 3)

		return upcomingEvents.map((event) => {
			const eventStartDate = new Date(event.dates.startDate)
			const timeDiff = eventStartDate.getTime() - today.getTime()
			const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24))

			let timeText = ''
			if (daysDiff === 0) {
				timeText = 'Bugün'
			} else if (daysDiff === 1) {
				timeText = 'Yarın'
			} else {
				timeText = `${daysDiff} gün sonra`
			}

			return (
				<li className="d-flex mb-3" key={event.id}>
					<span className="me-2">
						{daysDiff === 0 ? (
							<i className="bx bx-calendar-event bx-sm text-primary" />
						) : (
							<i className="bx bx-calendar bx-sm text-secondary" />
						)}
					</span>
					<div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
						<div className="me-2">
							<h6 className="mb-0 lh-1">{event.title}</h6>
							<small className="text-muted">{formatDate(event.dates.startDate, false)}</small>
						</div>
						<div className="item-progress">{timeText}</div>
					</div>
				</li>
			)
		})
	}

	return (
		<div
			className="card"
			style={{
				height: 300
			}}
		>
			<div className="card-header d-flex align-items-start justify-content-between">
				<div className="card-title mb-0">
					<h5 className="m-0 me-2">Etkinlikler</h5>
					<small className="card-subtitle text-muted">Yakındaki etkinlikler</small>
				</div>
			</div>
			<div className="card-body">
				<div id="salesChart" />
				<ul className="p-0 m-0">{renderUpcomingEvents()}</ul>
			</div>
		</div>
	)
}
