import { CustomReactTable, FaIcon } from 'components/ui'
import { format } from 'date-fns'
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const VacationSummaryTab = () => {
	const navigate = useNavigate()
	const { employee } = useSelector((state) => state.ikEmployeeEdit)

	const data = [
		// 5 örnek data
		{
			type: 'İzin',
			total: 0,
			available: 0,
			loss: 0,
			balance: 0,
			used: 0
		},
		{
			type: 'Yıllık',
			total: 0,
			available: 0,
			loss: 0,
			balance: 0,
			used: 0
		},
		{
			type: 'Ücretli İzin',
			total: 0,
			available: 0,
			loss: 0,
			balance: 0,
			used: 0
		},
		{
			type: 'Ücretsiz İzin',
			total: 0,
			available: 0,
			loss: 0,
			balance: 0,
			used: 0
		},
		{
			type: 'Yarım Gün İzin',
			total: 0,
			available: 0,
			loss: 0,
			balance: 0,
			used: 0
		}
	]

	const columns = [
		{
			Header: 'İzin Türü',
			accessor: 'type'
		},
		{
			Header: 'Toplam Devreden',
			accessor: 'total'
		},
		{
			Header: 'Kullanılabilir Devreden',
			accessor: 'available'
		},
		{
			Header: 'Devir Kayıpları',
			accessor: 'loss'
		},
		{
			Header: 'Kullanılabilir İzin Bakiyesi',
			accessor: 'balance'
		},
		{
			Header: 'Güncel Dönemde Kullanılan',
			accessor: 'used'
		}
	]

	//kıdem yılı hesaplayan fonksiyon
	const calculateSeniority = (startDate) => {
		const today = new Date()
		const start = new Date(startDate)
		const diff = today - start
		const diffYears = Math.floor(diff / 1000 / 60 / 60 / 24 / 365)
		return diffYears
	}

	const handleEditAccrualStartDate = () => {
		navigate(`/employees/${employee._id}/general`)
	}

	return (
		<Card.Body>
			<Row className="g-4 mb-4">
				<Col md={6}>
					<Card>
						<Card.Header>
							<Card.Title as="h5">Kıdem Yılı</Card.Title>
							<Card.Text as="h3">
								{employee.accrualStartDate ? calculateSeniority(employee.accrualStartDate) : '-'}
							</Card.Text>
						</Card.Header>
					</Card>
				</Col>

				<Col md={6}>
					<Card>
						<Card.Header>
							<Card.Title as="h5">Esas Hakediş Tarihi</Card.Title>
							<Card.Text as="h3">
								<span onClick={handleEditAccrualStartDate} className="cursor-pointer text-primary">
									<FaIcon icon="edit" className="me-2" size="2xs" />{' '}
								</span>
								{employee.accrualStartDate ? format(new Date(employee.accrualStartDate), 'dd.MM.yyyy') : '-'}
							</Card.Text>
						</Card.Header>
					</Card>
				</Col>
			</Row>
			<Row className="g-4">
				<Col md={12}>
					<Card>
						<Card.Header>
							<Card.Title as="h5">İzinler</Card.Title>
							<Card.Text>Yıl içinde limitli izinler*</Card.Text>
						</Card.Header>
						<Card.Body>
							<CustomReactTable columns={columns} data={data} />
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Card.Body>
	)
}

export default VacationSummaryTab
