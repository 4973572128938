import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'

import COLUMNS from './Columns'

const CompanyUnitTable = (props) => {
	const params = useParams()

	const { tab_name, item_id } = params
	const { _id } = props.companyStructure

	const [companyUnits, setCompanyUnits] = useState([])

	const fetchData = async () => {
		try {
			const response = await Service.get(`/company-structures/${_id}/company-units`)
			setCompanyUnits(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const data = useMemo(() => companyUnits)
	const columns = useMemo(() => COLUMNS, [])

	const tableInstance = useTable(
		{
			columns,
			data
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		flatRows,
		prepareRow,
		page,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		pageOptions,
		gotoPage,
		pageCount,
		setPageSize,
		state,
		setGlobalFilter
	} = tableInstance

	const { globalFilter, pageIndex, pageSize } = state

	useEffect(() => {
		fetchData()
	}, [item_id])

	return (
		<table
			{...getTableProps()}
			className="datatables-users table table-responsive border-top dataTable table-striped no-footer dtr-column"
			style={{ width: 1382 }}
		>
			<thead>
				{headerGroups.map((headerGroup) => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column) => (
							<th className="control sorting_disabled" {...column.getHeaderProps(column.getSortByToggleProps())}>
								{column.render('Header')}
								<span>{column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}</span>
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{page.map((row) => {
					prepareRow(row)
					return (
						<tr {...row.getRowProps()}>
							{row.cells.map((cell) => {
								return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
							})}
						</tr>
					)
				})}
			</tbody>
		</table>
	)
}

export default CompanyUnitTable
