import { ApproveButton, CloseButton } from 'components/fast-ui'
import { FaIcon } from 'components/ui'
import CustomReactSelect from 'components/ui/CustomReactSelect'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { ErrorMessage } from '@hookform/error-message'

const CompanyUnitTransferModal = ({ show, onHide }) => {
	const params = useParams()
	const { sub_item_id } = params

	const [companyUnit, setCompanyUnit] = useState({})
	const [companyUnits, setCompanyUnits] = useState([])

	const fetchCompanyUnits = async (companyStructure_id) => {
		try {
			const response = await Service.get('/company-structures/' + companyStructure_id + '/company-units')
			setCompanyUnits(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchCompanyUnit = async () => {
		try {
			const response = await Service.get(`/company-units/${sub_item_id}`)
			setCompanyUnit(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchCompanyUnit()
	}, [])

	useEffect(() => {
		if (!companyUnit?.companyStructure) return
		fetchCompanyUnits(companyUnit?.companyStructure._id)
	}, [companyUnit])

	//use react hook form
	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	const handleTransfer = async (data) => {
		try {
			const response = await Service.post(`/company-units/${sub_item_id}/transfer`, data)
			sendToast('Birimdeki personeller aktarıldı.', true)
			onHide()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<Modal show onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<div className="content-left">
					<Modal.Title as={'h5'}>
						<FaIcon icon="exchange" className="me-2" />"{companyUnit?.name}" Çalışanlarını Aktar
					</Modal.Title>
				</div>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Row>
						<Col md={12} className="mb-3">
							<Form.Label className="mb-2">Etkilenecek Çalışanlar</Form.Label>
							{companyUnit?.employees?.map((item, index) => (
								<div key={index}>
									{item?.firstName} {item?.lastName}
								</div>
							))}
						</Col>

						<Col md={12}>
							<Form.Label className="mb-2">Aktarılacak {companyUnit?.companyStructure?.name} Birimi</Form.Label>
							<Controller
								name="newCompanyUnit"
								control={control}
								rules={{ required: 'Bu alan gerekli.' }}
								render={({ field }) => (
									<>
										<CustomReactSelect
											options={companyUnits}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option._id}
											onChange={field.onChange}
											value={field.value}
											placeholder="Aktarılacak birim.."
										/>
										<Form.Control.Feedback type="invalid">
											<ErrorMessage errors={errors} name="newCompanyUnit" />
										</Form.Control.Feedback>
									</>
								)}
							/>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<CloseButton onClick={onHide} />
				<ApproveButton onClick={handleSubmit((d) => handleTransfer(d))} />
			</Modal.Footer>
		</Modal>
	)
}

export default CompanyUnitTransferModal
