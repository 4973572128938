import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip
} from 'chart.js'
import React from 'react'
import { Card } from 'react-bootstrap'
import { Line } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

export const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: 'top'
		}
	}
}

const labels = [
	'Ocak',
	'Şubat',
	'Mart',
	'Nisan',
	'Mayıs',
	'Haziran',
	'Temmuz',
	'Ağustos',
	'Eylül',
	'Ekim',
	'Kasım',
	'Aralık'
]

const initialEmployees = 100

const newHires = [28, 48, 40, 19, 86, 27, 90, 65, 59, 80, 81, 56]
const terminations = [65, 59, 80, 81, 56, 55, 40, 28, 48, 40, 19, 86]

const totalEmployees = new Array(labels.length).fill(0)
totalEmployees[0] = initialEmployees

for (let i = 1; i < labels.length; i++) {
	totalEmployees[i] = totalEmployees[i - 1] + newHires[i - 1] - terminations[i - 1]
}

const data = {
	labels: labels,
	datasets: [
		{
			label: 'Toplam Çalışanlar',
			data: totalEmployees,
			borderColor: 'rgb(75, 192, 192)',
			backgroundColor: 'rgba(75, 192, 192, 0.5)'
		},
		{
			label: 'Yeni Çalışanlar',
			data: newHires,
			borderColor: 'rgb(53, 162, 235)',
			backgroundColor: 'rgba(53, 162, 235, 0.5)'
		},
		{
			label: 'İşten Çıkarılanlar',
			data: terminations,
			borderColor: 'rgb(255, 99, 132)',
			backgroundColor: 'rgba(255, 99, 132, 0.5)'
		},

		{
			label: 'Değişim',
			data: newHires.map((value, index) => value - terminations[index]),
			borderColor: 'rgb(255, 205, 86)',
			backgroundColor: 'rgba(255, 205, 86, 0.5)'
		}
	]
}
const EmployeeDismissalChart = () => {
	return (
		<Card>
			<Card.Header>
				<Card.Title as="h5" className="mb-0">
					Çalışan Sayısı Değişimleri
				</Card.Title>
			</Card.Header>
			<Card.Body>
				<Line options={options} data={data} height={250} />
			</Card.Body>
		</Card>
	)
}

export default EmployeeDismissalChart
