export const getStatusBadgeClass = (status) => {
	switch (status) {
		case 'pending':
			return 'bg-warning'
		case 'rejected':
			return 'bg-danger'
		case 'approved':
			return 'bg-success'
		default:
			return 'bg-dark'
	}
}
