import { ApproveButton, CloseButton, CreateButton } from 'components/fast-ui'
import { FaIcon } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { ErrorMessage } from '@hookform/error-message'

const CreateSchemeModal = () => {
	const params = useParams()
	const navigate = useNavigate()

	const { scheme_id } = params
	const [show, setShow] = useState(false)

	useEffect(() => {
		if (scheme_id === 'add') {
			setShow(true)
		} else {
			setShow(false)
		}
	}, [params])

	const handleClose = () => {
		setShow(false)
		navigate(`/ik-organization-schemes`)
	}

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	const handleCreate = async (d) => {
		const body = d
		try {
			const newScheme = await Service.post('/organization-schemes', body)
			sendToast('Şema oluşturuldu.')
			navigate(`/ik-organization-schemes`)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<div className="content-left">
					<Modal.Title as={'h5'}>
						<FaIcon icon={'sitemap'} className="me-2" /> Şema Oluştur
					</Modal.Title>
				</div>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col xs={12} className="mb-3">
						<Form.Label>Şema Adı</Form.Label>
						<Form.Control
							{...register('name', {
								required: 'Bu alan gerekli.'
							})}
						/>
						<ErrorMessage
							errors={errors}
							name={'name'}
							render={({ message }) => <div className="invalid-feedback">{message}</div>}
						/>
					</Col>
					<Col xs={12} className="mb-3">
						<Form.Label>Şema Başlığı</Form.Label>
						<Form.Control
							{...register('title', {
								required: 'Bu alan gerekli.'
							})}
						/>
						<ErrorMessage
							errors={errors}
							name={'title'}
							render={({ message }) => <div className="invalid-feedback">{message}</div>}
						/>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<CloseButton onClick={handleClose} />
				<CreateButton onClick={handleSubmit((d) => handleCreate(d))} />
			</Modal.Footer>
		</Modal>
	)
}

export default CreateSchemeModal
