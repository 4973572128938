import { TabButton, TabButtonContainer } from 'components/ui'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchEmployee, setEmployee } from 'stores/ikEmployeeEditSlice'

import BackToWorkModal from './components/BackToWorkModal'
import DismissalModal from './components/DismissalModal'
import UserCard from './components/user-card'
import CareerTab from './tabs/career-tab'
import FilesTab from './tabs/files-tab'
import GeneralTab from './tabs/general-tab'
import OtherTab from './tabs/other-tab'
import PersonalTab from './tabs/personal-tab'
import VacationTab from './tabs/vacation-tab'

const EmployeeEdit = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const params = useParams()

	const { employee_id, tab_name } = params

	useEffect(() => {
		if (employee_id !== null && employee_id !== 'new') {
			dispatch(fetchEmployee(employee_id))
		} else {
			dispatch(setEmployee([]))
		}
	}, [employee_id])

	const handleClick = () => {
		navigate('/employees', { replace: true })
	}

	return (
		<div className="container-xxl flex-grow-1 container-p-y">
			<Row>
				<Col className="mb-4">
					<TabButtonContainer>
						<TabButton onClick={handleClick} icon="angle-left" />

						<TabButton
							onClick={() => navigate(`/employees/${employee_id}/general`)}
							icon="circle-user"
							text="Genel"
							active={tab_name === 'general'}
						/>

						<TabButton
							onClick={() => navigate(`/employees/${employee_id}/career/positions`)}
							disabled={employee_id === 'new'}
							icon="id-card"
							text="Kariyer"
							active={tab_name === 'career'}
						/>

						<TabButton
							onClick={() => navigate(`/employees/${employee_id}/personal`)}
							disabled={employee_id === 'new'}
							icon="square-user"
							text="Kişisel"
							active={tab_name === 'personal'}
						/>

						<TabButton
							disabled={employee_id === 'new'}
							onClick={() => navigate(`/employees/${employee_id}/other`)}
							icon="circle-info"
							text="Diğer"
							active={tab_name === 'other'}
						/>

						<TabButton
							disabled={employee_id === 'new'}
							onClick={() => navigate(`/employees/${employee_id}/vacation`)}
							icon="calendar"
							text="İzinler"
							active={tab_name === 'vacation'}
						/>
						<TabButton
							disabled={employee_id === 'new'}
							onClick={() => navigate(`/employees/${employee_id}/files`)}
							icon="file"
							text="Dosya"
							active={tab_name === 'files'}
						/>
					</TabButtonContainer>
				</Col>
			</Row>
			<Row>
				{tab_name !== 'career' && tab_name !== 'vacation' && tab_name !== 'files' && (
					<Col xs={12} md={4} sm={4}>
						<UserCard />
					</Col>
				)}

				{tab_name === 'career' && (
					<Col md={12}>
						<CareerTab />
					</Col>
				)}

				{tab_name === 'vacation' && (
					<Col md={12}>
						<VacationTab />
					</Col>
				)}

				{tab_name === 'files' && (
					<Col md={12}>
						<FilesTab />
					</Col>
				)}

				<Col xs={12} md={7} sm={8}>
					{(tab_name === 'general' || tab_name === undefined) && <GeneralTab />}
					{tab_name === 'personal' && <PersonalTab />}
					{tab_name === 'other' && <OtherTab />}
				</Col>
			</Row>
			<DismissalModal />
			<BackToWorkModal />
		</div>
	)
}

export default EmployeeEdit
