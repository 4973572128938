import Service from 'global/Service'
import { formatDate } from 'global/functions/formatDate'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Form, Modal, Table } from 'react-bootstrap'
import { useParams } from 'react-router'

const EventViewModal = ({ onClose }) => {
	const params = useParams()
	const { event_id } = params

	const [data, setData] = useState(null)

	const fetchData = async () => {
		try {
			const response = await Service.get(`/events/${event_id}`)
			setData(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	const handleClose = () => {
		onClose && onClose()
	}
	return (
		<Modal show onHide={handleClose} size="md" centered>
			<Modal.Header closeButton>
				<Modal.Title as="h5">Etkinlik Görüntüle</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Table striped bordered>
					<tbody>
						<tr>
							<td className="text-primary">Başlık</td>
							<td className="text-dark">{data?.title}</td>
						</tr>
						<tr>
							<td className="text-primary">Açıklama</td>
							<td className="text-dark">{data?.description}</td>
						</tr>
						<tr>
							<td className="text-primary">Başlangıç Tarihi</td>
							<td className="text-dark">{formatDate(data?.dates?.startDate)}</td>
						</tr>
						<tr>
							<td className="text-primary">Bitiş Tarihi</td>
							<td className="text-dark">{formatDate(data?.dates?.endDate)}</td>
						</tr>
						<tr>
							<td className="text-primary">Konum</td>
							<td className="text-dark">{data?.location}</td>
						</tr>
						<tr>
							<td className="text-primary">Link</td>
							<td className="text-dark">{data?.link}</td>
						</tr>
						<tr>
							<td className="text-primary">Oluşturan</td>
							<td className="text-dark">{data?.creator?.fullName}</td>
						</tr>
					</tbody>
				</Table>
			</Modal.Body>
		</Modal>
	)
}

export default EventViewModal
