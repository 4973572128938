import { SaveButton } from 'components/fast-ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useState } from 'react'
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import { ErrorMessage } from '@hookform/error-message'

const ChangePasswordSettings = () => {
	const [showCurrentPassword, setShowCurrentPassword] = useState(false)
	const [showNewPassword, setShowNewPassword] = useState(false)
	const [showConfirmPassword, setShowConfirmPassword] = useState(false)

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		watch,
		reset,
		formState: { errors, isValid }
	} = useForm({ mode: 'onChange' })

	const handleSave = async (data) => {
		const body = data

		try {
			const response = await Service.post('/settings/change-password', body)
			sendToast('Şifreniz başarıyla değiştirildi.')
			reset()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<Card className="mb-4">
			<Card.Header>
				<Card.Title as="h5" className="mb-0">
					Şifre Değiştir
				</Card.Title>
			</Card.Header>
			<Card.Body>
				<Form>
					<Row>
						<Col md={6} className="mb-3 form-password-toggle">
							<Form.Label htmlFor="currentPassword">Eski Şifre</Form.Label>
							<InputGroup className="input-group-merge">
								<Form.Control
									type={showCurrentPassword ? 'text' : 'password'}
									{...register('currentPassword', {
										required: 'Eski şifre alanı boş bırakılamaz'
									})}
									id="currentPassword"
									placeholder="••••••••"
								/>
								<InputGroup.Text
									className="cursor-pointer"
									onClick={() => setShowCurrentPassword(!showCurrentPassword)}
								>
									{showCurrentPassword ? <i className="bx bx-show"></i> : <i className="bx bx-hide"></i>}
								</InputGroup.Text>
							</InputGroup>
							<ErrorMessage
								errors={errors}
								name={'currentPassword'}
								render={({ message }) => <div className="invalid-feedback">{message}</div>}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3 form-password-toggle">
							<Form.Label htmlFor="newPassword">Yeni Şifre</Form.Label>
							<InputGroup className="input-group-merge">
								<Form.Control
									type={showNewPassword ? 'text' : 'password'}
									{...register('newPassword', {
										required: 'Yeni şifre alanı boş bırakılamaz',
										minLength: {
											value: 8,
											message: 'Şifre en az 8 karakter olmalıdır'
										},
										pattern: {
											value: /^(?=.*[A-Z])(?=.*[0-9!@#$%^&*])(?=.*[a-z]).{8,}$/,
											message: 'Şifre en az bir büyük harf, bir sayı veya sembol içermelidir'
										}
									})}
									id="newPassword"
									placeholder="••••••••"
								/>
								<InputGroup.Text className="cursor-pointer" onClick={() => setShowNewPassword(!showNewPassword)}>
									{showNewPassword ? <i className="bx bx-show"></i> : <i className="bx bx-hide"></i>}
								</InputGroup.Text>
							</InputGroup>
							<ErrorMessage
								errors={errors}
								name={'newPassword'}
								render={({ message }) => <div className="invalid-feedback">{message}</div>}
							/>
						</Col>
						{watch('newPassword') && (
							<Col md={6} className="mb-3 form-password-toggle">
								<Form.Label htmlFor="confirmPassword">Yeni Şifre Tekrar</Form.Label>
								<InputGroup className="input-group-merge">
									<Form.Control
										type={showConfirmPassword ? 'text' : 'password'}
										{...register('confirmPassword', {
											required: 'Yeni şifre tekrar alanı boş bırakılamaz',
											validate: (value) => value === getValues('newPassword') || 'Yeni şifreler eşleşmiyor'
										})}
										id="confirmPassword"
										placeholder="••••••••"
									/>
									<InputGroup.Text
										className="cursor-pointer"
										onClick={() => setShowConfirmPassword(!showConfirmPassword)}
									>
										{showConfirmPassword ? <i className="bx bx-show"></i> : <i className="bx bx-hide"></i>}
									</InputGroup.Text>
								</InputGroup>
								<ErrorMessage
									errors={errors}
									name={'confirmPassword'}
									render={({ message }) => <div className="invalid-feedback">{message}</div>}
								/>
							</Col>
						)}
						{watch('newPassword') && (
							<Col xs={12} className="mb-4">
								<p className="fw-semibold mt-2">Şifrenizin aşağıdaki kriterleri karşıladığından emin olun:</p>
								<ul className="ps-3 mb-0">
									<li className="mb-1">Minimum 8 karakter</li>
									<li className="mb-1">En az bir büyük harf</li>
									<li>En az bir sayı veya sembol</li>
								</ul>
							</Col>
						)}
						<Col xs={12} className="mt-1">
							<SaveButton onClick={handleSubmit((data) => handleSave(data))} disabled={!isValid} />
						</Col>
					</Row>
				</Form>
			</Card.Body>
		</Card>
	)
}

export default ChangePasswordSettings
