import React from 'react'
import { Col } from 'react-bootstrap'

import CompetencePacketDefinition from './competence-packets'
import CompetenceTypeDefinition from './competence-types'
import CompetenceDefinition from './competencies'

const CompetenceDefinitionsTab = () => {
	return (
		<>
			<Col sm={12} md={6} lg={9} className="mb-4">
				<CompetenceDefinition />
			</Col>
			<Col sm={12} md={6} lg={3} className="mb-4">
				<CompetenceTypeDefinition />
			</Col>
			<Col md={12}>
				<CompetencePacketDefinition />
			</Col>
		</>
	)
}

export default CompetenceDefinitionsTab
