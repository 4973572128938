import anonimProfilePhoto from 'assets/img/avatars/anonim-profile-photo.jpg'
import { SendButton } from 'components/fast-ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useState } from 'react'
import { Form, Image } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import useAuthStore from 'stores/authStore'
import useProjectTaskStore from 'stores/projectTaskStore'

import TaskComment from './components/TaskComment'

const TaskActivity = () => {
	const refreshTask = useProjectTaskStore((state) => state.refreshProjectTask)
	const task = useProjectTaskStore((state) => state.projectTask)
	const user = useAuthStore((state) => state.user)

	const [editingCommentId, setEditingCommentId] = useState(null)

	const handleCreate = async (data) => {
		const body = data

		try {
			const response = await Service.post(`/project-tasks/${task._id}/comments`, body)
			refreshTask()
			reset({ content: '' })
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleCommentEditClick = (commentId) => {
		setEditingCommentId(commentId)
	}

	const handleCommentSuccess = () => {
		setEditingCommentId(null)
	}

	const handleKeyPress = async (event, commentId) => {
		if (event.key === 'Enter') {
			handleSubmit(handleCreate)()
		}
	}

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm({ mode: 'onChange' })

	return (
		<div>
			<Form.Group className="d-flex align-items-center justify-content-between gap-2 mb-3">
				<div
					className="avatar"
					style={{
						width: '32px',
						height: '32px'
					}}
				>
					<Image
						src={user.profilePhotoUrl || anonimProfilePhoto}
						roundedCircle
						style={{
							width: '32px',
							height: '32px'
						}}
					/>
				</div>
				<div className="d-flex align-items-center gap-2 w-100">
					<Form.Control
						type="text"
						name="content"
						placeholder="Yorum yaz..."
						onKeyDown={handleKeyPress}
						{...register('content', { required: 'Gerekli alan.' })}
					/>
					<SendButton onClick={handleSubmit(handleCreate)} size="md" icon={null} />
				</div>
			</Form.Group>

			<div>
				{task?.comments
					?.sort((a, b) => new Date(b.createDate) - new Date(a.createDate))
					.map((comment) => (
						<TaskComment
							data={comment}
							isEditing={editingCommentId === comment._id}
							onEditClick={handleCommentEditClick}
							onSuccess={handleCommentSuccess}
						/>
					))}
			</div>
		</div>
	)
}

export default TaskActivity
