const Inputs = [
	{
		name: 'firstName',
		label: 'Ad',
		placeholder: 'Ad',
		options: {
			required: 'Bu alan gerekli.'
		}
	},
	{
		name: 'lastName',
		label: 'Soyad',
		placeholder: 'Soyad',
		options: {
			required: 'Bu alan gerekli.'
		}
	},
	{
		name: 'workMail',
		label: 'Mail (İş)',
		placeholder: 'Mail (İş)',
		options: {
			pattern: {
				value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/i,
				message: 'geçersiz mail'
			}
		}
	},
	{
		name: 'mail',
		label: 'Mail (Kişisel)',
		placeholder: 'Mail (Kişisel)',
		options: {
			pattern: {
				value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/i,
				message: 'geçersiz mail'
			}
		}
	}
]
export default Inputs
