import { CustomPopperTooltip, FaIcon, Notification } from 'components/ui'
import Service from 'global/Service'
import { formatDate } from 'global/functions/formatDate'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import NotificationItem from './components/NotificationItem'

const Notifications = () => {
	const [data, setData] = useState([])

	const fetchData = async () => {
		try {
			const response = await Service.get('/notifications2/me/all')
			console.log(response.data, 'me/all')
			setData(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	const markAllAsRead = async () => {
		try {
			await Service.post('/notifications2/read-all')
			fetchData()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-2">
			<a
				className="nav-link dropdown-toggle hide-arrow"
				href="#"
				data-bs-toggle="dropdown"
				data-bs-auto-close="outside"
				aria-expanded="false"
			>
				<i className="bx bx-bell bx-sm" />
				{/* {data.unreadCount !== undefined && data.unreadCount !== 0 && (
					<span className="badge bg-danger rounded-pill badge-notifications">{data.unreadCount}</span>
				)} */}
			</a>
			<ul className="dropdown-menu dropdown-menu-end py-0">
				<li className="dropdown-menu-header border-bottom">
					<div className="dropdown-header d-flex align-items-center py-3">
						<h5 className="text-body mb-0 me-auto">Bildirim</h5>
						<a href="#" onClick={() => markAllAsRead()} className="dropdown-notifications-all text-body">
							<CustomPopperTooltip text="tümünü okundu olarak işaretle">
								<FaIcon icon="check-circle" size="lg" />
							</CustomPopperTooltip>
						</a>
					</div>
				</li>
				<li className="dropdown-notifications-list scrollable-container ps">
					<ul className="list-group list-group-flush">
						<ul className="list-group list-group-flush">
							{data.notifications &&
								data.notifications.map((notification) => <Notification key={notification._id} data={notification} />)}
						</ul>
					</ul>
					<div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
						<div className="ps__thumb-x" tabIndex={0} style={{ left: 0, width: 0 }} />
					</div>
					<div className="ps__rail-y" style={{ top: 0, right: 0 }}>
						<div className="ps__thumb-y" tabIndex={0} style={{ top: 0, height: 0 }} />
					</div>
				</li>
				<li className="dropdown-menu-footer border-top">
					<Link to="/my-notifications" className="dropdown-item d-flex justify-content-center p-3">
						tüm bildirimler
					</Link>
				</li>
			</ul>
		</li>
	)
}

export default Notifications
