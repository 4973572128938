import { CloseButton, SaveButton } from 'components/fast-ui'
import { ButtonContainer, FaIcon } from 'components/ui'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeDocumentName, setContextRenameValue } from 'stores/documentSlice'

const RenameModal = () => {
	const dispatch = useDispatch()
	const { rename } = useSelector((state) => state.document.context)

	const [renamedFileName, setRenamedFileName] = useState(rename.value)

	const handleSave = () => {
		dispatch(changeDocumentName(rename.id, rename.value))
		setRenamedFileName(rename.value)
	}

	return (
		<div
			className="modal fade"
			id="renameModal"
			aria-labelledby="renameModalLabel"
			tabIndex={-1}
			style={{ display: 'none' }}
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="renameModalLabel">
							<FaIcon icon="pen-to-square" className="me-2" />
							Yeniden Adlandır
						</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col mb-3">
								<input
									value={renamedFileName}
									onChange={(e) => {
										dispatch(setContextRenameValue(e.target.value))
										setRenamedFileName(e.target.value)
									}}
									type="text"
									id="nameWithTitle"
									className="form-control"
									placeholder="Ad"
								/>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<ButtonContainer>
							<CloseButton data-bs-dismiss="modal" />
							<SaveButton data-bs-dismiss="modal" onClick={handleSave} />
						</ButtonContainer>
					</div>
				</div>
			</div>
		</div>
	)
}

export default RenameModal
