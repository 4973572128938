import { DeleteButton } from 'components/fast-ui'
import UploadItem from 'pages/document/modals/upload-modal/UploadItem'
import React from 'react'
import { useDropzone } from 'react-dropzone'

import FileIcon from '../FileIcon'

const CustomDropzone = ({ files, setFiles, multiple, props }) => {
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: (acceptedFiles) => {
			setFiles([
				...files,
				...acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
						uploaded: false,
						deleted: false,
						error: false
					})
				)
			])
		},
		multiple: multiple
	})

	const handleDelete = (file) => {
		setFiles(files.filter((f) => f.name !== file.name))
	}

	return (
		<>
			<div className="dropbox p-5 mb-3 text-center" {...getRootProps()}>
				<input {...getInputProps()} />
				{isDragActive ? (
					<span>Buraya bırakın ...</span>
				) : multiple ? (
					<span> Yüklenecek dosyaları sürükleyin veya buraya tıklayın.</span>
				) : (
					<span>Dosyayı sürükleyin veya buraya tıklayın.</span>
				)}
			</div>
			{files.length > 0 && (
				<div className="selected-files border border-1 rounded px-3 py-2">
					{files.map((file) => (
						<div key={file.name} className="selected-file d-flex align-items-center justify-content-between mt-2 mb-2">
							<div>
								<FileIcon
									className="file-icon"
									style={{ width: '40px', height: '40px' }}
									extension={file.name.split('.').pop()}
								/>
								<span className="file-name ms-2">{file.name}</span>
							</div>
							<DeleteButton onClick={() => handleDelete(file)} size="md" />
						</div>
					))}
				</div>
			)}
		</>
	)
}

export default CustomDropzone
