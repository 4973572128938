import cloudIcon from 'assets/img/icons/documents/general/cloud.png'
import { ApproveButton, CloseButton } from 'components/fast-ui'
import { ButtonContainer, FaIcon } from 'components/ui'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { moveFocusedFolder, moveSelectedDocuments, setContextMoveTargetFolderID } from 'stores/documentSlice'

import Folder from '../document-left/components/Folder'
import FolderTree from '../document-left/components/FolderTree'

const MoveModal = () => {
	const { selectedItems } = useSelector((state) => state.document.main)
	const { items } = useSelector((state) => state.document.tree)
	const focusedFolder = useSelector((state) => state.document.tree.focusedFolder.content)
	const dispatch = useDispatch()

	const folderMoveTargetHandleClick = (folderID) => {
		dispatch(setContextMoveTargetFolderID(folderID))
	}

	const handleClick = () => {
		if (focusedFolder._id !== undefined) {
			dispatch(moveFocusedFolder())
		}
		if (selectedItems.length > 0) {
			dispatch(moveSelectedDocuments())
		}
	}

	return (
		<div
			className="modal fade"
			id="moveModal"
			aria-labelledby="moveModalLabel"
			tabIndex={-1}
			style={{ display: 'none' }}
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="moveModalLabel">
							<FaIcon icon="arrows-up-down-left-righ" className="me-2" />
							Taşınacak klasörü seçin
						</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
					</div>
					<div className="modal-body">
						<ul className="list-unstyled">
							<Folder
								_id={'cloud'}
								name="Cloud"
								openIcon={cloudIcon}
								closeIcon={cloudIcon}
								onClick={folderMoveTargetHandleClick}
								forMove
							>
								<FolderTree
									folderList={items}
									parent_id={'cloud'}
									itemOnClick={folderMoveTargetHandleClick}
									disableSelectionsAndParents
									forMove
								/>
							</Folder>
						</ul>
					</div>
					<div className="modal-footer">
						<ButtonContainer>
							<CloseButton data-bs-dismiss="modal" />
							<ApproveButton onClick={handleClick} data-bs-dismiss="modal" />
						</ButtonContainer>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MoveModal
