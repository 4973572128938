import axios from 'axios'
import Service from 'global/Service'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { create } from 'zustand'

const cookies = new Cookies()

const useAuthStore = create((set) => ({
	isLoading: true,
	isAuthenticated: false,
	user: null,
	accessToken: null,
	refreshToken: null,
	setIsLoading: (isLoading) => set({ isLoading }),
	setIsAuthenticated: (isAuthenticated) => set({ isAuthenticated }),
	setUser: (user) => set({ user }),
	setAccessToken: (accessToken) => {
		set({ accessToken })
		const decoded = jwt_decode(accessToken)
		cookies.set('accessToken', accessToken, { path: '/', expires: new Date(decoded.exp * 1000) })
		const { fetchUser } = useAuthStore.getState()
		fetchUser()
	},
	setRefreshToken: (refreshToken) => {
		set({ refreshToken })
		const decoded = jwt_decode(refreshToken)
		cookies.set('refreshToken', refreshToken, { path: '/', expires: new Date(decoded.exp * 1000) })
	},
	init: async () => {
		console.log('init')
		const { setAccessToken, setRefreshToken, setIsAuthenticated, setIsLoading, logout } = useAuthStore.getState()

		const accessToken = cookies.get('accessToken')
		const refreshToken = cookies.get('refreshToken')

		if (accessToken && refreshToken) {
			console.log('accessToken ve refreshToken var')
			setAccessToken(accessToken)
			setRefreshToken(refreshToken)
		} else if (refreshToken) {
			console.log('refreshToken var', refreshToken)
			setRefreshToken(refreshToken)

			const response = await Service.post('/token/refresh', {
				refreshToken: refreshToken
			})

			setAccessToken(response.data.accessToken)
		} else {
			console.log('refreshToken yok')
			setIsAuthenticated(false)
			setIsLoading(false)
		}
	},
	fetchUser: async () => {
		const { accessToken, setIsLoading, setIsAuthenticated, setUser, logout } = useAuthStore.getState()

		if (accessToken) {
			console.log('accessToken var', accessToken)
			try {
				const response = await Service.get('/init')

				setUser(response.data)
				setIsAuthenticated(true)
			} catch (error) {
				logout()
			} finally {
				setIsLoading(false)
			}
		}
	},
	logout: async () => {
		const { setIsAuthenticated } = useAuthStore.getState()
		setIsAuthenticated(false)
		cookies.remove('accessToken', { path: '/' })
		cookies.remove('refreshToken', { path: '/' })
		console.log('refreshToken silindi')
	}
}))

export default useAuthStore
