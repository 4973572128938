import { ArcElement, Chart as ChartJS, Legend, RadialLinearScale, Tooltip } from 'chart.js'
import Service from 'global/Service'
import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { PolarArea } from 'react-chartjs-2'

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend)

const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: 'bottom',
			labels: {
				generateLabels: function (chart) {
					const data = chart.data
					if (data.labels.length && data.datasets.length) {
						const value = data.datasets[0].data.reduce((a, b) => a + b)
						return [
							{
								text: `${value} Çalışan`,
								fillStyle: '#686B71'
							}
						]
					}
					return []
				}
			}
		}
	},
	scales: {
		r: {
			ticks: {
				display: false // Remove vertical numbers
			},
			grid: {
				display: false // Removes the circulair lines
			}
		}
	}
}

const EmployeeDepartmentDistribution = () => {
	const [data, setData] = useState({})

	const fetchData = async () => {
		const response = await Service.get(`/charts/ik/employee-distribution`)
		setData(response.data)
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<Card>
			<Card.Header>
				<Card.Title as="h5" className="mb-0">
					Çalışan Departman Dağılımı
				</Card.Title>
			</Card.Header>
			<Card.Body>{data.labels && <PolarArea data={data} options={options} height={250} />}</Card.Body>
		</Card>
	)
}

export default EmployeeDepartmentDistribution
