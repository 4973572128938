import { CloseButton, CreateButton, DeleteButton, SaveButton } from 'components/fast-ui'
import { CustomFormCheck, CustomReactSelect } from 'components/ui'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import Flatpickr from 'react-flatpickr'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { ErrorMessage } from '@hookform/error-message'

const dayTypes = [
	{ value: 'half_day', label: 'Yarım Gün' },
	{ value: 'full_day', label: 'Tam Gün' },
	{ value: 'multiple_days', label: 'Tarih Aralığı' }
]

const HolidayModal = () => {
	const params = useParams()
	const { item_id, sub_item_id } = params
	const navigate = useNavigate()

	const [edit, setEdit] = useState(false)
	const [show, setShow] = useState(true)

	const fetchData = async () => {
		try {
			const response = await Service.get(`/holiday-calendars/${item_id}/${sub_item_id}`)
			reset(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (sub_item_id) {
			if (sub_item_id === 'add') {
				setEdit(false)
				reset({})
			} else {
				setEdit(true)
				fetchData()
			}
		}
	}, [params])

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		formState,
		watch,
		setValue,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	const handleClose = () => {
		navigate(`/settings/holiday-calendars/${item_id}`)
	}

	const handleCreate = async (data) => {
		try {
			const response = await Service.post(`/holiday-calendars/${item_id}`, data)

			sendToast('Tatil günü başarıyla eklendi')
			navigate(`/settings/holiday-calendars/${item_id}`)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleSave = async (data) => {
		try {
			const response = await Service.patch(`/holiday-calendars/${item_id}/${sub_item_id}`, data)
			sendToast('Tatil günü başarıyla güncellendi')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDelete = async () => {
		try {
			const response = await Service.delete(`/holiday-calendars/${item_id}/${sub_item_id}`)
			sendToast('Tatil günü başarıyla silindi')
			navigate(`/settings/holiday-calendars/${item_id}`)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const dayType = watch('type')
	const startDate = watch('startDate')

	useEffect(() => {
		if (dayType === 'multiple_days') {
			trigger(['startDate', 'endDate'])
		} else {
			setValue('endDate', getValues('startDate'))
		}
	}, [dayType, startDate])

	return (
		<Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title as="h5">{edit ? 'Tatil Günü Düzenle' : 'Tatil Günü Ekle'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Row>
						<Col md={6} className="mb-3">
							<Controller
								name="name"
								control={control}
								defaultValue=""
								rules={{ required: 'Gerekli alan.' }}
								render={({ field }) => (
									<Form.Group>
										<Form.Label>Ad</Form.Label>
										<Form.Control type="text" {...field} isInvalid={errors.name} />
										<Form.Control.Feedback type="invalid">
											<ErrorMessage errors={errors} name="name" />
										</Form.Control.Feedback>
									</Form.Group>
								)}
							/>
						</Col>
						<Col md={6} className="mb-3">
							<Controller
								name="type"
								control={control}
								defaultValue={dayTypes[1].value}
								rules={{ required: 'Gerekli alan.' }}
								render={({ field }) => (
									<Form.Group>
										<Form.Label>Tür</Form.Label>
										<CustomReactSelect
											value={dayTypes.filter((obj) => obj.value === field.value)}
											onChange={(d) => field.onChange(d?.value)}
											ref={field.ref}
											options={dayTypes}
										/>
										<Form.Control.Feedback type="invalid">
											<ErrorMessage errors={errors} name="type" />
										</Form.Control.Feedback>
									</Form.Group>
								)}
							/>
						</Col>

						<Col md={6} className="mb-3">
							<Controller
								name="startDate"
								control={control}
								defaultValue=""
								rules={{ required: 'Gerekli alan.' }}
								render={({ field }) => (
									<Form.Group>
										<Form.Label>Başlangıç Tarihi</Form.Label>
										<Flatpickr
											className="form-control"
											value={field.value}
											ref={field.ref}
											onChange={field.onChange}
											placeholder=""
											options={{
												allowInput: true,
												dateFormat: 'd-m-Y',
												locale: { ...Turkish }
											}}
										/>
										<Form.Control.Feedback type="invalid">
											<ErrorMessage errors={errors} name="startDate" />
										</Form.Control.Feedback>
									</Form.Group>
								)}
							/>
						</Col>
						<Col md={6} className="mb-3">
							<Controller
								name="endDate"
								control={control}
								defaultValue=""
								rules={{
									required: {
										value: watch('type') === 'multiple_days',
										message: 'Gerekli alan.'
									},
									min: {
										value: watch('startDate'),
										message: 'Bitiş tarihi başlangıç tarihinden önce olamaz.'
									}
								}}
								render={({ field }) => (
									<Form.Group>
										<Form.Label>Bitiş Tarihi</Form.Label>
										<Flatpickr
											className="form-control"
											onChange={field.onChange}
											value={field.value}
											ref={field.ref}
											placeholder=""
											options={{
												allowInput: true,
												dateFormat: 'd-m-Y',
												locale: { ...Turkish }
											}}
											disabled={watch('type') !== 'multiple_days'}
										/>
										<Form.Control.Feedback type="invalid">
											<ErrorMessage errors={errors} name="endDate" />
										</Form.Control.Feedback>
									</Form.Group>
								)}
							/>
						</Col>
						<Col md={12} className="mb-3">
							<Controller
								name="description"
								control={control}
								defaultValue=""
								render={({ field }) => (
									<Form.Group>
										<Form.Label>Açıklama</Form.Label>
										<Form.Control type="text" {...field} isInvalid={errors.description} as="textarea" rows={2} />
										<Form.Control.Feedback type="invalid">
											<ErrorMessage errors={errors} name="description" />
										</Form.Control.Feedback>
									</Form.Group>
								)}
							/>
						</Col>

						<Col md={12} className="mb-3">
							<Controller
								name="isRecurring"
								control={control}
								defaultValue={false}
								render={({ field }) => (
									<Form.Group>
										<CustomFormCheck id="isRecurring" type="checkbox" label="Tekrarlanan Tatil" field={field}>
											<Form.Text className="text-muted">Bu tatil her yıl aynı tarihlerde tekrarlanır.</Form.Text>
										</CustomFormCheck>
									</Form.Group>
								)}
							/>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{edit ? (
					<>
						<SaveButton onClick={handleSubmit((d) => handleSave(d))} />
						<DeleteButton onClick={handleDelete} />
					</>
				) : (
					<>
						<CreateButton onClick={handleSubmit((d) => handleCreate(d))} />
					</>
				)}
			</Modal.Footer>
		</Modal>
	)
}

export default HolidayModal
