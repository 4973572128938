import Service from 'global/Service'

import { createSlice } from '@reduxjs/toolkit'

export const ikCompetenceAssessmentSlice = createSlice({
	name: 'ikCompetenceAssessment',
	initialState: {
		employeeList: [],
		selectedAssessors: [],
		selectedEmployees: []
	},
	reducers: {
		setEmployeeList: (state, action) => {
			state.employeeList = action.payload
		},
		setSelectedAssessors: (state, action) => {
			state.selectedAssessors = action.payload
		},
		setSelectedEmployees: (state, action) => {
			state.selectedEmployees = action.payload
		}
	}
})

export const { setEmployeeList, setSelectedAssessors, setSelectedEmployees } = ikCompetenceAssessmentSlice.actions

export const refreshEmployeeList = () => (dispatch) => {
	let promise = Service.get('/employees')
	promise.then((response) => {
		dispatch(setEmployeeList(response.data))
	})
}

export default ikCompetenceAssessmentSlice.reducer
