import { AddButton } from 'components/fast-ui'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import React from 'react'
import { useState } from 'react'
import Flatpickr from 'react-flatpickr'
import { useNavigate, useParams } from 'react-router-dom'

import AddEventModal from '../EventAddModal'

const CalendarSidebar = () => {
	const params = useParams()
	const { event_id } = params

	const navigate = useNavigate()

	const [showModal, setShowModal] = useState(false)

	const handleAddEvent = () => {
		navigate('/calendar/add')
		setShowModal(true)
	}

	const handleClose = () => {
		setShowModal(false)
	}

	return (
		<>
			<div className="col app-calendar-sidebar" id="app-calendar-sidebar">
				<div className="border-bottom p-4 my-sm-0 mb-3">
					<div className="d-grid">
						<AddButton text="Etkinlik Ekle" className="btn-primary" onClick={handleAddEvent} />
					</div>
				</div>
				<div className="p-4">
					<div className="ms-n2">
						<Flatpickr
							style={{ display: 'none' }}
							options={{
								inline: true,
								dateFormat: 'd-m-Y',
								locale: { ...Turkish }
							}}
						/>
					</div>
					<hr className="container-m-nx my-4" />

					<div className="mb-4">
						<small className="text-small text-muted text-uppercase align-middle">Filtre</small>
					</div>
					<div className="form-check mb-2">
						<input
							className="form-check-input select-all"
							type="checkbox"
							id="selectAll"
							data-value="all"
							defaultChecked
						/>
						<label className="form-check-label" htmlFor="selectAll">
							Tümü
						</label>
					</div>
					<div className="app-calendar-events-filter">
						<div className="form-check mb-2">
							<input
								className="form-check-input input-filter"
								type="checkbox"
								id="select-personal"
								data-value="personal"
								defaultChecked
							/>
							<label className="form-check-label" htmlFor="select-personal">
								İzinlerim
							</label>
						</div>
						<div className="form-check mb-2">
							<input
								className="form-check-input input-filter"
								type="checkbox"
								id="select-business"
								data-value="business"
								defaultChecked
							/>
							<label className="form-check-label" htmlFor="select-business">
								Etkinlikler
							</label>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default CalendarSidebar
