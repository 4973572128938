import { default as lightLogo } from 'assets/svg/logos/meno-light.svg'
import { CloseButton } from 'components/fast-ui'
import { Button } from 'components/ui'
import AuthService from 'global/AuthService'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Card, Container, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import useAuthStore from 'stores/authStore'

import { ErrorMessage } from '@hookform/error-message'

const AuthTwoFactor = () => {
	const setAccessToken = useAuthStore((state) => state.setAccessToken)
	const setRefreshToken = useAuthStore((state) => state.setRefreshToken)

	const location = useLocation()
	const navigate = useNavigate()
	const { mail, password, org_id } = location.state

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		formState: { errors }
	} = useForm({ mode: 'onChange' })

	const handleSend = async (data) => {
		const body = {
			org_id: org_id,
			mail,
			password,
			otp_attempt: data.otp_attempt.replace(/\s/g, '')
		}
		try {
			const response = await AuthService.post('/auth/verify-2fa', body)

			if (response.data.accessToken && response.data.refreshToken) {
				setRefreshToken(response.data.refreshToken)
				setAccessToken(response.data.accessToken)
				sendToast('Giriş başarılı.')
				navigate('/home')
			}
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<div className="authentication-wrapper authentication-basic container-p-y">
			<div className="authentication-inner py-4">
				<Card style={{ padding: '40px 30px' }}>
					<Card.Body>
						<Card.Img className="app-brand" variant="top" src={lightLogo} height={30} />

						<Form onSubmit={handleSubmit((data) => handleSend(data))}>
							<Form.Group className="mb-3">
								<Form.Label>2FA</Form.Label>
								<Form.Control
									type="text"
									placeholder="000 000"
									{...register('otp_attempt', {
										required: 'Bu alan gerekli.'
									})}
									onChange={(e) => {
										const input = e.target.value.replace(/\D/g, '').substring(0, 6) // sadece rakamları al ve ilk 6 karakteri al
										const regex = /^([0-9]{0,3})([0-9]{0,3})$/
										const matches = input.match(regex) // iki parçaya bölmek için regex kullan
										const formatted = matches ? `${matches[1]} ${matches[2]}`.trim() : ''
										e.target.value = formatted
									}}
								/>
								<Form.Control.Feedback type="invalid">
									<ErrorMessage errors={errors} name="otp_attempt" />
								</Form.Control.Feedback>
							</Form.Group>
							<Button text="Onayla" className="w-100" onClick={handleSubmit((data) => handleSend(data))} />
						</Form>
					</Card.Body>
				</Card>
			</div>
		</div>
	)
}

export default AuthTwoFactor
