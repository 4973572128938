import { SaveButton } from 'components/fast-ui'
import CustomReactSelect from 'components/ui/CustomReactSelect'
import { allCountries } from 'constants/Countries'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchEmployee } from 'stores/ikEmployeeEditSlice'

const options = [
	{ value: 'chocolate', label: 'Chocolate' },
	{ value: 'strawberry', label: 'Strawberry' },
	{ value: 'vanilla', label: 'Vanilla' }
]

const bankAccountTypes = [
	{ value: 'check', label: 'Çek' },
	{ value: 'other', label: 'Diğer' },
	{ value: 'futures', label: 'Vadeli' },
	{ value: 'demand', label: 'Vadesiz' }
]

const OtherTab = () => {
	const params = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { employee_id, module_name, module_id } = params
	const { employee } = useSelector((state) => state.ikEmployeeEdit)
	const { workingStatus } = employee

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		formState,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	useEffect(() => {
		if (employee._id !== undefined) {
			reset(employee)
		}
	}, [employee])

	const handleSave = async (data) => {
		const body = data
		try {
			const patchedEmployee = await Service.patch(`/employees/${employee_id}`, body)
			dispatch(fetchEmployee(employee_id))
			sendToast('Personel kaydedildi.')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<Form>
			<Card className="mb-5">
				<Card.Header>
					<Card.Title>Adres Bilgileri</Card.Title>
				</Card.Header>
				<Card.Body>
					<Row>
						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Adres</Form.Label>
							<Form.Control disabled={!workingStatus} {...register('addressInfo.address')} />
						</Col>

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Adres (devamı)</Form.Label>
							<Form.Control disabled={!workingStatus} {...register('addressInfo.address2')} />
						</Col>

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Ev Telefonu</Form.Label>
							<Form.Control disabled={!workingStatus} {...register('addressInfo.homePhone')} />
						</Col>

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Ülke</Form.Label>
							<Controller
								name="addressInfo.country"
								control={control}
								render={({ field }) => (
									<CustomReactSelect
										value={allCountries.filter((obj) => obj.code === field.value)}
										onChange={(d) => field.onChange(d?.code)}
										ref={field.ref}
										placeholder=""
										isDisabled={!workingStatus}
										options={allCountries}
										getOptionValue={(option) => option.code}
										getOptionLabel={(option) => option.name}
									/>
								)}
							/>
						</Col>

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Şehir</Form.Label>
							<Form.Control disabled={!workingStatus} {...register('addressInfo.city')} />
						</Col>

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Posta Kodu</Form.Label>
							<Form.Control disabled={!workingStatus} {...register('addressInfo.postCode')} />
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card className="mb-5">
				<Card.Header>
					<Card.Title>Banka Bilgileri</Card.Title>
				</Card.Header>
				<Card.Body>
					<Row>
						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Banka Adı</Form.Label>
							<Form.Control disabled={!workingStatus} {...register('bankAccount.name')} />
						</Col>

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Hesap Tipi</Form.Label>
							<Controller
								name="bankAccount.accountType"
								control={control}
								render={({ field }) => (
									<CustomReactSelect
										value={bankAccountTypes.filter((obj) => obj.value === field.value)}
										onChange={(d) => field.onChange(d?.value)}
										ref={field.ref}
										isDisabled={!workingStatus}
										options={bankAccountTypes}
										placeholder=""
									/>
								)}
							/>
						</Col>

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Hesap No</Form.Label>
							<Form.Control disabled={!workingStatus} {...register('bankAccount.accountNo')} />
						</Col>

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>IBAN</Form.Label>
							<Form.Control disabled={!workingStatus} {...register('bankAccount.iban')} />
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card className="mb-5">
				<Card.Header>
					<Card.Title>Acil Durum Bilgileri</Card.Title>
				</Card.Header>
				<Card.Body>
					<Row>
						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Acil Durumda Aranacak Kişi</Form.Label>
							<Form.Control disabled={!workingStatus} {...register('emergencyContactPerson.name')} />
						</Col>

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Acil Durumda Aranacak Kişi Yakınlık Derecesi</Form.Label>
							<Form.Control disabled={!workingStatus} {...register('emergencyContactPerson.proximityDegree')} />
						</Col>

						<Col xs={12} sm={6} className="mb-3">
							<Form.Label>Acil Durumda Aranacak Kişi Telefon</Form.Label>
							<Form.Control disabled={!workingStatus} {...register('emergencyContactPerson.phone')} />
						</Col>
					</Row>
				</Card.Body>
			</Card>
			{!workingStatus === false ? (
				<div className="mt-3">
					<SaveButton onClick={handleSubmit((d) => handleSave(d))} />
				</div>
			) : null}
		</Form>
	)
}

export default OtherTab
