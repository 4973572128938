import { CreateButton } from 'components/fast-ui'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

import HolidayCalendarModal from './components/HolidayCalendarModal'
import HolidayCalendarEdit from './components/holiday-calendar-edit'
import HolidayCalendarsTable from './components/holiday-calendars-table'

const HolidayCalendars = () => {
	const params = useParams()
	const { tab_name, item_id } = params
	const navigate = useNavigate()

	const [tab, setTab] = useState('list')

	useEffect(() => {
		if (tab_name === 'holiday-calendars') {
			if (item_id) {
				if (item_id === 'add') {
					setTab('list')
				} else {
					setTab('edit')
				}
			} else {
				setTab('list')
			}
		}
	}, [params])

	const handleNewCalendar = () => {
		navigate('/settings/holiday-calendars/add')
	}

	return (
		<>
			{tab === 'list' && (
				<>
					<Row className="mb-4">
						<HolidayCalendarsTable />
					</Row>
					<Row>
						<Col lg={12} md={12} sm={12} xs={12}>
							<CreateButton text="Yeni Tatil Takvimi" onClick={handleNewCalendar}></CreateButton>
						</Col>
					</Row>
				</>
			)}

			{tab === 'edit' && <HolidayCalendarEdit />}

			<HolidayCalendarModal />
		</>
	)
}

export default HolidayCalendars
