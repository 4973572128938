import { CloseButton, DeleteButton } from 'components/fast-ui'
import { ButtonContainer, FaIcon } from 'components/ui'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cleanTrash, sendTrashSelectedDocuments } from 'stores/documentSlice'

const CleanTrashModal = () => {
	const dispatch = useDispatch()
	const { selectedItems } = useSelector((state) => state.document.main)

	const handleCleanTrash = () => {
		dispatch(cleanTrash())
	}

	return (
		<div
			className="modal fade"
			id="cleanTrashModal"
			aria-labelledby="cleanTrashModalLabel"
			tabIndex={-1}
			style={{ display: 'none' }}
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="cleanTrashModalLabel">
							<FaIcon icon="triangle-exclamation" className="me-2" />
							Emin misiniz?
						</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
					</div>
					<div className="modal-body">
						<div className="row">
							<p className="fw-semibold">
								Çöp kutusundaki tüm nesneleri ve alt klasörlerdeki nesneleri kalıcı olarak silmek istediğinize emin
								misiniz?
							</p>
							<p>Not: Bu işlem geri döndürülemez.</p>
						</div>
					</div>
					<div className="modal-footer">
						<ButtonContainer>
							<CloseButton data-bs-dismiss="modal" />
							<DeleteButton data-bs-dismiss="modal" onClick={handleCleanTrash} text="Çöp Kutusunu Boşalt" />
						</ButtonContainer>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CleanTrashModal
