import { FaIcon, TabButton } from 'components/ui'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ControlledMenu, MenuItem, useMenuState } from '@szhsin/react-menu'

const RoleItem = ({ data }) => {
	const navigate = useNavigate()
	const params = useParams()

	const [isActive, setIsActive] = useState(false)
	const { tab_name, item_id } = params

	useEffect(() => {
		if (data._id === item_id) {
			setIsActive(true)
		} else {
			setIsActive(false)
		}
	}, [params])

	const [menuProps, toggleMenu] = useMenuState()
	const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })

	const handleContextMenu = (e) => {
		e.preventDefault()
		setAnchorPoint({ x: e.clientX, y: e.clientY })
		toggleMenu()
	}

	const handleDelete = () => {
		navigate(`/settings/roles/${data._id}/delete`)
	}

	return (
		<>
			<div className="d-flex flex-column" onContextMenu={handleContextMenu}>
				<TabButton
					className="mb-1"
					text={
						<span className="d-flex justify-content-start align-items-center">
							<FaIcon type="solid" icon="circle-small" size="xs" className="me-2" style={{ color: data.hexColor }} />
							{data.name}
						</span>
					}
					active={isActive}
					onClick={() => navigate(`/settings/roles/${data._id}/view`)}
				/>
			</div>
			<ControlledMenu className="position-absolute" {...menuProps} anchorPoint={anchorPoint} onClose={toggleMenu}>
				<MenuItem onClick={handleDelete}>
					<span className="text-danger">
						<FaIcon icon="trash" size="sm" className="me-2" />
						Sil
					</span>
				</MenuItem>
			</ControlledMenu>
		</>
	)
}

export default RoleItem
