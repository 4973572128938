import { CloseButton, DangerApproveButton } from 'components/fast-ui'
import { ButtonContainer } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { Modal } from 'react-bootstrap'

const TwoFactorDisableModal = ({ show, onHide, onDisabled }) => {
	const handleClose = () => {
		onHide()
	}

	const handleDisable = async () => {
		try {
			const response = await Service.post('/two-factor/disable')
			sendToast(response.data.message, true)
			onDisabled && onDisabled()
			handleClose()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<Modal show={show} onHide={handleClose} centered>
			<Modal.Header closeButton>
				<Modal.Title as="h5">2FA'yı Devre Dışı Bırak</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					2FA, hesabınızı ek bir koruma katmanıyla donatarak yetkisiz erişimlere karşı güvenliğinizi artırır. Bu
					özelliği devre dışı bırakmanız durumunda hesap güvenliğiniz düşecektir. Emin misiniz?
				</p>
			</Modal.Body>
			<Modal.Footer>
				<ButtonContainer>
					<CloseButton onClick={handleClose} />
					<DangerApproveButton onClick={handleDisable} />
				</ButtonContainer>
			</Modal.Footer>
		</Modal>
	)
}

export default TwoFactorDisableModal
