import { CreateButton } from 'components/fast-ui'
import { FaIcon } from 'components/ui'
import CustomReactTable from 'components/ui/CustomReactTable'
import DeleteModal from 'components/ui/DeleteModal'
import amountTypes from 'constants/amountTypes'
import currencies from 'constants/currencies'
import extraSalaryPayments from 'constants/extraSalaryPayments'
import periods from 'constants/periods'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'

import AddSalaryModal from './components/AddSalaryModal'

const columns = [
	{
		Header: 'Başlangıç Tarihi',
		accessor: 'effectiveOnDate',
		Cell: ({ row, value }) => (
			<span>
				{new Date(value).toLocaleDateString('tr-TR', {
					day: 'numeric',
					month: 'long',
					year: 'numeric'
				})}

				{row.original.isActive && <span className="badge bg-primary ms-2">Varsayılan</span>}
			</span>
		)
	},
	{
		Header: 'Maaş',
		accessor: 'amount',
		Cell: ({ row, value }) => (
			<span>
				{`${value.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${
					currencies.find((currency) => currency.value === row.original.currency).label
				} / ${periods.find((period) => period.value === row.original.payPeriod).label}`}
			</span>
		)
	},
	{
		Header: 'Maaş Türü',
		accessor: 'amountType',
		Cell: ({ value }) => <span>{amountTypes.find((amountType) => amountType.value === value).label}</span>
	},
	{
		Header: 'Ek Ödemeler',
		accessor: 'extraPayments',
		Cell: ({ row }) => (
			<span>
				{row.original.items.map((item, index) => (
					<span key={index} className="badge bg-primary me-2">
						{extraSalaryPayments.find((extraSalaryPayment) => extraSalaryPayment.value === item.type).label}
					</span>
				))}
			</span>
		)
	},
	{
		Header: 'İşlemler',
		accessor: 'actions',
		Cell: ({ row }) => (
			<Link to={`/employees/${row.original.employee}/career/salaries/${row.original._id}/delete`}>
				<span className="text-danger cursor-pointer">
					<FaIcon icon="times" />
				</span>
			</Link>
		)
	}
]

const CareerSalaryTab = () => {
	const params = useParams()
	const navigate = useNavigate()

	const { employee_id, tab_name, module_name, module_id: salary_id, process } = params

	const [data, setData] = useState([])

	const [showAddSalaryModal, setShowAddSalaryModal] = useState(false)
	const [showDeleteSalaryModal, setShowDeleteSalaryModal] = useState(false)

	const fetchData = async () => {
		try {
			const response = await Service.get(`/salaries/employee/${employee_id}`)
			setData(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [params])

	useEffect(() => {
		if (employee_id && tab_name === 'career' && module_name === 'salaries' && salary_id) {
			if (salary_id === 'add') {
				setShowAddSalaryModal(true)
			} else {
				setShowAddSalaryModal(false)
				if (process === 'delete') {
					setShowDeleteSalaryModal(true)
				} else {
					setShowDeleteSalaryModal(false)
				}
			}
		} else {
			setShowAddSalaryModal(false)
			setShowDeleteSalaryModal(false)
		}
	}, [params])

	const handleAddSalary = () => {
		navigate(`/employees/${employee_id}/career/salaries/add`)
	}

	const handleDeleteOk = async () => {
		try {
			const response = await Service.delete(`/salaries/${salary_id}`)
			sendToast('Maaş nesnesi silindi.')
			navigate(`/employees/${employee_id}/career/salaries`)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDeleteHide = () => {
		navigate(`/employees/${employee_id}/career/salaries`)
	}

	return (
		<Card>
			<Card.Header>
				<Card.Title as="h5">
					<span className="me-3">Maaşlar</span>{' '}
					<CreateButton className="btn btn-primary btn-sm" text="Ekle" onClick={handleAddSalary} />
				</Card.Title>
			</Card.Header>
			<Card.Body>
				<CustomReactTable columns={columns} data={data} defaultSortDesc={true} defaultSortBy={'effectiveOnDate'} />
			</Card.Body>
			<AddSalaryModal />
			<DeleteModal show={showDeleteSalaryModal} name="Maaş" onOk={handleDeleteOk} onHide={handleDeleteHide} />
		</Card>
	)
}

export default CareerSalaryTab
