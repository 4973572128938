const taskStatuses = [
	{
		value: 'waiting',
		label: 'Beklemede'
	},
	{
		value: 'inprogress',
		label: 'Devam Ediyor'
	},
	{
		value: 'completed',
		label: 'Tamamlandı'
	},
	{
		value: 'canceled',
		label: 'İptal Edildi'
	}
]

export default taskStatuses
