import { CloseButton, CreateButton, DeleteButton, ResetButton, SaveButton } from 'components/fast-ui'
import { FaIcon } from 'components/ui'
import CustomReactSelect from 'components/ui/CustomReactSelect'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import Flatpickr from 'react-flatpickr'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { fetchEmployee } from 'stores/ikEmployeeEditSlice'

import { ErrorMessage } from '@hookform/error-message'

const employmentTypes = [
	{ value: 'full_time', label: 'Tam Zamanlı' },
	{ value: 'part_time', label: 'Yarı Zamanlı' },
	{ value: 'temporary', label: 'Geçici' },
	{ value: 'contract', label: 'Kontratlı' },
	{ value: 'freelance', label: 'Serbest Çalışan' },
	{ value: 'intern', label: 'Stajyer' },
	{ value: 'mobile', label: 'Gezici' }
]

const PositionHistoryModal = () => {
	const params = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { employee_id, module_name, module_id } = params

	const [isNew, setIsNew] = useState(false)
	const [show, setShow] = useState(false)

	const [companyStructures, setCompanyStructures] = useState([])
	const [companyUnits, setCompanyUnits] = useState([])
	const [employees, setEmployees] = useState([])

	const fetchEmployees = async () => {
		try {
			const employees = await Service.get('/employees')
			setEmployees(employees.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchCompanyStructures = async () => {
		try {
			const companyStructures = await Service.get('/company-structures')
			setCompanyStructures(companyStructures.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchCompanyUnits = async () => {
		try {
			const companyUnits = await Service.get(`/company-units`)
			setCompanyUnits(companyUnits.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleClose = () => {
		setShow(false)
		navigate(`/employees/${employee_id}/career`)
	}

	const fetchPositionData = async () => {
		const positions = await Service.get(`/employee-positions/${employee_id}`)
		//! tekil çağırma gerçekleştirilecek
		const formData = positions.data.find((p) => {
			return p._id === module_id
		})

		const defaultCompanyStructures = companyStructures.map((cs) => {
			return {
				companyStructure: cs,
				unit: null
			}
		})

		formData.units = defaultCompanyStructures.map((cs) => {
			const unit = formData.units.find((u) => {
				return u.companyStructure._id === cs.companyStructure._id
			})
			if (unit) {
				return unit
			} else {
				return cs
			}
		})

		reset(formData)
	}

	useEffect(() => {
		fetchCompanyStructures()
		fetchCompanyUnits()
		fetchEmployees()
	}, [params])

	useEffect(() => {
		if (module_name && module_name === 'positions' && module_id) {
			setShow(true)
			if (module_id === 'add') {
				setIsNew(true)

				const defaultCompanyStructures = companyStructures.map((cs) => {
					return {
						companyStructure: cs,
						unit: null
					}
				})

				reset({
					units: defaultCompanyStructures
				})
			} else {
				setIsNew(false)
				fetchPositionData()
			}
		} else {
			setShow(false)
		}
	}, [params])

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		formState,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	const handleCreate = async (data) => {
		data.units = data.units.filter((u) => u.unit !== null)
		const body = data
		try {
			const newPosition = await Service.post(`/employee-positions/${employee_id}`, body)
			dispatch(fetchEmployee(employee_id))
			sendToast('Pozisyon oluşturuldu.')
			navigate(`/employees/${employee_id}/career`)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleSave = async (data) => {
		data.units = data.units.filter((u) => u.unit !== null)
		const body = data

		try {
			const patchedEmployee = await Service.patch(`/employee-positions/${employee_id}/${module_id}`, body)
			dispatch(fetchEmployee(employee_id))
			sendToast('Pozisyon kaydedildi.')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDelete = async (e) => {
		e.preventDefault()
		try {
			const deletedPosition = await Service.delete(`/employee-positions/${employee_id}/${module_id}`)
			dispatch(fetchEmployee(employee_id))
			navigate(`/employees/${employee_id}/career`)
			sendToast('Pozisyon silindi.')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	//react-hook-form array field
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'units'
	})

	// fields içine companyStructures'leri ekle
	const handleCompanyStructures = () => {
		if (companyStructures.length > 0) {
			companyStructures.forEach((cs) => {
				//fields companyStructure içermiyor ise
				if (!fields.find((f) => f.companyStructure._id === cs._id)) {
					append({ companyStructure: cs, unit: null })
				}
			})
		}
	}

	return (
		<>
			<Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<div className="content-left">
						<Modal.Title as={'h5'}>
							<FaIcon icon={'plus'} className="me-2" /> Pozisyon Ekle
						</Modal.Title>
						<small>
							{isNew
								? 'Yeni pozisyon tanımlama formu.'
								: /* selectedPosition._id + */ ' numaralı pozisyon için düzenleme formu.'}
						</small>
					</div>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Row>
							{fields.map(
								(item, index) =>
									item.companyStructure.isActive && (
										<Col md={6} className="mb-3" key={item.companyStructure._id}>
											<Form.Label>{item.companyStructure.name}</Form.Label>
											<Controller
												name={`units.${index}.unit`}
												control={control}
												render={({ field }) => (
													<CustomReactSelect
														isClearable
														value={field.value}
														onChange={field.onChange}
														ref={field.ref}
														options={companyUnits.filter((unit) => unit.companyStructure === item.companyStructure._id)}
														getOptionValue={(option) => option._id}
														getOptionLabel={(option) => option.name}
														placeholder="Birim.."
													/>
												)}
											/>
											<ErrorMessage
												errors={errors}
												name={`units.${index}.unit`}
												render={({ message }) => <div className="invalid-feedback">{message}</div>}
											/>
										</Col>
									)
							)}
							<Col md={6} className="mb-3">
								<Form.Label>Başlangıç Tarihi</Form.Label>
								<Controller
									name="startDate"
									control={control}
									rules={{ required: 'Bu alan gereklidir.' }}
									render={({ field }) => (
										<Flatpickr
											className="form-control"
											value={field.value}
											onChange={field.onChange}
											ref={field.ref}
											options={{ dateFormat: 'd-m-Y H:i', locale: { ...Turkish }, allowInput: true }}
										/>
									)}
								/>
								<ErrorMessage
									errors={errors}
									name={'startDate'}
									render={({ message }) => <div className="invalid-feedback">{message}</div>}
								/>
							</Col>
							<Col md={6} className="mb-3">
								<Form.Label>Bitiş Tarihi</Form.Label>
								<Controller
									name="finishDate"
									control={control}
									render={({ field }) => (
										<Flatpickr
											className="form-control"
											value={field.value}
											onChange={field.onChange}
											ref={field.ref}
											options={{ dateFormat: 'd-m-Y H:i', locale: { ...Turkish }, allowInput: true }}
										/>
									)}
								/>
							</Col>

							<Col md={6} className="mb-3">
								<Form.Label>Çalışma Şekli</Form.Label>
								<Controller
									name="employmentType"
									control={control}
									rules={{ required: 'Bu alan gereklidir.' }}
									render={({ field }) => (
										<CustomReactSelect
											isClearable
											value={employmentTypes.find((et) => et.value === field.value)}
											onChange={(value) => field.onChange(value.value)}
											options={employmentTypes}
											placeholder={'Çalışma şekli..'}
										/>
									)}
								/>
								<ErrorMessage
									errors={errors}
									name={'employmentType'}
									render={({ message }) => <div className="invalid-feedback">{message}</div>}
								/>
							</Col>

							<Col md={6} className="mb-3">
								<Form.Label>Yöneticisi</Form.Label>
								<Controller
									name="manager"
									control={control}
									render={({ field }) => (
										<CustomReactSelect
											isClearable
											value={field.value}
											onChange={field.onChange}
											options={employees}
											placeholder={'Yönetici..'}
											getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
											getOptionValue={(option) => option._id}
										/>
									)}
								/>
								<ErrorMessage
									errors={errors}
									name={'manager'}
									render={({ message }) => <div className="invalid-feedback">{message}</div>}
								/>
							</Col>

							<Col md={12} className="mb-3">
								<Form.Label>Varsayılan</Form.Label>
								<Controller
									name="isDefault"
									control={control}
									render={({ field }) => (
										<Form.Check
											type="checkbox"
											id="isDefault"
											checked={field.value}
											onChange={(e) => field.onChange(e.target.checked)}
											label="Bu pozisyonu çalışan için varsayılan olarak belirle."
										/>
									)}
								/>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					{isNew ? (
						<CreateButton onClick={handleSubmit((d) => handleCreate(d))} />
					) : (
						<>
							<DeleteButton onClick={handleDelete} />
							<SaveButton onClick={handleSubmit((d) => handleSave(d))} />
						</>
					)}
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default PositionHistoryModal
