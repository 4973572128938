import 'react-toastify/dist/ReactToastify.css'

import { default as lightLogo } from 'assets/svg/logos/meno-light.svg'
import AuthService from 'global/AuthService'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import { Button, Card, Container, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import useOrganizationStore from 'stores/organizationStore'

const ForgotPassword = () => {
	const organizationId = useOrganizationStore((state) => state.organizationId)
	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState,
		watch,
		formState: { errors }
	} = useForm({ mode: 'onChange' })

	const handleSend = async (data) => {
		const body = {
			mail: data.mail,
			org_id: organizationId
		}

		try {
			const response = await AuthService.post('/auth/forgot-password', body)
			sendToast('Şifrenizi sıfırlamak için e-posta adresinize bir bağlantı gönderdik.')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<div className="authentication-wrapper authentication-basic container-p-y">
			<div className="authentication-inner py-4">
				<Card style={{ padding: '40px 30px' }}>
					<Card.Body>
						<Card.Img className="app-brand" variant="top" src={lightLogo} height={30} />
						<Form onSubmit={handleSubmit((data) => handleSend(data))}>
							<h5 className="text-center">Şifrenizi mi unuttunuz?</h5>
							<Form.Text className="text-center mb-4">
								<p>Şifrenizi nasıl sıfırlayacağınızı ile ilgili talimatları size e-posta ile göndereceğiz.</p>
							</Form.Text>
							<Form.Group className="mb-3" controlId="formBasicEmail">
								<Form.Label>E-posta</Form.Label>
								<Form.Control
									type="text"
									placeholder="E-posta adresi"
									{...register('mail', { required: 'Bu alan gerekli.' })}
								/>
								{errors.mail && <Form.Text className="text-danger">{errors.mail.message}</Form.Text>}
							</Form.Group>
							<Button className="w-100" onClick={handleSubmit((data) => handleSend(data))}>
								Şifremi Sıfırla
							</Button>
						</Form>
						<Card.Text className="text-center mt-3">
							<Link to="/auth/login">
								<i className="bx bx-chevron-left scaleX-n1-rtl"></i>Ana sayfaya dön
							</Link>
						</Card.Text>
					</Card.Body>
				</Card>
			</div>
		</div>
	)
}

export default ForgotPassword
