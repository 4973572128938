import { ProjectButton } from 'components/fast-ui/Buttons'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const TaskCopy = () => {
	const navigate = useNavigate()
	const params = useParams()

	const { workspace_id, project_id, task_id } = params

	const handleCopy = async () => {
		try {
			const response = await Service.patch(`/project-tasks/${task_id}/copy`)
			sendToast(response.data.message, true)
			navigate(`/projects/${workspace_id}/${project_id}/${task_id}`)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<span>
			<ProjectButton text="Kopyala" icon="copy" onClick={handleCopy} />
		</span>
	)
}

export default TaskCopy
