import { AddButton, CloseButton } from 'components/fast-ui'
import { ButtonContainer, EditableText, FaIcon } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useState } from 'react'
import { Button, Form, ProgressBar } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import useProjectTaskStore from 'stores/projectTaskStore'

const TaskCheckListItem = ({ data, showAddTask, onAddNewTaskClick, onCloseClick }) => {
	const task = useProjectTaskStore((state) => state.projectTask)
	const refreshTask = useProjectTaskStore((state) => state.refreshProjectTask)

	const [focusedCheckListItemId, setFocusedCheckListItemId] = useState(false)

	const completedItemsCount = data.items.filter((item) => item.isCompleted).length
	const totalItemsCount = data.items.length

	const completedPercentage = totalItemsCount === 0 ? 0 : Math.round((completedItemsCount / totalItemsCount) * 100)

	const {
		register,
		handleSubmit,
		reset,
		setFocus,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	const resetTitle = () => {
		reset({ title: '' })
	}

	const handleAddNewTaskClick = () => {
		onAddNewTaskClick && onAddNewTaskClick(data._id)
	}

	const handleCloseClick = () => {
		onCloseClick && onCloseClick()
		resetTitle()
	}

	const handleSend = async (formData) => {
		const body = formData

		try {
			const response = await Service.post(`/project-tasks/${task._id}/check-lists/${data._id}/items`, body)
			refreshTask()
			resetTitle()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDeleteCheckList = async () => {
		try {
			const response = await Service.delete(`/project-tasks/${task._id}/check-lists/${data._id}`)
			refreshTask()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDeleteCheckListItem = async (itemId) => {
		try {
			const response = await Service.delete(`/project-tasks/${task._id}/check-lists/${data._id}/items/${itemId}`)
			refreshTask()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleCompletedCheckListItemChange = async (event, itemId) => {
		const isChecked = event.target.checked

		const body = {
			isCompleted: isChecked
		}

		try {
			const response = await Service.patch(
				`/project-tasks/${task._id}/check-lists/${data._id}/items/${itemId}/is-completed`,
				body
			)
			refreshTask()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleCheckListItemTitleChange = (newTitle, itemId) => {
		const body = {
			title: newTitle
		}

		try {
			const response = Service.patch(`/project-tasks/${task._id}/check-lists/${data._id}/items/${itemId}/title`, body)
			refreshTask()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<div className="mb-3">
			<h6 className="d-flex align-items-center justify-content-between mb-3">
				<span>
					<FaIcon icon="fa-regular fa-check-double" className="me-2" />
					{data && data.title && <EditableText value={data.title} />}
				</span>
				<Button variant="light" className="btn-sm shadow-none" onClick={handleDeleteCheckList}>
					Sil
				</Button>
			</h6>
			<div>
				<div className="d-flex align-items-center gap-2 mb-3">
					<small>
						{completedPercentage === 100 || isNaN(completedPercentage)
							? `${completedPercentage.toFixed(0)}%`
							: `${completedPercentage.toFixed(0)}%`}
					</small>
					{completedPercentage === 100 ? (
						<ProgressBar variant="success" now={completedPercentage} style={{ height: '6px', width: '100%' }} />
					) : (
						<ProgressBar variant="primary" now={completedPercentage} style={{ height: '6px', width: '100%' }} />
					)}
				</div>
				<div className="d-flex flex-column gap-3">
					{data &&
						data.items &&
						data.items.map((item) => (
							<div
								className="d-flex align-items-center justify-content-between"
								onMouseEnter={() => setFocusedCheckListItemId(item._id)}
								onMouseLeave={() => setFocusedCheckListItemId(null)}
								key={item._id}
							>
								<div className="d-flex align-items-start gap-1">
									<Form.Check
										type="checkbox"
										value={item.isCompleted}
										checked={item.isCompleted}
										className="me-2"
										onChange={(e) => handleCompletedCheckListItemChange(e, item._id)}
									/>
									<span className={item.isCompleted ? 'text-muted text-decoration-line-through' : ''}>
										<EditableText
											value={item.title}
											onBlur={(newTitle) => handleCheckListItemTitleChange(newTitle, item._id)}
										/>
									</span>
								</div>
								<div className={focusedCheckListItemId === item._id ? 'd-flex gap-2' : 'd-none'}>
									<FaIcon
										type="light"
										icon="fa-regular fa-trash"
										onClick={() => handleDeleteCheckListItem(item._id)}
										className="cursor-pointer"
									/>
								</div>
							</div>
						))}

					{showAddTask ? (
						<div className="d-flex flex-column gap-2">
							<Form.Control type="text" name="title" {...register('title')} autoFocus placeholder="Bir öğe ekleyin" />
							<ButtonContainer>
								<AddButton icon={null} className="btn-sm shadow-none" onClick={handleSubmit(handleSend)} />
								<CloseButton type="light" className="btn-sm shadow-none" onClick={handleCloseClick} />
							</ButtonContainer>
						</div>
					) : (
						<div className="d-flex align-items-center">
							<AddButton
								text="Bir öğe ekleyin"
								icon={null}
								className="btn-sm shadow-none"
								type="light"
								onClick={handleAddNewTaskClick}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default TaskCheckListItem
