import { CloseButton, CreateButton, DeleteButton, SaveButton } from 'components/fast-ui'
import CustomReactSelect from 'components/ui/CustomReactSelect'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { ErrorMessage } from '@hookform/error-message'

const basisOptions = [{ value: 'tr', label: 'Türk resmi tatil takvimi' }]

const HolidayCalendarModal = () => {
	const params = useParams()
	const { tab_name, item_id } = params

	const navigate = useNavigate()
	const [show, setShow] = useState(false)

	useEffect(() => {
		if (tab_name === 'holiday-calendars' && item_id === 'add') {
			setShow(true)
		} else {
			setShow(false)
		}
	}, [item_id])

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	const handleCreate = async (data) => {
		try {
			const response = await Service.post('/holiday-calendars', data)
			sendToast('Tatil Takvimi Oluşturuldu')
			navigate(`/settings/holiday-calendars`)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleSave = async (data) => {
		try {
			const response = await Service.patch(`/holiday-calendars/${item_id}`, data)
			sendToast('Tatil Takvimi Güncellendi')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDelete = async () => {
		try {
			const response = await Service.delete(`/holiday-calendars/${item_id}`)
			sendToast('Tatil Takvimi Silindi')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleClose = () => {
		setShow(false)
		navigate('/settings/holiday-calendars')
	}

	return (
		<Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title as="h5">Yeni Tatil Takvimi Oluştur</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Row>
						<Col md={6} className="mb-3">
							<Controller
								name="name"
								control={control}
								defaultValue=""
								rules={{ required: 'Gerekli alan.' }}
								render={({ field }) => (
									<Form.Group>
										<Form.Label>Ad</Form.Label>
										<Form.Control type="text" {...field} isInvalid={errors.name} />
										<Form.Control.Feedback type="invalid">
											<ErrorMessage errors={errors} name="name" />
										</Form.Control.Feedback>
									</Form.Group>
								)}
							/>
						</Col>

						<Col md={6}>
							<Controller
								name="description"
								control={control}
								defaultValue=""
								render={({ field }) => (
									<Form.Group>
										<Form.Label>Açıklama</Form.Label>
										<Form.Control type="text" {...field} isInvalid={errors.description} />
										<Form.Control.Feedback type="invalid">
											<ErrorMessage errors={errors} name="description" />
										</Form.Control.Feedback>
									</Form.Group>
								)}
							/>
						</Col>
						<Col md={12}>
							<Controller
								name="basis"
								control={control}
								render={({ field }) => (
									<Form.Group>
										<Form.Label>Temel (opsiyonel)</Form.Label>
										<CustomReactSelect
											isClearable
											value={basisOptions.filter((obj) => obj.value === field.value)}
											onChange={(d) => field.onChange(d?.value)}
											ref={field.ref}
											placeholder="Temel.."
											options={basisOptions}
										/>
									</Form.Group>
								)}
							/>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<CreateButton onClick={handleSubmit((d) => handleCreate(d))} />
			</Modal.Footer>
		</Modal>
	)
}

export default HolidayCalendarModal
