import React from 'react'
import { Col, Row } from 'react-bootstrap'

import ChangePasswordSettings from './components/ChangePasswordSettings'
import TwoFactorSettings from './components/TwoFactorSettings'

const MySettings = () => {
	return (
		<>
			<Row className="mb-4">
				<Col md={6}>
					<TwoFactorSettings />
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<ChangePasswordSettings />
				</Col>
			</Row>
		</>
	)
}

export default MySettings
