import { ApproveButton, DownloadButton, RejectButton } from 'components/fast-ui'
import { Approvers, ButtonContainer } from 'components/ui'
import downloadFile from 'global/functions/downloadFile'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

const exampleData = [
	{
		_id: '2',
		name: 'Doküman 12',
		uploader: 'Nihat',
		uploadDate: '02/01/2023',
		status: 'pending',
		location: 'Konum 2'
	},
	{
		_id: '3',
		name: 'Doküman 3',
		uploader: 'Barış',
		uploadDate: '03/01/2023',
		status: 'pending',
		location: 'Konum 3'
	}
]

const exampleApproversData = [
	{
		order: 1,
		employee: {
			fullName: 'Nihat Turan'
		},
		status: 'approved',
		_id: '63e2d8bcd7236ccdfb5b789b',
		approvalDate: '2023-02-14T22:36:26.298Z'
	},
	{
		order: 2,
		employee: {
			fullName: 'Barış Öner'
		},
		status: 'pending',
		_id: '63e2d8cad7236ccdfb5b7948'
	}
]

const DocumentApproveModal = ({ onHide, mode }) => {
	const [data, setData] = useState(null)

	const params = useParams()
	const navigate = useNavigate()

	const fetchData = () => {
		try {
			const lineData = exampleData.find((item) => item._id === params.document_id)
			setData(lineData)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (params.document_id) {
			fetchData()
		}
	}, [params])

	const handleClose = () => {
		navigate('/document-approval')
		onHide && onHide()
	}

	const handleDownload = () => {
		console.log(data)
		handleClose()
	}

	const handleReject = () => {
		navigate(`/document-approval/${params.document_id}/reject`)
	}

	const handleApprove = () => {
		sendToast('Doküman onaylandı', true)
		handleClose()
	}

	return (
		<Modal show onHide={handleClose} centered>
			<Modal.Header closeButton>
				<Modal.Title className="mb-0" as={'h5'}>
					Doküman İncele
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{mode === 'approve' ? (
					<p>
						<b>{data?.uploader}</b> tarafından yüklenen <b>{data?.name}</b> dokümanını onaylıyor musunuz?
					</p>
				) : (
					<p>
						<b>{data?.uploader}</b> tarafından yüklenen <b>{data?.name}</b> dokümanını inceliyorsunuz.
					</p>
				)}
				<Approvers data={exampleApproversData} />
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-between">
				<DownloadButton onClick={handleDownload} />

				{mode === 'approve' && (
					<ButtonContainer>
						<RejectButton onClick={handleReject} />
						<ApproveButton onClick={handleApprove} />
					</ButtonContainer>
				)}
			</Modal.Footer>
		</Modal>
	)
}

export default DocumentApproveModal
