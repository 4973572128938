import { TabButton, TabButtonContainer } from 'components/ui'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

import MyCompetenceRatings from './my-competence-ratings'

const MyRatings = () => {
	const params = useParams()
	const navigate = useNavigate()

	const { tab_name, module_name, module_id } = params
	const [tab, setTab] = useState('competence')

	const handleTabChange = (tab) => {
		navigate(`/my-ratings/${tab}`)
	}

	useEffect(() => {
		if (tab_name) {
			setTab(tab_name)
		}
	}, [params])

	useEffect(() => {
		if (tab === 'competence' && module_name !== 'detail') {
			navigate(`/my-ratings/competence/list`)
		}
	}, [])

	return (
		<div className="container-xxl flex-grow-1 container-p-y">
			<Row>
				<Col md={12} className="mb-4">
					<TabButtonContainer>
						<TabButton
							onClick={() => handleTabChange('competence/list')}
							icon="star"
							text="Yetkinlikler"
							active={tab === 'competence' && module_name === 'list'}
						/>
						{tab_name === 'competence' && module_name === 'detail' && (
							<TabButton
								onClick={() => handleTabChange('competence/list')}
								icon="arrow-left"
								text="Listeye dön"
								active={tab === 'competence' && module_name === 'detail'}
							/>
						)}
					</TabButtonContainer>
				</Col>
				<Col md={12}>{tab === 'competence' && <MyCompetenceRatings />}</Col>
			</Row>
		</div>
	)
}

export default MyRatings
