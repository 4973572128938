import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import { create } from 'zustand'

const useProjectTaskStore = create((set, get) => ({
	projectTask: null,
	projectTaskPopper: null,
	setProjectTask: (task) => set({ projectTask: task }),
	setProjectTaskPopper: (taskPopper) => set({ projectTaskPopper: taskPopper }),
	fetchProjectTask: async (task_id) => {
		try {
			const response = await Service.get(`/project-tasks/${task_id}`)
			set({ projectTask: response.data })
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	},
	refreshProjectTask: async () => {
		const { projectTask } = get()
		try {
			const response = await Service.get(`/project-tasks/${projectTask._id}`)
			set({ projectTask: response.data })
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	},
	resetProjectTask: () => set({ projectTask: null })
}))

export default useProjectTaskStore
