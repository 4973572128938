import { FaIcon } from 'components/ui'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const Storage = () => {
	return (
		<>
			<Row className="align-items-center mb-2">
				<Col className="d-flex align-items-center">
					<FaIcon icon="hard-drive" size="md" className="me-2" />
					<span>Depolama</span>
				</Col>
			</Row>
			<Row>
				<Col className="mb-3 w-100 w-md-50">
					<div className="progress">
						<div
							className="progress-bar bg-primary"
							role="progressbar"
							aria-valuenow="15"
							aria-valuemin="0"
							aria-valuemax="100"
							style={{ width: '15%' }}
						></div>
						<div
							className="progress-bar bg-success"
							role="progressbar"
							aria-valuenow="30"
							aria-valuemin="0"
							aria-valuemax="100"
							style={{ width: '30%' }}
						></div>
						<div
							className="progress-bar bg-danger"
							role="progressbar"
							aria-valuenow="20"
							aria-valuemin="0"
							aria-valuemax="100"
							style={{ width: '20%' }}
						></div>
					</div>
				</Col>
			</Row>
		</>
	)
}

export default Storage
