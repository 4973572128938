import { CloseButton, CreateButton, SaveButton } from 'components/fast-ui'
import { ButtonContainer, CustomDatePicker, CustomDateTimePicker, CustomReactSelect, FaIcon } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

const EventAddModal = ({ onClose }) => {
	const params = useParams()
	const { event_id } = params

	const navigate = useNavigate()

	const handleCreate = async (data) => {
		const body = data
		try {
			const response = await Service.post('/events', body)
			sendToast('Etkinlik başarıyla oluşturuldu.', true)
			handleClose()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleClose = () => {
		onClose && onClose()
		navigate('/calendar')
	}

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState,
		watch,
		formState: { errors, isDirty }
	} = useForm({ mode: 'onChange' })

	return (
		<Modal show onHide={handleClose} size="md" centered>
			<Modal.Header closeButton>
				<Modal.Title as="h5">Etkinlik Ekle</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form className="d-flex flex-column gap-2">
					<Form.Group>
						<Form.Label>Adı</Form.Label>
						<Form.Control type="text" {...register('title', { required: true })} />
						{errors.title && <Form.Text className="text-danger">Bu alan zorunludur.</Form.Text>}
					</Form.Group>
					<Form.Group>
						<Form.Label>Açıklama</Form.Label>
						<Form.Control as="textarea" rows={3} {...register('description')} />
					</Form.Group>
					<Form.Group>
						<Form.Label>Başlangıç Tarihi</Form.Label>
						<Controller
							name="startDate"
							control={control}
							rules={{ required: true }}
							render={({ field }) => (
								<CustomDateTimePicker {...field} selected={field.value} onChange={(date) => field.onChange(date)} />
							)}
						/>
						{errors.startDate && <Form.Text className="text-danger">Bu alan zorunludur.</Form.Text>}
					</Form.Group>
					<Form.Group>
						<Form.Label>Bitiş Tarihi</Form.Label>
						<Controller
							name="endDate"
							control={control}
							rules={{ required: true }}
							render={({ field }) => (
								<CustomDateTimePicker {...field} selected={field.value} onChange={(date) => field.onChange(date)} />
							)}
						/>
						{errors.endDate && <Form.Text className="text-danger">Bu alan zorunludur.</Form.Text>}
					</Form.Group>
					<Form.Group>
						<Form.Label>Konum</Form.Label>
						<Form.Control type="text" {...register('location')} />
					</Form.Group>
					<Form.Group>
						<Form.Label>Link</Form.Label>
						<Form.Control type="text" {...register('link')} />
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<ButtonContainer>
					<CloseButton onClick={handleClose} />
					<CreateButton onClick={handleSubmit(handleCreate)} disabled={!isDirty} />
				</ButtonContainer>
			</Modal.Footer>
		</Modal>
	)
}

export default EventAddModal
