import React, { useEffect, useState } from 'react'

const EditableInput = (props) => {
	const [lastValue, setLastValue] = useState('')
	const [value, setValue] = useState(props.value)

	const handleChange = (e) => {
		setValue(e.target.value)
		props.handleChange && props.handleChange(e)
	}

	const handleOnBlur = (e) => {
		if (value === '') {
			setValue(lastValue)
		} else {
			if (lastValue !== value) {
				props.onBlur && props.onBlur(e)
			}
		}
	}

	return (
		<input
			className={`form-control editable-input ${props.className}`}
			style={props.style}
			value={value}
			onFocus={() => setLastValue(value)}
			onChange={handleChange}
			onBlur={handleOnBlur}
			placeholder={props.placeholder}
			disabled={props.disabled}
		/>
	)
}

export default EditableInput
