import notificationTypes from 'constants/notificationTypes'
import Service from 'global/Service'
import { formatDate } from 'global/functions/formatDate'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import FaIcon from '../FaIcon'
import { Timeago, Tooltip } from '..'

const Notification = ({ data }) => {
	const notificationType = notificationTypes.find((item) => item.type === data.type)
	const navigate = useNavigate()

	if (!notificationType) {
		throw new Error(`Notification type '${data.type}' not found`)
	}

	const title = notificationType.titleTemplate(data.data)
	const message = (
		<span
			dangerouslySetInnerHTML={{
				__html: notificationType.messageTemplate(data)
			}}
		/>
	)
	const link = notificationType.linkTemplate(data)

	const handleClick = async () => {
		if (!data.isRead) {
			try {
				const response = await Service.patch(`/notifications2/${data._id}/read`)
			} catch (error) {
				sendToast(error.response.data.message, false)
			}
		}

		if (link) {
			navigate(link)
		}
	}

	return (
		<li
			key={data._id}
			className="list-group-item list-group-item-action dropdown-notifications-item py-3"
			onClick={handleClick}
			type="button"
		>
			<div className="d-flex">
				<div className="flex-shrink-0 me-3">
					<div className="avatar">
						{data.source === 'system' ? (
							<span className="avatar-initial rounded-circle bg-label-secondary">SYS</span>
						) : data.sender.profilePhotoUrl ? (
							<img src={`${data.sender.profilePhotoUrl}`} alt="avatar" className="w-px-40 h-auto rounded-circle" />
						) : (
							<span className="avatar-initial rounded-circle bg-label-primary">{`${data.sender.fullName}`}</span>
						)}
					</div>
				</div>
				<div className="flex-grow-1">
					<h6 className="mb-2">{title}</h6>

					<p className="mb-2">{message}</p>

					<div className="text-muted d-flex align-items-center gap-2">
						<Tooltip content={formatDate(data.createDate)}>
							<FaIcon icon="clock" />
						</Tooltip>
						<small>
							<Timeago date={data.createDate} />
						</small>
					</div>
				</div>
				{!data.isRead && (
					<div className="flex-shrink-0 dropdown-notifications-actions">
						<a href="#" className="dropdown-notifications-read">
							<span className="badge badge-dot" />
						</a>
					</div>
				)}
			</div>
		</li>
	)
}

export default Notification
