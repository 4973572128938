import Service from 'global/Service'

import { createSlice } from '@reduxjs/toolkit'

const companyStructureSlice = createSlice({
	name: 'companyStructure',
	initialState: {
		companyStructures: []
	},
	reducers: {
		setCompanyStructures: (state, action) => {
			state.companyStructures = action.payload
		}
	}
})

export const { setCompanyStructures } = companyStructureSlice.actions

export const refreshCompanyStructures = () => async (dispatch) => {
	const companyStructures = await Service.get(`/company-structures`)
	try {
		dispatch(setCompanyStructures(companyStructures.data))
	} catch (error) {}
}

export default companyStructureSlice.reducer
