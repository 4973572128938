import Service from 'global/Service'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'

import MyCompetenceRatingCard from './components/MyCompetenceRatingCard'

const MyCompetenceRatingList = () => {
	const params = useParams()
	const { tab_name, module_name, module_id: competenceRatingId } = params

	const [pendingRatings, setPendingRatings] = useState([])
	const [completedRatings, setCompletedRatings] = useState([])

	const fetchRatings = async () => {
		try {
			const response = await Service.get(`/competence-ratings/my-ratings`)
			const { data } = response
			setPendingRatings(data.pending)
			setCompletedRatings(data.completed)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchRatings()
	}, [])

	return (
		<>
			<Card className="mb-4">
				<Card.Header>
					<Card.Title>Bekleyen Yetkinlik Değerlendirmeleri</Card.Title>
				</Card.Header>
				<Card.Body>
					<Row>
						{pendingRatings.length > 0 ? (
							pendingRatings.map((rating) => (
								<Col md={4} key={rating._id}>
									<Link to={`/my-ratings/competence/detail/${rating._id}`}>
										<MyCompetenceRatingCard key={rating._id} {...rating} iCompleted={false} />
									</Link>
								</Col>
							))
						) : (
							<p>Henüz bekleyen bir yetkinlik değerlendirmeniz bulunmamaktadır.</p>
						)}
					</Row>
				</Card.Body>
			</Card>
			<Card>
				<Card.Header>
					<Card.Title> Tamamlanan Yetkinlik Değerlendirmeleri</Card.Title>
				</Card.Header>
				<Card.Body>
					<Row>
						{completedRatings.length > 0 ? (
							completedRatings.map((rating) => (
								<Col md={4} key={rating._id}>
									<MyCompetenceRatingCard key={rating._id} {...rating} iCompleted />
								</Col>
							))
						) : (
							<p>Henüz tamamlanan bir yetkinlik değerlendirmeniz bulunmamaktadır.</p>
						)}
					</Row>
				</Card.Body>
			</Card>
		</>
	)
}

export default MyCompetenceRatingList
