import { FaIcon } from 'components/ui'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useRoleStore from 'stores/settings/roleStore'

import RoleItem from './components/RoleItem'

const RoleList = () => {
	const params = useParams()
	const navigate = useNavigate()

	const roles = useRoleStore((state) => state.roles)

	const handleClickNew = () => {
		navigate(`/settings/roles/add`)
	}

	return (
		<>
			<div className="border rounded p-3">
				<div className="mb-3">
					<button onClick={handleClickNew} type="button" className={`btn btn-primary w-100`}>
						<FaIcon icon="circle-plus" className="me-2" /> Yeni Rol
					</button>
				</div>
				{roles.map((item, index) => (
					<RoleItem key={index} data={item} />
				))}
			</div>
		</>
	)
}

export default RoleList
