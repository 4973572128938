import { CreateButton, DeleteButton, SaveButton } from 'components/fast-ui'
import Service from 'global/Service'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useMemo, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'

import COLUMNS from './components/Columns'
import CompetenceTypeModal from './components/CompetenceTypeModal'

const CompetenceTypeDefinition = () => {
	const params = useParams()
	const { tab_name, module_name, module_id: competenceTypeId } = params
	const navigate = useNavigate()

	const [competenceTypes, setCompetenceTypes] = useState([])
	const [showCompetenceTypeModal, setShowCompetenceTypeModal] = useState(false)

	const fetchCompetenceTypes = async () => {
		try {
			const res = await Service.get('/competence-types')
			setCompetenceTypes(res.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleCreateButton = () => {
		navigate('/ik-competencies/definitions/types/add')
	}

	useEffect(() => {
		fetchCompetenceTypes()
		if (tab_name === 'definitions' && module_name === 'types' && competenceTypeId) {
			setShowCompetenceTypeModal(true)
		} else {
			setShowCompetenceTypeModal(false)
		}
	}, [params])

	const data = useMemo(() => competenceTypes)
	const columns = useMemo(() => COLUMNS, [])

	const tableInstance = useTable(
		{
			columns,
			data
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		flatRows,
		prepareRow,
		page,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		pageOptions,
		gotoPage,
		pageCount,
		setPageSize,
		state,
		setGlobalFilter
	} = tableInstance

	const { globalFilter, pageIndex, pageSize } = state

	return (
		<>
			<Card className="h-100">
				<Card.Body>
					<div className="d-flex align-items-start justify-content-between">
						<div className="content-left">
							<div className="d-flex align-items-end mt-2">
								<h4 className="mb-0 me-2">Yetkinlik Türleri</h4>
							</div>
							<small>Toplam: {competenceTypes.length}</small>
						</div>
					</div>
					<div className="mt-3">
						<CreateButton onClick={handleCreateButton} />
					</div>
					<div className="mt-3">
						<table
							{...getTableProps()}
							className="datatables-users table border-top dataTable table-striped no-footer dtr-column table-responsive"
							style={{ width: 1382 }}
						>
							<thead>
								{headerGroups.map((headerGroup) => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map((column) => (
											<th
												className="control sorting_disabled"
												{...column.getHeaderProps(column.getSortByToggleProps())}
											>
												{column.render('Header')}
												<span>{column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}</span>
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()}>
								{page.map((row) => {
									prepareRow(row)
									return (
										<tr {...row.getRowProps()}>
											{row.cells.map((cell) => {
												return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
											})}
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
					<div className="mt-3 d-flex justify-content-end">
						<ul className="pagination">
							<li
								className={'paginate_button page-item previous ' + (canPreviousPage ? '' : 'disabled')}
								onClick={() => previousPage()}
							>
								<a href="#" aria-controls="DataTables_Table_0" data-dt-idx={0} tabIndex={0} className="page-link">
									Önceki
								</a>
							</li>
							<li
								className={'paginate_button page-item next ' + (canNextPage ? '' : 'disabled')}
								onClick={() => nextPage()}
							>
								<a href="#" aria-controls="DataTables_Table_0" data-dt-idx={6} tabIndex={0} className="page-link">
									Sonraki
								</a>
							</li>
						</ul>
					</div>
				</Card.Body>
			</Card>
			{showCompetenceTypeModal && (
				<CompetenceTypeModal
					id={competenceTypeId}
					show={showCompetenceTypeModal}
					onHide={() => navigate('/ik-competencies/definitions')}
				/>
			)}
		</>
	)
}

export default CompetenceTypeDefinition
