import { FaIcon } from 'components/ui'
import dateFormat from 'dateformat'
import prettyBytes from 'pretty-bytes'
import React from 'react'
import { useSelector } from 'react-redux'

const InfoModal = () => {
	const { info } = useSelector((state) => state.document.context)

	return (
		<div
			className="modal fade"
			id="folderInfoModal"
			aria-labelledby="folderInfoModalLabel"
			tabIndex={-1}
			style={{ display: 'none' }}
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="folderInfoModalLabel">
							<FaIcon icon="circle-info" size="sm" className="me-2" />
							Bilgi
						</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
					</div>
					<div className="modal-body">
						<p>
							Ad: <b>{info?.digitalName}</b>
						</p>
						<p>
							Tür: <b>{info?.isFolder ? 'Klasör' : info?.file?.extension}</b>
						</p>
						<p>
							Dizin:
							<br />
							<b>
								Cloud
								{info?.breadcrumb.map((item) => (
									<>
										<FaIcon icon="angle-right" className="mx-2" /> {item?.digitalName}
									</>
								))}
							</b>
						</p>
						<div className="row">
							{info?.childFolderCount > 0 && (
								<div className="col-sm-4">
									<p>
										Toplam klasör:
										<br />
										<b>{info?.childFolderCount}</b>
									</p>
								</div>
							)}
							{info?.childFileCount > 0 && (
								<div className="col-sm-4">
									<p>
										Toplam dosya:
										<br />
										<b>{info?.childFileCount}</b>
									</p>
								</div>
							)}
							{info?.totalSize > 0 && (
								<div className="col-sm-4">
									<p>
										Toplam boyut:
										<br />
										<b>{prettyBytes(info?.totalSize ? info?.totalSize : 0)}</b>
									</p>
								</div>
							)}
							{info?.createDate && (
								<div className="col-sm-4">
									<p>
										Oluşturulma tarihi:
										<br />
										<b>{dateFormat(new Date(info?.createDate), 'yyyy-mm-dd hh:MM')}</b>
									</p>
								</div>
							)}
							{/* {info?.updateDate && (
								<div className="col-sm-4">
									<p>
										Değiştirilme tarihi:
										<br />
										<b>{dateFormat(new Date(info?.updateDate), 'yyyy-mm-dd hh:MM')}</b>
									</p>
								</div>
							)} */}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default InfoModal
