import { CloseButton, CreateButton, DeleteButton, SaveButton } from 'components/fast-ui'
import { ButtonContainer, CustomReactSelect } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { Form, Modal, Stack } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

const ProjectModal = ({ onHide, isNew, formData }) => {
	const params = useParams()
	const navigate = useNavigate()
	const { workspace_id, project_id } = params

	const handleClose = () => {
		onHide && onHide()
	}

	const handleSave = async (data) => {
		const body = data
		try {
			const response = await Service.patch(`/workspaces/${workspace_id}/projects/${project_id}`, body)
			sendToast('Proje güncellendi.')
			handleClose()
			navigate(`/workspaces/${workspace_id}`)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleCreate = async (data) => {
		const body = data
		try {
			const response = await Service.post(`/workspaces/${workspace_id}/projects`, body)
			sendToast('Proje oluşturuldu.')
			handleClose()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDelete = async (data) => {
		const body = data
		try {
			const response = await Service.delete(`/workspaces/${workspace_id}/projects/${project_id}`, body)
			handleClose()
			navigate(`/workspaces/${workspace_id}`)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange',
		defaultValues: formData
	})

	return (
		<Modal show={true} onHide={handleClose} centered>
			<Modal.Header closeButton>
				<Modal.Title as="h5">Proje {isNew ? 'Oluştur' : 'Düzenle'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Stack gap={3}>
						<Form.Group>
							<Form.Label>Proje Başlığı</Form.Label>
							<Form.Control type="text" placeholder="Proje Başlığı" {...register('name', { required: true })} />
						</Form.Group>
						<Form.Group>
							<Form.Label>Proje Açıklaması</Form.Label>
							<Form.Control
								as="textarea"
								rows={3}
								placeholder="Proje Açıklaması"
								{...register('description', { required: true })}
								isInvalid={errors.description}
							/>
							{errors.description && (
								<span className="text-danger">
									<small>Bu alan zorunludur.</small>
								</span>
							)}
						</Form.Group>
					</Stack>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<ButtonContainer>
					<CloseButton variant="secondary" onClick={handleClose} />
					{isNew ? (
						<CreateButton onClick={handleSubmit((data) => handleCreate(data))} />
					) : (
						<ButtonContainer>
							<DeleteButton onClick={handleSubmit((data) => handleDelete(data))} />
							<SaveButton onClick={handleSubmit((data) => handleSave(data))} />
						</ButtonContainer>
					)}
				</ButtonContainer>
			</Modal.Footer>
		</Modal>
	)
}

export default ProjectModal
