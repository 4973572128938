import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { ChromePicker, CirclePicker } from 'react-color'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { ErrorMessage } from '@hookform/error-message'

const RoleViewTab = ({ form }) => {
	const [showColorPicker, setShowColorPicker] = useState(false)

	const setColor = (color) => {
		setValue('hexColor', color)
	}

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState,
		watch,
		formState: { errors }
	} = form

	return (
		<Col lg={8} md={10} sm={12}>
			<Card>
				<Card.Body>
					<Form>
						<Row>
							<Controller
								name="name"
								control={control}
								defaultValue=""
								rules={{ required: 'Gerekli alan.' }}
								render={({ field }) => (
									<Form.Group>
										<Form.Label>Rol Adı</Form.Label>
										<Form.Control type="text" {...field} />
										<Form.Control.Feedback type="invalid">
											<ErrorMessage errors={errors} name="name" />
										</Form.Control.Feedback>
									</Form.Group>
								)}
							/>

							<div className="d-flex">
								<Controller
									name="hexColor"
									control={control}
									defaultValue="#146ddd"
									rules={{ required: 'Gerekli alan.' }}
									render={({ field }) => (
										<Form.Group className="mt-3">
											<Form.Label>Rol Rengi</Form.Label>
											<div className="d-flex flex-wrap gap-3 align-items-center">
												<span
													className="d-inline-block"
													type="button"
													onClick={() => setShowColorPicker(!showColorPicker)}
													style={{
														width: '50px',
														height: '50px',
														backgroundColor: field.value,
														borderRadius: '50%'
													}}
												/>

												{showColorPicker && (
													<div className="position-absolute" style={{ zIndex: 2, top: 145, left: 84 }}>
														<ChromePicker
															color={field.value}
															onChange={(color) => {
																field.onChange(color.hex)
															}}
															disableAlpha
															styles={{
																default: {
																	picker: {
																		boxShadow: 'none',
																		border: '1px solid #ccc',
																		borderRadius: '4px'
																	},
																	//font
																	body: {
																		fontFamily: 'Segoe UI, Helvetica, Arial, sans-serif',
																		fontSize: '14px',
																		fontWeight: '400',
																		lineHeight: '1.42857143',
																		color: '#333'
																	}
																}
															}}
														/>
													</div>
												)}
												<div style={{ maxWidth: 400 }}>
													<CirclePicker
														className="mt-3"
														width="100%"
														circleSize={25}
														color={watch('hexColor')}
														onChangeComplete={(color) => {
															setColor(color.hex)
														}}
														colors={[
															'#F44336',
															'#E91E63',
															'#9C27B0',
															'#9B59B6',
															'#673AB7',
															'#3F51B5',
															'#2196F3',
															'#03A9F4',
															'#00BCD4',
															'#009688',
															'#4CAF50',
															'#8BC34A',
															'#CDDC39',
															'#FFEB3B',
															'#FFC107',
															'#FF9800',
															'#FF5722',
															'#795548',
															'#607D8B',
															'#969696'
														]}
													/>
												</div>
											</div>
										</Form.Group>
									)}
								/>
							</div>
						</Row>
					</Form>
				</Card.Body>
			</Card>
		</Col>
	)
}

export default RoleViewTab
