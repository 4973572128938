import { FaIcon } from 'components/ui'
import { modules } from 'constants/Modules'
import React from 'react'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import ApprovalStepItem from './ApprovalStepItem'

const ApprovalCard = ({ data }) => {
	const navigate = useNavigate()

	const handleEdit = () => {
		navigate(`/settings/approval-processes/${data._id}`)
	}
	return (
		<Card>
			<Card.Header>
				<Card.Title as="h5">
					{data.name}
					<span type="button">
						<FaIcon icon="edit" className="ms-2" onClick={handleEdit} />
					</span>
				</Card.Title>
			</Card.Header>
			<Card.Body>
				<div className="d-flex flex-wrap align-items-center" style={{ gap: '1rem' }}>
					<ApprovalStepItem icon="user" name="Çalışan" />
					<FaIcon icon="arrow-right" className="ms-3 me-3" />

					{data.items.map((item, index) => (
						<>
							<ApprovalStepItem
								data={item}
								stepNo={item.order}
								name={`${item.employee.firstName} ${item.employee.lastName}`}
							/>
							<FaIcon icon="arrow-right" className="ms-3 me-3" />
						</>
					))}

					<ApprovalStepItem icon="check-circle" name="Onaylandı" />
				</div>
			</Card.Body>
		</Card>
	)
}

export default ApprovalCard
