import { FaIcon } from 'components/ui'
import { format } from 'date-fns'
import { Link, useNavigate } from 'react-router-dom'

const COLUMNS = [
	{
		Header: 'Ad',
		accessor: 'name',
		Cell: (cell) => {
			return <span className="text-truncate d-flex align-items-center">{cell.row.original.name}</span>
		}
	},
	{
		Header: 'Çalışan Sayısı',
		accessor: 'employeeCount',
		Cell: (cell) => {
			return <span className="text-truncate d-flex align-items-center">{cell.row.original.employees.length}</span>
		}
	},
	{
		Header: 'Seçenekler',
		accessor: 'options',
		Cell: ({ row }) => {
			return (
				<div className="d-flex align-items-center">
					<div className="d-flex align-items-center">
						<Link
							to={`/settings/company-structure/${row.original.companyStructure._id}/${row.original._id}`}
							className="text-primary me-3"
						>
							<FaIcon icon="edit" />
						</Link>

						{row.original.companyStructure.type !== 'company' && (
							<>
								<Link
									to={`/settings/company-structure/${row.original.companyStructure._id}/${row.original._id}/transfer`}
									className="text-warning me-3"
								>
									<FaIcon icon="exchange-alt" />
								</Link>
								<Link
									to={`/settings/company-structure/${row.original.companyStructure._id}/${row.original._id}/delete`}
									className="text-danger me-3"
								>
									<FaIcon icon="trash" />
								</Link>
							</>
						)}
					</div>
				</div>
			)
		}
	}
]

export default COLUMNS
