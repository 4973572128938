import anonimProfilePhoto from 'assets/img/avatars/anonim-profile-photo.jpg'
import { CustomReactTable, FaIcon, TabButton, TabButtonContainer, Tooltip } from 'components/ui'
import NoData from 'components/ui/NoData'
import Service from 'global/Service'
import { formatDate } from 'global/functions/formatDate'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useMemo, useState } from 'react'
import { Card, Pagination } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useAuthStore from 'stores/authStore'

const MyTasks = () => {
	const navigate = useNavigate()

	const params = useParams()
	const { task_id } = params

	const user = useAuthStore((state) => state.user)

	const [data, setData] = useState([])
	const [selectedTab, setSelectedTab] = useState('all')

	const fetchData = async () => {
		try {
			const response = await Service.get('/tasks/created')
			setData(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleTabClick = (tab) => {
		setSelectedTab(tab)
	}

	useEffect(() => {
		if (task_id === 'created-tasks') {
			fetchData()
		}
	}, [params])

	const columns = useMemo(
		() => [
			{
				Header: 'Görev',
				accessor: 'title',
				Cell: ({ row }) => {
					return (
						<Link to={`/tasks/${row.original._id}`} className="text-decoration-none">
							{row.original.title}
						</Link>
					)
				}
			},
			{
				Header: 'Oluşturma Tarihi',
				accessor: 'createDate',
				Cell: ({ value }) => formatDate(value, false)
			},
			{
				Header: 'Bitiş Tarihi',
				accessor: 'endDate',
				Cell: ({ value }) => formatDate(value, false)
			},
			{
				Header: 'Çalışan',
				accessor: 'members',
				Cell: ({ value }) => (
					<ul className="list-unstyled users-list m-0 avatar-group d-flex align-items-center">
						{value
							.filter((member) => member._id !== user._id)
							.map((member) => (
								<Tooltip content={member?.fullName}>
									<img
										key={member._id}
										src={member?.profilePhotoUrl || anonimProfilePhoto}
										alt="Avatar"
										className="d-flex avatar avatar-xs pull-up rounded-circle border border-white"
									/>
								</Tooltip>
							))}
					</ul>
				)
			},
			{
				Header: 'Durum',
				accessor: 'status',
				Cell: ({ value }) => {
					let badgeClass = ''
					let badgeText = ''
					switch (value) {
						case 'waiting':
							badgeClass = 'badge bg-warning'
							badgeText = 'Beklemede'
							break
						case 'inprogress':
							badgeClass = 'badge bg-primary'
							badgeText = 'Devam Ediyor'
							break
						case 'completed':
							badgeClass = 'badge bg-success'
							badgeText = 'Tamamlandı'
							break
						case 'canceled':
							badgeClass = 'badge bg-danger'
							badgeText = 'İptal Edildi'
							break
						default:
							break
					}
					return <p className={`badge mb-0 ${badgeClass}`}>{badgeText}</p>
				}
			}
		],
		[]
	)

	return (
		<Card>
			<Card.Header>
				<Card.Title as="h5">Oluşturduğum Görevler</Card.Title>
				{data.length === 0 && <NoData title="Görev bulunamadı" subTitle="Henüz bir görev oluşturmadınız." />}
				{data.length > 0 && (
					<TabButtonContainer>
						<TabButton active={selectedTab === 'all'} icon="list" text="Tümü" onClick={() => handleTabClick('all')} />
						<TabButton active={selectedTab === 'waiting'} icon="clock" onClick={() => handleTabClick('waiting')} />
						<TabButton active={selectedTab === 'inprogress'} icon="play" onClick={() => handleTabClick('inprogress')} />
						<TabButton active={selectedTab === 'completed'} icon="check" onClick={() => handleTabClick('completed')} />
						<TabButton active={selectedTab === 'canceled'} icon="xmark" onClick={() => handleTabClick('canceled')} />
					</TabButtonContainer>
				)}
			</Card.Header>

			{data.length > 0 && (
				<Card.Body>
					<CustomReactTable
						data={data.filter((task) => {
							if (selectedTab === 'all') return true
							else return task.status === selectedTab
						})}
						columns={columns}
						defaultSortBy="createDate"
						defaultSortDesc={true}
						pageSizeCount={10}
					/>
				</Card.Body>
			)}
		</Card>
	)
}

export default MyTasks
