import { CustomReactTable, FaIcon } from 'components/ui/index.js'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Card, Col } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'

const columns = [
	{
		Header: 'Ad',
		accessor: 'name',
		Cell: (cell) => {
			return (
				<span className="fw-semibold">
					{cell.row.original.name}
					{cell.row.original.isDefault && <span className="badge bg-primary ms-2">Varsayılan</span>}
				</span>
			)
		}
	},
	{
		Header: 'Açıklama',
		accessor: 'description'
	},
	{
		Header: 'Seçenekler',
		accessor: 'options',
		Cell: (cell) => {
			return (
				<div className="d-flex align-items-center">
					<div className="d-flex align-items-center">
						<Link to={`/vacations/rules/${cell.row.original._id}`} className="text-primary me-3">
							<FaIcon icon="edit" />
						</Link>
					</div>
				</div>
			)
		}
	}
]

const VacationRulesTable = () => {
	const params = useParams()
	const [vacationRules, setVacationRules] = useState([])

	const fetchVacationRules = async () => {
		try {
			const response = await Service.get('/vacation-rules')
			setVacationRules(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchVacationRules()
	}, [params])

	return (
		<Col lg={12} md={12} sm={12} xs={12}>
			<Card>
				<Card.Header>
					<Card.Title as="h5">İzin Kuralları</Card.Title>
				</Card.Header>
				<Card.Body>
					<CustomReactTable columns={columns} data={vacationRules} pageSizeCount={100} />
				</Card.Body>
			</Card>
		</Col>
	)
}

export default VacationRulesTable
