const currencies = [
	{ value: 'TRY', label: 'TL' },
	{ value: 'USD', label: 'USD' },
	{ value: 'EUR', label: 'EUR' },
	{ value: 'GBP', label: 'GBP' },
	{ value: 'CHF', label: 'CHF' },
	{ value: 'JPY', label: 'JPY' },
	{ value: 'CNY', label: 'CNY' },
	{ value: 'RUB', label: 'RUB' },
	{ value: 'AUD', label: 'AUD' },
	{ value: 'CAD', label: 'CAD' },
	{ value: 'NZD', label: 'NZD' },
	{ value: 'DKK', label: 'DKK' },
	{ value: 'SEK', label: 'SEK' }
]

export default currencies
