import Service from 'global/Service'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Nestable from 'react-nestable'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchOrganizationScheme } from 'stores/ikOrganizationSlice'

import SchemeBody from './components/SchemeBody'
import SchemeFooter from './components/SchemeFooter'
import SchemeHeader from './components/SchemeHeader'

const SchemeEditor = () => {
	const params = useParams()
	const dispatch = useDispatch()

	const { scheme_id } = params

	useEffect(() => {
		dispatch(fetchOrganizationScheme(scheme_id))
	}, [scheme_id])

	return (
		<>
			<Col md={12} className="mb-4">
				<SchemeHeader />
			</Col>
			<Col md={12} className="mb-4">
				<SchemeBody />
			</Col>
			<Row>
				<SchemeFooter />
			</Row>
		</>
	)
}

export default SchemeEditor
