import React from 'react'
import { Container } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify'

const AuthLayout = ({ children }) => {
	return (
		<Container>
			<ToastContainer />
			{children}
		</Container>
	)
}

export default AuthLayout
