import { CreateButton } from 'components/fast-ui'
import { ExpenseCreateModal, VacationCreateModal } from 'components/modals'
import { FaIcon } from 'components/ui'
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import useAuthStore from 'stores/authStore'

const Requests = () => {
	const params = useParams()
	const navigate = useNavigate()
	const { tab_name } = params

	const user = useAuthStore((state) => state.user)
	const handleClickVacation = () => {
		navigate('/requests/vacation')
	}

	const handleClickExpense = () => {
		navigate('/requests/expense')
	}

	return (
		<Container fluid="xxl" className="flex-grow-1 container-p-y">
			<Row>
				<Col md={4} className="mb-4">
					<Card>
						<Card.Body>
							<div className="d-flex align-items-center">
								<div
									className="d-flex align-items-center justify-content-center rounded-circle bg-light border me-4"
									style={{ width: '5rem', height: '5rem' }}
								>
									<span className="text-dark">
										<FaIcon icon="calendar" style={{ fontSize: '2.5rem' }} />
									</span>
								</div>
								<div>
									<h5>İzin Talebi</h5>
									<CreateButton onClick={handleClickVacation} />
								</div>
							</div>
						</Card.Body>
					</Card>
				</Col>
				<Col md={4}>
					<Card>
						<Card.Body>
							<div className="d-flex align-items-center">
								<div
									className="d-flex align-items-center justify-content-center rounded-circle bg-light border me-4"
									style={{ width: '5rem', height: '5rem' }}
								>
									<span className="text-dark">
										<FaIcon icon="money-bill-transfer" style={{ fontSize: '2.5rem' }} />
									</span>
								</div>
								<div>
									<h5>Masraf Talebi</h5>
									<CreateButton onClick={handleClickExpense} />
								</div>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			{tab_name === 'vacation' && (
				<VacationCreateModal
					employeeId={user._id}
					adminMode={false}
					show={tab_name === 'vacation'}
					onHide={() => navigate('/requests')}
				/>
			)}
			{tab_name === 'expense' && (
				<ExpenseCreateModal
					employeeId={user._id}
					adminMode={false}
					show={tab_name === 'expense'}
					onHide={() => navigate('/requests')}
				/>
			)}
		</Container>
	)
}

export default Requests
