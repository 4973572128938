import { CloseButton, CreateButton, DeleteButton, SaveButton } from 'components/fast-ui'
import { CustomDatePicker, CustomReactSelect, FaIcon } from 'components/ui'
import Service from 'global/Service'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

const WorkCalendarModal = ({ show }) => {
	const params = useParams()
	const navigate = useNavigate()
	const { employee_id, tab_name, module_name, module_id } = params

	const [workCalendars, setWorkCalendars] = useState([])
	const [isEditing, setIsEditing] = useState(false)

	const fetchWorkCalendars = async () => {
		try {
			const response = await Service.get('/work-calendars')
			setWorkCalendars(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchData = async () => {
		try {
			const response = await Service.get(`/employee-work-calendars/${employee_id}/${module_id}`)
			reset(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (module_id) {
			fetchWorkCalendars()
			if (module_id === 'add') {
				reset({})
				setIsEditing(false)
			} else {
				fetchData()
				setIsEditing(true)
			}
		}
	}, [params])

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		formState,
		setValue,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	const handleClose = () => {
		navigate(`/employees/${employee_id}/career/work-calendars`)
	}

	const handleCreate = async (data) => {
		try {
			const response = await Service.post(`/employee-work-calendars/${employee_id}`, data)
			sendToast('Çalışma takvimi eklendi.')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleSave = async (data) => {
		try {
			const response = await Service.patch(`/employee-work-calendars/${employee_id}/${module_id}`, data)
			sendToast('Çalışma takvimi güncellendi.')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDelete = async () => {
		navigate(`/employees/${employee_id}/career/work-calendars/${module_id}/delete`)
	}

	return (
		<Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'}>
					{isEditing ? (
						<>
							<FaIcon icon="edit" className="me-2" /> Çalışma Takvimi Düzenle
						</>
					) : (
						<>
							<FaIcon icon="plus" className="me-2" /> Çalışma Takvimi Ekle
						</>
					)}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Row>
						<Col md={12} className="mb-3">
							<Form.Label>Çalışma Takvimi</Form.Label>
							<Controller
								name="workCalendar"
								control={control}
								rules={{
									required: 'Bu alan zorunludur.'
								}}
								render={({ field }) => (
									<CustomReactSelect
										value={field.value}
										onChange={field.onChange}
										options={workCalendars}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option._id}
									/>
								)}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.workCalendar && errors.workCalendar.message}
							</Form.Control.Feedback>
						</Col>
						<Col md={12} className="mb-3">
							<Form.Label>Başlangıç Tarihi</Form.Label>
							<Controller
								name="startDate"
								control={control}
								defaultValue={null}
								rules={{
									required: 'Bu alan zorunludur.'
								}}
								render={({ field }) => (
									<CustomDatePicker value={field.value} onChange={field.onChange} placeholder="Başlangıç Tarihi" />
								)}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.startDate && errors.startDate.message}
							</Form.Control.Feedback>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{isEditing ? (
					<>
						<DeleteButton onClick={handleDelete} />
						<SaveButton onClick={handleSubmit((d) => handleSave(d))} />
					</>
				) : (
					<>
						<CreateButton onClick={handleSubmit((d) => handleCreate(d))} />
					</>
				)}
			</Modal.Footer>
		</Modal>
	)
}

export default WorkCalendarModal
