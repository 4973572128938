export const hexToColorName = (hexColor) => {
	const colorNames = {
		'#f44336': 'kırmızı',
		'#e91e63': 'pembe',
		'#9c27b0': 'mor',
		'#9b59b6': 'açık mor',
		'#3f51b5': 'mavi',
		'#2196f3': 'açık mavi',
		'#009688': 'turkuaz',
		'#4caf50': 'yeşil',
		'#cddc39': 'limon yeşili',
		'#ffc107': 'sarı',
		'#ff9800': 'turuncu',
		'#ff5722': 'açık turuncu',
		'#795548': 'kahverengi',
		'#607d8b': 'gri',
		'#969696': 'açık gri'
	}

	const lowerCaseHexColor = hexColor.toLowerCase()
	const colorName = colorNames[lowerCaseHexColor]
	return colorName || hexColor
}
