import PropTypes from 'prop-types'
import React from 'react'
import { Nav } from 'react-bootstrap'

const TabButtonContainer = ({ children, className }) => {
	return <ul className={`nav nav-tabs tabs-line d-inline-flex ${className}`}>{children}</ul>
}

TabButtonContainer.propTypes = {
	className: PropTypes.string
}

export default TabButtonContainer
