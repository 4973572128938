import { CloseButton, DeleteButton } from 'components/fast-ui'
import { ButtonContainer, CustomReactTable, FaIcon } from 'components/ui'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useState } from 'react'
import { Card, Modal } from 'react-bootstrap'

const data = [
	{
		name: 'Dosya 1',
		description: 'Dosya 1 Açıklaması',
		uploader: 'Ahmet Yılmaz',
		uploadDate: '01.01.2021',
		actions: ''
	},
	{
		name: 'Dosya 2',
		description: 'Dosya 2 Açıklaması',
		uploader: 'Ahmet Yılmaz',
		uploadDate: '01.01.2021',
		actions: ''
	}
]

const PersonnelFileHistory = () => {
	const [showDeleteModal, setShowDeleteModal] = useState(false)

	const columns = [
		{
			Header: 'Dosya Adı',
			accessor: 'name'
		},
		{
			Header: 'Yükleyen Kişi',
			accessor: 'uploader'
		},
		{
			Header: 'Yüklenme Tarihi',
			accessor: 'uploadDate'
		},
		{
			Header: 'İşlem',
			accessor: 'actions',
			Cell: (props) => {
				return (
					<>
						<FaIcon className="text-primary me-2 cursor-pointer" icon="fas fa-download" onClick={handleDownload} />
						<FaIcon
							className="text-danger cursor-pointer"
							icon="fas fa-trash"
							onClick={() => setShowDeleteModal(true)}
						/>
					</>
				)
			}
		}
	]

	const handleDownload = () => {
		sendToast('Dosya indirme işlemi başarılı.')
	}

	const handleDelete = () => {
		sendToast('Dosya silindi')
		setShowDeleteModal(false)
	}

	return (
		<>
			<Card>
				<Card.Header>
					<Card.Title as="h5" className="mb-0">
						Özlük Dosyaları Geçmişi
					</Card.Title>
				</Card.Header>
				<Card.Body>
					<CustomReactTable data={data} columns={columns} />
				</Card.Body>
			</Card>

			<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
				<Modal.Header closeButton>
					<Modal.Title as={'h5'}>Emin misiniz?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Seçilen dosyayı silmek istediğinize emin misiniz?</p>
				</Modal.Body>
				<Modal.Footer>
					<ButtonContainer>
						<CloseButton onClick={() => setShowDeleteModal(false)} />
						<DeleteButton onClick={handleDelete} />
					</ButtonContainer>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default PersonnelFileHistory
