import React from 'react'
import { Col, Row } from 'react-bootstrap'

import AverageSalariesChart from './components/AverageSalariesChart'
import EducationLevelChart from './components/EducationLevelChart'
import EmployeeDepartmentDistribution from './components/EmployeeDepartmentDistribution'
import EmployeeDismissalChart from './components/EmployeeDismissalChart'
import EmployeeGenderDistribution from './components/EmployeeGenderDistribution'
import WorkingTypeChart from './components/WorkingTypeChart'

const StandartTab = () => {
	return (
		<Row>
			<Col md={4} className="mb-4">
				<EmployeeDepartmentDistribution />
			</Col>

			<Col md={8} className="mb-4">
				<EmployeeDismissalChart />
			</Col>

			<Col md={8} className="mb-4">
				<AverageSalariesChart />
			</Col>

			<Col md={4} className="mb-4">
				<EmployeeGenderDistribution />
			</Col>

			<Col md={4} className="mb-4">
				<WorkingTypeChart />
			</Col>

			<Col md={8} className="mb-4">
				<EducationLevelChart />
			</Col>
		</Row>
	)
}

export default StandartTab
