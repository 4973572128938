import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import { dismissalReasons } from 'constants/dismissalReasons'
import React from 'react'
import { Card } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
	responsive: true,
	maintainAspectRatio: false,
	indexAxis: 'y',
	elements: {
		bar: {
			borderWidth: 2
		}
	},
	responsive: true,
	plugins: {
		legend: {
			display: false
		}
	}
}

const labels = dismissalReasons.map((reason) => reason.label.slice(0, 30)).slice(0, 10)

export const data = {
	labels,
	datasets: [
		{
			label: 'İşten Ayrılan Kişi Sayısı',
			data: [10, 20, 30, 40],
			borderColor: 'rgb(255, 99, 132)',
			backgroundColor: 'rgba(255, 99, 132, 0.5)'
		}
	]
}

const ReasonsForDismissalChart = () => {
	return (
		<Card>
			<Card.Header>
				<Card.Title as="h5" className="mb-0">
					İşten Ayrılma Nedenleri
				</Card.Title>
			</Card.Header>
			<Card.Body>
				<Bar options={options} data={data} height={250} />
			</Card.Body>
		</Card>
	)
}

export default ReasonsForDismissalChart
