import React from 'react'

const KanbanTagItem = ({ tagName, hexColor }) => {
	return (
		<badge className="badge" style={{ backgroundColor: hexColor }}>
			{tagName}
		</badge>
	)
}

export default KanbanTagItem
