import Service from 'global/Service'
import React from 'react'

let competenceTypes = []

export const competenceTypeFilter = ({ column }) => {
	const { filterValue, setFilter } = column
	let promise = Service.get('https://api.devbont.site/api/competence-types/combo')
	promise.then((response) => {
		competenceTypes = response.data
	})

	return (
		<>
			<select
				className="form-select text-capitalize"
				onChange={(e) => setFilter(e.target.value)}
				value={filterValue || ''}
			>
				<option value="">Tür..</option>

				{competenceTypes.map((n) => (
					<option value={n.label}>{n.label}</option>
				))}
			</select>
		</>
	)
}
