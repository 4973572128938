import 'assets/vendor/libs/datatables-bs5/datatables.bootstrap5.css'
import 'assets/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css'
import 'assets/vendor/libs/datatables-buttons-bs5/buttons.bootstrap5.css'
import 'assets/vendor/libs/select2/select2.css'
import 'assets/vendor/libs/formvalidation/dist/css/formValidation.min.css'

import React from 'react'
import { useSelector } from 'react-redux'

import { EmployeeList } from './employee-list'

const Employees = () => {
	const { show } = useSelector((state) => state.ik.editor)
	return (
		<div className="container-xxl flex-grow-1 container-p-y">
			<div className="row g-4 mb-4">
				<EmployeeList />
			</div>
		</div>
	)
}

export default Employees
