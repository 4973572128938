import 'assets/css/demo.css'
import 'assets/vendor/css/rtl/core.css'
import 'assets/vendor/css/rtl/theme-bordered.css'
import 'assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css'
import 'assets/vendor/libs/typeahead-js/typeahead.css'
import 'assets/vendor/css/meno-core.css'

import { Navbar } from 'components/theme'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import LoadingBar from 'react-top-loading-bar'
import useApiStore from 'stores/apiStore'
import { disableExpanded, enableExpanded } from 'stores/siteSlice'

import Menu from '../theme/Menu'

const DefaultLayout = ({ children }) => {
	const dispatch = useDispatch()
	const { miniMenu, expanded } = useSelector((state) => state.site)
	const isLoading = useApiStore((state) => state.isLoading)

	return (
		<div className="layout-wrapper layout-content-navbar  ">
			<LoadingBar color="#086BD9" progress={isLoading ? 50 : 100} loaderSpeed={200} />
			<ToastContainer />
			<div className="layout-container">
				<Menu />
				<div className="layout-page">
					<Navbar />
					<div className="content-wrapper">{children}</div>
				</div>
			</div>
			<div
				onClick={() => {
					dispatch(disableExpanded())
				}}
				className="layout-overlay layout-menu-toggle"
			/>
			<div
				className="drag-target"
				style={{
					touchAction: 'pan-y',
					userSelect: 'none',
					WebkitUserDrag: 'none',
					WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'
				}}
			/>
		</div>
	)
}

export default DefaultLayout
