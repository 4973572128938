import { sendToast } from 'global/sendToast'
import React, { useState } from 'react'
import { createRef } from 'react'
import { useEffect } from 'react'
import { Form } from 'react-bootstrap'

import FaIcon from '../FaIcon'

const ImageFilePicker = ({ field, error, defaultImage }) => {
	const acceptedExtensions = ['.svg', '.png', '.jpg', '.jpeg']

	const [preview, setPreview] = useState(null)

	useEffect(() => {
		if (defaultImage) {
			setPreview(defaultImage)
		}
	}, [defaultImage])

	const handleFileChange = (e) => {
		const file = e.target.files[0]

		if (isValidFileType(file.name)) {
			setPreview(URL.createObjectURL(file))
			field.onChange(e.target.files)
			console.log('file', file)
		} else {
			sendToast('Geçersiz dosya türü.', false)
			console.log('Geçersiz dosya türü.')
		}
	}

	const isValidFileType = (fileName) => {
		const fileExtension = fileName.substring(fileName.lastIndexOf('.'))
		return acceptedExtensions.includes(fileExtension)
	}

	const ref = createRef()

	return (
		<>
			<div className="d-flex flex-wrap justify-content-start align-items-center gap-3">
				{preview && <img src={preview || defaultImage} alt="Preview" style={{ width: 'auto', height: '38px' }} />}
				<Form.Control
					ref={ref}
					className={`mb-2 ${preview ? 'd-none' : ''}`}
					type="file"
					isInvalid={error}
					onChange={handleFileChange}
					accept={acceptedExtensions}
				/>
				{/* edit icon */}
				<span type="button" onClick={() => ref.current.click()}>
					<FaIcon icon={'edit'} className="text-primary" />
				</span>
			</div>
		</>
	)
}

export default ImageFilePicker
