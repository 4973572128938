import { FaIcon } from 'components/ui'
import { AvatarGroup } from 'components/ui'
import Service from 'global/Service'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { setSelectedRatingID } from 'stores/ik/competence/ikCompetenceRatingSlice'

import CompetenceRatingDetailsCard from './components/CompetenceRatingDetailsCard'

const CompetenceRatingDetails = () => {
	const params = useParams()
	const { tab_name, module_name: sub_tab, module_id: competenceRatingId } = params

	const [competenceRating, setCompetenceRating] = useState(null)
	const { employee, competencies, raters, createDate, ratings, averageAnswers, averageScore, isCompleted } =
		competenceRating || {}

	const fetchData = async () => {
		try {
			const response = await Service.get(`/competence-ratings/${competenceRatingId}`)
			setCompetenceRating(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<>
			{competenceRating && (
				<CompetenceRatingDetailsCard
					employee={employee}
					competencies={competencies}
					raters={raters}
					createDate={createDate}
					ratings={ratings}
					averageAnswers={averageAnswers}
					averageScore={averageScore}
					isCompleted={isCompleted}
				/>
			)}
		</>
	)
}

export default CompetenceRatingDetails
