import React from 'react'

import DocumentItem from './DocumentItem'

const DocumentContainer = ({
	data,
	tagList,
	selectedItems,
	selectionResetDedector,
	handleClickDocumentItem,
	handleDoubleClickDocumentItem
}) => {
	const sortedList = [...data].sort((a, b) => b.isFolder - a.isFolder || b.digitalName - a.digitalName)
	return sortedList.map((document) => {
		const selectedStatus = selectedItems.some((item) => item._id === document._id)
		return (
			<>
				<DocumentItem
					data={document}
					tagList={tagList}
					onClick={handleClickDocumentItem}
					onDoubleClick={handleDoubleClickDocumentItem}
					selectionResetDedector={selectionResetDedector}
					selected={selectedStatus}
				/>
			</>
		)
	})
}

export default DocumentContainer
