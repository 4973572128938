import { ApproveButton, CreateButton, ResetButton } from 'components/fast-ui'
import { Button, CustomReactTable, DeleteModal, FaIcon } from 'components/ui'
import { differenceInDays, format } from 'date-fns'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'

import WorkCalendarModal from './components/WorkCalendarModal'

const columns = [
	{
		Header: 'Başlangıç',
		accessor: 'startDate',
		Cell: ({ row, value }) => (
			<span>
				{new Date(value).toLocaleDateString('tr-TR', {
					day: 'numeric',
					month: 'long',
					year: 'numeric'
				})}
				{row.original.isCurrent && <span className="badge bg-primary ms-2">Güncel</span>}
			</span>
		)
	},
	{
		Header: 'Bitiş',
		accessor: 'endDate',
		Cell: ({ value }) => (
			<span>
				{value
					? new Date(value).toLocaleDateString('tr-TR', {
							day: 'numeric',
							month: 'long',
							year: 'numeric'
					  })
					: '-'}
			</span>
		)
	},
	{
		Header: 'Süre',
		accessor: 'duration',
		Cell: ({ row }) => {
			const startDate = new Date(row.original.startDate)
			let endDate = row.original.endDate && new Date(row.original.endDate)
			const todayDate = new Date()

			endDate = endDate ? endDate : todayDate
			const diffInDays = differenceInDays(endDate, startDate)
			const diffInMonths = Math.floor(diffInDays / 30)
			const remainingDays = diffInDays - diffInMonths * 30
			return (
				<>
					{diffInDays > 0 ? (
						<span>
							{diffInMonths} ay {remainingDays} gün
						</span>
					) : (
						<>-</>
					)}
				</>
			)
		}
	},
	{
		Header: 'Çalışma Takvimi',
		accessor: 'workCalendar',
		Cell: ({ value }) => (
			<span>
				<Link to={`/settings/work-calendars`}>{value?.name}</Link>
			</span>
		)
	},
	{
		Header: 'İşlemler',
		accessor: 'id',
		Cell: ({ row, value }) => {
			const params = useParams()
			const { employee_id } = params

			return (
				<Link to={`/employees/${employee_id}/career/work-calendars/${row.original._id}`}>
					<span className="text-primary cursor-pointer">
						<FaIcon icon="edit" />
					</span>
				</Link>
			)
		}
	}
]

const CareerWorkCalendarTab = () => {
	const params = useParams()
	const navigate = useNavigate()
	const { employee_id, tab_name, module_name, module_id: workCalendar_id, process } = params

	const [data, setData] = useState([])

	const [showModal, setShowModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)

	const fetchData = async () => {
		try {
			const response = await Service.get(`/employee-work-calendars/${employee_id}`)
			setData(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (module_name === 'work-calendars') {
			fetchData()
		}
	}, [params])

	useEffect(() => {
		if (workCalendar_id) {
			setShowModal(true)
			if (process === 'delete') {
				setShowModal(false)
				setShowDeleteModal(true)
			} else {
				setShowDeleteModal(false)
			}
		} else {
			setShowModal(false)
			setShowDeleteModal(false)
		}
	}, [params])

	const handleAddWorkCalendar = () => {
		navigate(`/employees/${employee_id}/${tab_name}/work-calendars/add`)
	}

	const handleDeleteOk = async () => {
		try {
			await Service.delete(`/employee-work-calendars/${employee_id}/${workCalendar_id}`)
			sendToast('Çalışma takvimi silindi', true)
			navigate(`/employees/${employee_id}/${tab_name}/work-calendars`)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDeleteHide = async () => {
		navigate(`/employees/${employee_id}/${tab_name}/work-calendars/${workCalendar_id}`)
	}

	return (
		<Card>
			<Card.Header>
				<Card.Title as="h5">
					<span className="me-3">Çalışma Takvimleri</span>{' '}
					<CreateButton className="btn btn-primary btn-sm" text="Ekle" onClick={handleAddWorkCalendar} />
				</Card.Title>
			</Card.Header>
			<Card.Body>
				<CustomReactTable columns={columns} data={data} defaultSortDesc={true} defaultSortBy={'startDate'} />
			</Card.Body>
			<WorkCalendarModal show={showModal} />
			<DeleteModal show={showDeleteModal} name="Çalışma Takvimi" onOk={handleDeleteOk} onHide={handleDeleteHide} />
		</Card>
	)
}

export default CareerWorkCalendarTab
