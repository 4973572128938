import Service from 'global/Service'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import CompetenceRatingCard from './components/CompetenceRatingCard'

const CompetenceRatings = () => {
	const [competenceRatings, setCompetenceRatings] = useState([])

	const fetchCompetenceRatings = async () => {
		try {
			const response = await Service.get('/competence-ratings')
			setCompetenceRatings(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchCompetenceRatings()
	}, [])

	return (
		<>
			{competenceRatings.map((cr) => (
				<Col md={4}>
					<Link to={`/ik-competencies/results/details/${cr._id}`} key={cr._id}>
						<CompetenceRatingCard
							key={cr._id}
							employee={cr.employee}
							raters={cr.raters}
							ratings={cr.ratings}
							averageScore={cr.averageScore}
							createDate={cr.createDate}
						/>
					</Link>
				</Col>
			))}
		</>
	)
}

export default CompetenceRatings
