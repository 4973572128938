import { FaIcon } from 'components/ui'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ControlledMenu, MenuDivider, MenuItem, useMenuState } from '@szhsin/react-menu'

const Folder = ({
	data,
	_id,
	onClick,
	onContextMenu,
	openIcon,
	closeIcon,
	opened,
	name,
	children,
	isHaveChild,
	infoClick,
	renameClick,
	disableSelectionsAndParents,
	forMove = false
}) => {
	const [menuProps, toggleMenu] = useMenuState()
	const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })

	const [openStatus, setOpenStatus] = useState(opened)
	const [hoverStatus, setHoverStatus] = useState(false)
	const [disabledStatus, setDisabledStatus] = useState(false)

	const { selectedItems } = useSelector((state) => state.document.main)
	const { targetFolderID } = useSelector((state) => state.document.context.move)
	const selectedFolderID = useSelector((state) => state.document.tree.selectedFolder.content._id)
	const { selectedFolder, focusedFolder } = useSelector((state) => state.document.tree)

	const defaultButtonClasses = 'd-flex align-items-center my-1 py-1 px-2 ms-1 d-inline'
	const hoverButtonClasses = 'd-flex align-items-center my-1 py-1 px-2 ms-1 bg-light rounded d-inline '

	const handleClick = () => {
		setOpenStatus(!openStatus)
		!disabledStatus && onClick && onClick(_id)
	}

	const handleMouseEnter = () => {
		setHoverStatus(true)
	}

	const handleMouseLeave = () => {
		setHoverStatus(false)
	}

	const handleToggle = () => {
		setOpenStatus(!openStatus)
	}

	const handleContextMenu = (e) => {
		if (onContextMenu) {
			e.preventDefault()
			setAnchorPoint({ x: e.clientX, y: e.clientY })
			toggleMenu(true)
			onContextMenu(data)
		}
	}

	const handleClose = () => {
		toggleMenu(false)
	}

	const infoHandleClick = () => {
		infoClick && infoClick(_id)
	}

	const renameHandleClick = () => {
		renameClick && renameClick(_id, name)
	}

	useEffect(() => {
		if (disableSelectionsAndParents) {
			const selectedItemsMatch = selectedItems?.some((item) => item._id === _id || item.parentID === _id)
			const focusedFolderMatch = focusedFolder.content._id === _id || focusedFolder.content.parent_id === _id
			setDisabledStatus(selectedItemsMatch || focusedFolderMatch)
		}
	}, [selectedItems, focusedFolder])

	return (
		<>
			<li>
				<div className="d-flex justify-content-start align-items-center">
					<span onClick={handleToggle} type="button" className={`p-1 ${!children && 'd-none'}`}>
						{isHaveChild && (
							<i
								className={`fa-regular ${openStatus ? 'fa-chevron-down' : 'fa-chevron-right'} fa-xs`}
								style={!children ? { opacity: 0 } : !hoverStatus ? { opacity: 0.6 } : { opacity: 1 }}
							/>
						)}
					</span>
					<div
						type="button"
						className={hoverStatus ? hoverButtonClasses : defaultButtonClasses}
						style={disabledStatus ? { opacity: 0.5 } : { opacity: 1 }}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						onClick={handleClick}
						onContextMenu={handleContextMenu}
					>
						{openStatus && <img className="me-2" src={openIcon} style={{ width: 18, height: 18 }} alt="" />}
						{!openStatus && <img className="me-2" src={closeIcon} style={{ width: 18, height: 18 }} alt="" />}

						<span>
							{forMove ? (
								targetFolderID === _id ? (
									<b>{name}</b>
								) : (
									<>{name}</>
								)
							) : selectedFolderID === _id ? (
								<b>{name}</b>
							) : (
								<>{name}</>
							)}
						</span>
					</div>
				</div>
				<ul className="list-unstyled ms-3">
					<div className={!openStatus && 'd-none'}>{!disabledStatus && children}</div>
				</ul>
			</li>
			<ControlledMenu className="position-absolute" {...menuProps} anchorPoint={anchorPoint} onClose={handleClose}>
				<MenuItem onClick={handleClick}>
					<span>
						<FaIcon icon="folder" size="sm" className="me-2" />
						Aç
					</span>
				</MenuItem>
				<MenuDivider />
				<MenuItem onClick={infoHandleClick} data-bs-toggle="modal" data-bs-target="#folderInfoModal">
					<span>
						<FaIcon icon="circle-info" size="sm" className="me-2" />
						Bilgi
					</span>
				</MenuItem>
				<MenuDivider />
				<MenuItem onClick={renameHandleClick} data-bs-toggle="modal" data-bs-target="#renameModal">
					<span>
						<FaIcon icon="pen-to-square" size="sm" className="me-2" />
						Adlandır
					</span>
				</MenuItem>
				<MenuItem data-bs-toggle="modal" data-bs-target="#moveModal">
					<span>
						<FaIcon icon="arrows-up-down-left-right" size="sm" className="me-2" /> Taşı
					</span>
				</MenuItem>
				<MenuDivider />
				<MenuItem data-bs-toggle="modal" data-bs-target="#deleteFromTreeModal">
					<span>
						<FaIcon icon="trash" size="sm" className="me-2" /> Sil
					</span>
				</MenuItem>
			</ControlledMenu>
		</>
	)
}

export default Folder
