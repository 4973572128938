import { TabButton, TabButtonContainer } from 'components/ui'
import Service from 'global/Service'
import { showErrorMessage } from 'global/sendError'
import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'

import CompetenceRatingDetails from './competence-rating-details'
import CompetenceRatings from './competence-ratings'

const CompetenceResultsTab = () => {
	const params = useParams()
	const { tab_name, module_name: sub_tab, module_id: competenceRatingId } = params

	const [tab, setTab] = useState('list')

	useEffect(() => {
		if (sub_tab) {
			setTab(sub_tab)
		}
	}, [params])

	return (
		<>
			{tab === 'list' && <CompetenceRatings />}
			{tab === 'details' && <CompetenceRatingDetails />}
		</>
	)
}

export default CompetenceResultsTab
