import { GlobalFilter } from 'components/ui'
import React, { useEffect, useMemo } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { refreshEmployeeList, setSelectedEmployees } from 'stores/ik/competence/ikCompetenceAssessmentSlice'

import { Checkbox } from './Checkbox'
import COLUMNS from './Columns'

export const CompetenceEmployees = (props) => {
	const { employeeList } = useSelector((state) => state.ikCompetenceAssessment)
	const { selectedEmployeeIDs } = useSelector((state) => state.ikCompetenceAssessment)

	const data = useMemo(() => employeeList)
	const columns = useMemo(() => COLUMNS, [])
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(refreshEmployeeList())
	}, [])

	const initialState = {}

	const tableInstance = useTable(
		{
			columns,
			data,
			initialState
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((columns) => {
				return [
					{
						id: 'selection',
						Header: ({ getToggleAllRowsSelectedProps }) => <Checkbox {...getToggleAllRowsSelectedProps()} />,
						Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />
					},
					...columns
				]
			})
		}
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		flatRows,
		prepareRow,
		page,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		pageOptions,
		gotoPage,
		pageCount,
		setPageSize,
		state,
		setGlobalFilter,
		selectedFlatRows
	} = tableInstance

	const { globalFilter, pageIndex, pageSize } = state

	useEffect(() => {
		dispatch(setSelectedEmployees(selectedFlatRows.map((row) => row.original)))
	}, [selectedFlatRows])

	useEffect(() => {
		setPageSize(5)
	}, [])

	return (
		<Card>
			<Card.Header className="border-bottom">
				<Card.Title as="h4">Çalışanlar</Card.Title>
				<small>Hangi çalışanların yetkinlikleri değerlendirilecek?</small>
			</Card.Header>
			<div className="card-datatable table-responsive">
				<div className="dataTables_wrapper dt-bootstrap5 no-footer">
					<div className="d-flex flex-wrap align-items-center mt-3 mx-3">
						<div className="me-2 mb-3">
							<label>
								<select className="form-select" value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
									{[5, 10, 25, 50, 100].map((pageSize) => (
										<option key={pageSize} value={pageSize}>
											{pageSize}
										</option>
									))}
								</select>
							</label>
						</div>
						<div className="mb-3 me-2">
							<label>
								<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
							</label>
						</div>
						<div>
							<div className="d-flex flex-wrap align-items-center justify-content-between">
								{headerGroups.map((headerGroup) =>
									headerGroup.headers.map((column) => {
										const shouldRenderFilter =
											column.canFilter &&
											(column.id === 'position.location.name' ||
												column.id === 'position.department.name' ||
												column.id === 'position.title.name')
										return shouldRenderFilter ? <div className="me-2 mb-3">{column.render('Filter')}</div> : null
									})
								)}
							</div>
						</div>
					</div>
					<Table {...getTableProps()} className="dataTable border-top" striped responsive>
						<thead>
							{headerGroups.map((headerGroup) => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column) => (
										<th className="control sorting_disabled" {...column.getHeaderProps(column.getSortByToggleProps())}>
											{column.render('Header')}
											<span>{column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}</span>
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{page.map((row) => {
								prepareRow(row)
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
										})}
									</tr>
								)
							})}
						</tbody>
					</Table>
					{/* <pre>
                        <code>
                            {JSON.stringify(
                                {
                                    selectedFlatRows: selectedFlatRows.map((row) => row.original.employeeID),
                                },
                                null,
                            )}
                        </code>
                    </pre> */}
					<div className="row mx-2">
						<div className="col-sm-12 col-md-6">
							<div className="dataTables_info" role="status" aria-live="polite">
								Toplam {employeeList.length} personel arasından {rows.length} sonuç
							</div>
						</div>
						<div className="col-sm-12 col-md-6">
							<div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
								<ul className="pagination">
									<li
										className={'paginate_button page-item previous ' + (canPreviousPage ? '' : 'disabled')}
										onClick={() => previousPage()}
									>
										<a href="#" className="page-link">
											Önceki
										</a>
									</li>
									<li
										className={'paginate_button page-item next ' + (canNextPage ? '' : 'disabled')}
										onClick={() => nextPage()}
									>
										<a href="#" className="page-link">
											Sonraki
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Card>
	)
}
