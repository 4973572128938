import anonimProfilePhoto from 'assets/img/avatars/anonim-profile-photo.jpg'
import { FaIcon } from 'components/ui'
import { format } from 'date-fns'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { disableReadonlyEditor, enableReadonlyEditor, selectEmployee, showEditor } from 'stores/ikSlice'

import { departmentFilter, locationFilter, statusFilter, titleFilter } from './ColumnFilter'

const COLUMNS = [
	{
		Header: 'Personel',
		accessor: 'fullName',
		Cell: (cell) => {
			return (
				<div className="d-flex justify-content-start align-items-center">
					<div className="avatar-wrapper">
						<div className="avatar avatar-sm me-3">
							{cell.row.original.profilePhotoUrl == null ? (
								<img src={anonimProfilePhoto} alt="Avatar" className="rounded-circle" />
							) : (
								<img src={`${cell.row.original.profilePhotoUrl}`} alt="Avatar" className="rounded-circle" />
							)}
						</div>
					</div>
					<div className="d-flex flex-column">
						<a href="#" className="text-body text-truncate">
							<span className="fw-semibold">{cell.row.original.fullName}</span>
						</a>
						<small className="text-muted">{cell.row.original.workMail}</small>
					</div>
				</div>
			)
		}
	},
	{
		Header: 'İş Maili',
		accessor: 'workMail',
		Cell: (cell) => {
			return <></>
		}
	},
	{
		Header: 'Şube',
		accessor: 'position.location.name',
		Filter: locationFilter
	},

	{
		Header: 'Departman',
		accessor: 'position.department.name',
		Filter: departmentFilter
	},
	{
		Header: 'Ünvan',
		accessor: 'position.title.name',
		Cell: (cell) => {
			return <span className="fw-semibold">{cell.row.original?.position?.title?.name}</span>
		},
		Filter: titleFilter
	},
	{
		id: 'workingStatus',
		Header: 'Statü',
		accessor: (d) => {
			return d.workingStatus ? 'working' : 'leaved'
		},
		Cell: (cell) => {
			if (cell.row.original?.workingStatus) {
				return <span className="badge bg-label-secondary">Aktif</span>
			} else {
				return <span className="badge bg-label-danger">Ayrıldı</span>
			}
		},
		Filter: statusFilter
	},
	{
		Header: 'İşlem',
		Cell: (cell) => {
			const dispatch = useDispatch()
			return (
				<div className="d-inline-block">
					{/* 	<button
						className="btn btn-sm btn-icon hide-arrow"
						onClick={() => {
							dispatch(selectEmployee(cell.row.original.employeeID))
							dispatch(enableReadonlyEditor())
							dispatch(showEditor())
						}}
					>
						<i className="bx bx-show" />
					</button> */}
					<Link to={'/employees/' + cell.row.original._id}>
						<button
							className="btn btn-sm btn-icon hide-arrow"
							onClick={() => {
								dispatch(selectEmployee(cell.row.original.employeeID))
								dispatch(disableReadonlyEditor())
								dispatch(showEditor())
							}}
						>
							<i className="bx bxs-edit" />
						</button>
					</Link>
				</div>
			)
		}
	}
]

export default COLUMNS
