import { CloseButton, SaveButton } from 'components/fast-ui'
import { ButtonContainer, CustomPopperTooltip, CustomReactSelect, FaIcon } from 'components/ui'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Col, Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

const exampleData = [
	{
		_id: '3',
		fullName: 'İstanbul',
		struct: 'Şube',
		structHexColor: '#0ae'
	},
	{
		_id: '4',
		fullName: 'Anadolu',
		struct: 'Bölge',
		structHexColor: '#e004d5'
	},
	{
		_id: '5',
		fullName: 'Talha',
		role: 'Çalışan',
		roleDetail: 'İşe Alım',
		roleHexColor: '#e91e63'
	}
]

const AuthorizationModal = ({ show, onClose }) => {
	const [selectedEmployees, setSelectedEmployees] = useState([])
	const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState([])
	const [activeIcons, setActiveIcons] = useState({})
	const [filteredData, setFilteredData] = useState(exampleData)

	useEffect(() => {
		const filterData = exampleData.filter(
			(item) => !selectedEmployees.find((selectedItem) => selectedItem.value === item._id)
		)
		setFilteredData(filterData)
	}, [selectedEmployees])

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState: { errors, isDirty, isValid }
	} = useForm({
		mode: 'onChange'
	})

	const handleClose = () => {
		onClose()
	}

	const onSubmit = async (data) => {
		handleClose()
		sendToast('Yetkilendirme başarıyla kaydedildi.', true)
	}

	const handleDeleteEmployee = () => {
		const newSelectedEmployees = [...selectedEmployees]
		newSelectedEmployees.splice(selectedEmployeeIndex, 1)
		setSelectedEmployees(newSelectedEmployees)
		setSelectedEmployeeIndex(null)
	}

	const handleIconClick = (employeeId, icon) => {
		setActiveIcons((prevActiveIcons) => {
			const updatedActiveIcons = {
				...prevActiveIcons,
				[employeeId]: {
					...prevActiveIcons[employeeId],
					[icon]: !prevActiveIcons[employeeId]?.[icon]
				}
			}
			if (icon === 'edit' || icon === 'trash') {
				updatedActiveIcons[employeeId].eye = true
			}
			return updatedActiveIcons
		})
	}

	const formatOptionLabel = ({ label, role, roleHexColor, struct, structHexColor }) => (
		<div style={{ display: 'flex' }}>
			<div className="d-flex align-items-center gap-2">
				<div style={{ backgroundColor: structHexColor }} className="badge ">
					{struct}
				</div>
				<div style={{ backgroundColor: roleHexColor }} className="badge">
					{role}
				</div>
				<strong>{label}</strong>
			</div>
		</div>
	)

	return (
		<Modal show={show} onHide={handleClose} size="md" centered>
			<Modal.Header closeButton>
				<Modal.Title as="h5">Yetkilendirme</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Col md={12} className="mb-3">
					<Form.Label>Kurala şirket birimi, rol veya çalışan dahil et.</Form.Label>
					<CustomReactSelect
						placeholder="Ara..."
						onChange={(selectedOption) => {
							setSelectedEmployees([...selectedEmployees, ...selectedOption])
						}}
						options={filteredData.map((item) => ({
							label: item.fullName,
							struct: item.struct,
							structHexColor: item.structHexColor,
							role: item.role,
							roleHexColor: item.roleHexColor,
							roleDetail: item.roleDetail,
							value: item._id
						}))}
						value={[]}
						isMulti={true}
						formatOptionLabel={formatOptionLabel}
						isClearable={false}
					/>
				</Col>
				<Col md={12} className="mb-3">
					<Form.Label>Sıralama</Form.Label>
					{selectedEmployees.map((employee, index) => (
						<div key={employee.value} className="d-flex align-items-center justify-content-between mb-2">
							<div className="d-flex align-items-center w-100 border rounded  p-2 me-3">
								<div className="d-flex align-items-center  w-100 me-2 gap-2">
									<span style={{ backgroundColor: employee.structHexColor }} className="badge">
										{employee.struct}
									</span>
									<span style={{ backgroundColor: employee.roleHexColor }} className="badge">
										{employee.role}
									</span>
									<span>{employee.label}</span>
									{employee.role === 'Çalışan' && (
										<CustomPopperTooltip text={employee.roleDetail}>
											<FaIcon className="cursor-pointer" icon="fa fa-info-circle" size="md" />
										</CustomPopperTooltip>
									)}
								</div>
								<div className="d-flex align-items-center gap-2 me-2">
									<CustomPopperTooltip text="Görüntüleme">
										<FaIcon
											className={`cursor-pointer ${activeIcons[employee.value]?.eye ? 'text-primary' : 'text-muted'}`}
											icon="fa fa-eye"
											size="lg"
											onClick={() => handleIconClick(employee.value, 'eye')}
										/>
									</CustomPopperTooltip>
									<CustomPopperTooltip text="Düzenleme">
										<FaIcon
											className={`cursor-pointer ${activeIcons[employee.value]?.edit ? 'text-success' : 'text-muted'}`}
											icon="fa fa-edit"
											size="lg"
											onClick={() => handleIconClick(employee.value, 'edit')}
										/>
									</CustomPopperTooltip>
									<CustomPopperTooltip text="Silme">
										<FaIcon
											className={`cursor-pointer ${activeIcons[employee.value]?.trash ? 'text-danger' : 'text-muted'}`}
											icon="fa fa-trash"
											size="lg"
											onClick={() => handleIconClick(employee.value, 'trash')}
										/>
									</CustomPopperTooltip>
									<CustomPopperTooltip text="Yetkilendirme" placement="top">
										<FaIcon
											className={`cursor-pointer ${activeIcons[employee.value]?.gear ? 'text-primary' : 'text-muted'}`}
											icon="fa fa-gear"
											size="lg"
											onClick={() => handleIconClick(employee.value, 'gear')}
										/>
									</CustomPopperTooltip>
								</div>
							</div>
							<span>
								<FaIcon
									icon="fa fa-times"
									className="text-danger cursor-pointer"
									onClick={handleDeleteEmployee}
									size="lg"
								/>
							</span>
						</div>
					))}
					{selectedEmployees.length === 0 && (
						<div className="d-flex align-items-center justify-content-center h-100">
							<div className="text-muted">Henüz bir personel seçilmedi.</div>
						</div>
					)}
				</Col>
			</Modal.Body>
			<Modal.Footer>
				<ButtonContainer>
					<CloseButton onClick={handleClose} />
					<SaveButton onClick={handleSubmit(onSubmit)} disabled={selectedEmployees.length === 0} />
				</ButtonContainer>
			</Modal.Footer>
		</Modal>
	)
}

export default AuthorizationModal
