import { FaIcon } from 'components/ui'
import { formatDate } from 'global/functions/formatDate'
import React from 'react'
import { Card } from 'react-bootstrap'

const MyCompetenceRatingEmployeeCard = ({ employee, competencies, createDate, isCompleted }) => {
	return (
		<Card className="h-100">
			<Card.Header className="border-bottom">
				<div className="d-flex align-items-center me-3">
					<img src="https://i.pravatar.cc/300" alt="Avatar" className="rounded-circle me-3" width={54} />
					<div className="card-title mb-0">
						<h5 className="mb-0">{employee.fullName}</h5>
						<small className="text-muted">Yetkinlik Değerlendirme Formu</small>
					</div>
				</div>
			</Card.Header>

			<Card.Body>
				<div className="d-flex flex-wrap justify-content-center gap-4 py-4 mb-3 border-bottom">
					<div className="text-center">
						<h6 className="mb-2">Yetkinlik</h6>
						<div className="avatar avatar-xl">
							<span className="avatar-initial rounded-circle bg-label-info">{competencies.length}</span>
						</div>
					</div>
				</div>

				<div className="info-container border-bottom pb-2 mb-3">
					<small className="text-muted">Hakkında</small>
					<div>
						<ul className="list-unstyled mt-3">
							<li className="d-flex flex-wrap align-items-center mb-3">
								<i className="bx bx-user me-2" />
								<span className="fw-semibold me-2">İşe Başlama Tarihi:</span>{' '}
								<span>{formatDate(employee.startDate)}</span>
							</li>
							<li className="d-flex flex-wrap align-items-center mb-3">
								<i className="bx bx-flag me-2" />
								<span className="fw-semibold me-2">Lokasyon:</span> <span>{employee.position?.location?.name}</span>
							</li>
							<li className="d-flex flex-wrap align-items-center mb-3">
								<i className="bx bx-briefcase me-2" />
								<span className="fw-semibold me-2">Departman:</span> <span>{employee.position?.department?.name}</span>
							</li>
							<li className="d-flex flex-wrap align-items-center mb-3">
								<i className="bx bx-star me-2" />
								<span className="fw-semibold me-2">Ünvan:</span> <span>{employee.position?.title?.name}</span>
							</li>
						</ul>
					</div>
				</div>

				<div className="info-container">
					<div className="mb-3 d-flex flex-wrap align-items-center justify-content-between">
						<small className="text-muted">Oluşturma Tarihi:</small>
						<span className="badge bg-label-secondary fw-semibold mx-2">
							<FaIcon icon="calendar" className="me-2" />
							{formatDate(createDate)}
						</span>
					</div>
					<div className="mb-3 d-flex flex-wrap align-items-center justify-content-between">
						<small className="text-muted">Durum:</small>
						{isCompleted ? (
							<span className="badge bg-label-success fw-semibold mx-2">
								<FaIcon icon="check" className="me-2" />
								Tamamlandı
							</span>
						) : (
							<span className="badge bg-label-warning fw-semibold mx-2">
								<FaIcon icon="clock" className="me-2" />
								Bekleniyor
							</span>
						)}
					</div>
				</div>
			</Card.Body>
		</Card>
	)
}

export default MyCompetenceRatingEmployeeCard
