import { Button, FaIcon } from 'components/ui'
import React from 'react'
import { forwardRef } from 'react'

export const CreateButton = ({ text = 'Oluştur', ...rest }) => {
	return <Button type="primary" icon="circle-plus" text={text} {...rest} />
}

export const SaveButton = ({ text = 'Kaydet', ...rest }) => {
	return <Button type="primary" icon="floppy-disk" text={text} {...rest} />
}

export const ExamineButton = ({ text = 'İncele', ...rest }) => {
	return <Button type="primary" icon="eye" text={text} {...rest} />
}

export const ApproveButton = ({ text = 'Onayla', ...rest }) => {
	return <Button type="primary" icon="circle-check" text={text} {...rest} />
}

export const RejectButton = ({ text = 'Reddet', ...rest }) => {
	return <Button type="danger" icon="circle-x" text={text} {...rest} />
}

export const DangerApproveButton = ({ text = 'Onayla', ...rest }) => {
	return <Button type="danger" icon="circle-check" text={text} {...rest} />
}

export const SendButton = ({ text = 'Gönder', ...rest }) => {
	return <Button type="primary" icon="paper-plane" text={text} {...rest} />
}

export const ContinueButton = ({ text = 'Devam Et', ...rest }) => {
	return <Button type="primary" icon="arrow-right" text={text} {...rest} />
}

export const DeleteButton = ({ text = 'Sil', icon, ...rest }) => {
	return <Button type="danger" icon="trash" text={text} {...rest} />
}

export const ResetButton = ({ text = 'Sıfırla', ...rest }) => {
	return <Button type="outline-secondary" icon="arrow-rotate-right" text={text} {...rest} />
}

export const DownloadButton = ({ text = 'İndir', ...rest }) => {
	return <Button type="outline-secondary" icon="download" text={text} {...rest} />
}

export const CloseButton = ({ text = 'İptal', ...rest }) => {
	return <Button type="outline-secondary" text={text} {...rest} />
}

export const AddButton = ({ text = 'Ekle', ...rest }) => {
	return <Button type="primary" icon="plus" text={text} {...rest} />
}

export const GiveUpButton = ({ text = 'Vazgeç', ...rest }) => {
	return <Button type="outline-secondary" icon="circle-x" text={text} {...rest} />
}

export const EditButton = ({ text = 'Düzenle', ...rest }) => {
	return <Button type="outline-secondary" icon="pencil" text={text} {...rest} />
}

export const DocumentButton = ({ text = 'Eylem', icon, ...rest }) => {
	return (
		<div className="flex-wrap">
			<Button type="outline-secondary" icon={icon || 'file'} text={text} {...rest} />
		</div>
	)
}

export const ExportButton = ({ text = 'Export', icon, ...rest }) => {
	return (
		<div className="flex-wrap">
			<Button type="outline-secondary" icon={icon || 'file-export'} text={text} {...rest} />
		</div>
	)
}

export const ImportButton = ({ text = 'Import', icon, ...rest }) => {
	return (
		<div className="flex-wrap">
			<Button type="outline-secondary" icon={icon || 'file-import'} text={text} {...rest} />
		</div>
	)
}

export const ProjectButton = forwardRef(({ text = 'İşlem', icon, ...rest }, ref) => {
	return (
		<div className="flex-wrap">
			<Button
				type="light"
				icon={icon || 'project-diagram'}
				text={text}
				style={{ lineHeight: '1rem' }}
				className="shadow-none w-100 text-start mb-2"
				ref={ref}
				{...rest}
			/>
		</div>
	)
})
