import { CloseButton, DangerApproveButton, DeleteButton } from 'components/fast-ui'
import { ButtonContainer, FaIcon } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import useRoleStore from 'stores/settings/roleStore'

const RoleDeleteModal = ({ show, data, onClose }) => {
	const navigate = useNavigate()
	const fetchRoles = useRoleStore((state) => state.fetchRoles)

	const handleClose = () => {
		navigate(`/settings/roles`)
		fetchRoles()
	}

	const handleDelete = async () => {
		try {
			const response = await Service.delete(`/roles/${data._id}`)
			sendToast('Rol başarıyla silindi.', true)
			handleClose()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'}>
					<FaIcon icon="trash" className="me-2" />
					Rol Sil
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FaIcon icon="circle-dot" style={{ color: data?.hexColor }} className="me-2" />
				{data?.name} rolünü silmek istediğinize emin misiniz? Bu işlem geri alınamaz.
			</Modal.Body>
			<Modal.Footer>
				<ButtonContainer>
					<CloseButton onClick={handleClose} />
					<DangerApproveButton onClick={handleDelete} />
				</ButtonContainer>
			</Modal.Footer>
		</Modal>
	)
}

export default RoleDeleteModal
