import React from 'react'

const FaIcon = ({ type = 'regular', icon, size, flip, className, ...rest }) => {
	const classes = ['fa', `fa-${type}`, `fa-${icon}`]
	if (size) classes.push(`fa-${size}`)
	if (flip) classes.push(`fa-flip-${flip}`)
	if (className) classes.push(className)

	return <i className={classes.join(' ')} {...rest}></i>
}

export default FaIcon
