import chroma from 'chroma-js'
import { CloseButton, CreateButton, DeleteButton, SaveButton } from 'components/fast-ui'
import { ButtonContainer, FaIcon, ImageFilePicker } from 'components/ui'
import CustomReactSelect from 'components/ui/CustomReactSelect'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { Fragment, useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { ErrorMessage } from '@hookform/error-message'

const needsApproval = [
	{
		value: 'vacation',
		label: 'İzin'
	},
	{
		value: 'document',
		label: 'Doküman'
	}
]

const CompanyUnitModal = ({ show, onHide }) => {
	const params = useParams()
	const navigate = useNavigate()

	const { tab_name, item_id, sub_item_id } = params
	const [companyStructure, setCompanyStructure] = useState({})
	const [companyUnit, setCompanyUnit] = useState({})

	const [parentCompanyUnits, setParentCompanyUnits] = useState([])
	const [roles, setRoles] = useState([])
	const [approvalProcesses, setApprovalProcesses] = useState([])
	const [holidayCalendars, setHolidayCalendars] = useState([])
	const [vacationRules, setVacationRules] = useState([])
	const [competencePackets, setCompetencePackets] = useState([])

	const onSubmit = (data) => {
		// form submit işlemi
	}

	const fetchRoles = async () => {
		try {
			const response = await Service.get('/roles')
			setRoles(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchCompetencePackets = async () => {
		try {
			const response = await Service.get('/competence-packets')
			setCompetencePackets(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchVacationRules = async () => {
		try {
			const response = await Service.get('/vacation-rules')
			setVacationRules(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchParentCompanyUnits = async () => {
		try {
			if (companyStructure._id) {
				const sequence = companyStructure.sequence - 1
				if (sequence > 0) {
					const response = await Service.get(`/company-structures/sequence/${sequence}/company-units`)
					setParentCompanyUnits(response.data)
				}
			}
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchHolidayCalendars = async () => {
		try {
			const response = await Service.get('/holiday-calendars')
			setHolidayCalendars(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchApprovalProcesses = async () => {
		try {
			const response = await Service.get('/approval-processes')
			setApprovalProcesses(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchCompanyStructure = async () => {
		try {
			const response = await Service.get(`/company-structures/${item_id}`)
			setCompanyStructure(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchCompanyUnit = async () => {
		try {
			const response = await Service.get(`/company-units/${sub_item_id}`)
			setCompanyUnit(response.data)
			reset(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (tab_name === 'company-structure' && item_id) {
			fetchCompanyStructure()
			if (sub_item_id === 'add') {
				setIsEditing(false)
			} else {
				fetchCompanyUnit()
				setIsEditing(true)
			}
		}
	}, [params])

	useEffect(() => {
		fetchRoles()
		fetchApprovalProcesses()
		fetchHolidayCalendars()
		fetchVacationRules()
		fetchCompetencePackets()
	}, [])

	const colourStyles = {
		control: (styles) => ({ ...styles, backgroundColor: 'white' }),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			const color = chroma(data.hexColor)
			return {
				...styles,
				backgroundColor: isDisabled
					? undefined
					: isSelected
					? data.hexColor
					: isFocused
					? color.alpha(0.1).css()
					: undefined,
				color: isDisabled
					? '#ccc'
					: isSelected
					? chroma.contrast(color, 'white') > 2
						? 'white'
						: 'black'
					: data.hexColor,
				cursor: isDisabled ? 'not-allowed' : 'default',

				':active': {
					...styles[':active'],
					backgroundColor: !isDisabled ? (isSelected ? data.hexColor : color.alpha(0.3).css()) : undefined
				}
			}
		},
		multiValue: (styles, { data }) => {
			const color = chroma(data.hexColor)
			return {
				...styles,
				backgroundColor: color.alpha(0.1).css()
			}
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: data.hexColor
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: data.hexColor,
			':hover': {
				backgroundColor: data.hexColor,
				color: 'white'
			}
		})
	}

	const [isEditing, setIsEditing] = useState(false)
	const [isHoldingOrCompany, setIsHoldingOrCompany] = useState(false)
	const [isCompany, setIsCompany] = useState(false)

	useEffect(() => {
		fetchParentCompanyUnits()
		if (companyStructure.isLocked) {
			setIsHoldingOrCompany(true)
		} else {
			setIsHoldingOrCompany(false)
		}
		if (companyStructure.type === 'company') {
			setIsCompany(true)
		} else {
			setIsCompany(false)
		}
	}, [companyStructure])

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState,
		watch,
		formState: { errors }
	} = useForm({
		defaultValues: {
			approvalProcesses: [
				{
					approvalProcess: null,
					type: 'vacation'
				}
			]
		},
		mode: 'onChange'
	})

	//approvalProcesses için array field
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'approvalProcesses'
	})

	const handleCreate = async (data) => {
		const body = {
			...data,
			companyStructure: item_id
		}
		try {
			const response = await Service.post(`/company-units`, body)
			sendToast('Şirket birimi oluşturuldu.', true)
			onHide()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleSave = async (data) => {
		const formData = new FormData()

		if (data.name) formData.append('name', data.name)
		if (data.parent) formData.append('parent', data.parent)
		if (data.phone) formData.append('phone', data.phone)
		if (data.emailDomain) formData.append('emailDomain', data.emailDomain)
		if (data.mersisNumber) formData.append('mersisNumber', data.mersisNumber)
		if (data.sgkRegistrationNumber) formData.append('sgkRegistrationNumber', data.sgkRegistrationNumber)
		if (data.address) formData.append('address', data.address)

		if (data.roles) formData.append('roles', JSON.stringify(data.roles))
		if (data.holidayCalendar) formData.append('holidayCalendar', JSON.stringify(data.holidayCalendar))
		if (data.competencePacket) formData.append('competencePacket', JSON.stringify(data.competencePacket))
		if (data.vacationRule) formData.append('vacationRule', JSON.stringify(data.vacationRule))

		if (data.approvalProcesses) formData.append('approvalProcesses', JSON.stringify(data.approvalProcesses))

		if (data.logo && data.logo.length > 0) {
			formData.append('logo', data.logo[0], data.logo[0].name)
		}

		if (data.icon && data.icon.length > 0) {
			formData.append('icon', data.icon[0], data.icon[0].name)
		}

		try {
			const response = await Service.patch(`/company-units/${sub_item_id}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			sendToast('Şirket birimi güncellendi.', true)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<Modal
			show
			onHide={onHide}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			size={isHoldingOrCompany ? 'lg' : 'md'}
		>
			<Modal.Header closeButton>
				<div className="content-left">
					<Modal.Title as={'h5'}>
						<FaIcon icon="building" className="me-2" />

						{isEditing ? <>{companyStructure.name} Düzenle</> : <>Yeni {companyStructure.name} Oluştur</>}
					</Modal.Title>
				</div>
			</Modal.Header>
			<Modal.Body>
				<Form autoComplete="off">
					<Row>
						<Col md={isHoldingOrCompany ? 6 : 12} className="mb-3">
							<Row>
								<Col md={12}>
									<p>{companyStructure.name} Bilgileri</p>
								</Col>
								<Col md={6} className="mb-3">
									<Controller
										name="name"
										control={control}
										defaultValue=""
										rules={{ required: 'Gerekli alan.' }}
										render={({ field }) => (
											<Form.Group>
												<Form.Label>{companyStructure.name} Adı</Form.Label>
												<Form.Control type="text" {...field} isInvalid={errors.name} />
												<Form.Control.Feedback type="invalid">
													<ErrorMessage
														errors={errors}
														name="name"
														render={({ message }) => <div className="invalid-feedback">{message}</div>}
													/>
												</Form.Control.Feedback>
											</Form.Group>
										)}
									/>
								</Col>
								{!isHoldingOrCompany && (
									<Col md={6} className="mb-3">
										<Controller
											name="parent"
											control={control}
											render={({ field }) => (
												<Form.Group>
													<Form.Label>Bağlı olduğu üst birim (opsiyonel)</Form.Label>
													<CustomReactSelect
														isClearable
														value={field.value}
														onChange={field.onChange}
														ref={field.ref}
														placeholder="Üst birim.."
														options={parentCompanyUnits}
														getOptionValue={(option) => option._id}
														getOptionLabel={(option) => option.name}
													/>
													<Form.Control.Feedback type="invalid">
														<ErrorMessage errors={errors} name="parent" />
													</Form.Control.Feedback>
												</Form.Group>
											)}
										/>
									</Col>
								)}

								{isHoldingOrCompany && (
									<>
										<Col md={6} className="mb-3">
											<Controller
												name="phone"
												control={control}
												defaultValue=""
												rules={{
													required: 'Gerekli alan.',
													pattern: {
														value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i,
														message: 'Geçersiz telefon.'
													}
												}}
												render={({ field }) => (
													<Form.Group>
														<Form.Label>Telefon</Form.Label>
														<Form.Control type="tel" {...field} isInvalid={errors.phone} />
														<Form.Control.Feedback type="invalid">
															<ErrorMessage
																errors={errors}
																name="phone"
																render={({ message }) => <div className="invalid-feedback">{message}</div>}
															/>
														</Form.Control.Feedback>
													</Form.Group>
												)}
											/>
										</Col>
										<Col md={6} className="mb-3">
											<Controller
												name="emailDomain"
												control={control}
												defaultValue=""
												render={({ field }) => (
													<Form.Group>
														<Form.Label>E-posta Uzantısı</Form.Label>
														<Form.Control type="text" {...field} isInvalid={errors.emailDomain} />
														<Form.Control.Feedback type="invalid">
															<ErrorMessage errors={errors} name="emailDomain" />
														</Form.Control.Feedback>
													</Form.Group>
												)}
											/>
										</Col>
										<Col md={6} className="mb-3">
											<Controller
												name="mersisNumber"
												control={control}
												defaultValue=""
												render={({ field }) => (
													<Form.Group>
														<Form.Label>Mersis Numarası</Form.Label>

														<Form.Control type="text" {...field} isInvalid={errors.mersisNumber} />
														<Form.Control.Feedback type="invalid">
															<ErrorMessage errors={errors} name="mersisNumber" />
														</Form.Control.Feedback>
													</Form.Group>
												)}
											/>
										</Col>

										<Col md={6} className="mb-3">
											<Controller
												name="sgkRegistrationNumber"
												control={control}
												defaultValue=""
												render={({ field }) => (
													<Form.Group>
														<Form.Label>SGK Kayıt Numarası</Form.Label>
														<Form.Control type="text" {...field} isInvalid={errors.sgkRegistrationNumber} />
														<Form.Control.Feedback type="invalid">
															<ErrorMessage errors={errors} name="sgkRegistrationNumber" />
														</Form.Control.Feedback>
													</Form.Group>
												)}
											/>
										</Col>
									</>
								)}

								<Col md={12} className="mb-3">
									<Controller
										name="address"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<Form.Group>
												<Form.Label>Adres</Form.Label>
												<Form.Control type="text" {...field} isInvalid={errors.address} />
												<Form.Control.Feedback type="invalid">
													<ErrorMessage errors={errors} name="address" />
												</Form.Control.Feedback>
											</Form.Group>
										)}
									/>
								</Col>
							</Row>
						</Col>
						<Col md={isHoldingOrCompany ? 6 : 12} className="mb-3">
							<Row>
								<Col md={12}>
									<p>Bu birime bağlı çalışanlar için:</p>
								</Col>

								<Col md={6} className="mb-3">
									<Controller
										name="holidayCalendar"
										control={control}
										render={({ field }) => (
											<Form.Group>
												<Form.Label>Tatil Takvimi</Form.Label>
												<CustomReactSelect
													isClearable
													value={field.value}
													onChange={field.onChange}
													ref={field.ref}
													placeholder="Tatil takvimi.."
													options={holidayCalendars}
													getOptionValue={(option) => option._id}
													getOptionLabel={(option) => option.name}
												/>
												<Form.Control.Feedback type="invalid">
													<ErrorMessage errors={errors} name="holidayCalendar" />
												</Form.Control.Feedback>
											</Form.Group>
										)}
									/>
								</Col>

								<Col md={6} className="mb-3">
									<Controller
										name="competencePacket"
										control={control}
										render={({ field }) => (
											<Form.Group>
												<Form.Label>Yetkinlik Paketi</Form.Label>
												<CustomReactSelect
													isClearable
													value={field.value}
													onChange={field.onChange}
													ref={field.ref}
													placeholder="Yetkinlik Paketi.."
													options={competencePackets}
													getOptionValue={(option) => option._id}
													getOptionLabel={(option) => option.name}
												/>
												<Form.Control.Feedback type="invalid">
													<ErrorMessage errors={errors} name="competencePacket" />
												</Form.Control.Feedback>
											</Form.Group>
										)}
									/>
								</Col>

								<Col md={6} className="mb-3">
									<Controller
										name="vacationRule"
										control={control}
										render={({ field }) => (
											<Form.Group>
												<Form.Label>İzin Kuralı</Form.Label>
												<CustomReactSelect
													isClearable
													value={field.value}
													onChange={field.onChange}
													ref={field.ref}
													placeholder="İzin kuralı.."
													options={vacationRules}
													getOptionValue={(option) => option._id}
													getOptionLabel={(option) => option.name}
												/>
												<Form.Control.Feedback type="invalid">
													<ErrorMessage errors={errors} name="vacationRule" />
												</Form.Control.Feedback>
											</Form.Group>
										)}
									/>
								</Col>
								<Col md={6} className="mb-3">
									<Controller
										name="roles"
										control={control}
										render={({ field }) => (
											<Form.Group>
												<Form.Label>Roller</Form.Label>
												<CustomReactSelect
													isMulti
													isClearable
													value={field.value}
													onChange={field.onChange}
													ref={field.ref}
													placeholder="Roller.."
													options={roles}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option._id}
													styles={colourStyles}
												/>
												<Form.Control.Feedback type="invalid">
													<ErrorMessage errors={errors} name="roles" />
												</Form.Control.Feedback>
											</Form.Group>
										)}
									/>
								</Col>

								{needsApproval.map((item, index) => (
									<Fragment key={item._id}>
										<Col md={6} className="mb-3">
											<input
												type="hidden"
												{...register(`approvalProcesses[${index}].type`)}
												defaultValue={item.value}
											/>
											<Controller
												name={`approvalProcesses[${index}].approvalProcess`}
												control={control}
												render={({ field }) => (
													<Form.Group>
														<Form.Label>{item.label} Onay Süreci</Form.Label>
														<CustomReactSelect
															isClearable
															value={field.value}
															onChange={field.onChange}
															ref={field.ref}
															placeholder="Onay süreci.."
															options={approvalProcesses}
															getOptionValue={(option) => option._id}
															getOptionLabel={(option) => option.name}
														/>
														<Form.Control.Feedback type="invalid">
															<ErrorMessage errors={errors} name={`approvalProcesses[${index}].approvalProcess`} />
														</Form.Control.Feedback>
													</Form.Group>
												)}
											/>
										</Col>
									</Fragment>
								))}
							</Row>
							{isCompany && (
								<Row>
									<Col md={12}>
										<p>Ek dosyalar:</p>
									</Col>

									<Col>
										<Controller
											name="logo"
											control={control}
											render={({ field }) => (
												<Form.Group className="mb-2">
													<Form.Label>Logo</Form.Label>
													<ImageFilePicker field={field} error={errors.logo} defaultImage={companyUnit?.logoUrl} />
													<Form.Control.Feedback type="invalid">
														<ErrorMessage errors={errors} name="logo" />
													</Form.Control.Feedback>
												</Form.Group>
											)}
										/>
										<Controller
											name="icon"
											control={control}
											render={({ field }) => (
												<Form.Group className="mb-2">
													<Form.Label>Icon</Form.Label>
													<ImageFilePicker field={field} error={errors.icon} defaultImage={companyUnit?.iconUrl} />
													<Form.Control.Feedback type="invalid">
														<ErrorMessage errors={errors} name="icon" />
													</Form.Control.Feedback>
												</Form.Group>
											)}
										/>
									</Col>
								</Row>
							)}
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{isEditing ? (
					<>
						<SaveButton onClick={handleSubmit((d) => handleSave(d))} />
					</>
				) : (
					<>
						<CreateButton onClick={handleSubmit((d) => handleCreate(d))} />
					</>
				)}
			</Modal.Footer>
		</Modal>
	)
}

export default CompanyUnitModal
