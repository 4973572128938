import { CloseButton, CreateButton, DeleteButton, SaveButton } from 'components/fast-ui'
import { CustomReactSelect } from 'components/ui'
import Service from 'global/Service'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'

const CompetencePacketModal = ({ id, show, onHide }) => {
	const [isEditing, setIsEditing] = useState(false)

	const [competencies, setCompetencies] = useState([])
	const [data, setData] = useState([])

	const fetchCompetencies = async () => {
		try {
			const res = await Service.get('/competencies')
			setCompetencies(res.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchData = async () => {
		try {
			const res = await Service.get(`/competence-packets/${id}`)
			reset(res.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (id) {
			if (id === 'add') {
				setIsEditing(false)
			} else {
				fetchData()
				setIsEditing(true)
			}
		}
	}, [id])

	useEffect(() => {
		fetchCompetencies()
	}, [])

	const handleClose = () => {
		onHide && onHide()
	}

	const handleCreate = async (data) => {
		try {
			const res = await Service.post('/competence-packets', data)
			sendToast('Yetkinlik başarıyla oluşturuldu')
			onHide && onHide()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDelete = async (data) => {
		try {
			const res = await Service.delete(`/competence-packets/${id}`)
			sendToast('Yetkinlik paketi başarıyla silindi')
			onHide && onHide()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleSave = async (data) => {
		try {
			const res = await Service.patch(`/competence-packets/${id}`, data)
			sendToast('Yetkinlik paketi başarıyla güncellendi')
			onHide && onHide()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		formState,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	const formatOptionLabel = ({ value, label, name, description, type }) => (
		<div style={{ display: 'flex' }}>
			<div className="me-2 text-wrap text-primary">
				{name}
				<div className="text-secondary">
					<small>{type.name}</small>
				</div>
			</div>
			<div className="text-wrap">{description}</div>
		</div>
	)

	return (
		<Modal show={show} onHide={handleClose} area-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title as="h5">{isEditing ? 'Yetkinlik Paketi Düzenle' : 'Yetkinlik Paketi Ekle'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-3">
						<Form.Label>Yetkinlik Paketi Adı</Form.Label>
						<Form.Control
							type="text"
							placeholder="Yetkinlik Paketi Adı"
							{...register('name', { required: 'Bu alan gerekli.' })}
						/>
						{errors.name && <Form.Text className="text-danger">{errors.name.message}</Form.Text>}
					</Form.Group>
					<Form.Group>
						<Form.Label>Yetkinlikler</Form.Label>
						<Controller
							name="competencies"
							control={control}
							rules={{ required: 'Bu alan gerekli.' }}
							render={({ field }) => (
								<CustomReactSelect
									isMulti
									options={competencies}
									placeholder="Yetkinlikler.."
									value={field.value}
									onChange={field.onChange}
									ref={field.ref}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option._id}
									formatOptionLabel={formatOptionLabel}
								/>
							)}
						/>
						{errors.type && <Form.Text className="text-danger">{errors.type.message}</Form.Text>}
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<CloseButton onClick={handleClose} />
				{isEditing ? (
					<>
						<DeleteButton onClick={handleSubmit((d) => handleDelete(d))} />
						<SaveButton onClick={handleSubmit((d) => handleSave(d))} />
					</>
				) : (
					<>
						<CreateButton onClick={handleSubmit((d) => handleCreate(d))} />
					</>
				)}
			</Modal.Footer>
		</Modal>
	)
}

export default CompetencePacketModal
