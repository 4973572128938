import { CloseButton, SendButton } from 'components/fast-ui'
import { ButtonContainer, FaIcon } from 'components/ui'
import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

const ExpenseCreateModal = ({ show, onHide }) => {
	const handleClose = () => {
		onHide && onHide()
	}

	const onSubmit = (data) => {
		console.log(data)
	}

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState,
		watch,
		formState: { errors }
	} = useForm({ mode: 'onChange' })
	return (
		<Modal show={show} onHide={handleClose} centered>
			<Modal.Header closeButton>
				<Modal.Title as="h5">
					<FaIcon icon="money-bill-transfer" className="me-2" />
					Masraf Talebi Oluştur
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Group className="mb-3">
						<Form.Label>Fişler</Form.Label>
						<Form.Control type="file" name="files" {...register('files', { required: true })} />
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Mesaj</Form.Label>
						<Form.Control as="textarea" rows={3} name="message" {...register('message', { required: true })} />
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<ButtonContainer>
					<CloseButton onClick={handleClose} />
					<SendButton onClick={handleSubmit(onSubmit)} />
				</ButtonContainer>
			</Modal.Footer>
		</Modal>
	)
}

export default ExpenseCreateModal
