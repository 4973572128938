import PropTypes from 'prop-types'
import React from 'react'
import { Nav } from 'react-bootstrap'

const TabButtonContainer = ({ children, className }) => {
	return (
		<Nav className={`flex-row flex-wrap ${className}`} style={{ columnGap: 10, rowGap: 8 }}>
			{children}
		</Nav>
	)
}

TabButtonContainer.propTypes = {
	className: PropTypes.string
}

export default TabButtonContainer
