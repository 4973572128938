import { CreateButton } from 'components/fast-ui'
import { CustomReactSelect, CustomReactTable } from 'components/ui'
import { FaIcon } from 'components/ui'
import { format } from 'date-fns'
import { tr } from 'date-fns/locale'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useMemo, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'

const columns = [
	{
		Header: 'Ad',
		accessor: 'name',
		Cell: (cell) => {
			return <span className="text-truncate d-flex align-items-center">{cell.row.original.name}</span>
		}
	},
	//başlangıç tarihi (sadece gün ve ay adı gösterilecek) türkçe
	{
		Header: 'Başlangıç Tarihi',
		accessor: 'startDate',
		Cell: (cell) => {
			return (
				<span className="text-truncate d-flex align-items-center">
					{format(new Date(cell.row.original.startDate), 'dd MMMM', { locale: tr })}
				</span>
			)
		}
	},
	//bitiş tarihi (sadece gün ve ay adı gösterilecek) türkçe
	{
		Header: 'Bitiş Tarihi',
		accessor: 'endDate',
		Cell: (cell) => {
			return (
				<span className="text-truncate d-flex align-items-center">
					{format(new Date(cell.row.original.endDate), 'dd MMMM', { locale: tr })}
				</span>
			)
		}
	},
	//başlangıç tarihi ve bitiş tarihi arasındaki gün sayısı hesaplanır math
	{
		Header: 'Gün Sayısı',
		accessor: 'dayCount',
		Cell: (cell) => {
			return (
				<span className="text-truncate d-flex align-items-center">
					{cell.row.original.type === 'full_day' && <>1 gün</>}
					{cell.row.original.type === 'half_day' && <>0.5 gün</>}
					{cell.row.original.type === 'multiple_days' && (
						<>
							{Math.round(
								(new Date(cell.row.original.endDate).getTime() - new Date(cell.row.original.startDate).getTime()) /
									(1000 * 3600 * 24)
							)}{' '}
							gün
						</>
					)}
				</span>
			)
		}
	},
	//tekrar eder
	{
		Header: 'Tekrarlayan',
		accessor: 'isRecurring',
		Cell: (cell) => {
			return (
				<span className="text-truncate d-flex align-items-center">
					{cell.row.original.isRecurring === true && <>Evet</>}
					{cell.row.original.isRecurring === false && <>Hayır</>}
				</span>
			)
		}
	},
	//edit icon
	{
		Header: 'Düzenle',
		accessor: 'edit',
		Cell: (cell) => {
			const navigate = useNavigate()
			const params = useParams()
			const { item_id } = params
			return (
				<span className="text-truncate d-flex align-items-center">
					<Link
						to={`/settings/holiday-calendars/${item_id}/${cell.row.original._id}`}
						className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
					>
						<span className="svg-icon svg-icon-md svg-icon-primary">
							<FaIcon icon="edit" />
						</span>
					</Link>
				</span>
			)
		}
	}
]

const HolidaysTable = () => {
	const params = useParams()
	const navigate = useNavigate()

	const { item_id } = params

	const [holidays, setHolidays] = useState([])
	const [filteredHolidays, setFilteredHolidays] = useState([])
	const [selectedYear, setSelectedYear] = useState(null)

	const fetchData = async () => {
		try {
			const response = await Service.get(`/holiday-calendars/${item_id}`)

			setHolidays(response.data.holidays)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (selectedYear === null) {
			setSelectedYear(new Date().getFullYear())
		}

		//seçilen yıla göre holidays filtrelenir
		const filtered = holidays.filter((holiday) => {
			const year = new Date(holiday.startDate).getFullYear()
			const isRecurring = holiday.isRecurring

			//recurring ise yıl kontrolü yapmaz
			if (isRecurring) {
				return true
			} else {
				return year === selectedYear
			}
		})

		setFilteredHolidays(filtered)
	}, [selectedYear, holidays])

	useEffect(() => {
		fetchData()
	}, [params])

	const data = useMemo(() => filteredHolidays)

	const handleNewHoliday = () => {
		navigate(`/settings/holiday-calendars/${item_id}/add`)
	}

	return (
		<Col lg={12} md={12} sm={12} xs={12}>
			<Card>
				<Card.Header>
					<Card.Title as="h5">Tatil Günleri</Card.Title>
				</Card.Header>
				<Card.Body>
					<>
						<Row className="mb-4">
							<Col md={3}>
								<CustomReactSelect
									isClearable
									options={Array.from(new Array(new Date().getFullYear() - 1970 + 1), (val, index) => index + 1970)
										.map((year) => ({
											value: year,
											label: year
										}))
										.reverse()}
									placeholder="Yıl filtrele.."
									onChange={(e) => (e ? setSelectedYear(e.value) : setSelectedYear(null))}
								/>
							</Col>
						</Row>
						<CustomReactTable data={data} columns={columns} />
					</>
				</Card.Body>
				<Card.Footer>
					<CreateButton text="Yeni Tatil Günü" onClick={handleNewHoliday} />
				</Card.Footer>
			</Card>
		</Col>
	)
}

export default HolidaysTable
