import { CloseButton, ContinueButton, CreateButton, SendButton } from 'components/fast-ui'
import { CustomFormCheck, FaIcon } from 'components/ui'
import CustomReactSelect from 'components/ui/CustomReactSelect'
import { format } from 'date-fns'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import Service from 'global/Service'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Alert, Col, Form, Modal, Row, Table } from 'react-bootstrap'
import DatePicker from 'react-flatpickr'
import { Controller, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'

import { ErrorMessage } from '@hookform/error-message'

const VacationCreateModal = ({ employeeId, adminMode, show, onHide, onSuccess }) => {
	const navigate = useNavigate()

	const [page, setPage] = useState(1)

	const [employee, setEmployee] = useState(null)
	const [employees, setEmployees] = useState([])
	const [vacationTypes, setVacationTypes] = useState([])
	const [selectedVacationType, setSelectedVacationType] = useState(null)
	const [employeeApprovalProcess, setEmployeeApprovalProcess] = useState(null)

	const fetchEmployee = async () => {
		try {
			const response = await Service.get(`/employees/${employeeId}`)
			setEmployee(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchEmployees = async () => {
		try {
			const response = await Service.get('/employees')
			setEmployees(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchEmployeeApprovalProcess = async () => {
		try {
			const response = await Service.get(`/employee-approval-processes/vacation/${employeeId}`)
			setEmployeeApprovalProcess(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchVacationTypes = async () => {
		try {
			const response = await Service.get(`/employee-vacations/types/${employeeId}`)
			setVacationTypes(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchEmployee()
		fetchEmployees()
		fetchVacationTypes()
		fetchEmployeeApprovalProcess()
	}, [])

	const handleClose = () => {
		onHide && onHide()
	}

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState,
		watch,
		formState: { errors }
	} = useForm({ mode: 'onChange' })

	const [startDate, endDate] = watch(['startDate', 'endDate'])
	const [substitute, description, type, days, skipApproval] = watch([
		'substitute',
		'description',
		'type',
		'days',
		'skipApproval'
	])

	useEffect(() => {
		setSelectedVacationType(type)
	}, [type])

	useEffect(() => {
		if (startDate && endDate && selectedVacationType) {
			fetchVacationDays()
		}
	}, [startDate, endDate])

	const fetchVacationDays = async () => {
		const body = {
			employeeId: employeeId,
			vacationTypeId: selectedVacationType._id,
			startDate: startDate,
			endDate: endDate
		}

		try {
			const response = await Service.post('/employee-vacations/calculate-days', body)
			setValue('days', response.data.vacationDays)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleContinue = async (data) => {
		const body = {
			employeeId: employeeId,
			vacationTypeId: data.type._id,
			...data
		}
		try {
			const response = await Service.post('/employee-vacations/check-days', body)
			setPage(2)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleSend = async (data) => {
		const body = {
			employeeId: employeeId,
			...data
		}
		try {
			const response = await Service.post('/vacations', body)

			sendToast('İzin talebi başarıyla oluşturuldu.')
			setPage(3)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'}>
					<FaIcon icon={'calendar'} className={'me-2'} />
					İzin Talebi Oluştur
				</Modal.Title>
			</Modal.Header>
			<div>
				{page === 1 && (
					<>
						<Modal.Body>
							<Form>
								<Row>
									<Col md={12} className="mb-3">
										<Form.Label>İzin Türü</Form.Label>
										<Controller
											name="type"
											control={control}
											rules={{ required: 'İzin türü boş bırakılamaz' }}
											render={({ field }) => (
												<CustomReactSelect
													options={vacationTypes}
													placeholder="İzin türü seçiniz"
													value={field.value}
													onChange={field.onChange}
													ref={field.ref}
													isSearchable={false}
													getOptionValue={(option) => option._id}
													getOptionLabel={(option) => option.name}
												/>
											)}
										/>
										<Form.Control.Feedback type="invalid">
											<ErrorMessage errors={errors} name="type" />
										</Form.Control.Feedback>
									</Col>
									<Col md={12} className="mb-3">
										{watch('type') && (
											<Alert variant="secondary">
												<h6 className="alert-heading mb-1">
													<div className="d-flex align-items-center justify-content-between">
														{watch('type').is_paid ? (
															<span>
																<FaIcon icon={'dollar-sign'} className={'me-2'} />
																Ücretli İzin
															</span>
														) : (
															<span>
																<FaIcon icon={'dollar-sign'} className={'me-2'} />
																Ücretsiz İzin
															</span>
														)}
														{watch('type').day_limit && (
															<span>
																<FaIcon icon={'calendar'} className={'me-2'} />
																En çok {watch('type').day_limit} gün
															</span>
														)}
													</div>
												</h6>
												<span>{watch('type').description}</span>
											</Alert>
										)}
									</Col>
									<Col md={6} className="mb-3">
										<Form.Label>İzin Başlangıç Tarihi</Form.Label>
										<Controller
											name="startDate"
											control={control}
											rules={{
												required: 'İzin bitiş tarihi boş bırakılamaz'
											}}
											render={({ field }) => (
												<DatePicker
													className="form-control"
													value={field.value}
													onChange={field.onChange}
													ref={field.ref}
													options={{
														allowInput: true,
														enableTime: true,
														defaultHour: 9,
														time_24hr: true,
														dateFormat: 'd-m-Y H:i',
														minuteIncrement: 5,
														locale: { ...Turkish }
													}}
												/>
											)}
										/>
										<Form.Control.Feedback type="invalid">
											<ErrorMessage errors={errors} name="startDate" />
										</Form.Control.Feedback>
									</Col>

									<Col md={6} className="mb-3">
										<Form.Label>İzin Bitiş Tarihi</Form.Label>
										<Controller
											name="endDate"
											control={control}
											rules={{
												required: 'İzin bitiş tarihi boş bırakılamaz'
											}}
											render={({ field }) => (
												<DatePicker
													className="form-control"
													value={field.value}
													onChange={field.onChange}
													ref={field.ref}
													options={{
														allowInput: true,
														enableTime: true,
														defaultHour: 18,
														time_24hr: true,
														dateFormat: 'd-m-Y H:i',
														minuteIncrement: 5,
														locale: { ...Turkish },
														minDate: startDate && new Date(startDate)
													}}
												/>
											)}
										/>
										<Form.Control.Feedback type="invalid">
											<ErrorMessage errors={errors} name="endDate" />
										</Form.Control.Feedback>
									</Col>
									<Col md={6} className="mb-3">
										<Form.Label>İzin Süresi (gün)</Form.Label>

										<Controller
											name="days"
											control={control}
											render={({ field }) => (
												<Form.Control
													{...field}
													type="number"
													value={field.value}
													onChange={field.onChange}
													disabled={!adminMode}
												/>
											)}
										/>
									</Col>

									<Col md={6} className="mb-3">
										<Form.Label className="me-1">Yerine Bakacak Kişi</Form.Label>
										<Controller
											name="substitute"
											control={control}
											rules={{
												required: {
													value: selectedVacationType && selectedVacationType.is_substitute_required,
													message: 'Yerine bakacak kişi seçiniz'
												}
											}}
											render={({ field }) => (
												<CustomReactSelect
													placeholder="Çalışan seçiniz"
													isSearchable={true}
													options={employees}
													value={field.value}
													onChange={field.onChange}
													ref={field.ref}
													getOptionValue={(option) => option._id}
													getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
												/>
											)}
										/>
										<Form.Control.Feedback type="invalid">
											<ErrorMessage errors={errors} name="substitute" />
										</Form.Control.Feedback>
									</Col>
									<Col md={12} className="mb-3">
										<Form.Label>İzin Açıklaması</Form.Label>
										<Controller
											name="description"
											control={control}
											rules={{
												required: {
													value: selectedVacationType && selectedVacationType.is_message_required,
													message: 'İzin açıklaması boş bırakılamaz'
												}
											}}
											render={({ field }) => (
												<Form.Control {...field} as="textarea" rows={3} placeholder="İzin açıklaması giriniz" />
											)}
										/>
										<Form.Control.Feedback type="invalid">
											<ErrorMessage errors={errors} name="description" />
										</Form.Control.Feedback>
									</Col>
									{adminMode && (
										<Col md={12} className="mb-3">
											<Controller
												name="skipApproval"
												control={control}
												render={({ field }) => (
													<CustomFormCheck
														id="skipApproval"
														field={field}
														type="checkbox"
														label="Onay Sürecini Atla"
														value={field.value}
													>
														<Form.Text className="text-muted">İzin onaylanmış olarak işaretlenecektir.</Form.Text>
													</CustomFormCheck>
												)}
											/>
										</Col>
									)}
								</Row>
							</Form>
						</Modal.Body>
						<Modal.Footer>
							<CloseButton onClick={handleClose} />
							<ContinueButton
								onClick={handleSubmit((d) => handleContinue(d))}
								disabled={days ? days > selectedVacationType.max_days || days < selectedVacationType.min_days : true}
							/>
						</Modal.Footer>
					</>
				)}
				{page === 2 && (
					<>
						<Modal.Body>
							<Alert variant="light">
								<Alert.Heading>{`${employee?.firstName} ${employee?.lastName}`}</Alert.Heading>
								{adminMode ? (
									<p>
										{days} gün izin talebinde bulunuyor.{' '}
										{skipApproval
											? 'İzin onaylanmış olarak işaretlenecek ve izin süresi hesaptan düşecektir.'
											: 'İzin talebi onaylandığında izin süresi hesaptan düşecektir.'}
									</p>
								) : (
									<p>
										{days} gün izin talebinde bulunuyorsunuz. İzin talebiniz onaylandığında izin süreniz hesabınızdan
										düşülecektir.
									</p>
								)}
							</Alert>

							<Table striped bordered hover>
								<tbody>
									<tr>
										<td>İzin Başlangıç Tarihi</td>
										<td>{startDate && format(new Date(startDate), 'dd.MM.yyyy HH:mm')}</td>
									</tr>
									<tr>
										<td>İzin Bitiş Tarihi</td>
										<td>{endDate && format(new Date(endDate), 'dd.MM.yyyy HH:mm')}</td>
									</tr>
									<tr>
										<td>İzin Süresi</td>
										<td>{days} gün</td>
									</tr>
									<tr>
										<td>İzin Açıklaması</td>
										<td>{description}</td>
									</tr>
									<tr>
										<td>Yerine Bakacak Kişi</td>
										<td>{substitute ? substitute.firstName + ' ' + substitute.lastName : 'Yok'}</td>
									</tr>
									<tr>
										<td>İzin Türü</td>
										<td>{type ? type.name : 'Yok'}</td>
									</tr>
								</tbody>
							</Table>
						</Modal.Body>
						<Modal.Footer>
							<CloseButton text="Geri" onClick={() => setPage(1)} />
							{adminMode ? (
								<CreateButton onClick={handleSubmit((d) => handleSend(d))} />
							) : (
								<SendButton onClick={handleSubmit((d) => handleSend(d))} />
							)}
						</Modal.Footer>
					</>
				)}
				{page === 3 && (
					<>
						<Modal.Body>
							<Alert variant="light">
								{adminMode ? (
									<>
										<Alert.Heading>İzin Talebi Başarıyla Oluşturuldu</Alert.Heading>
										<Link to="/vacations/requestes">İzinler sayfasına git</Link>
									</>
								) : (
									<>
										<Alert.Heading>İzin Talebiniz Başarıyla Gönderildi</Alert.Heading>
										<p>İzin talebiniz yöneticiniz tarafından onaylandığında izin süreniz hesabınızdan düşülecektir.</p>
										<Link to="/profile/vacations">İzinlerim sayfasına git</Link>
									</>
								)}
							</Alert>
						</Modal.Body>
						<Modal.Footer>
							<CloseButton text="Tamam" onClick={handleClose} />
						</Modal.Footer>
					</>
				)}
			</div>
		</Modal>
	)
}

export default VacationCreateModal
