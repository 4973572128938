import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import useAuthStore from 'stores/authStore'

const PrivateRoutes = () => {
	const isLoading = useAuthStore((state) => state.isLoading)
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated)

	if (isLoading) {
		return <div>Loading...</div>
	}

	return <>{isAuthenticated ? <Outlet /> : <Navigate to="/auth/login" />}</>
}

export default PrivateRoutes
