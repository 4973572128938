import 'assets/vendor/libs/dropzone/dropzone.css'

import { DocumentButton } from 'components/fast-ui'
import { FaIcon } from 'components/ui'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
	cleanTrash,
	deleteFromTrashSelectedDocuments,
	searchItem,
	setContextRenameID,
	setContextRenameValue,
	setMainDetailedView,
	setMainSearchShowStatus,
	setMainSearchValue,
	setMainSelectedItems,
	setTreeNewFolderDigitalName,
	undoFromTrashSelectedDocuments,
	updateAllWithItemsFrom
} from 'stores/documentSlice'

import AuthorizationModal from '../modals/AuthorizationModal'

const DocumentTop = () => {
	const dispatch = useDispatch()
	const [showAuthorizationModal, setShowAuthorizationModal] = useState(false)

	const { detailedView, search, selectedItems, itemsFrom, trashItems } = useSelector((state) => state.document.main)

	const resetFolderName = () => {
		dispatch(setTreeNewFolderDigitalName(''))
	}

	const handleResetSelections = () => {
		dispatch(setMainSelectedItems([]))
	}

	const handleCleanTrash = () => {
		if (itemsFrom === 'trash' && !search.showStatus) {
			dispatch(cleanTrash())
		}
	}

	const handleUndoTrash = () => {
		dispatch(undoFromTrashSelectedDocuments())
	}

	const handleDeleteTrash = () => {
		dispatch(deleteFromTrashSelectedDocuments())
	}

	const handleRename = () => {
		dispatch(setContextRenameID(selectedItems[0]._id))
		dispatch(setContextRenameValue(selectedItems[0].digitalName))
	}

	useEffect(() => {
		if (search.value?.length > 0) {
			dispatch(setMainSearchShowStatus(true))
			dispatch(searchItem(search.value))
		} else {
			dispatch(setMainSearchShowStatus(false))
		}
	}, [search.value, dispatch])

	const itemsFromTrash = itemsFrom === 'trash'
	const itemsFromTag = itemsFrom === 'tag'
	const selectedAnyItems = selectedItems.length !== 0
	const selectedSingleItem = selectedItems.length === 1
	const isFolderSelected = selectedSingleItem && selectedItems[0].isFolder
	const showAuthorizationButton = isFolderSelected && itemsFrom !== 'tag' && !search.showStatus

	return (
		<>
			<Col xl={12} className="d-flex justify-content-between pt-2 pb-2 rounded-top border flex-wrap gap-2">
				<div className="d-flex flex-wrap" style={{ gap: 8 }}>
					{itemsFromTrash ? (
						<>
							{search.showStatus === false && selectedAnyItems ? (
								<>
									<DocumentButton
										icon="rotate-left"
										text="Geri Yükle"
										data-bs-toggle="modal"
										data-bs-target="#undoTrashModal"
									/>
									<DocumentButton
										icon="eraser"
										text="Kalıcı olarak sil"
										data-bs-toggle="modal"
										data-bs-target="#deletePermanentModal"
									/>
								</>
							) : (
								<>
									<DocumentButton
										icon="brush"
										text="Çöp kutusunu boşalt"
										disabled={itemsFrom === 'tag' || search.showStatus || trashItems.length === 0}
										data-bs-dismiss="modal"
										data-bs-toggle="modal"
										data-bs-target="#cleanTrashModal"
									/>
								</>
							)}
						</>
					) : (
						<>
							{selectedAnyItems ? (
								<>
									<DocumentButton
										icon="arrows-up-down-left-right"
										text="Taşı"
										data-bs-toggle="modal"
										data-bs-target="#moveModal"
									/>
									<DocumentButton
										onClick={handleRename}
										icon="edit"
										text="Adlandır"
										data-bs-toggle="modal"
										data-bs-target="#renameModal"
										disabled={selectedItems.length !== 1}
									/>
									<DocumentButton icon="trash" text="Sil" data-bs-toggle="modal" data-bs-target="#deleteModal" />
									<DocumentButton icon="gear" text="Yetki" onClick={() => setShowAuthorizationModal(true)} />
									<DocumentButton
										icon="rectangle-history-circle-plus"
										text="Revize"
										data-bs-toggle="modal"
										data-bs-target="#reviseModal"
										disabled={!selectedSingleItem || itemsFrom === 'tag' || search.showStatus}
										hidden={selectedSingleItem && selectedItems[0].isFolder}
									/>
								</>
							) : (
								<>
									<DocumentButton
										onClick={resetFolderName}
										icon="folder-plus"
										text="Yeni Klasör"
										data-bs-toggle="modal"
										data-bs-target="#newFolderModal"
										disabled={itemsFrom === 'tag' || search.showStatus}
									/>
									<DocumentButton
										icon="upload"
										text="Dosya Yükle"
										data-bs-toggle="modal"
										data-bs-target="#fileUploadModal"
										disabled={itemsFrom === 'tag' || search.showStatus}
									/>

									<DocumentButton
										icon="gear"
										text="Yetki"
										onClick={() => setShowAuthorizationModal(true)}
										disabled={itemsFrom === 'tag' || search.showStatus || !showAuthorizationButton}
										hidden={!showAuthorizationButton}
									/>
								</>
							)}
						</>
					)}
				</div>

				<div className="d-flex flex-wrap">
					{selectedAnyItems ? (
						<DocumentButton onClick={handleResetSelections} icon="xmark" text="Seçimi temizle" />
					) : (
						<div className="d-flex align-items-center gap-2">
							<Form.Control
								type="search"
								placeholder="Ara..."
								className="w-100 me-1"
								onChange={(e) => {
									dispatch(setMainSearchValue(e.target.value))
								}}
								value={search.value || ''}
							/>
							<div className="border rounded">
								<FaIcon
									onClick={() => dispatch(setMainDetailedView(!detailedView))}
									icon={detailedView ? 'table-list' : 'grid-2'}
									size="lg"
									className="cursor-pointer p-3"
								/>
							</div>
							<div className="border rounded">
								<FaIcon
									onClick={() => {
										dispatch(updateAllWithItemsFrom())
									}}
									icon="arrows-rotate"
									size="lg"
									className="cursor-pointer p-3"
								/>
							</div>
						</div>
					)}
				</div>
			</Col>
			{showAuthorizationModal && (
				<AuthorizationModal show={showAuthorizationModal} onClose={() => setShowAuthorizationModal(false)} />
			)}
		</>
	)
}

export default DocumentTop
