import React from 'react'

const GlobalFilter = ({ filter, setFilter }) => {
	return (
		<span>
			<input
				type="search"
				className="form-control"
				placeholder="Ara.."
				value={filter || ''}
				onChange={(e) => setFilter(e.target.value)}
			/>
		</span>
	)
}

export default GlobalFilter
