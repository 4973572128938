import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import React from 'react'
import Flatpickr from 'react-flatpickr'

const CustomDatePicker = ({ value, ref, onChange }) => {
	return (
		<Flatpickr
			className="form-control"
			value={value}
			ref={ref}
			onChange={onChange}
			placeholder="DD-MM-YYYY"
			options={{
				allowInput: true,
				dateFormat: 'd-m-Y',
				locale: { ...Turkish }
			}}
		/>
	)
}

export default CustomDatePicker
