import { CloseButton, SaveButton } from 'components/fast-ui'
import { ButtonContainer } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { CirclePicker } from 'react-color'
import { useParams } from 'react-router-dom'

const CompanyStructureColorModal = ({ onHide }) => {
	const params = useParams()
	const { item_id: companyStructureId } = params

	const [color, setColor] = useState()

	const handleColorChange = (newColor) => {
		setColor(newColor.hex)
	}

	const handleSave = async () => {
		const body = {
			hexColor: color
		}
		try {
			const response = await Service.patch(`/company-structures/${companyStructureId}/change-color`, body)
			sendToast('Renk değiştirildi.', true)
			onHide && onHide()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleClose = () => {
		onHide && onHide()
	}

	return (
		<Modal show onHide={handleClose} centered size="md">
			<Modal.Header closeButton>
				<Modal.Title as="h5">Renk Seç</Modal.Title>
			</Modal.Header>
			<Modal.Body className="d-flex justify-content-center">
				<CirclePicker color={color} onChange={handleColorChange} />
			</Modal.Body>
			<Modal.Footer>
				<ButtonContainer>
					<CloseButton variant="secondary" onClick={handleClose} />
					<SaveButton variant="primary" onClick={handleSave} disabled={color ? false : true} />
				</ButtonContainer>
			</Modal.Footer>
		</Modal>
	)
}

export default CompanyStructureColorModal
