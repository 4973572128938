import React from 'react'

const AvatarGroup = ({ employees }) => {
	return (
		<div className="d-flex align-items-center">
			<ul className="list-unstyled d-flex align-items-center avatar-group mb-0">
				{employees.map((employee, index) => (
					<li
						data-bs-toggle="tooltip"
						data-popup="tooltip-custom"
						data-bs-placement="top"
						title={employee.fullName}
						className="avatar avatar-sm pull-up"
						key={index}
					>
						{employee.profilePhotoUrl ? (
							<img className="rounded-circle" src={employee.profilePhotoUrl} alt="Avatar" />
						) : (
							<span class="avatar-initial rounded-circle bg-label-secondary">
								{`${employee.firstName[0]} ${employee.lastName[0]}`}
							</span>
						)}
					</li>
				))}
				{employees.length > 4 && (
					<li>
						<small className="text-muted ms-1">+{employees.length - 4}</small>
					</li>
				)}
			</ul>
		</div>
	)
}

export default AvatarGroup
