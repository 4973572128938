import Service from 'global/Service'

import { createSlice } from '@reduxjs/toolkit'

export const ikCompetenceTypeDefinitionSlice = createSlice({
	name: 'ikCompetenceTypeDefinition',
	initialState: {
		competenceType: {
			list: [],
			selected: {
				id: 0,
				name: null
			},
			status: {
				showStatus: false,
				content: {
					statusCode: null,
					statusMessage: null
				}
			}
		},
		colorTagList: []
	},
	reducers: {
		setCompetenceTypeList: (state, action) => {
			state.competenceType.list = action.payload
		},
		setSelectedCompetenceType: (state, action) => {
			state.competenceType.selected = action.payload
		},
		setSelectedCompetenceTypeName: (state, action) => {
			state.competenceType.selected.name = action.payload
		},
		setCompetenceTypeStatusContent: (state, action) => {
			state.competenceType.status.content = action.payload
		}
	}
})

export const {
	setCompetenceTypeList,
	setSelectedCompetenceType,
	setSelectedCompetenceTypeName,
	setCompetenceTypeStatusContent
} = ikCompetenceTypeDefinitionSlice.actions

export const refreshCompetenceTypeList = () => (dispatch) => {
	let promise = Service.get('https://api.devbont.site/api/competence-types')
	promise.then((response) => {
		dispatch(setCompetenceTypeList(response.data))
	})
}

export const selectCompetenceType = (competenceID) => (dispatch) => {
	let promise = Service.get(`https://api.devbont.site/api/competence-types/${competenceID}`)
	promise.then((response) => {
		const data = response.data[0]
		dispatch(
			setCompetenceTypeStatusContent({
				status_code: null,
				status_message: null
			})
		)
		dispatch(setSelectedCompetenceType(data))
	})
}

export const saveCompetenceType = (CompetenceDetails) => (dispatch) => {
	const body = CompetenceDetails
	let promise = Service.post(`https://api.devbont.site/api/competence-types`, body)
	promise.then((response) => {
		dispatch(setCompetenceTypeStatusContent(response.data))
		dispatch(refreshCompetenceTypeList())
	})
}

export const deleteCompetenceType = (competenceID) => (dispatch) => {
	let promise = Service.delete(`https://api.devbont.site/api/competence-types/${competenceID}`)
	promise.then((response) => {
		dispatch(setCompetenceTypeStatusContent(response.data))
		dispatch(refreshCompetenceTypeList())
	})
}

export const newCompetenceType = () => (dispatch) => {
	dispatch(
		setCompetenceTypeStatusContent({
			status_code: null,
			status_message: null
		})
	)
	dispatch(
		setSelectedCompetenceType({
			id: 0,
			name: ''
		})
	)
}

export default ikCompetenceTypeDefinitionSlice.reducer
