import React from 'react'
import { ColorRing, Vortex } from 'react-loader-spinner'

const LoaderSpinner = () => {
	return (
		<>
			{/* <ColorRing
			visible={true}
			height="80"
			width="80"
			ariaLabel="blocks-loading"
			wrapperStyle={{}}
			wrapperClass="blocks-wrapper"
			colors={['#086BD9', '#4690E3', '#84B5EC', '#C1DAF6', '#FFFFFF']}
		/> */}
			<Vortex
				visible={true}
				height="80"
				width="80"
				ariaLabel="vortex-loading"
				wrapperStyle={{}}
				wrapperClass="vortex-wrapper"
				colors={['#086BD9', '#086BD9', '#086BD9', '#4690E3', '#84B5EC', '#C1DAF6', '#FFFFFF']}
			/>
		</>
	)
}

export default LoaderSpinner
