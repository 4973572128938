import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import Service from 'global/Service'
import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { Doughnut, Pie } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: 'bottom',
			labels: {
				generateLabels: function (chart) {
					const data = chart.data
					if (data.labels.length && data.datasets.length) {
						const value = data.datasets[0].data.reduce((a, b) => a + b)
						return [
							{
								text: `${value} Çalışan`,
								fillStyle: '#686B71'
							}
						]
					}
					return []
				}
			}
		}
	}
} /* 
const labels = ['Satış', 'Pazarlama', 'Muhasebe', 'İnsan Kaynakları', 'Teknik', 'Yönetim']

const data = {
	labels,
	datasets: [
		{
			label: '# of Votes',
			data: [12, 19, 3, 5, 2, 2],
			backgroundColor: [
				'rgba(255, 99, 132, 0.2)',
				'rgba(54, 162, 235, 0.2)',
				'rgba(255, 206, 86, 0.2)',
				'rgba(75, 192, 192, 0.2)',
				'rgba(153, 102, 255, 0.2)',
				'rgba(255, 159, 64, 0.2)'
			],
			borderColor: [
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 206, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 159, 64, 1)'
			],
			borderWidth: 1
		}
	]
}
 */
const NumberOfLeavesByAgeGroup = () => {
	const [data, setData] = useState({})

	const fetchData = async () => {
		const response = await Service.get(`/charts/ik/employee-distribution`)
		setData(response.data)
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<Card>
			<Card.Header>
				<Card.Title as="h5" className="mb-0">
					Yaş Gruplarına Göre Ayrılma Sayısı
				</Card.Title>
			</Card.Header>
			<Card.Body>{data.labels && <Pie data={data} options={options} height={250} />}</Card.Body>
		</Card>
	)
}

export default NumberOfLeavesByAgeGroup
