import React from 'react'
import { Container } from 'react-bootstrap'

import ExpensesRequests from './components/expenses-requests'
import ExpensesRequestsList from './components/expenses-requests-list'

const Expenses = () => {
	return (
		<>
			<Container fluid="xxl" className="flex-grow-1 container-p-y">
				<ExpensesRequests />
				<ExpensesRequestsList />
			</Container>
		</>
	)
}

export default Expenses
