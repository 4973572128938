import anonimProfilePhoto from 'assets/img/avatars/anonim-profile-photo.jpg'
import { Tooltip } from 'components/ui'
import React from 'react'

const KanbanAssigned = ({ members }) => {
	return (
		<div className="avatar-group d-flex align-items-center assigned-avatar">
			{members?.map((member) => (
				<Tooltip content={member?.fullName} key={member.id}>
					<div className="avatar avatar-xs">
						<img
							src={member.profilePhotoUrl || anonimProfilePhoto}
							alt="profile"
							className="rounded-circle pull-up"
							width="32"
							height="32"
						/>
					</div>
				</Tooltip>
			))}
		</div>
	)
}

export default KanbanAssigned
