import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import { create } from 'zustand'

const useProjectStore = create((set, get) => ({
	project: null,
	setProject: (project) => set({ project }),
	fetchProject: async (project_id) => {
		try {
			const response = await Service.get(`/projects/${project_id}`)
			set({ project: response.data })
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	},
	refreshProject: async () => {
		const { project } = useProjectStore.getState()
		try {
			const response = await Service.get(`/projects/${project._id}`)
			set({ project: response.data })
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	},
	resetProject: () => set({ project: null })
}))

export default useProjectStore
