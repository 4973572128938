import anonimProfilePhoto from 'assets/img/avatars/anonim-profile-photo.jpg'
import { ProjectButton } from 'components/fast-ui/Buttons'
import { Button, CustomMarkdownEditor, EditableText, FaIcon, Tooltip } from 'components/ui'
import Service from 'global/Service'
import { formatDate } from 'global/functions/formatDate'
import { hexToColorName } from 'global/functions/hexToColorName'
import { sendToast } from 'global/sendToast'
import React, { useState } from 'react'
import { useMemo } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { Col, Form, Modal, ProgressBar, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { withReact } from 'slate-react'
import useAuthStore from 'stores/authStore'
import useProjectTaskStore from 'stores/projectTaskStore'

import TaskActivity from './components/TaskActivity'
import TaskArchive from './components/TaskArchive'
import TaskCheckLists from './components/TaskCheckLists'
import TaskCopy from './components/TaskCopy'
import TaskDatePopper from './components/TaskDatePopper'
import TaskListPopper from './components/TaskListPopper'
import TaskMemberPopper from './components/TaskMemberPopper'
import TaskTagPopper from './components/TaskTagPopper'

const ProjectTaskModal = () => {
	const editor = useMemo(() => withHistory(withReact(createEditor())), [])

	const [date, setDate] = useState([])

	const navigate = useNavigate()
	const params = useParams()
	const { workspace_id, project_id, task_id } = params

	const user = useAuthStore((state) => state.user)

	const fetchTask = useProjectTaskStore((state) => state.fetchProjectTask)
	const task = useProjectTaskStore((state) => state.projectTask)
	const resetTask = useProjectTaskStore((state) => state.resetProjectTask)
	const refreshTask = useProjectTaskStore((state) => state.refreshProjectTask)

	const taskPopper = useProjectTaskStore((state) => state.projectTaskPopper)
	const setTaskPopper = useProjectTaskStore((state) => state.setProjectTaskPopper)

	const memberTopButtonRef = useRef(null)
	const memberRightButtonRef = useRef(null)

	const tagsTopButtonRef = useRef(null)
	const tagsRightButtonRef = useRef(null)

	const listButtonRef = useRef(null)
	const dateButtonRef = useRef(null)

	const handleTitleChange = async (title) => {
		const body = { title }

		try {
			const response = await Service.patch(`/project-tasks/${task_id}/change-title`, body)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDescriptionChange = async (description) => {
		const body = { description }

		try {
			const response = await Service.patch(`/project-tasks/${task_id}/change-description`, body)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchDate = async () => {
		try {
			const response = await Service.get(`/project-tasks/${task_id}/date`)
			setDate(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleClose = () => {
		resetTask()
		setTaskPopper(null)
		navigate(`/projects/${workspace_id}/${project_id}`)
	}

	useEffect(() => {
		if (task_id) {
			fetchDate()
			fetchTask(task_id)
		}
	}, [params])

	const handleCompletedCheckListItemChange = async (event, itemId) => {
		const isChecked = event.target.checked

		const body = {
			isCompleted: isChecked
		}

		try {
			const response = await Service.patch(`/project-tasks/${task_id}/dates-is-completed`, body)
			refreshTask()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleFollowClick = async () => {
		try {
			const response = await Service.post(`/project-tasks/${task_id}/follow`)
			refreshTask()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleUnFollowClick = async () => {
		try {
			const response = await Service.post(`/project-tasks/${task_id}/unfollow`)
			refreshTask()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleSpaceClick = (e) => {}

	const handlePopperCloseClick = () => {
		setTaskPopper(null)
	}

	return (
		<Modal show={true} onHide={handleClose} centered size="lg" onClick={handleSpaceClick}>
			<Modal.Header closeButton className="border-0 mb-0 pb-0">
				<div className="d-flex flex-column gap-2">
					<Modal.Title as="h5">
						{task && task.title && <EditableText value={task.title} onBlur={handleTitleChange} />}
					</Modal.Title>
					<h6 className="mb-0 fw-light">
						<span className="text-decoration-underline">{task?.column?.title}</span>
						<span className="me-2"> listesinde</span>
						<FaIcon icon="eye" size="sm" />
					</h6>
				</div>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col md={9} className="d-flex flex-column gap-3">
						<div className="d-flex flex-wrap justify-content-start gap-3">
							<div>
								<h6>Üyeler</h6>
								<div className="d-flex align-items-center justify-content-center gap-2">
									{task?.members?.map((member) => (
										<Tooltip content={member?.fullName} key={member.id}>
											<img
												src={member.profilePhotoUrl || anonimProfilePhoto}
												alt="profile"
												className="rounded-circle"
												width="32"
												height="32"
											/>
										</Tooltip>
									))}
									<Button
										type="light"
										className="rounded-circle d-flex align-items-center justify-content-center shadow-none"
										size="sm"
										icon="plus"
										style={{ width: '32px', height: '32px' }}
										ref={memberTopButtonRef}
										onClick={() => setTaskPopper('member-top')}
									/>

									<TaskMemberPopper
										buttonRef={memberTopButtonRef}
										show={taskPopper === 'member-top'}
										onClose={handlePopperCloseClick}
									/>
								</div>
							</div>
							<div>
								<h6>Etiketler</h6>
								<div className="d-flex align-items-center gap-2 w-100">
									{task?.tags?.map((tag) => (
										<Tooltip content={`Renk: ${hexToColorName(tag?.hexColor)}, başlık: '${tag?.name}'`}>
											<div key={tag._id}>
												<div className="rounded" style={{ backgroundColor: tag.hexColor }}>
													<div className="text-center text-white px-3 py-1">{tag.name}</div>
												</div>
											</div>
										</Tooltip>
									))}
									<Button
										type="light"
										className="rounded-circle d-flex align-items-center justify-content-center shadow-none"
										size="sm"
										icon="plus"
										style={{ width: '32px', height: '32px' }}
										onClick={() => setTaskPopper('tags-top')}
										ref={tagsTopButtonRef}
									/>
									<TaskTagPopper
										buttonRef={tagsTopButtonRef}
										show={taskPopper === 'tags-top'}
										onClose={handlePopperCloseClick}
									/>
								</div>
							</div>
							<div>
								<h6>Bildirimler</h6>
								<div className="d-flex align-items-center">
									{task?.notificationRecipients.some((recipient) => recipient.employee === user._id) ? (
										<Button type="light" className="rounded me-1 shadow-none" size="sm" onClick={handleUnFollowClick}>
											<span className="d-flex align-items-center gap-2">
												<FaIcon icon="eye-slash" size="sm" />
												Takip ediliyor
											</span>
										</Button>
									) : (
										<Button type="light" className="rounded me-1 shadow-none" size="sm" onClick={handleFollowClick}>
											<span className="d-flex align-items-center gap-2">
												<FaIcon icon="eye" size="sm" />
												<span>Takip et</span>
											</span>
										</Button>
									)}
								</div>
							</div>
						</div>
						{date && (
							<div className="d-flex flex-column">
								<h6>Tarihler</h6>
								<div className="d-flex align-items-center">
									<Form.Check
										type="checkbox"
										className="me-2"
										value={task?.isCompleted}
										checked={task?.dates.isCompleted}
										onChange={(e) => {
											handleCompletedCheckListItemChange(e, task?._id)
										}}
									/>
									<span className="badge bg-light text-dark">
										{formatDate(date.startDate, false)} - {formatDate(date.endDate, false)}
										{task?.isCompleted && <span className="badge bg-success text-white ms-2">Tamamlandı</span>}
									</span>
								</div>
							</div>
						)}
						<div>
							<h6>
								<FaIcon icon="align-left" className="me-2" />
								Açıklama
							</h6>
							<div>
								{task && task.description && (
									<CustomMarkdownEditor value={task.description} onBlur={handleDescriptionChange} />
								)}
								{task && !task.description && <CustomMarkdownEditor value={null} onBlur={handleDescriptionChange} />}
							</div>
						</div>
						<TaskCheckLists />
						<div>
							<h6>
								<FaIcon icon="fa-solid fa-comment-lines" className="me-2" />
								Etkinlik
							</h6>
							<TaskActivity />
						</div>
					</Col>
					<Col md={3}>
						<div className="mb-3">
							<h6>Karta Ekle</h6>
							<div>
								<ProjectButton
									text="Üyeler"
									icon="fa-regular fa-user-group"
									onClick={() => setTaskPopper('member-right')}
									ref={memberRightButtonRef}
								/>
								<ProjectButton
									text="Etiketler"
									icon="fa-regular fa-tags"
									onClick={() => setTaskPopper('tags-right')}
									ref={tagsRightButtonRef}
								/>
								<ProjectButton
									text="Kontrol Listesi"
									icon="fa-regular fa-check-double"
									onClick={() => setTaskPopper('list')}
									ref={listButtonRef}
								/>
								<ProjectButton
									text="Takvim"
									icon="fa-regular fa-calendars"
									onClick={() => setTaskPopper('date')}
									ref={dateButtonRef}
								/>

								<TaskMemberPopper
									buttonRef={memberRightButtonRef}
									show={taskPopper === 'member-right'}
									onClose={handlePopperCloseClick}
								/>
								<TaskTagPopper
									buttonRef={tagsRightButtonRef}
									show={taskPopper === 'tags-right'}
									onClose={handlePopperCloseClick}
								/>
								<TaskListPopper
									buttonRef={listButtonRef}
									show={taskPopper === 'list'}
									onClose={handlePopperCloseClick}
								/>
								<TaskDatePopper
									buttonRef={dateButtonRef}
									show={taskPopper === 'date'}
									onClose={handlePopperCloseClick}
								/>
							</div>
						</div>
						<div>
							<h6>Hareketler</h6>
							<div>
								<TaskCopy />
								<TaskArchive />
							</div>
						</div>
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
	)
}

export default ProjectTaskModal
