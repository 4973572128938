import { DeleteButton, ExportButton, SaveButton } from 'components/fast-ui'
import { ButtonContainer } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useEffect } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
	setSelectedOrganizationItems,
	setSelectedOrganizationName,
	setSelectedOrganizationTitle
} from 'stores/ikOrganizationSlice'

import { ErrorMessage } from '@hookform/error-message'

const SchemeHeader = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const params = useParams()

	const { scheme_id } = params
	const { selectedOrganization } = useSelector((state) => state.ikOrganization)

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		formState,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	useEffect(() => {
		reset(selectedOrganization)
	}, [selectedOrganization._id])

	const titleWatch = watch('title')
	const nameWatch = watch('name')

	useEffect(() => {
		dispatch(setSelectedOrganizationTitle(titleWatch))
		dispatch(setSelectedOrganizationName(nameWatch))
	}, [titleWatch, nameWatch])

	const handleSave = async (d) => {
		let body = { ...selectedOrganization }
		body.items = getChilds(null, body.tempItems)
		try {
			const savedScheme = await Service.patch(`/organization-schemes/${scheme_id}`, body)
			sendToast('Şema kaydedildi.')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDelete = async () => {
		try {
			await Service.delete(`/organization-schemes/${scheme_id}`)
			sendToast('Şema silindi.')
			navigate(`/ik-organization-schemes`)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const getChilds = (parent_id, data) => {
		let array = []
		data.forEach((element) => {
			const item = { employee: element.employee._id, parentEmployee_id: parent_id }
			array = [...array, item]

			if (element.children) {
				const childs = getChilds(element.employee._id, element.children)
				array = [...array, ...childs]
			}
		})
		return array
	}

	return (
		<Card>
			<Card.Header>
				<Card.Title as="h5">İşlemler</Card.Title>
			</Card.Header>
			<Card.Body>
				<Form>
					<Row>
						<Col xs={12} sm={3} className="mb-3">
							<Form.Label>Şema Adı</Form.Label>
							<Form.Control {...register('name', { required: 'Bu alan gerekli.' })} />
							<ErrorMessage
								errors={errors}
								name={'name'}
								render={({ message }) => <div className="invalid-feedback">{message}</div>}
							/>
						</Col>
						<Col xs={12} sm={3} className="mb-3">
							<Form.Label>Başlık</Form.Label>
							<Form.Control {...register('title', { required: 'Bu alan gerekli.' })} />
							<ErrorMessage
								errors={errors}
								name={'title'}
								render={({ message }) => <div className="invalid-feedback">{message}</div>}
							/>
						</Col>
						<Col xs={12} sm={6}>
							<Form.Label>İşlemler</Form.Label>
							<div className="d-flex justify-content-start">
								<ButtonContainer>
									<SaveButton onClick={handleSubmit((d) => handleSave(d))} />
									<DeleteButton onClick={handleDelete} />
								</ButtonContainer>
							</div>
						</Col>
					</Row>
				</Form>
			</Card.Body>
		</Card>
	)
}

export default SchemeHeader
