import { ApproveButton, DownloadButton, RejectButton } from 'components/fast-ui'
import { Approvers, ButtonContainer, FaIcon } from 'components/ui'
import { format } from 'date-fns'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useState } from 'react'
import { Modal, Table } from 'react-bootstrap'

const data = [
	{
		id: 2,
		fullName: 'Mehmet Yılmaz',
		date: '02.12.2021',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
		status: 'pending',
		approvers: [
			{
				id: 1,
				fullName: 'Ahmet Yılmaz',
				date: '02.12.2021',
				description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
				status: 'pending'
			},
			{
				id: 2,
				fullName: 'Mehmet Yılmaz',
				date: '02.12.2021',
				description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
				status: 'pending'
			}
		]
	}
]

const ExpensesRequestsModal = ({ show, onModalClose }) => {
	const [showApprovers, setShowApprovers] = useState(true)
	const [showModal, setShowModal] = useState(false)

	const handleApprove = async () => {
		sendToast('Masraf talebi onaylandı.')
		onModalClose()
	}

	const handleReject = async () => {
		sendToast('Masraf talebi reddedildi.')
		onModalClose()
	}

	const handleClose = () => {
		onModalClose()
	}

	return (
		<Modal show={show} onHide={handleClose} size="md" centered>
			<Modal.Header closeButton>
				<Modal.Title as="h5">Masraf İncele</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="d-flex align-items-center mb-4">
					<div className="avatar avatar-xl">
						<img src="https://via.placeholder.com/500" alt="avatar" />
					</div>
					<div className="ms-3">
						<h6 className="mb-2">Berkay Derin</h6>
						<h5>Masraf talep ediyor.</h5>
					</div>
				</div>

				<div className="d-flex justify-content-center mb-4">
					<small>
						<div className="rounded-pill bg-secondary text-dark px-3 py-2">
							<span className="mb-0">
								<FaIcon icon="clock" className="me-2" />
								Bu talep için onay süreci devam ediyor.
							</span>
						</div>

						{data.status === 'approved' && (
							<div className="rounded-pill bg-success text-white px-3 py-2">
								<span className="mb-0">
									<FaIcon icon="check" className="me-2" />
									Bu talep onaylandı.
								</span>
							</div>
						)}

						{data.status === 'rejected' && (
							<div className="rounded-pill bg-danger text-white px-3 py-2">
								<span className="mb-0">
									<FaIcon icon="times" className="me-2" />
									Bu talep reddedildi.
								</span>
							</div>
						)}

						{data.status === 'canceled' && (
							<div className="rounded-pill bg-dark text-white px-3 py-2">
								<span className="mb-0">
									<FaIcon icon="times" className="me-2" />
									Bu talep iptal edildi.
								</span>
							</div>
						)}
					</small>
				</div>
				<Table striped bordered hover className="mb-3">
					<tbody>
						{true && (
							<>
								<tr>
									<td>Masraf Mesajı</td>
									<td>{data[0].description}</td>
								</tr>
								{data.cancelReason && (
									<tr>
										<td>İptal Sebebi</td>
										<td>{data.cancelReason}</td>
									</tr>
								)}
								<tr>
									<td>Masraf Tarihi</td>
									<td>{format(new Date(), 'dd.MM.yyyy')}</td>
								</tr>
								<tr>
									<td>Masraf Durumu</td>
									<td>
										{data.status === 'pending' && <span className="badge bg-warning">Beklemede</span>}
										{data.status === 'approved' && <span className="badge bg-success">Onaylandı</span>}
										{data.status === 'rejected' && <span className="badge bg-danger">Reddedildi</span>}
										{data.status === 'canceled' && <span className="badge bg-secondary">İptal Edildi</span>}
									</td>
								</tr>
								<tr>
									<td>Masraf Fişi</td>
									<td>
										<DownloadButton />
									</td>
								</tr>
							</>
						)}
					</tbody>
				</Table>
				{showApprovers && <Approvers data={data[0].approvers} />}
			</Modal.Body>
			<Modal.Footer>
				{/* {module_id === 'approve' && ( */}
				<>
					<ButtonContainer>
						<RejectButton onClick={handleReject} />
						<ApproveButton onClick={handleApprove} />
					</ButtonContainer>
				</>
				{/* )} */}
			</Modal.Footer>
		</Modal>
	)
}

export default ExpensesRequestsModal
