import 'react-nestable/dist/styles/index.css'

import React from 'react'
import { Col } from 'react-bootstrap'

import EmployeeList from './components/EmployeeList'
import EmployeeStruct from './components/EmployeeStruct'

const SchemeFooter = () => {
	return (
		<>
			<Col md={6} className="mb-4">
				<EmployeeList />
			</Col>
			<Col md={6} className="mb-4">
				<EmployeeStruct />
			</Col>
		</>
	)
}

export default SchemeFooter
