import { ApproveButton, DownloadButton, RejectButton } from 'components/fast-ui'
import {
	Approvers,
	ButtonContainer,
	CustomPopperTooltip,
	CustomReactTable,
	FaIcon,
	TabButton,
	TabButtonContainer
} from 'components/ui'
import { format } from 'date-fns'
import { getStatusBadgeClass, getStatusBadgeText } from 'global/functions'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Card, Form, Modal, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import ExpensesRequestsModal from './ExpensesRequestsModal'

const ExpensesRequests = () => {
	const [tableData, setTableData] = useState([])
	const [tableColumns, setTableColumns] = useState([])
	const [filteredData, setFilteredData] = useState([])
	const [show, setShow] = useState(false)
	const [data, setData] = useState({})

	useEffect(() => {
		const columns = [
			{
				Header: 'Talep Eden',
				accessor: 'name'
			},
			{
				Header: 'Talep Tarihi',
				accessor: 'date'
			},
			{
				Header: 'Açıklama',
				accessor: 'description'
			},
			{
				Header: 'Durum',
				accessor: 'status',
				Cell: ({ row }) => {
					return (
						<span className={`badge ${getStatusBadgeClass(row.original.status)}`}>
							{getStatusBadgeText(row.original.status)}
						</span>
					)
				}
			},
			{
				Header: 'Seçenekler',
				accessor: 'id',
				Cell: ({ row }) => {
					return (
						<>
							<FaIcon icon="eye text-primary cursor-pointer" onClick={() => handleShow(row.original.id)} />
						</>
					)
				}
			}
		]

		const data = [
			{
				id: 1,
				name: 'Ahmet Yılmaz',
				date: '02.12.2021',
				description: 'Lorem ipsum',
				status: 'approved'
			},
			{
				id: 2,
				name: 'Mehmet Yılmaz',
				date: '12.12.2021',
				description: 'Lorem ipsum 2',
				status: 'rejected'
			},
			{
				id: 3,
				name: 'Ali Yılmaz',
				date: '22.12.2021',
				description: 'Lorem ipsum 3',
				status: 'pending'
			}
		]

		setTableData(data)
		setTableColumns(columns)
		setFilteredData(data)
	}, [])

	const handleFilter = (filterValue) => {
		if (filterValue === '') {
			setFilteredData(tableData)
		} else {
			const filteredData = tableData.filter((item) => {
				return item.status === filterValue
			})
			setFilteredData(filteredData)
		}
	}

	const handleClose = () => setShow(false)

	const handleShow = (rowData) => {
		setData(rowData)
		setShow(true)
	}

	return (
		<>
			<Card className="mb-3">
				<Card.Header>
					<Card.Title as="h5">Masraf Talepleri</Card.Title>
					<TabButtonContainer>
						<TabButton icon="list" text="Tümü" onClick={() => handleFilter('')} />
						<TabButton icon="clock" text="Bekleyen" onClick={() => handleFilter('pending')} />
						<TabButton icon="check" text="Onaylanan" onClick={() => handleFilter('approved')} />
						<TabButton icon="xmark" text="Reddedilen" onClick={() => handleFilter('rejected')} />
					</TabButtonContainer>
				</Card.Header>
				<Card.Body>
					<CustomReactTable data={filteredData} columns={tableColumns} />
				</Card.Body>
			</Card>

			<ExpensesRequestsModal show={show} handleClose={handleClose} data={data} />
		</>
	)
}

export default ExpensesRequests
