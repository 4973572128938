import Service from 'global/Service'
import { showErrorMessage } from 'global/sendError'
import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'

import MyCompetenceRatingDetail from './tabs/my-competence-rating-detail'
import MyCompetenceRatingList from './tabs/my-competence-rating-list'

const MyCompetenceRatings = () => {
	const params = useParams()
	const { tab_name, module_name, module_id: competenceRatingId } = params

	const [tab, setTab] = useState('list')

	useEffect(() => {
		if (competenceRatingId) {
			setTab('detail')
		} else {
			setTab('list')
		}
	}, [params])

	return (
		<>
			{tab === 'list' && <MyCompetenceRatingList />}
			{tab === 'detail' && <MyCompetenceRatingDetail competenceRatingId={competenceRatingId} />}
		</>
	)
}

export default MyCompetenceRatings
