import React from 'react'
import { Row } from 'react-bootstrap'

import WorkCalendarList from './components/WorkCalendarList'
import WorkCalendarModal from './components/WorkCalendarModal'

const WorkCalendars = () => {
	return (
		<Row>
			<WorkCalendarList />
			<WorkCalendarModal />
		</Row>
	)
}

export default WorkCalendars
