import illustrator from 'assets/img/icons/documents/file-types/adobe-illustrator.png'
import apk from 'assets/img/icons/documents/file-types/apk.png'
import css from 'assets/img/icons/documents/file-types/css.png'
import doc from 'assets/img/icons/documents/file-types/doc.png'
import excel from 'assets/img/icons/documents/file-types/excel.png'
import font from 'assets/img/icons/documents/file-types/font-file.png'
import image from 'assets/img/icons/documents/file-types/image.png'
import js from 'assets/img/icons/documents/file-types/js-file.png'
import mail from 'assets/img/icons/documents/file-types/mail.png'
import mp3 from 'assets/img/icons/documents/file-types/mp3.png'
import music from 'assets/img/icons/documents/file-types/music.png'
import pdf from 'assets/img/icons/documents/file-types/pdf.png'
import php from 'assets/img/icons/documents/file-types/php.png'
import powerpoint from 'assets/img/icons/documents/file-types/powerpoint.png'
import ppt from 'assets/img/icons/documents/file-types/ppt.png'
import psd from 'assets/img/icons/documents/file-types/psd.png'
import sql from 'assets/img/icons/documents/file-types/sql.png'
import svg from 'assets/img/icons/documents/file-types/svg.png'
import text from 'assets/img/icons/documents/file-types/text.png'
import txt from 'assets/img/icons/documents/file-types/txt.png'
import video from 'assets/img/icons/documents/file-types/video.png'
import word from 'assets/img/icons/documents/file-types/word.png'
import xls from 'assets/img/icons/documents/file-types/xls.png'
import zip from 'assets/img/icons/documents/file-types/zip.png'
import closedFolderIcon from 'assets/img/icons/documents/general/folder-closed.png'
import React from 'react'

const list = [
	{
		name: 'folder',
		img: closedFolderIcon
	},
	{
		name: 'pdf',
		img: pdf
	},
	{
		name: 'xlsx',
		img: xls
	},
	{
		name: 'xls',
		img: xls
	},
	{
		name: 'psd',
		img: psd
	},
	{
		name: 'gif',
		img: image
	},
	{
		name: 'ai',
		img: illustrator
	},
	{
		name: 'doc',
		img: doc
	},
	{
		name: 'png',
		img: image
	},
	{
		name: 'jpg',
		img: image
	},
	{
		name: 'jpeg',
		img: image
	},
	{
		name: 'mp3',
		img: mp3
	},
	{
		name: 'rar',
		img: zip
	},
	{
		name: 'zip',
		img: zip
	},
	{
		name: 'svg',
		img: svg
	},
	{
		name: 'txt',
		img: txt
	},
	{
		name: 'excel',
		img: excel
	},
	{
		name: 'ppt',
		img: ppt
	},
	{
		name: 'video',
		img: video
	}
]

const FileIcon = ({ className, extension = 'folder', style }) => {
	const image = list.filter((item) => item.name === extension)[0]?.img

	return (
		<>
			<img className={className} src={image} style={style} alt="" />
		</>
	)
}

export default FileIcon
