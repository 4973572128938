import { ApproveButton, CloseButton, ResetButton } from 'components/fast-ui'
import { FaIcon } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { fetchEmployee } from 'stores/ikEmployeeEditSlice'

const BackToWorkModal = () => {
	const params = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { employee_id, module_name } = params
	const { employee } = useSelector((state) => state.ikEmployeeEdit)
	const [show, setShow] = useState(false)

	useEffect(() => {
		if (module_name === 'backtowork') {
			setShow(true)
		} else {
			setShow(false)
		}
	}, [module_name])

	const handleClose = () => {
		setShow(false)
		navigate(`/employees/${employee_id}/general`)
	}

	const handleClickApprove = async () => {
		const body = {
			dismissal: {}
		}
		try {
			await Service.patch(`/employees/${employee_id}`, body)
			sendToast('Personel yeniden işe alındı.')
			dispatch(fetchEmployee(employee_id))
			handleClose()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<div className="content-left">
					<Modal.Title as={'h5'}>
						<FaIcon icon={'rotate-left'} className="me-2" /> Çalışanı işe geri al
					</Modal.Title>
				</div>
			</Modal.Header>
			<Modal.Body>
				<p>
					<b>{`${employee?.firstName} ${employee?.lastName}`} </b> adlı personeli işe geri almak istediğinizden emin
					misiniz?
				</p>
			</Modal.Body>
			<Modal.Footer>
				<CloseButton onClick={handleClose} />
				<ApproveButton onClick={handleClickApprove} />
			</Modal.Footer>
		</Modal>
	)
}

export default BackToWorkModal
