import 'react-popper-tooltip/dist/styles.css'

import React from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'

const Tooltip = ({ content, children }) => {
	const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip()
	return (
		<>
			<span ref={setTriggerRef}>{children}</span>

			{visible && (
				<div
					ref={setTooltipRef}
					{...getTooltipProps({
						className: 'tooltip-container',
						style: {
							position: 'absolute',
							top: '0',
							left: '50%',
							transform: 'translateX(-50%)'
						}
					})}
				>
					<div {...getArrowProps({ className: 'tooltip-arrow' })} />
					{content}
				</div>
			)}
		</>
	)
}

export default Tooltip
