import { CustomReactSelect } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { FormGroup, FormLabel } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

const ProjectSelect = () => {
	const navigate = useNavigate()
	const params = useParams()
	const { workspace_id, project_id, task_id } = params

	const [workspaces, setWorkspaces] = useState([])
	const [workspaceProjects, setWorkspaceProjects] = useState([])

	const fetchWorkspaces = async () => {
		try {
			const response = await Service.get(`/workspaces`)
			setWorkspaces(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchWorkspaceProjects = async () => {
		try {
			const response = await Service.get(`/workspaces/${workspace_id}/projects`)
			setWorkspaceProjects(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (workspace_id) {
			fetchWorkspaceProjects()
		}
	}, [params])

	useEffect(() => {
		fetchWorkspaces()
	}, [])

	const {
		control,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	return (
		<div className="d-flex gap-3 flex-wrap">
			<FormGroup style={{ minWidth: '200px' }}>
				<FormLabel>Çalışma Alanı</FormLabel>
				<Controller
					name="workspace"
					control={control}
					render={({ field }) => (
						<CustomReactSelect
							options={workspaces}
							placeholder="Seçiniz"
							onChange={(e) => {
								navigate(`/projects/${e._id}`)
							}}
							value={workspaces.find((c) => c._id === workspace_id) || ''}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option._id}
						/>
					)}
				/>
			</FormGroup>
			<FormGroup style={{ minWidth: '200px' }}>
				<FormLabel>Proje</FormLabel>
				<Controller
					name="project"
					control={control}
					render={({ field }) => (
						<CustomReactSelect
							options={workspaceProjects}
							placeholder="Seçiniz"
							onChange={(e) => {
								navigate(`/projects/${workspace_id}/${e._id}`)
							}}
							value={workspaceProjects.find((c) => c._id === project_id) || ''}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option._id}
						/>
					)}
				/>
			</FormGroup>
		</div>
	)
}

export default ProjectSelect
