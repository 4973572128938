import { CloseButton, ContinueButton, CreateButton } from 'components/fast-ui'
import { ButtonContainer, FaIcon } from 'components/ui'
import CustomReactSelect from 'components/ui/CustomReactSelect'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Table } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

const options = [
	{ value: 'startFromScratch', label: 'Sıfırdan başla' },
	{ value: 'exampleTurkishLaborLaw', label: 'Örnek Türk İş Kanunu' }
]

const VacationRuleModal = () => {
	const params = useParams()
	const navigate = useNavigate()

	const { module_name, tab_name, id } = params

	const [show, setShow] = useState(false)
	const [page, setPage] = useState(1)

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		formState,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	useEffect(() => {
		if (tab_name === 'rules' && module_name === 'add') {
			setShow(true)
		} else {
			setShow(false)
		}
	}, [params])

	const handleClose = () => {
		navigate('/vacations/rules')
	}

	const handleContinue = () => {
		setPage(2)
	}

	const handleCreate = async (d) => {
		const body = d
		try {
			const response = await Service.post('/vacation-rules', body)
			if (response.status === 200) {
				navigate('/vacations/rules')
			}
			sendToast('İzin kuralı başarıyla oluşturuldu.')
			handleClose()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'}>
					<FaIcon icon="scale-balanced" className="me-2" />
					Yeni İzin Kuralı
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					{page === 1 && (
						<Col xs={12} sm={12} className="mb-3">
							<Form.Label>Şema</Form.Label>
							<Controller
								name="scheme"
								control={control}
								defaultValue={options[0].value}
								render={({ field }) => (
									<CustomReactSelect
										value={options.filter((obj) => obj.value === field.value)}
										onChange={(d) => field.onChange(d?.value)}
										ref={field.ref}
										placeholder=""
										options={options}
									/>
								)}
							/>
						</Col>
					)}
					{page === 2 && (
						<>
							<Col xs={12} sm={12} className="mb-3">
								<Form.Label>Ad</Form.Label>
								<Controller
									name="name"
									control={control}
									rules={{ required: { value: true, message: 'Bu alan zorunludur.' } }}
									render={({ field }) => (
										<Form.Control {...field} type="text" placeholder="Ad" isInvalid={errors.name} />
									)}
								/>
								<Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
							</Col>
							<Col xs={12} sm={12} className="mb-3">
								<Form.Label>Açıklama</Form.Label>
								<Controller
									name="description"
									control={control}
									rules={{ required: { value: true, message: 'Bu alan zorunludur.' } }}
									render={({ field }) => (
										<Form.Control {...field} type="text" placeholder="Açıklama" isInvalid={errors.description} />
									)}
								/>
								<Form.Control.Feedback type="invalid">{errors.description?.message}</Form.Control.Feedback>
							</Col>
						</>
					)}
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{page === 1 && (
					<ButtonContainer>
						<CloseButton onClick={handleClose} />
						<ContinueButton onClick={handleSubmit((d) => handleContinue(d))} />
					</ButtonContainer>
				)}
				{page === 2 && (
					<ButtonContainer>
						<CloseButton
							text="Geri"
							onClick={() => {
								setPage(1)
							}}
						/>
						<CreateButton onClick={handleSubmit((d) => handleCreate(d))} />
					</ButtonContainer>
				)}
			</Modal.Footer>
		</Modal>
	)
}

export default VacationRuleModal
