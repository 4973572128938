import 'react-popper-tooltip/dist/styles.css'

import React, { useEffect, useState } from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'

const CustomPopperTooltip = ({ text, children }) => {
	const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip()

	return (
		<>
			<span ref={setTriggerRef}>{children}</span>
			{visible && (
				<div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
					<div {...getArrowProps({ className: 'tooltip-arrow' })} />
					{text}
				</div>
			)}
		</>
	)
}

export default CustomPopperTooltip
