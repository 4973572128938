import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useMemo, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'

import COLUMNS from './Columns'

const PositionHistoryTable = () => {
	const params = useParams()
	const { employee_id, module_name, module_id } = params

	const [employeePositions, setEmployeePositions] = useState([])
	const [companyStructures, setCompanyStructures] = useState([])
	const [stateColumns, setStateColumns] = useState([])

	const fetchCompanyStructures = async () => {
		try {
			const response = await Service.get('/company-structures')
			setCompanyStructures(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const fetchEmployeePositions = async () => {
		try {
			const response = await Service.get(`/employee-positions/${employee_id}`)
			setEmployeePositions(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchCompanyStructures()
		fetchEmployeePositions()
	}, [params])

	const personal = useSelector((state) => state.ikEmployeeEdit.employee)

	useEffect(() => {
		const companyStructureColumns = companyStructures
			.filter((item) => item.isActive)
			.map((item) => {
				return {
					Header: item.name,
					id: item._id,
					accessor: (row) => {
						const unit = row.units.find((unit) => unit.companyStructure._id === item._id)
						return unit ? unit.unit?.name : ''
					}
				}
			})
		setStateColumns([...COLUMNS, ...companyStructureColumns])
	}, [companyStructures])

	const data = useMemo(() => employeePositions)
	const columns = useMemo(() => stateColumns)
	const dispatch = useDispatch()

	const tableInstance = useTable(
		{
			columns,
			data
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		flatRows,
		prepareRow,
		page,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		pageOptions,
		gotoPage,
		pageCount,
		setPageSize,
		state,
		setGlobalFilter
	} = tableInstance

	const { globalFilter, pageIndex, pageSize } = state

	return (
		<Table
			{...getTableProps()}
			className="datatables-users table border-top dataTable table-striped table-responsive no-footer dtr-column"
		>
			<thead>
				{headerGroups.map((headerGroup) => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column) => (
							<th className="control sorting_disabled" {...column.getHeaderProps(column.getSortByToggleProps())}>
								{column.render('Header')}
								<span>{column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}</span>
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{page.map((row) => {
					prepareRow(row)
					return (
						<tr {...row.getRowProps()}>
							{row.cells.map((cell) => {
								return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
							})}
						</tr>
					)
				})}
			</tbody>
		</Table>
	)
}

export default PositionHistoryTable
