import './index.css'

import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import useProjectStore from 'stores/projectStore'

import NewColumnButton from './components/NewColumnButton'
import ProjectColumn from './components/ProjectColumn'
import ProjectTaskModal from './components/ProjectTaskModal'

const Project = () => {
	const params = useParams()
	const { task_id } = params

	const project = useProjectStore((state) => state.project)
	const refreshProject = useProjectStore((state) => state.refreshProject)

	const changeColumn = async (taskId, targetColumn, index) => {
		const body = {
			targetColumn: targetColumn,
			index: index
		}

		try {
			const response = await Service.patch(`/project-tasks/${taskId}/change-column`, body)
			refreshProject()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const onDragEnd = (result) => {
		const { source, destination, draggableId } = result
		changeColumn(draggableId, destination?.droppableId, destination?.index)

		// Zustand'deki proje objesini güncelle

		const updatedProject = { ...project }

		if (!destination) return updatedProject // item was dropped outside of the list

		if (source.droppableId === destination.droppableId) {
			// item was dropped in the same column
			const column = updatedProject.columns.find((item) => item.column._id === source.droppableId)
			const tasks = column.column.tasks
			const task = tasks.splice(source.index, 1)[0]
			tasks.splice(destination.index, 0, task)
		} else {
			// task was dropped in a different column
			const sourceColumn = updatedProject.columns.find((item) => item.column._id === source.droppableId)
			const destinationColumn = updatedProject.columns.find((item) => item.column._id === destination.droppableId)
			const sourceTasks = sourceColumn.column.tasks
			const destinationTasks = destinationColumn.column.tasks
			const task = sourceTasks.splice(source.index, 1)[0]
			destinationTasks.splice(destination.index, 0, task)
		}
	}

	return (
		<>
			<DragDropContext onDragEnd={onDragEnd}>
				<Container fluid="xxl" className="flex-grow-1 ps-0">
					<div className="kanban-container">
						{project.columns.map((item) => (
							<ProjectColumn data={item.column} />
						))}

						<NewColumnButton />
					</div>
				</Container>
			</DragDropContext>
			{task_id && <ProjectTaskModal />}
		</>
	)
}

export default Project
