import { DeleteButton, SaveButton } from 'components/fast-ui'
import { FaIcon } from 'components/ui'
import React, { useEffect } from 'react'
import { CirclePicker } from 'react-color'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
	createTag,
	deleteTag,
	refreshTagList,
	resetSelectedTag,
	selectTag,
	setSelectedTagHexColor,
	setSelectedTagName
} from 'stores/documentSlice'

import { TagElement } from '../components/TagElement'

const TagList = () => {
	const dispatch = useDispatch()

	const { items, selectedTag } = useSelector((state) => state.document.tags)
	const selectedTagID = selectedTag.content._id

	useEffect(() => {
		dispatch(refreshTagList())
	}, [])

	const navigate = useNavigate()

	const handleTagElementClick = (tagID) => {
		navigate(`/documents/tag/${tagID}`)
		//dispatch(selectTag(tagID))
	}

	return (
		<>
			<div className="row">
				<label htmlFor="defaultFormControlInput" className="form-label">
					Etiketler
				</label>
				<ul className="list-unstyled">
					{items.map((item) => (
						<TagElement data={item} selected={selectedTagID === item._id} onClick={handleTagElementClick} />
					))}
					<li key="test">
						<div
							className="text-secondary rounded"
							onClick={() => dispatch(resetSelectedTag())}
							type="button"
							data-bs-toggle="modal"
							data-bs-target="#newTagModal"
						>
							<FaIcon type="solid" icon="plus" size="sm" />
						</div>
					</li>
				</ul>
			</div>
		</>
	)
}

export default TagList
