import './fullCalendar.css'

import Service from 'global/Service'
import { formatDate } from 'global/functions/formatDate'
import { sendToast } from 'global/sendToast'
import { useState } from 'react'
import { useEffect } from 'react'
import { Table } from 'react-bootstrap'
import Flatpickr from 'react-flatpickr'
import { useNavigate, useParams } from 'react-router'

import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'

import ViewEventModal from '../EventViewModal'

function renderEventContent(data) {
	return (
		<>
			<div className="fc-daygrid-event-dot" wfd-invisible="true"></div>
			<div className="fc-event-time">{data.timeText}</div>
			<div className="fc-event-title">{data.event.title}</div>
			<div className="fc-event-location">{data.event.location}</div>
			<div className="fc-event-link">{data.event.link}</div>
			<div className="fc-event-creator">{data.event.creator}</div>
			<div className="fc-event-description">{data.event.description}</div>
		</>
	)
}

const CalendarContent = () => {
	const params = useParams()
	const navigate = useNavigate()

	const [data, setData] = useState([])
	const [modalContent, setModalContent] = useState(null)
	const [modalVisible, setModalVisible] = useState(false)

	const fetchData = async () => {
		try {
			const response = await Service.get('/events')
			setData(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const openModal = (content) => {
		setModalContent(content)
		setModalVisible(true)
	}

	const closeModal = () => {
		setModalVisible(false)
	}

	useEffect(() => {
		if (!params.event_id) {
			fetchData()
		}
	}, [params])

	const handleEventClick = (eventClickInfo) => {
		const { event } = eventClickInfo
		navigate(`/calendar/${event.id}`)
	}

	const events = data.map((item) => ({
		id: item._id,
		color: '#EFF6FE',
		title: item.title,
		description: item.description,
		start: new Date(item.dates.startDate),
		end: new Date(item.dates.endDate),
		location: item.location,
		link: item.link,
		creator: item.creator.firstName,
		allDay: true
	}))

	return (
		<div className="col app-calendar-content">
			<div className="card shadow-none border-0">
				<div className="card-body pb-0">
					<div id="calendar" class="fc fc-media-screen fc-direction-ltr fc-theme-standard">
						<FullCalendar
							plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
							displayEventTime={true}
							initialView="dayGridMonth"
							weekends={true}
							events={events}
							eventContent={renderEventContent}
							locale={'tr'}
							eventClick={handleEventClick}
						/>
					</div>
				</div>
			</div>
			<button onClick={() => console.log(events)} className="btn btn-primary">
				console log
			</button>
		</div>
	)
}

export default CalendarContent
