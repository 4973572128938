import { format } from 'date-fns'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Modal, Table } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

const vacationTypes = [
	{ value: 'military', label: 'Askerlik İzni' },
	{ value: 'paternity', label: 'Babalık İzni' },
	{ value: 'birth', label: 'Doğum İzni' },
	{ value: 'postpartum', label: 'Doğum Sonrası İzni' },
	{ value: 'marriage', label: 'Evlilik İzni' },
	{ value: 'sick', label: 'Hastalık İzni' },
	{ value: 'jobSearch', label: 'İş Arama İzni' },
	{ value: 'reason', label: 'Mazeret İzni' },
	{ value: 'breastfeeding', label: 'Süt İzni' },
	{ value: 'unpaid', label: 'Ücretsiz İzin' },
	{ value: 'death', label: 'Vefat İzni' },
	{ value: 'annual', label: 'Yıllık İzni' },
	{ value: 'road', label: 'Yol İzni' }
]

const VacationShowModal = () => {
	const navigate = useNavigate()
	const params = useParams()
	const { tab_name, module_name, module_id, process } = params

	const [show, setShow] = useState(false)
	const [data, setData] = useState(null)

	const handleClose = () => {
		setShow(false)
		navigate(`/profile/${tab_name}`)
	}

	const fetchData = async () => {
		try {
			const response = await Service.get(`/vacations/${module_id}`)
			setData(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (tab_name === 'vacations' && module_name === 'list' && module_id && process === 'show') {
			setShow(true)
			fetchData()
		}
	}, [params])

	return (
		<Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>İzin Detayı</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Table striped bordered hover>
					<tbody>
						{data && (
							<>
								<tr>
									<td>İzin Türü</td>
									<td>{data.type.name}</td>
								</tr>
								<tr>
									<td>İzin Başlangıç Tarihi</td>
									<td>{data.startDate ? format(new Date(data.startDate), 'dd/MM/yyyy HH:mm') : 'yok'}</td>
								</tr>
								<tr>
									<td>İzin Bitiş Tarihi</td>
									<td>{data.endDate ? format(new Date(data.endDate), 'dd/MM/yyyy HH:mm') : 'yok'}</td>
								</tr>
								<tr>
									<td>İzin Süresi</td>
									<td>
										{data.startDate && data.endDate
											? Math.floor((new Date(data.endDate) - new Date(data.startDate)) / (1000 * 60 * 60 * 24)) + 1
											: 0}{' '}
										gün
									</td>
								</tr>
								<tr>
									<td>İzin Durumu</td>
									<td>
										{data.status === 'pending' && <span className="badge bg-warning">Beklemede</span>}
										{data.status === 'approved' && <span className="badge bg-success">Onaylandı</span>}
										{data.status === 'rejected' && <span className="badge bg-danger">Reddedildi</span>}
										{data.status === 'canceled' && <span className="badge bg-secondary">İptal Edildi</span>}
									</td>
								</tr>
								<tr>
									<td>İzin Açıklaması</td>
									<td>{data.description}</td>
								</tr>
								{data.cancelReason && (
									<tr>
										<td>İptal Sebebi</td>
										<td>{data.cancelReason}</td>
									</tr>
								)}
							</>
						)}
					</tbody>
				</Table>
			</Modal.Body>
		</Modal>
	)
}

export default VacationShowModal
