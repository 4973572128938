import Service from 'global/Service'

import { createSlice } from '@reduxjs/toolkit'

export const ratingsCompetenceSlice = createSlice({
	name: 'ratingsCompetence',
	initialState: {
		ratingList: [],
		selectedRating: {},
		selectedRatingID: 0
	},
	reducers: {
		setRatingList: (state, action) => {
			state.ratingList = action.payload
		},
		setSelectedRating: (state, action) => {
			state.selectedRating = action.payload
		},
		setSelectedRatingID: (state, action) => {
			state.selectedRatingID = action.payload
		},
		setQuestionScore: (state, action) => {
			state.selectedRating.questions[action.payload.questionIndex].score = action.payload.score
		}
	}
})

export const { setRatingList, setSelectedRatingID, setSelectedRating, setQuestionScore } =
	ratingsCompetenceSlice.actions

export const refreshRatingList = () => (dispatch) => {
	let promise = Service.get('https://api.devbont.site/api/rating/competencies')
	promise.then((response) => {
		dispatch(setRatingList(response.data))
	})
}

export const changeQuestionScore = (selectedRatingID, questionIndex, score) => (dispatch) => {
	dispatch(setQuestionScore({ selectedRatingID: selectedRatingID, questionIndex: questionIndex, score: score }))
}

export const sendRating = (ratingDetails) => (dispatch) => {
	const body = ratingDetails
	let promise = Service.post('https://api.devbont.site/api/rating/competencies', body)
	promise.then((response) => {})
}

export default ratingsCompetenceSlice.reducer
