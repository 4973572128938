import { Loader, LoaderSpinner } from 'components/ui'
import ForgotPassword from 'pages/auth/forgot-password'
import Login from 'pages/auth/login/index'
import ResetPassword from 'pages/auth/reset-password'
import AuthTwoFactor from 'pages/auth/two-factor'
import Calendar from 'pages/calendar'
import Dashboard from 'pages/dashboard'
import Document from 'pages/document'
import DocumentApproval from 'pages/document-approval'
import Error from 'pages/error'
import Comptencies from 'pages/ik/competencies'
import HRDashboard from 'pages/ik/dashboard'
import Employees from 'pages/ik/employees'
import EmployeeEdit from 'pages/ik/employees/employee-edit'
import Expenses from 'pages/ik/expenses'
import OrganizationSchemes from 'pages/ik/organization-schemes'
import Vacations from 'pages/ik/vacations'
import Profile from 'pages/profile'
import Projects from 'pages/projects'
import Requests from 'pages/requests'
import Settings from 'pages/settings'
import Tasks from 'pages/tasks'
import MyNotifications from 'pages/user/my-notifications'
import MyRatings from 'pages/user/my-ratings'
import Workspaces from 'pages/workspaces'
import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import BounceLoader from 'react-spinners/BounceLoader'
import useAuthStore from 'stores/authStore'
import useGeneralStore from 'stores/generalStore'
import useOrganizationStore from 'stores/organizationStore'
import AuthRoutes from 'utils/AuthRoutes'
import PrivateRoutes from 'utils/PrivateRoutes'

import { AuthLayout, DefaultLayout } from './components/layouts'

const protectedRoutes = [
	{
		key: 'first',
		path: '/',
		element: <Navigate to="/home" />
	},
	{
		key: 'home',
		path: '/home',
		element: <Dashboard />
	},
	{
		key: 'calendar',
		path: '/calendar',
		element: <Calendar />,
		permissions: ['calendar_access'],
		params: ['event_id']
	},
	{
		key: 'tasks',
		path: '/tasks',
		element: <Tasks />,
		params: ['task_id']
	},
	{
		key: 'requests',
		path: '/requests',
		element: <Requests />,
		params: ['tab_name']
	},
	{
		key: 'vacations',
		path: '/vacations',
		element: <Vacations />,
		params: [
			'tab_name',
			'tab_name/:module_name',
			'tab_name/:module_name/:module_id',
			'tab_name/:module_name/:module_id/:module_sub_id'
		]
	},
	{
		key: 'employees',
		path: '/employees',
		element: <Employees />
	},
	{
		key: 'employee-edit',
		path: '/employees',
		element: <EmployeeEdit />,
		params: [
			'employee_id',
			'employee_id/:tab_name',
			'employee_id/:tab_name/:module_name',
			'employee_id/:tab_name/:module_name/:module_id',
			'employee_id/:tab_name/:module_name/:module_id/:process'
		]
	},
	{
		key: 'ik-dashboard',
		path: '/ik-dashboard',
		element: <HRDashboard />
	},
	{
		key: 'ik-organization-schemes',
		path: '/ik-organization-schemes',
		element: <OrganizationSchemes />,
		params: ['scheme_id']
	},
	{
		key: 'ik-competencies',
		path: '/ik-competencies',
		element: <Comptencies />,
		params: ['tab_name', 'tab_name/:module_name', 'tab_name/:module_name/:module_id']
	},
	{
		key: 'my-ratings',
		path: '/my-ratings',
		element: <MyRatings />,
		params: ['tab_name', 'tab_name/:module_name', 'tab_name/:module_name/:module_id']
	},
	{
		key: 'documents',
		path: '/documents',
		element: <Document />,
		params: ['path', 'path/:path_id']
	},
	{
		key: 'settings',
		path: '/settings',
		element: <Settings />,
		params: [
			'tab_name',
			'tab_name/:item_id',
			'tab_name/:item_id/:sub_item_id',
			'tab_name/:item_id/:sub_item_id/:process_name'
		]
	},
	{
		key: 'profile',
		path: '/profile',
		element: <Profile />,
		params: ['module_name', 'module_name/:module_id', 'module_name/:module_id/:process']
	},
	{
		key: 'my-notifications',
		path: '/my-notifications',
		element: <MyNotifications />
	},
	{
		key: 'document-approval',
		path: '/document-approval',
		element: <DocumentApproval />,
		params: ['document_id', 'document_id/:process_name']
	},
	{
		key: 'expenses',
		path: '/expenses',
		element: <Expenses />
	},
	{
		key: 'projects',
		path: '/projects',
		element: <Projects />,
		params: ['workspace_id', 'workspace_id/:project_id', 'workspace_id/:project_id/:task_id']
	},
	{
		key: 'workspaces',
		path: '/workspaces',
		element: <Workspaces />,
		params: ['workspace_id', 'workspace_id/:project_id', 'workspace_id/:project_id/:process']
	}
]

const authRoutes = [
	{
		key: 'login',
		path: '/auth/login',
		element: <Login />
	},
	{
		key: 'two-factor',
		path: '/auth/two-factor',
		element: <AuthTwoFactor />
	},

	{
		key: 'forgot-password',
		path: '/auth/forgot-password',
		element: <ForgotPassword />
	},
	{
		key: 'reset-password',
		path: '/auth/reset-password',
		element: <ResetPassword />,
		params: ['token']
	}
]

const App = () => {
	{
		const isLoadingOrganization = useOrganizationStore((state) => state.isLoading)
		const validateOrganization = useOrganizationStore((state) => state.validateOrganization)
		const isValidOrganization = useOrganizationStore((state) => state.isValidOrganization)

		const init = useAuthStore((state) => state.init)
		const isLoadingAuth = useAuthStore((state) => state.isLoading)
		const user = useAuthStore((state) => state.user)

		useEffect(() => {
			validateOrganization()
		}, [])

		useEffect(() => {
			if (isValidOrganization) {
				init()
			}
		}, [isValidOrganization])

		if (isLoadingOrganization) {
			return (
				<div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
					<LoaderSpinner />
				</div>
			)
		}

		if (!isValidOrganization) {
			return <div>Invalid organization</div>
		}

		if (isLoadingAuth) {
			return (
				<div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
					<LoaderSpinner />
				</div>
			)
		}

		return (
			<Routes>
				<Route element={<AuthRoutes />}>
					{authRoutes.map((route, index) => {
						return <Route key={index} path={route.path} element={<AuthLayout>{route.element}</AuthLayout>} />
					})}
				</Route>

				<Route element={<PrivateRoutes />}>
					{protectedRoutes.map((route, index) => {
						// İzin kontrolü yap
						const hasPermission = route.permissions
							? route.permissions.some((permission) => user?.permissions.includes(permission))
							: true

						if (!hasPermission) {
							// Kullanıcının izni yoksa ana sayfaya yönlendir
							return <Route key={index} path={route.path} element={<Navigate to="/home" />} />
						}

						// İzin varsa rotayı render et
						return (
							<Route key={index} path={route.path} element={<DefaultLayout>{route.element}</DefaultLayout>}>
								{route.params &&
									route.params.map((param, index) => (
										<Route key={index} path={`:${param}`} element={<DefaultLayout>{route.element}</DefaultLayout>} />
									))}
							</Route>
						)
					})}
				</Route>

				<Route path="*" element={<Error />} />
			</Routes>
		)
	}
}

export default App
