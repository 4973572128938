import { CloseButton, DeleteButton, SaveButton } from 'components/fast-ui'
import { ButtonContainer, FaIcon, TabButton, TabButtonContainer } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import VacationType from './components/VacationType'
import VacationTypesTable from './components/vacation-types-table'

const VacationRuleEdit = () => {
	const params = useParams()
	const navigate = useNavigate()
	const { tab_name, module_name, module_id } = params

	const [data, setData] = useState({})
	const [showVacationType, setShowVacationType] = useState(false)

	useEffect(() => {
		if (module_id) {
			setShowVacationType(true)
		} else {
			setShowVacationType(false)
		}
	}, [params])

	const fetchData = async () => {
		try {
			const response = await Service.get(`/vacation-rules/${module_name}`)

			setData(response.data)
			reset(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		formState,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	const handleSave = async (d) => {
		const body = d
		try {
			const response = await Service.patch(`/vacation-rules/${module_name}`, body)
			if (response.status === 200) {
				sendToast('İzin kuralı başarıyla güncellendi', true)
			}
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDelete = async () => {
		try {
			const response = await Service.delete(`/vacation-rules/${module_name}`)
			if (response.status === 200) {
				sendToast('İzin kuralı başarıyla silindi', true)
				navigate('/vacations/rules')
			}
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleNewVacationType = () => {
		navigate(`/vacations/rules/${module_name}/add`)
	}

	return (
		<>
			{showVacationType && (
				<>
					<Col md={12} className="mb-4">
						<TabButtonContainer>
							<TabButton
								text="Kurala dön"
								icon="arrow-left"
								onClick={() => navigate(`/vacations/rules/${module_name}`)}
							/>
						</TabButtonContainer>
					</Col>
					<Col md={12}>
						<VacationType />
					</Col>
				</>
			)}

			{!showVacationType && (
				<>
					<Col md={12} className="mb-4">
						<TabButtonContainer>
							<TabButton text="Kurallara dön" icon="arrow-left" onClick={() => navigate(`/vacations/rules`)} />
						</TabButtonContainer>
					</Col>
					<Row className="g-4">
						<>
							<Col md={12}>
								<Card>
									<Card.Header>
										<Card.Title className="mb-0" as="h5">
											Detaylar
										</Card.Title>
									</Card.Header>

									<Card.Body>
										<Form>
											<Row className="g-2">
												<Col md={3}>
													<Form.Label>Ad</Form.Label>
													<Controller
														name="name"
														control={control}
														rules={{ required: { value: true, message: 'Bu alan zorunludur.' } }}
														render={({ field }) => (
															<Form.Control {...field} type="text" placeholder="Ad" isInvalid={errors.name} />
														)}
													/>
													<Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
												</Col>
												<Col md={5}>
													<Form.Label>Açıklama</Form.Label>
													<Controller
														name="description"
														control={control}
														rules={{ required: { value: true, message: 'Bu alan zorunludur.' } }}
														render={({ field }) => (
															<Form.Control
																{...field}
																type="text"
																placeholder="Açıklama"
																isInvalid={errors.description}
															/>
														)}
													/>
													<Form.Control.Feedback type="invalid">{errors.description?.message}</Form.Control.Feedback>
												</Col>
												<Col md={4}>
													<Form.Label>İşlem</Form.Label>
													<ButtonContainer>
														<SaveButton onClick={handleSubmit((data) => handleSave(data))} />
														<DeleteButton onClick={handleSubmit((data) => handleDelete())} />
													</ButtonContainer>
												</Col>
											</Row>
										</Form>
									</Card.Body>
								</Card>
							</Col>

							<Col md={12}>
								<VacationTypesTable />
							</Col>

							<Col md={12}>
								<Card>
									<Card.Header>
										<Card.Title as="h5">İzin Kuralının Bağlı Olduğu Birimler</Card.Title>
									</Card.Header>
									<Card.Body>
										<p>Bu kural 25 çalışana atandı.</p>
									</Card.Body>
								</Card>
							</Col>
						</>
					</Row>
				</>
			)}
		</>
	)
}

export default VacationRuleEdit
