import { configureStore } from '@reduxjs/toolkit'

import documentReducer from './documentSlice'
import ikCompetenceAssessmentReducer from './ik/competence/ikCompetenceAssessmentSlice'
import ikCompetenceReducer from './ik/competence/ikCompetenceDefinitionSlice'
import ikCompetenceRatingReducer from './ik/competence/ikCompetenceRatingSlice'
import ikCompetenceTypeReducer from './ik/competence/ikCompetenceTypeDefinitionSlice'
import ikEmployeeEditReducer from './ikEmployeeEditSlice'
import ikOrganizationReducer from './ikOrganizationSlice'
import ikReducer from './ikSlice'
import ratingsCompetenceReducer from './ratingsCompetenceSlice'
import companyStructureReducer from './settings/companyStructureSlice'
import siteReducer from './siteSlice'

export default configureStore({
	reducer: {
		site: siteReducer,
		ik: ikReducer,
		ikCompetenceTypeDefinition: ikCompetenceTypeReducer,
		ikCompetenceDefinition: ikCompetenceReducer,
		ikCompetenceAssessment: ikCompetenceAssessmentReducer,
		ikCompetenceRating: ikCompetenceRatingReducer,
		ratingsCompetence: ratingsCompetenceReducer,
		ikOrganization: ikOrganizationReducer,
		document: documentReducer,
		ikEmployeeEdit: ikEmployeeEditReducer,
		companyStructure: companyStructureReducer
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware()
})
