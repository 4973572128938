import { CloseButton, DangerApproveButton, DeleteButton } from 'components/fast-ui'
import { ButtonContainer, FaIcon } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

const CompanyStructureDeleteModal = ({ show, onHide }) => {
	const params = useParams()
	const { item_id } = params

	const [companyStructure, setCompanyStructure] = useState({})

	const fetchCompanyStructure = async () => {
		try {
			const response = await Service.get(`/company-structures/${item_id}`)
			setCompanyStructure(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchCompanyStructure()
	}, [])

	const handleDelete = async () => {
		try {
			const response = await Service.delete(`/company-structures/${item_id}`)
			sendToast('Şirket yapısı silindi.', true)
			onHide()
		} catch (error) {
			const { status } = error.response
			const { message } = error.response.data

			if (status === 400) {
				sendToast(error.response.data.message, false)
			} else {
				sendToast(error.response.data.message, false)
			}
		}
	}

	return (
		<Modal show onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<div className="content-left">
					<Modal.Title as={'h5'}>
						<FaIcon icon="exclamation-triangle" className="me-2" />
						{companyStructure?.name} yapısı silinecek
					</Modal.Title>
				</div>
			</Modal.Header>
			<Modal.Body>
				<p>{companyStructure?.name} adlı yapı silinecek, onaylıyor musunuz?</p>
			</Modal.Body>
			<Modal.Footer>
				<ButtonContainer>
					<CloseButton onClick={onHide} />
					<DangerApproveButton onClick={handleDelete} />
				</ButtonContainer>
			</Modal.Footer>
		</Modal>
	)
}

export default CompanyStructureDeleteModal
