import { EditableText, FaIcon } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { Card } from 'react-bootstrap'
import useProjectStore from 'stores/projectStore'

import KanbanCard from '../ProjectTask'
import NewTask from './components/NewTaskButton'

const ProjectColumn = ({ data }) => {
	const [showAddTask, setShowAddTask] = useState(false)
	const refreshProject = useProjectStore((state) => state.refreshProject)

	const handleChangeTitle = async (value) => {
		const body = {
			title: value
		}

		try {
			const response = await Service.patch(`/project-columns/${data._id}/change-title`, body)
			refreshProject()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleArchive = async () => {
		try {
			const response = await Service.patch(`/project-columns/${data._id}/set-archived`)
			refreshProject()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<div key={data._id} className="kanban-column flex-shrink-0">
			<Card className="h-auto bg-light">
				<Card.Header className="py-2 mt-3">
					<Card.Title className="d-flex justify-content-between align-items-center">
						<EditableText value={data.title} className="bg-light" onBlur={handleChangeTitle} />
						<div class="dropdown">
							<button
								class="btn p-0"
								type="button"
								id="analyticsOptions"
								data-bs-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<FaIcon icon="ellipsis-h" className="fs-6 cursor-pointer" />
							</button>
							<div class="dropdown-menu dropdown-menu-end" aria-labelledby="analyticsOptions">
								<button class="dropdown-item" type="button" onClick={handleArchive}>
									Listeyi arşivle
								</button>
							</div>
						</div>
					</Card.Title>
				</Card.Header>
				<Droppable droppableId={data._id}>
					{(provided) => (
						<Card.Body ref={provided.innerRef} {...provided.droppableProps}>
							{data.tasks.map((item, index) => {
								if (item?.task) {
									return (
										<Draggable key={item.task._id} draggableId={item.task._id} index={index}>
											{(provided) => (
												<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
													<KanbanCard data={item.task} />
												</div>
											)}
										</Draggable>
									)
								} else {
									return null
								}
							})}
							{provided.placeholder}
						</Card.Body>
					)}
				</Droppable>
				<NewTask columnId={data._id} />
			</Card>
		</div>
	)
}

export default ProjectColumn
