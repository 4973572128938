import { CustomMarkdownEditor, CustomReactSelect, EditableText, FaIcon } from 'components/ui'
import taskStatuses from 'constants/taskStatuses'
import Service from 'global/Service'
import { formatDate } from 'global/functions/formatDate'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import ReactMarkdown from 'react-markdown'
import { useParams } from 'react-router-dom'
import useAuthStore from 'stores/authStore'
import useTaskStore from 'stores/taskStore'

import TasksActivity from './components/TasksActivity'
import TasksArchive from './components/TasksArchive'
import TasksDate from './components/TasksDate'

const DetailTaskModal = ({ onHide }) => {
	const params = useParams()
	const { task_id } = params

	const user = useAuthStore((state) => state.user)

	const task = useTaskStore((state) => state.task)
	const fetchTask = useTaskStore((state) => state.fetchTask)
	const refreshTask = useTaskStore((state) => state.refreshTask)
	const resetTask = useTaskStore((state) => state.resetTask)

	const handleClose = () => {
		resetTask()
		onHide && onHide()
	}

	const handleTitleChange = async (title) => {
		const body = { title }

		try {
			const response = await Service.patch(`/tasks/${task_id}/change-title`, body)
			sendToast(response.data.message, true)
			refreshTask()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleDescriptionChange = async (description) => {
		const body = { description }

		try {
			const response = await Service.patch(`/tasks/${task_id}/change-description`, body)
			sendToast(response.data.message, true)
			refreshTask()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleStatusChange = async (status) => {
		const body = { status }

		try {
			const response = await Service.patch(`/tasks/${task_id}/change-status`, body)
			sendToast(response.data.message, true)
			refreshTask()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleCompletedCloseClick = () => {
		handleStatusChange('waiting')
	}

	useEffect(() => {
		fetchTask(task_id)
	}, [])

	const { control } = useForm({ mode: 'onChange' })

	return (
		<Modal show onHide={handleClose} size="lg" centered>
			<Modal.Header closeButton className="border-0 mb-0 pb-0">
				<Modal.Title as="h5">
					{task && task.creator === user._id ? (
						<EditableText value={task?.title} onBlur={handleTitleChange} />
					) : (
						<p className="mb-0">{task?.title}</p>
					)}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col md={9} className="d-flex flex-column gap-3">
						<Form.Group>
							<h6>
								<FaIcon icon="align-left" className="me-2" />
								Açıklama
							</h6>
							{task && task.creator === user._id ? (
								<CustomMarkdownEditor value={task?.description} onBlur={handleDescriptionChange} />
							) : (
								<ReactMarkdown>{task?.description}</ReactMarkdown>
							)}
						</Form.Group>
						<Form.Group>
							<h6>
								<FaIcon icon="fa-solid fa-comment-lines" className="me-2" />
								Etkinlik
							</h6>
							<TasksActivity onSend={() => fetchTask()} />
						</Form.Group>
					</Col>

					<Col md={3}>
						<div className="mb-3">
							<h6>Durum</h6>
							{task?.status === 'completed' && task?.members?.find((member) => member._id === user._id) ? (
								<span
									className="d-flex justify-content-between align-items-center badge bg-success w-100"
									style={{ lineHeight: '1rem' }}
								>
									Tamamlandı
									<span type="button" onClick={handleCompletedCloseClick}>
										<FaIcon icon="times" className="ms-2" />
									</span>
								</span>
							) : (
								<Controller
									name="status"
									control={control}
									render={({ field }) => (
										<Form.Group>
											<CustomReactSelect
												value={taskStatuses.find((status) => status.value === task?.status)}
												onChange={(value) => {
													field.onChange(value)
													handleStatusChange(value.value)
												}}
												placeholder="Durum..."
												options={taskStatuses}
												getOptionValue={(option) => option.value}
												getOptionLabel={(option) => option.label}
											/>
										</Form.Group>
									)}
								/>
							)}
						</div>
						<div className="mb-3">
							<h6>Hedef Termin</h6>
							<span className="badge bg-light text-dark w-100" style={{ lineHeight: '1rem' }}>
								{formatDate(task?.endDate, false)}
							</span>
						</div>
						<div className="mb-3">
							<h6>Oluşturma Tarihi</h6>
							<span className="badge bg-light text-dark w-100" style={{ lineHeight: '1rem' }}>
								{formatDate(task?.createDate, false)}
							</span>
						</div>
						{task && task.creator === user._id && (
							<div className="mb-3">
								<h6>Hareketler</h6>
								<TasksDate />
								<TasksArchive />
							</div>
						)}
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
	)
}

export default DetailTaskModal
