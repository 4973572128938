import Service from 'global/Service'

import { createSlice } from '@reduxjs/toolkit'

export const ikCompetenceRatingSlice = createSlice({
	name: 'ikCompetenceRating',
	initialState: {
		ratingList: [],
		selectedRatingID: 0
	},
	reducers: {
		setRatingList: (state, action) => {
			state.ratingList = action.payload
		},
		setSelectedRatingID: (state, action) => {
			state.selectedRatingID = action.payload
		}
	}
})

export const { setRatingList, setSelectedRatingID } = ikCompetenceRatingSlice.actions

export const refreshRatingList = () => (dispatch) => {
	let promise = Service.get('https://api.devbont.site/api/competence-ratings')
	promise.then((response) => {
		dispatch(setRatingList(response.data))
	})
}

export default ikCompetenceRatingSlice.reducer
