import { CloseButton, DangerApproveButton, DeleteButton } from 'components/fast-ui'
import { FaIcon } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

const CompanyUnitDeleteModal = ({ show, onHide }) => {
	const params = useParams()
	const { sub_item_id } = params

	const [companyUnit, setCompanyUnit] = useState({})

	const fetchCompanyUnit = async () => {
		try {
			const response = await Service.get(`/company-units/${sub_item_id}`)
			setCompanyUnit(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchCompanyUnit()
	}, [])

	const handleDelete = async () => {
		try {
			const response = await Service.delete(`/company-units/${sub_item_id}`)
			sendToast('Şirket birimi silindi.', true)
			onHide()
		} catch (error) {
			const { status } = error.response
			const { message } = error.response.data

			if (status === 400) {
				sendToast(error.response.data.message, false)
			} else {
				sendToast(error.response.data.message, false)
			}
		}
	}

	return (
		<Modal show onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<div className="content-left">
					<Modal.Title as={'h5'}>
						<FaIcon icon="exclamation-triangle" className="me-2" />
						{companyUnit?.companyStructure?.name} birimi silinecek
					</Modal.Title>
				</div>
			</Modal.Header>
			<Modal.Body>
				<p>"{companyUnit?.name}" adlı birim silinecek, onaylıyor musunuz?</p>
			</Modal.Body>
			<Modal.Footer>
				<CloseButton onClick={onHide} />
				<DangerApproveButton onClick={handleDelete} />
			</Modal.Footer>
		</Modal>
	)
}

export default CompanyUnitDeleteModal
