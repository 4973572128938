import { SaveButton } from 'components/fast-ui'
import { NavButton, NavButtonContainer, TabButton, TabButtonContainer } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Col, Row, Tab, TabContainer, Tabs } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import useRoleStore from 'stores/settings/roleStore'

import RoleDeleteModal from './components/RoleDeleteModal'
import RoleEditor from './components/RoleEditor'
import RoleList from './components/RoleList'

const Roles = () => {
	const params = useParams()
	const navigate = useNavigate()

	const fetchRoles = useRoleStore((state) => state.fetchRoles)
	const roles = useRoleStore((state) => state.roles)
	const { tab_name, item_id: role_id, sub_item_id: process } = params

	const [showEditor, setShowEditor] = useState(false)
	const [isNew, setIsNew] = useState(false)

	const form = useForm({ mode: 'onChange' })
	const { reset } = form

	useEffect(() => {
		if (role_id) {
			setShowEditor(true)
		} else {
			setShowEditor(false)
		}
		if (role_id === 'add') {
			setIsNew(true)
		} else {
			setIsNew(false)
		}
	}, [params])

	useEffect(() => {
		fetchRoles()
	}, [])

	const fetchRole = async () => {
		try {
			const response = await Service.get(`/roles/${role_id}`)
			reset(response.data)
			console.log('reset: ', response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (role_id) {
			if (role_id === 'add') {
				reset({
					name: '',
					hexColor: '#000000',
					permissions: {}
				})
			} else {
				fetchRole()
			}
		}
	}, [role_id])

	return (
		<Row>
			<Col lg={2} md={4} sm={6}>
				<RoleList />
			</Col>
			<Col lg={10} md={8} sm={6}>
				{showEditor && (
					<>
						<RoleEditor form={form} isNew={isNew} />
					</>
				)}
			</Col>

			{role_id && process === 'delete' && (
				<RoleDeleteModal show={true} data={roles.find((role) => role._id === role_id)} />
			)}
		</Row>
	)
}

export default Roles
