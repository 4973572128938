import { CloseButton, CreateButton } from 'components/fast-ui'
import { CustomDatePicker, CustomFormCheck, CustomReactSelect, FaIcon } from 'components/ui'
import currencies from 'constants/currencies'
import extraSalaryPayments from 'constants/extraSalaryPayments'
import periods from 'constants/periods'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

const minimumWage2023 = 10008

const AddSalaryModal = () => {
	const params = useParams()
	const navigate = useNavigate()

	const { employee_id, tab_name, module_name, module_id } = params

	const [show, setShow] = useState(false)
	const [filteredExtraItems, setFilteredExtraItems] = useState([])

	const handleClose = () => {
		setShow(false)
		navigate(`/employees/${employee_id}/career/salaries`)
	}

	useEffect(() => {
		if (tab_name === 'career' && module_name === 'salaries' && module_id === 'add') {
			reset({})
			setShow(true)
		} else {
			setShow(false)
		}
	}, [params])

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		formState,
		setValue,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	const isMinimumWage = watch('isMinimumWage')
	const amountType = watch('amountType')

	useEffect(() => {
		if (isMinimumWage) {
			setValue('amount', minimumWage2023)
			setValue('payPeriod', 'monthly')
			setValue('currency', 'TRY')
			setValue('amountType', 'gross')
		}
	}, [isMinimumWage])

	//use array field
	const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
		control,
		name: 'items'
	})

	useEffect(() => {
		const extraItemsFiltered = extraSalaryPayments.filter((item) => {
			const found = fields.find((field) => field.type === item.value)
			return !found
		})
		setFilteredExtraItems(extraItemsFiltered)
	}, [fields])

	const handleAddPaymentItem = (e) => {
		const { value } = e
		append({
			amount: 0,
			currency: 'TRY',
			amountType: 'gross',
			type: value,
			affectPayroll: true,
			comment: '',
			payPeriod: 'monthly'
		})
	}

	const handleCreate = async (data) => {
		const body = {
			...data,
			employee: employee_id
		}

		try {
			const response = await Service.post('/salaries', body)
			sendToast('Maaş eklendi')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<>
			<Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<div className="content-left">
						<Modal.Title as={'h5'}>
							<FaIcon icon="plus" className="me-2" /> Maaş Ekle
						</Modal.Title>
					</div>
				</Modal.Header>
				<Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
					<Form>
						<Row>
							<Col md={6} className="mb-3">
								<Form.Label>Maaş Miktarı</Form.Label>
								<Form.Control
									type="number"
									disabled={isMinimumWage}
									{...register('amount', {
										required: 'Bu alan gerekli.'
									})}
								/>
								<Form.Control.Feedback type="invalid">{errors.amount && errors.amount.message}</Form.Control.Feedback>
							</Col>

							<Col md={6} className="mb-3">
								<Form.Label>Para Birimi</Form.Label>
								<Controller
									name="currency"
									control={control}
									defaultValue={currencies[0].value}
									render={({ field }) => (
										<CustomReactSelect
											isClearable
											isDisabled={isMinimumWage}
											value={currencies.filter((obj) => obj.value === field.value)}
											onChange={(d) => field.onChange(d?.value)}
											options={currencies}
											getOptionLabel={(option) => option.label}
											getOptionValue={(option) => option.value}
											placeholder="Para birimi.."
										/>
									)}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.currency && errors.currency.message}
								</Form.Control.Feedback>
							</Col>
							<Col md={6} className="mb-3">
								<Form.Label>Maaş Ödeme Periyodu</Form.Label>
								<Controller
									name="payPeriod"
									control={control}
									defaultValue={periods[0].value}
									render={({ field }) => (
										<CustomReactSelect
											isDisabled={isMinimumWage}
											value={periods.filter((obj) => obj.value === field.value)}
											onChange={(d) => field.onChange(d?.value)}
											options={periods}
											getOptionLabel={(option) => option.label}
											getOptionValue={(option) => option.value}
										/>
									)}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.payPeriod && errors.payPeriod.message}
								</Form.Control.Feedback>
							</Col>
							<Col md={6} className="mb-3">
								<Form.Label>Maaş Başlangıç Tarihi</Form.Label>
								<Controller
									name="effectiveOnDate"
									rules={{ required: 'Gerekli alan.' }}
									control={control}
									render={({ field }) => (
										<CustomDatePicker value={field.value} onChange={field.onChange} ref={field.ref} />
									)}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.effectiveOnDate && errors.effectiveOnDate.message}
								</Form.Control.Feedback>
							</Col>
							<Col md={12} className="mb-3">
								<Controller
									name="isMinimumWage"
									control={control}
									defaultValue={false}
									render={({ field }) => (
										<CustomFormCheck field={field} type="checkbox" label="Asgari Ücret" id="isMinimumWage">
											<Form.Text>Asgari ücret tanımlanır.</Form.Text>
										</CustomFormCheck>
									)}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.isMinimumWage && errors.isMinimumWage.message}
								</Form.Control.Feedback>
							</Col>

							<Col md={12}>
								<Controller
									name="amountType"
									control={control}
									defaultValue="gross"
									render={({ field }) => (
										<Row>
											<Col sm={6} className="mb-3">
												<CustomFormCheck field={field} type="radio" label="Brüt" value="gross" id="gross">
													<Form.Text>Çalışanın maaşı brüttür.</Form.Text>
												</CustomFormCheck>
											</Col>
											<Col sm={6} className="mb-3">
												<CustomFormCheck
													field={field}
													type="radio"
													label="Net"
													value="net"
													id="net"
													disabled={isMinimumWage}
												>
													<Form.Text>Çalışanın maaşı nettir.</Form.Text>
												</CustomFormCheck>
											</Col>
										</Row>
									)}
								/>
								<Form.Control.Feedback type="invalid">{errors.type && errors.type.message}</Form.Control.Feedback>
							</Col>
							{amountType === 'net' && (
								<Col md={12} className="mb-3">
									<Controller
										name="isAgiIncluded"
										control={control}
										defaultValue={false}
										render={({ field }) => (
											<CustomFormCheck
												field={field}
												type="checkbox"
												label="AGİ Dahil"
												id="isAgiIncluded"
												disabled={isMinimumWage}
											>
												<Form.Text>Asgari Geçim İndirimi maaş'a dahildir.</Form.Text>
											</CustomFormCheck>
										)}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.isAgiIncluded && errors.isAgiIncluded.message}
									</Form.Control.Feedback>
								</Col>
							)}
							<Col md={12} className="mb-3">
								<Form.Label>Ödeme ekle</Form.Label>
								<CustomReactSelect
									onChange={(e) => handleAddPaymentItem(e)}
									options={filteredExtraItems}
									getOptionLabel={(option) => option.label}
									getOptionValue={(option) => option.value}
									placeholder="Ödeme türü seçin.."
								/>
							</Col>
							<Col md={12}>
								{fields.map((item, index) => (
									<Card className="mb-3" key={index}>
										<Card.Header>
											<div className="d-flex justify-content-between">
												<Card.Title as="h6" className="mb-0">
													{extraSalaryPayments.find((x) => x.value === item.type).label}
												</Card.Title>
												<span onClick={() => remove(index)} className="text-danger cursor-pointer">
													<FaIcon icon="times" />
												</span>
											</div>
										</Card.Header>
										<Card.Body>
											<Row>
												<Col md={6} className="mb-3">
													<Form.Label>Ödeme Miktarı</Form.Label>
													<Controller
														name={`items[${index}].amount`}
														control={control}
														render={({ field }) => (
															<Form.Control
																{...field}
																type="number"
																isInvalid={errors.items && errors.items[index] && errors.items[index].amount}
															/>
														)}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.items &&
															errors.items[index] &&
															errors.items[index].amount &&
															errors.items[index].amount.message}
													</Form.Control.Feedback>
												</Col>
												<Col md={6} className="mb-3">
													<Form.Label>Para Birimi</Form.Label>
													<Controller
														name={`items[${index}].currency`}
														control={control}
														render={({ field }) => (
															<CustomReactSelect
																isClearable
																value={currencies.filter((obj) => obj.value === field.value)}
																onChange={(d) => field.onChange(d?.value)}
																options={currencies}
																getOptionLabel={(option) => option.label}
																getOptionValue={(option) => option.value}
																placeholder="Para birimi.."
																isInvalid={errors.items && errors.items[index] && errors.items[index].currency}
															/>
														)}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.items &&
															errors.items[index] &&
															errors.items[index].currency &&
															errors.items[index].currency.message}
													</Form.Control.Feedback>
												</Col>
												<Col md={6} className="mb-3">
													<Form.Label>Açıklama</Form.Label>
													<Controller
														name={`items[${index}].description`}
														control={control}
														render={({ field }) => (
															<Form.Control
																{...field}
																type="text"
																isInvalid={errors.items && errors.items[index] && errors.items[index].description}
															/>
														)}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.items &&
															errors.items[index] &&
															errors.items[index].description &&
															errors.items[index].description.message}
													</Form.Control.Feedback>
												</Col>

												{['fixed_bonus', 'health_insurance', 'pension'].includes(watch(`items[${index}].type`)) ===
													false && (
													<Col md={6} className="mb-3">
														<Form.Label>Hak Ediş Dönemi</Form.Label>
														<Controller
															name={`items[${index}].period`}
															control={control}
															render={({ field }) => (
																<CustomReactSelect
																	isClearable
																	value={periods.filter((obj) => obj.value === field.value)}
																	onChange={(d) => field.onChange(d?.value)}
																	options={periods}
																	getOptionLabel={(option) => option.label}
																	getOptionValue={(option) => option.value}
																	placeholder="Hak ediş dönemi.."
																	isInvalid={errors.items && errors.items[index] && errors.items[index].period}
																/>
															)}
														/>
														<Form.Control.Feedback type="invalid">
															{errors.items &&
																errors.items[index] &&
																errors.items[index].period &&
																errors.items[index].period.message}
														</Form.Control.Feedback>
													</Col>
												)}

												<Col md={12}>
													<Controller
														name={`items[${index}].amountType`}
														control={control}
														defaultValue={false}
														render={({ field }) => (
															<Row>
																<Col sm={6} className="mb-3">
																	<CustomFormCheck
																		field={field}
																		type="radio"
																		label="Brüt"
																		value="gross"
																		id={`gross-${index}`}
																	>
																		<Form.Text>Ödeme brüttür.</Form.Text>
																	</CustomFormCheck>
																</Col>
																<Col sm={6} className="mb-3">
																	<CustomFormCheck
																		field={field}
																		type="radio"
																		label="Net"
																		value="net"
																		id={`net-${index}`}
																	>
																		<Form.Text>Ödeme nettir.</Form.Text>
																	</CustomFormCheck>
																</Col>
															</Row>
														)}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.type && errors.type.message}
													</Form.Control.Feedback>
												</Col>
											</Row>
										</Card.Body>
									</Card>
								))}
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<CreateButton onClick={handleSubmit((d) => handleCreate(d))} />
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default AddSalaryModal
