import React, { useEffect } from 'react'
import { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

import DocumentApprovalHistory from './components/document-approval-history'
import DocumentApprovalRequests from './components/document-approval-requests'
import DocumentApproveModal from './components/document-approval-requests/components/DocumentApproveModal'
import DocumentRejectModal from './components/document-approval-requests/components/DocumentRejectModal'

const DocumentApproval = () => {
	const [showModal, setShowModal] = useState(null)
	const params = useParams()

	useEffect(() => {
		const { document_id, process_name } = params
		if (document_id && process_name) {
			if (process_name === 'approve') {
				setShowModal('approve')
			} else if (process_name === 'reject') {
				setShowModal('reject')
			} else if (process_name === 'view') {
				setShowModal('view')
			} else {
			}
		} else {
			setShowModal(null)
		}
	}, [params])

	return (
		<>
			<Container fluid="xxl" className="flex-grow-1 container-p-y">
				<Row className="g-4">
					<Col xs={12} className="mb-3">
						<DocumentApprovalRequests />
					</Col>
					<Col xs={12} className="mb-3">
						<DocumentApprovalHistory />
					</Col>
				</Row>
			</Container>

			{showModal === 'view' && <DocumentApproveModal show={showModal === 'view'} mode="view" />}
			{showModal === 'approve' && <DocumentApproveModal mode="approve" />}
			{showModal === 'reject' && <DocumentRejectModal />}
		</>
	)
}

export default DocumentApproval
