import '@szhsin/react-menu/dist/core.css'
import '@szhsin/react-menu/dist/index.css'

import cloudIcon from 'assets/img/icons/documents/general/cloud.png'
import likeIcon from 'assets/img/icons/documents/general/favorite.svg'
import shareIcon from 'assets/img/icons/documents/general/share.svg'
import trashIcon from 'assets/img/icons/documents/general/trash.png'
import { FaIcon } from 'components/ui'
import dateFormat from 'dateformat'
import prettyBytes from 'pretty-bytes'
import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { refreshTreeList, selectFolder, selectTrash } from 'stores/documentSlice'

import { MenuItem } from '@szhsin/react-menu'

import Folder from './components/Folder'
import FolderTree from './components/FolderTree'
import Storage from './components/Storage'
import TagList from './tag-list'

const DocumentLeft = () => {
	const dispatch = useDispatch()

	const [isOpen, setIsOpen] = useState(false)

	const { items, focusedFolder } = useSelector((state) => state.document.tree)
	const { info, rename } = useSelector((state) => state.document.context)
	const { selectedItems } = useSelector((state) => state.document.main)

	useEffect(() => {
		dispatch(refreshTreeList())
	}, [dispatch])

	const navigate = useNavigate()
	const folderHandleClick = (folderID) => {
		if (folderID === 'cloud') {
			navigate(`/documents`)
		} else if (folderID === 'trash') {
			navigate(`/documents/trash`)
		} else {
			navigate(`/documents/folder/${folderID}`)
		}

		//dispatch(selectFolder(folderID))
	}

	const toggleFolder = () => {
		setIsOpen(!isOpen)
	}

	const folderMenuItems = (
		<>
			<MenuItem onClick={folderHandleClick}>
				<span className="text-secondary">
					<FaIcon icon="folder" size="sm" className="me-2" /> Aç
				</span>
			</MenuItem>
		</>
	)

	return (
		<>
			<div className="row">
				<label className="form-label">Klasörler</label>
				<ul className="list-unstyled">
					<Folder
						_id={'cloud'}
						name="Cloud"
						onClick={folderHandleClick}
						openIcon={cloudIcon}
						closeIcon={cloudIcon}
						isHaveChild
					>
						<FolderTree folderList={items} parent_id={'cloud'} itemOnClick={folderHandleClick} />
					</Folder>
					<Folder
						_id={'shared'}
						name="Paylaşılanlar"
						onClick={folderHandleClick}
						openIcon={shareIcon}
						closeIcon={shareIcon}
						menuItems={folderMenuItems}
					/>
					<Folder
						_id={'favorites'}
						name="Favorilerim"
						onClick={folderHandleClick}
						openIcon={likeIcon}
						closeIcon={likeIcon}
						menuItems={folderMenuItems}
					/>
					<Folder
						_id={'trash'}
						name="Çöp Kutusu"
						onClick={folderHandleClick}
						openIcon={trashIcon}
						closeIcon={trashIcon}
						menuItems={folderMenuItems}
					/>
				</ul>
			</div>

			<TagList />

			<Storage />
		</>
	)
}

export default DocumentLeft
