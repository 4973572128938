import React, { useEffect, useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'

const CustomReactTable = (props) => {
	const data = useMemo(() => props.data)
	const columns = useMemo(() => props.columns)

	const { defaultSortDesc, defaultSortBy, pageSizeCount = 10 } = props

	const tableInstance = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: pageSizeCount,
				sortBy: [
					{
						id: defaultSortBy,
						desc: defaultSortDesc
					}
				]
			}
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		flatRows,
		prepareRow,
		page,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		pageOptions,
		gotoPage,
		pageCount,
		setPageSize,
		state,
		setGlobalFilter
	} = tableInstance

	const { globalFilter, pageIndex, pageSize } = state

	return (
		<>
			<table
				{...getTableProps()}
				className="table dataTable table-responsive table-striped border-top no-footer dtr-column"
				style={{ width: 1382 }}
			>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th className="control sorting_disabled" {...column.getHeaderProps(column.getSortByToggleProps())}>
									{column.render('Header')}
									<span>{column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}</span>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row) => {
						prepareRow(row)
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
								})}
							</tr>
						)
					})}
				</tbody>
			</table>
			<Row className="mx-2">
				<Col md={6} sm={12}>
					<div className="text-muted">
						Toplam {data.length} veri arasından {rows.length} sonuç
					</div>
				</Col>
				<Col md={6} sm={12}>
					<div className="d-flex justify-content-end">
						<ul className="pagination">
							<li className={'paginate_button page-item first ' + (canPreviousPage ? '' : 'disabled')}>
								<span className="page-link cursor-pointer" onClick={() => gotoPage(0)}>
									<i class="tf-icon bx bx-chevrons-left"></i>
								</span>
							</li>
							<li className={'paginate_button page-item prev ' + (canPreviousPage ? '' : 'disabled')}>
								<span className="page-link cursor-pointer" onClick={() => previousPage()}>
									<i class="tf-icon bx bx-chevron-left"></i>
								</span>
							</li>
							{pageOptions.map((page) => {
								return (
									<li className={'paginate_button page-item me-1 ' + (pageIndex === page ? 'active' : '')}>
										<span className="page-link cursor-pointer" onClick={() => gotoPage(page)}>
											{page + 1}
										</span>
									</li>
								)
							})}
							<li className={'paginate_button page-item next ' + (canNextPage ? '' : 'disabled')}>
								<span className="page-link cursor-pointer" onClick={() => nextPage()}>
									<i class="tf-icon bx bx-chevron-right"></i>
								</span>
							</li>
							<li className={'paginate_button page-item last ' + (canNextPage ? '' : 'disabled')}>
								<span className="page-link cursor-pointer" onClick={() => gotoPage(pageCount - 1)}>
									<i class="tf-icon bx bx-chevrons-right"></i>
								</span>
							</li>
						</ul>
					</div>
				</Col>
			</Row>
		</>
	)
}

export default CustomReactTable
