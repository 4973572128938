import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import React from 'react'
import { Card } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: 'top'
		}
	}
}

const labels = ['İlkokul', 'Ortaokul', 'Lise', 'Önlisans', 'Lisans', 'Yüksek Lisans']

const data = {
	labels,
	datasets: [
		{
			label: 'Kadın',
			data: [65, 59, 80, 81, 56, 55, 40],
			backgroundColor: 'rgba(255, 99, 132, 0.5)'
		},
		{
			label: 'Erkek',
			data: [28, 48, 40, 19, 86, 27, 90],
			backgroundColor: 'rgba(53, 162, 235, 0.5)'
		}
	]
}

const EducationLevelChart = () => {
	return (
		<Card>
			<Card.Header>
				<Card.Title as="h5" className="mb-0">
					Eğitim Durumu
				</Card.Title>
			</Card.Header>
			<Card.Body>
				<Bar options={options} data={data} height={250} />
			</Card.Body>
		</Card>
	)
}

export default EducationLevelChart
