import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const MenuItem = ({ path, toggle, title, icon, base, children }) => {
	const [openStatus, setOpenStatus] = useState(false)
	const [pathIsThisStatus, setPathIsThisStatus] = useState(false)

	const handleClick = () => {
		if (children) {
			console.log('children olduğu için menünün açık durumu değişti')
			setOpenStatus(!openStatus)
		} else {
			console.log('children olmadığı için menü açık durumu değişmedi')
		}
	}

	const isBase = base !== null && window.location.pathname.startsWith(base)

	useEffect(() => {
		setOpenStatus(isBase)
	}, [])

	useEffect(() => {
		if (window.location.pathname === path) {
			setPathIsThisStatus(true)
		} else {
			setPathIsThisStatus(false)
		}
	}, [window.location.pathname])

	return (
		<li className={`${pathIsThisStatus ? 'menu-item active' : 'menu-item'} ${openStatus ? 'open' : ''}`}>
			<Link
				to={path !== undefined ? path : '#'}
				onClick={handleClick}
				className={'menu-link' + (toggle ? ' menu-toggle' : '')}
			>
				{icon !== undefined ? <i className={'menu-icon tf-icons ' + icon} /> : ''}
				<div data-i18n={title}>{title}</div>
			</Link>
			{children && <ul className="menu-sub">{children}</ul>}
		</li>
	)
}

export default MenuItem
