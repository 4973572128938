import { ApproveButton } from 'components/fast-ui'
import { ButtonContainer, FaIcon } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import useProjectStore from 'stores/projectStore'

const NewColumnButton = () => {
	const params = useParams()
	const { project_id } = params

	const refreshProject = useProjectStore((state) => state.refreshProject)

	const [showInput, setShowInput] = useState(false)
	const [title, setTitle] = useState('')

	const handleTitleChange = (e) => {
		setTitle(e.target.value)
	}

	const toggleAddList = () => {
		setShowInput(!showInput)
	}

	const handleSend = async () => {
		const body = {
			title: title,
			project: project_id
		}

		try {
			const response = await Service.post('/project-columns', body)
			sendToast('Liste oluşturuldu.', true)
			refreshProject()
			setTitle('')
			setShowInput(false)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<div>
			{showInput ? (
				<div className="kanban-column cursor-pointer">
					<Card className="border-0">
						<Card.Title as="h5" className="w-75">
							<Form.Control
								className="bg-light mb-2"
								type="text"
								placeholder="Liste başlığı girin"
								value={title}
								onChange={handleTitleChange}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										handleSend()
									}
								}}
							/>
							<ButtonContainer className="d-flex justify-content-start align-items-center">
								<ApproveButton onClick={handleSend} size="sm" />
								<FaIcon icon="times" className="float-end cursor-pointer" size="sm" onClick={toggleAddList} />
							</ButtonContainer>
						</Card.Title>
					</Card>
				</div>
			) : (
				<div className="kanban-column cursor-pointer" onClick={toggleAddList}>
					<Card className="mb-4 py-2 border-0" style={{ opacity: 0.5 }}>
						<Card.Title as="h5" className="ps-4 py-2 mb-0">
							<FaIcon icon="plus" /> Başka liste ekleyin
						</Card.Title>
					</Card>
				</div>
			)}
		</div>
	)
}

export default NewColumnButton
