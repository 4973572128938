import anonimProfilePhoto from 'assets/img/avatars/anonim-profile-photo.jpg'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import useAuthStore from 'stores/authStore'
import { enableExpanded, setDarkMode, setLightMode } from 'stores/siteSlice'

import Notifications from './components/Notifications'

const Navbar = () => {
	const user = useAuthStore((state) => state.user)
	const logout = useAuthStore((state) => state.logout)
	const dispatch = useDispatch()

	const handleMenuClick = () => {
		dispatch(enableExpanded())
	}

	return (
		<nav className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme" id="layout-navbar">
			<div className="container-fluid">
				<div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0   d-xl-none ">
					<a className="nav-item nav-link px-0 me-xl-4" href="#">
						<i onClick={handleMenuClick} className="bx bx-menu bx-sm" />
					</a>
				</div>
				<div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
					{/* <div className="navbar-nav align-items-center">
						<div className="nav-item navbar-search-wrapper mb-0">
							<a className="nav-item nav-link search-toggler px-0" href="#">
								<i className="bx bx-search-alt bx-sm" />
								<span className="d-none d-md-inline-block text-muted"> Ara (Ctrl+/)</span>
							</a>
						</div>
					</div> */}
					<ul className="navbar-nav flex-row align-items-center ms-auto">
						<Notifications />

						<li className="nav-item navbar-dropdown dropdown-user dropdown">
							<a className="nav-link dropdown-toggle hide-arrow" href="#" data-bs-toggle="dropdown">
								<div className="avatar avatar-online">
									{user.profilePhotoUrl ? (
										<img src={user.profilePhotoUrl} className="rounded-circle" style={{}} />
									) : (
										<img src={anonimProfilePhoto} alt="Avatar" className="rounded-circle" />
									)}
								</div>
							</a>
							<ul className="dropdown-menu dropdown-menu-end">
								<li>
									<Link to="/profile/profile" className="dropdown-item">
										<div className="d-flex">
											<div className="flex-shrink-0 me-3">
												<div className="avatar avatar-online">
													{user.profilePhotoUrl ? (
														<img src={user.profilePhotoUrl} className="rounded-circle" style={{}} />
													) : (
														<img src={anonimProfilePhoto} alt="Avatar" className="rounded-circle" />
													)}
												</div>
											</div>
											<div className="flex-grow-1">
												<span className="fw-semibold d-block lh-1">{user.fullName}</span>

												<small>
													{user.position?.title?.name} - {user.position?.department?.name}
												</small>
											</div>
										</div>
									</Link>
								</li>
								<li>
									<div className="dropdown-divider" />
								</li>
								<li>
									<Link to="/profile/profile" className="dropdown-item">
										<i className="bx bx-user me-2" />
										<span className="align-middle">Profilim</span>
									</Link>
								</li>
								<li>
									<Link to="/document-approval" className="dropdown-item">
										<i className="bx bx-check me-2" />
										<span className="align-middle">Onaylarım</span>
									</Link>
								</li>
								<li>
									<Link to="/workspaces" className="dropdown-item">
										<i className="bx bx-group me-2" />
										<span className="align-middle">Çalışma Alanlarım</span>
									</Link>
								</li>
								<li>
									<Link to="/my-ratings" className="dropdown-item">
										<i className="bx bx-line-chart me-2" />
										<span className="align-middle">Değerlendirmelerim</span>
									</Link>
								</li>
								<li>
									<a className="dropdown-item" href="/profile">
										<i className="bx bx-paper-plane me-2" />
										<span className="align-middle">Taleplerim</span>
									</a>
								</li>
								<li>
									<Link to="/profile/settings" className="dropdown-item">
										<i className="bx bx-cog me-2" />
										<span className="align-middle">Ayarlar</span>
									</Link>
								</li>

								<li>
									<div className="dropdown-divider" />
								</li>
								<li>
									<a className="dropdown-item" href="#" onClick={logout}>
										<i className="bx bx-power-off me-2" />
										<span className="align-middle">Çıkış yap</span>
									</a>
								</li>
							</ul>
						</li>
					</ul>
				</div>

				<div className="navbar-search-wrapper search-input-wrapper d-none">
					<span className="twitter-typeahead" style={{ position: 'relative', display: 'inline-block' }}>
						<input
							type="text"
							className="form-control search-input container-fluid border-0 tt-input"
							placeholder="Search..."
							aria-label="Search..."
							autoComplete="off"
							spellCheck="false"
							dir="auto"
							style={{ position: 'relative', verticalAlign: 'top' }}
						/>
						<pre
							aria-hidden="true"
							style={{
								position: 'absolute',
								visibility: 'hidden',
								whiteSpace: 'pre',
								fontFamily:
									'"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
								fontSize: 15,
								fontStyle: 'normal',
								fontVariant: 'normal',
								fontWeight: 400,
								wordSpacing: 0,
								letterSpacing: 0,
								textIndent: 0,
								textRendering: 'auto',
								textTransform: 'none'
							}}
						/>
						<div
							className="tt-menu navbar-search-suggestion ps"
							style={{ position: 'absolute', top: '100%', left: 0, zIndex: 100, display: 'none' }}
						>
							<div className="tt-dataset tt-dataset-pages" />
							<div className="tt-dataset tt-dataset-files" />
							<div className="tt-dataset tt-dataset-members" />
							<div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
								<div className="ps__thumb-x" tabIndex={0} style={{ left: 0, width: 0 }} />
							</div>
							<div className="ps__rail-y" style={{ top: 0, right: 0 }}>
								<div className="ps__thumb-y" tabIndex={0} style={{ top: 0, height: 0 }} />
							</div>
						</div>
					</span>
					<i className="bx bx-x bx-sm search-toggler cursor-pointer" />
				</div>
			</div>
		</nav>
	)
}

export default Navbar
