import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'

import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

const EmojiPicker = ({ onSelect }) => {
	const [emoji, setEmoji] = useState('😀')
	const [showPicker, setShowPicker] = useState(false)
	const buttonRef = useRef(null)
	const popoverRef = useRef(null)
	const { styles, attributes } = usePopper(buttonRef.current, popoverRef.current, {
		placement: 'bottom-start'
	})

	const handleClick = () => {
		console.log(buttonRef)
		setShowPicker(!showPicker)
	}

	const handleEmojiSelect = (e) => {
		setEmoji(e.native)
		onSelect && onSelect(e.native)
	}

	const handleClickOutside = (event) => {
		if (
			popoverRef.current &&
			!popoverRef.current.contains(event.target) &&
			buttonRef.current &&
			!buttonRef.current.contains(event.target)
		) {
			setShowPicker(false)
		}
	}

	useEffect(() => {
		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	return (
		<span>
			<Form.Control type="text" value={emoji} ref={buttonRef} onClick={handleClick} />
			{createPortal(
				<div
					ref={popoverRef}
					style={{ ...styles.popper, zIndex: 9999 }}
					{...attributes.popper}
					className={`${showPicker ? '' : 'd-none'}`}
				>
					<Picker data={data} onEmojiSelect={handleEmojiSelect} theme="light" locale="tr" />
				</div>,
				document.body
			)}
		</span>
	)
}

export default EmojiPicker
