import React from 'react'
import { Modal } from 'react-bootstrap'

import { CloseButton, DangerApproveButton } from 'components/fast-ui'
import { FaIcon } from 'components/ui'

const DeleteModal = ({ name, show, onHide, onOk }) => {
	return (
		<Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<div className="content-left">
					<Modal.Title as="h5">
						<FaIcon icon="exclamation-triangle" className="me-2" />
						{name} silinecek
					</Modal.Title>
				</div>
			</Modal.Header>
			<Modal.Body>
				<p>{name} silinecek, onaylıyor musunuz?</p>
			</Modal.Body>
			<Modal.Footer>
				<CloseButton onClick={onHide} />
				<DangerApproveButton onClick={onOk} />
			</Modal.Footer>
		</Modal>
	)
}

export default DeleteModal
