import { ApproveButton, CloseButton, DeleteButton, RejectButton } from 'components/fast-ui'
import { Approvers, ButtonContainer, FaIcon } from 'components/ui'
import { format } from 'date-fns'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Modal, Table } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

const VacationViewModal = ({ vacationId, show, showApprovers, showApproveButtons, onHide, onApprove, onReject }) => {
	const params = useParams()
	const [data, setData] = useState({})

	const fetchData = async () => {
		try {
			const response = await Service.get(`/vacations/${vacationId}`)
			setData(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (vacationId) {
			fetchData()
		}
	}, [params])

	const handleClose = () => {
		onHide && onHide()
	}

	const handleApprove = () => {
		onApprove && onApprove()
	}

	const handleReject = () => {
		onReject && onReject()
	}

	return (
		<Modal show={show} onHide={handleClose} area-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title as="h5">İzin İncele</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="d-flex align-items-center mb-4">
					<div className="avatar avatar-xl">
						<img src="https://via.placeholder.com/500" alt="avatar" />
					</div>
					<div className="ms-3">
						<h6 className="mb-2">
							{data?.requester?.firstName} {data?.requester?.lastName}
						</h6>
						<h5>
							<b>{data?.type?.name}</b> için{' '}
							<b>
								{data.startDate && data.endDate
									? Math.floor((new Date(data.endDate) - new Date(data.startDate)) / (1000 * 60 * 60 * 24))
									: 0}
							</b>{' '}
							gün izin talep ediyor.
						</h5>
					</div>
				</div>

				<div className="d-flex justify-content-center mb-4">
					<small>
						{data.status === 'pending' && (
							<div className="rounded-pill bg-secondary text-dark px-3 py-2">
								<span className="mb-0">
									<FaIcon icon="clock" className="me-2" />
									Bu talep için onay süreci devam ediyor.
								</span>
							</div>
						)}
						{data.status === 'approved' && (
							<div className="rounded-pill bg-success text-white px-3 py-2">
								<span className="mb-0">
									<FaIcon icon="check" className="me-2" />
									Bu talep onaylandı.
								</span>
							</div>
						)}

						{data.status === 'rejected' && (
							<div className="rounded-pill bg-danger text-white px-3 py-2">
								<span className="mb-0">
									<FaIcon icon="times" className="me-2" />
									Bu talep reddedildi.
								</span>
							</div>
						)}

						{data.status === 'canceled' && (
							<div className="rounded-pill bg-dark text-white px-3 py-2">
								<span className="mb-0">
									<FaIcon icon="times" className="me-2" />
									Bu talep iptal edildi.
								</span>
							</div>
						)}
					</small>
				</div>
				<Table striped bordered hover className="mb-3">
					<tbody>
						{true && (
							<>
								<tr>
									<td>İzin Türü</td>
									<td>{data?.type?.name}</td>
								</tr>
								<tr>
									<td>İzin Başlangıç Tarihi</td>
									<td>{data.startDate ? format(new Date(data?.startDate), 'dd/MM/yyyy HH:mm') : 'yok'}</td>{' '}
								</tr>
								<tr>
									<td>İzin Bitiş Tarihi</td>
									<td>{data.endDate ? format(new Date(data.endDate), 'dd/MM/yyyy HH:mm') : 'yok'}</td>{' '}
								</tr>
								<tr>
									<td>İzin Süresi</td>
									<td>
										{data.startDate && data.endDate
											? Math.floor((new Date(data.endDate) - new Date(data.startDate)) / (1000 * 60 * 60 * 24))
											: 0}{' '}
										gün
									</td>
								</tr>
								<tr>
									<td>İzin Durumu</td>
									<td>
										{data.status === 'pending' && <span className="badge bg-warning">Beklemede</span>}
										{data.status === 'approved' && <span className="badge bg-success">Onaylandı</span>}
										{data.status === 'rejected' && <span className="badge bg-danger">Reddedildi</span>}
										{data.status === 'canceled' && <span className="badge bg-secondary">İptal Edildi</span>}
									</td>
								</tr>
								<tr>
									<td>İzin Açıklaması</td>
									<td>{data.description}</td>{' '}
								</tr>
								{data.cancelReason && (
									<tr>
										<td>İptal Sebebi</td>
										<td>{data.cancelReason}</td>
									</tr>
								)}
							</>
						)}
					</tbody>
				</Table>

				{showApprovers && <Approvers data={data.approvers} />}
			</Modal.Body>
			<Modal.Footer>
				{showApproveButtons && (
					<ButtonContainer>
						<RejectButton onClick={handleReject} />
						<ApproveButton onClick={handleApprove} />
					</ButtonContainer>
				)}
			</Modal.Footer>
		</Modal>
	)
}

VacationViewModal.propTypes = {
	vacationId: PropTypes.string,
	show: PropTypes.bool,
	showApprovers: PropTypes.bool,
	showApproveButtons: PropTypes.bool,
	onHide: PropTypes.func,
	onApprove: PropTypes.func,
	onReject: PropTypes.func
}

export default VacationViewModal
