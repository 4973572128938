import { AddButton, CreateButton } from 'components/fast-ui'
import { FaIcon } from 'components/ui'
import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import CompanyUnitTable from './company-unit-table'

const CompanyUnitCard = (props) => {
	const navigate = useNavigate()
	const [showTable, setShowTable] = useState(true)

	const { companyStructure } = props

	const toggleTable = () => {
		setShowTable(!showTable)
	}

	const handleAddCompanyUnit = () => {
		navigate(`/settings/company-structure/${companyStructure._id}/add`)
	}

	return (
		<Card className="mb-3">
			<Card.Body className="pb-1">
				<div className="d-flex align-items-center justify-content-between mb-3">
					<Card.Title as="h6" onClick={toggleTable} className="cursor-pointer mb-0">
						{companyStructure.name}{' '}
						{showTable ? <FaIcon icon="chevron-down" size="xs" /> : <FaIcon icon="chevron-right" size="xs" />}
					</Card.Title>
					{companyStructure.type !== 'company' && <AddButton size="sm" onClick={handleAddCompanyUnit} />}
				</div>

				{showTable && <CompanyUnitTable companyStructure={companyStructure} />}
			</Card.Body>
		</Card>
	)
}

export default CompanyUnitCard
