import { FaIcon } from 'components/ui'
import dateFormat from 'dateformat'
import DocumentIcon from 'pages/document/components/document-icon'
import DocumentTag from 'pages/document/components/document-tag'
import prettyBytes from 'pretty-bytes'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	addTagToDocuments,
	removeTagFromDocuments,
	selectFolder,
	setContextInfoFromID,
	setContextRenameID,
	setContextRenameValue,
	setFocusedFolderContent
} from 'stores/documentSlice'

import { ControlledMenu, MenuDivider, MenuItem, SubMenu, useMenuState } from '@szhsin/react-menu'

const DocumentItem = (props) => {
	const dispatch = useDispatch()

	const [menuProps, toggleMenu] = useMenuState()
	const [isOpen, setOpen] = useState()
	const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })

	const [isHovering, setIsHovering] = useState(false)
	const [clickTimeOut, setClickTimeOut] = useState(null)
	const [isFavorite, setIsFavorite] = useState(false) // Favori durumu için state eklendi

	const { selectedItems, detailedView } = useSelector((state) => state.document.main)

	const handleMouseOver = () => {
		setIsHovering(true)
	}

	const handleMouseOut = () => {
		setIsHovering(false)
	}

	const handleClick = (e) => {
		dispatch(setFocusedFolderContent({}))

		if (clickTimeOut !== null) {
			props.onDoubleClick(e, props.data)
			clearTimeout(clickTimeOut)
			setClickTimeOut(null)
		} else {
			props.onClick(e, props.data)
			setClickTimeOut(
				setTimeout(() => {
					clearTimeout(clickTimeOut)
					setClickTimeOut(null)
				}, 500)
			)
		}
	}

	const handleContextMenu = (e) => {
		e.preventDefault()
		props.selectionResetDedector(props.data)
		setAnchorPoint({ x: e.clientX, y: e.clientY })
		toggleMenu(true)
		setOpen(true)
	}

	const handleClose = () => {
		toggleMenu(false)
		setOpen(false)
	}

	const toggleFavorite = () => {
		setIsFavorite(!isFavorite)
	}

	return (
		<>
			{detailedView && (
				<tr
					style={{ cursor: 'pointer' }}
					onClick={handleClick}
					onMouseOver={handleMouseOver}
					onMouseOut={handleMouseOut}
					onContextMenu={handleContextMenu}
				>
					<td className={props.selected || isHovering ? 'text-primary' : 'text-dark'}>
						<DocumentIcon
							className="me-2"
							extension={props.data.file?.extension}
							style={{ width: 'auto', height: 16 }}
						/>
						<span>{props.data.digitalName.substring(0, 30)}</span>
					</td>
					<td>{props.data.file?.extension}</td>
					<td>{props.data.file ? prettyBytes(props.data.file.size) : ''}</td>
					<td>
						{props.data.tags?.slice(0, 3).map((tag) => (
							<DocumentTag name={tag.name} hexColor={tag.hexColor} />
						))}
					</td>
					<td>{props.data.createDate && dateFormat(new Date(props.data.createDate), 'yyyy-mm-dd hh:MM')}</td>
				</tr>
			)}

			{!detailedView && (
				<div
					onClick={handleClick}
					onMouseOver={handleMouseOver}
					onMouseOut={handleMouseOut}
					onContextMenu={handleContextMenu}
					type="button"
					className={`d-inline-flex mb-3 me-3 border rounded text-center ${isOpen && 'border-dark'} ${
						!isOpen && props.selected && 'border-primary'
					}`}
					style={{
						width: 120,
						height: 120,
						flexDirection: 'column',
						verticalAlign: 'top',
						opacity: props.data.status === 'pending' ? 0.5 : 1
					}}
				>
					{['png', 'jpg', 'jpeg', 'gif'].includes(props.data.file?.extension) ? (
						<div className="d-flex justify-content-center align-items-start h-75">
							<img src={`${props.data.url}`} style={{ width: '100%', height: 80, objectFit: 'cover' }} />
						</div>
					) : (
						<div className="d-flex justify-content-center align-items-center h-75">
							<DocumentIcon
								className="mt-2 mb-1"
								extension={props.data.file?.extension}
								style={{ width: 'auto', height: 36 }}
							/>
						</div>
					)}

					<div className="position-relative text-truncate px-2">
						{props.data.tags?.slice(0, 3).map((tag) => (
							<DocumentTag name={tag.name} hexColor={tag.hexColor} />
						))}
						<span>{props.data.digitalName}</span>
						{isHovering && (
							<span onClick={handleContextMenu} className="position-absolute bottom-0 end-0 px-2">
								<i className="bx bx-dots-horizontal-rounded rounded-circle bg-light" />
							</span>
						)}
					</div>
				</div>
			)}
			<ControlledMenu
				className="d-inline-flex position-absolute"
				{...menuProps}
				anchorPoint={anchorPoint}
				onClose={handleClose}
			>
				<MenuItem
					onClick={(e) => {
						props.isFolder === true ? dispatch(selectFolder(props.data._id)) : handleClick(e)
					}}
				>
					<span>
						<FaIcon icon="folder" size="sm" className="me-2" />
						Aç
					</span>
				</MenuItem>
				<MenuDivider />
				<MenuItem
					onClick={() => dispatch(setContextInfoFromID(props.data._id))}
					data-bs-toggle="modal"
					data-bs-target="#folderInfoModal"
				>
					<span>
						<FaIcon icon="circle-info" size="sm" className="me-2" />
						Bilgi
					</span>
				</MenuItem>
				<MenuItem onClick={() => dispatch(setContextInfoFromID(props.data._id))}>
					<span onClick={toggleFavorite}>
						<FaIcon
							type={isFavorite ? 'solid' : 'regular'}
							icon="fa fa-heart"
							size="sm"
							className={isFavorite ? 'text-danger me-2' : 'me-2'}
						/>
						Favori
					</span>
				</MenuItem>
				<SubMenu
					label={
						<span>
							<FaIcon icon="tag" size="sm" className="me-2" />
							Etiket
						</span>
					}
				>
					{props.tagList.map((tag) => {
						const tagActive =
							selectedItems.filter((item) => item.tags.some((x) => x._id === tag._id)).length === selectedItems.length
						return (
							<>
								<MenuItem
									onClick={(e) => {
										e.keepOpen = true
										tagActive ? dispatch(removeTagFromDocuments(tag)) : dispatch(addTagToDocuments(tag))
									}}
								>
									<span style={{ color: tag.hexColor }}>
										<FaIcon type={tagActive ? 'solid' : 'regular'} icon="tag" size="sm" className="me-2" /> {tag.name}
									</span>
								</MenuItem>
							</>
						)
					})}
				</SubMenu>
				<MenuDivider />
				{selectedItems.length === 1 && (
					<MenuItem
						onClick={(e) => {
							e.keepOpen = true
							dispatch(setContextRenameID(props.data._id))
							dispatch(setContextRenameValue(props.data.digitalName))
						}}
						data-bs-toggle="modal"
						data-bs-target="#renameModal"
					>
						<span>
							<FaIcon icon="pen-to-square" size="sm" className="me-2" />
							Adlandır
						</span>
					</MenuItem>
				)}
				<MenuItem data-bs-toggle="modal" data-bs-target="#moveModal">
					<span>
						<FaIcon icon="arrows-up-down-left-right" size="sm" className="me-2" />
						Taşı
					</span>
				</MenuItem>
				<MenuDivider />
				<MenuItem data-bs-toggle="modal" data-bs-target="#deletePermanentModal">
					<span>
						<FaIcon icon="trash" size="sm" className="me-2" />
						Sil
					</span>
				</MenuItem>
			</ControlledMenu>
		</>
	)
}

export default DocumentItem
