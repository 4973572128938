import { CreateButton } from 'components/fast-ui'
import { CustomReactTable, FaIcon } from 'components/ui/index.js'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useMemo, useState } from 'react'
import { Card, Col } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'

const columns = [
	{
		Header: 'Ad',
		accessor: 'name',
		Cell: (cell) => {
			return <span className="text-truncate d-flex align-items-center">{cell.row.original.name}</span>
		}
	},
	{
		Header: 'Açıklama',
		accessor: 'description'
	},

	{
		Header: 'Seçenekler',
		accessor: 'options',
		Cell: (cell) => {
			return (
				<div className="d-flex align-items-center">
					<div className="d-flex align-items-center">
						<Link to={`/settings/holiday-calendars/${cell.row.original._id}`} className="text-primary me-3">
							<FaIcon icon="edit" />
						</Link>
					</div>
				</div>
			)
		}
	}
]

const HolidayCalendarsTable = () => {
	const params = useParams()
	const [holidayCalendars, setHolidayCalendars] = useState([])

	const fetchData = async () => {
		try {
			const response = await Service.get('/holiday-calendars')
			setHolidayCalendars(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const data = useMemo(() => holidayCalendars)

	useEffect(() => {
		fetchData()
	}, [params])

	return (
		<>
			<Col lg={12} md={12} sm={12} xs={12}>
				<Card>
					<Card.Header>
						<Card.Title as="h5">Tatil Takvimleri</Card.Title>
					</Card.Header>
					<Card.Body>
						<CustomReactTable data={data} columns={columns} />
					</Card.Body>
				</Card>
			</Col>
		</>
	)
}

export default HolidayCalendarsTable
