import axios from 'axios'
import { DownloadButton } from 'components/fast-ui'
import { TabButton, TabButtonContainer } from 'components/ui'
import dateFormat from 'dateformat'
import Service from 'global/Service'
import { formatDate } from 'global/functions/formatDate'
import prettyBytes from 'pretty-bytes'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setMainPreviewIsReviseTab } from 'stores/documentSlice'

import ReviseContainer from './components/ReviseContainer'

const FileDownload = require('js-file-download')

const DocumentRight = () => {
	const dispatch = useDispatch()
	const [selectedItemOne, setSelectedItemOne] = useState()
	const [previewURL, setPreviewURL] = useState()
	const [fileName, setFileName] = useState()
	const { selectedItems, preview } = useSelector((state) => state.document.main)

	useEffect(() => {
		if (selectedItems.length > 0) {
			if (selectedItems[0].isFolder === false) {
				setSelectedItemOne(selectedItems[0])
			} else {
				setSelectedItemOne(null)
			}
		}
	}, [selectedItems])

	useEffect(() => {
		setPreviewURL(`${selectedItemOne?.url}`)
		setFileName(`${selectedItemOne?.digitalName}.${selectedItemOne?.file.extension}`)
	}, [selectedItemOne])

	const openWindow = (url) => {
		window.open(url)
	}

	const downloadFile = (url, fileName) => {
		//resim ise yeni sekmede aç
		if (selectedItemOne?.file?.mimeType?.includes('image')) {
			openWindow(url)
			return
		}
		axios({
			url: url,
			method: 'GET',
			responseType: 'blob'
		}).then((response) => {
			// create file link in browser's memory
			const href = URL.createObjectURL(response.data)

			// create "a" HTML element with href to file & click
			const link = document.createElement('a')
			link.href = href
			link.setAttribute('download', fileName)
			link.setAttribute('target', '_blank') // Open in a new tab
			document.body.appendChild(link)
			link.click()

			// clean up "a" element & remove ObjectURL
			document.body.removeChild(link)
			URL.revokeObjectURL(href)
		})
	}

	return (
		<>
			<div className="d-flex align-items-center mt-1">
				<TabButtonContainer>
					<TabButton
						onClick={() => dispatch(setMainPreviewIsReviseTab(false))}
						icon={'circle-info'}
						text="Bilgi"
						active={!preview.isReviseTab}
					/>
					<TabButton
						onClick={() => dispatch(setMainPreviewIsReviseTab(true))}
						icon={'rectangle-history'}
						text="Revize"
						active={preview.isReviseTab}
					/>
				</TabButtonContainer>
			</div>

			<hr />

			{preview.isReviseTab && selectedItems.length === 1 && selectedItemOne?.isFolder === false && (
				<div className="overflow-auto" style={{ minHeight: 500, maxHeight: 520, overflowY: 'scroll' }}>
					<ReviseContainer data={selectedItemOne?.revisions} />
				</div>
			)}

			{!preview.isReviseTab && selectedItems.length === 1 && selectedItemOne?.isFolder === false && (
				<div>
					{selectedItemOne?.file?.mimeType?.includes('image') && (
						<div className="mt-3">
							<img src={previewURL} className="img-fluid" alt="" />
						</div>
					)}
					<div className="demo-inline-spacing">
						{/* <button onClick={() => openWindow(previewURL)} type="button" className="btn btn-primary">
							Görüntüle
						</button> */}
						<DownloadButton onClick={() => downloadFile(previewURL, fileName)} />
					</div>

					<div className="mt-4">
						<div className="row">
							<div className="col-md-4">
								<p className="fw-semibold">Dosya adı</p>
							</div>
							<div className="col-md-8">
								<p style={{ wordBreak: 'break-word' }}>{fileName}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<p className="fw-semibold">Boyut</p>
							</div>
							<div className="col-md-8">
								<p>{prettyBytes(selectedItemOne.file.size)}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<p className="fw-semibold">Oluşturma</p>
							</div>
							<div className="col-md-8">
								<p>{dateFormat(selectedItemOne.createDate, 'dd.mm.yyyy HH:MM')}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<p className="fw-semibold">Değiştirme</p>
							</div>
							<div className="col-md-8">
								<p>{formatDate(selectedItemOne.updateDate)}</p>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default DocumentRight
