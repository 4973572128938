import { FaIcon } from 'components/ui'
import React from 'react'
import { Nav } from 'react-bootstrap'

const TabButton = ({ className, icon, text, active = false, ...rest }) => {
	return (
		<Nav.Item>
			<li className="nav-item">
				<button type="button" className={`nav-link ${active ? 'active' : null} ${className && className}`} {...rest}>
					{icon && <FaIcon type={active ? 'solid' : 'regular'} icon={icon} className={text && 'me-2'} />}
					<span>{text}</span>
				</button>
			</li>
		</Nav.Item>
	)
}

export default TabButton
