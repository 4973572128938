import { FaIcon } from 'components/ui'
import React from 'react'

const ApprovalStepItem = ({ stepNo, name, imgSrc, icon, data }) => {
	const profilePhotoURL = data?.employee.profilePhotoUrl

	return (
		<div className="d-flex align-items-center gap-3 border rounded pe-1">
			{profilePhotoURL && (
				<div class="avatar avatar-md">
					<img src={profilePhotoURL} alt="..." className="" />
				</div>
			)}
			{icon ? (
				<div
					className="d-flex align-items-center justify-content-center border-end px-3"
					style={{ height: 48, width: 48 }}
				>
					<FaIcon icon={icon} />
				</div>
			) : (
				!profilePhotoURL && (
					<div class="avatar avatar-md">
						<span class="avatar-initial">{data?.employee?.firstName[0] + data?.employee?.lastName[0]}</span>
					</div>
				)
			)}
			<div>
				<a className="d-block me-2">{name}</a>
				{stepNo ? <div className="text-body small">#{stepNo}</div> : null}
			</div>
		</div>
	)
}

export default ApprovalStepItem
