import React from 'react'
import { useRef } from 'react'

const CustomFormCheck = ({ id, label, type = 'checkbox', value, field, disabled, children }) => {
	const isChecked = type === 'radio' ? field.value === value : field.value

	return (
		<div className="form-check custom-option custom-option-basic">
			<label className="form-check-label custom-option-content" htmlFor={id}>
				<input
					{...field}
					className="form-check-input"
					type={type}
					id={id}
					value={value}
					disabled={disabled}
					checked={isChecked}
				/>
				<span className="custom-option-header">
					<span className="h6 mb-0">{label}</span>
				</span>
				<span className="custom-option-body">{children}</span>
			</label>
		</div>
	)
}

export default CustomFormCheck
