import React from 'react'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const CreateScheme = () => {
	const navigate = useNavigate()

	const handleClick = () => {
		navigate(`/ik-organization-schemes/add`)
	}

	return (
		<Card className="h-100">
			<div className="row h-100">
				<div className="col-sm-5">
					<div className="d-flex align-items-end h-100 justify-content-center mt-sm-0 mt-3">
						<img
							src="../../assets/img/illustrations/lady-with-laptop-light.png"
							className="img-fluid"
							alt="Image"
							width={100}
						/>
					</div>
				</div>
				<div className="col-sm-7">
					<div className="card-body text-sm-end text-center ps-sm-0">
						<button onClick={handleClick} className="btn btn-primary mb-3 text-nowrap add-new-role">
							Yeni Şema
						</button>
						<p className="mb-0">Yeni bir şema oluşturun.</p>
					</div>
				</div>
			</div>
		</Card>
	)
}

export default CreateScheme
