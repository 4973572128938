import { CloseButton, DangerApproveButton, DeleteButton } from 'components/fast-ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { ErrorMessage } from '@hookform/error-message'

const VacationCancelModal = () => {
	const navigate = useNavigate()
	const params = useParams()
	const { tab_name, module_name, module_id, process } = params

	const [show, setShow] = useState(false)
	const [data, setData] = useState(null)

	const fetchData = async () => {
		try {
			const response = await Service.get(`/vacations/${module_id}`)
			setData(response.data)
			if (response.data.status === 'canceled') {
				/* navigate(`/profile/${tab_name}`) */
				/* setShow(false) */
			}
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (tab_name === 'vacations' && module_name === 'list' && module_id && process === 'cancel') {
			setShow(true)
			fetchData()
		}
	}, [params])

	const handleClose = () => {
		setShow(false)
		navigate(`/profile/${tab_name}`)
	}

	const handleCancel = async () => {
		try {
			const body = { status: 'canceled', cancelReason: getValues('cancelReason') }
			await Service.patch(`/vacations/cancel/${module_id}`, body)
			setShow(false)
			navigate(`/profile/${tab_name}`)
			sendToast('İzin iptal edildi')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState,
		watch,
		formState: { errors }
	} = useForm({ mode: 'onChange' })

	return (
		<Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>İzin İptal</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Controller
						name="cancelReason"
						control={control}
						rules={{ required: true }}
						render={({ field }) => (
							<Form.Group>
								<Form.Label>İptal Sebebi</Form.Label>
								<Form.Control as="textarea" rows={3} {...field} />
								<Form.Control.Feedback type="invalid">
									<ErrorMessage errors={errors} name="cancelReason" />
								</Form.Control.Feedback>{' '}
							</Form.Group>
						)}
					/>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<CloseButton text="Vazgeç" onClick={handleClose} />
				<DangerApproveButton text="İptal Et" onClick={handleCancel} />
			</Modal.Footer>
		</Modal>
	)
}

export default VacationCancelModal
