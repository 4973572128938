import CustomReactSelect from 'components/ui/CustomReactSelect'
import Service from 'global/Service'
import React, { useEffect } from 'react'

let locations = []
let departments = []
let titles = []
let statuses = []

export const locationFilter = ({ column }) => {
	const { filterValue, setFilter } = column

	let promiseLocation = Service.get('/company-units/locations')

	promiseLocation.then((response) => {
		locations = response.data
	})

	return (
		<>
			<CustomReactSelect
				isClearable
				onChange={(d) => {
					return d ? setFilter(d.name) : setFilter('')
				}}
				options={locations}
				getOptionValue={(option) => option.name}
				getOptionLabel={(option) => option.name}
				placeholder="Şube.."
			/>
		</>
	)
}

export const departmentFilter = ({ column }) => {
	const { filterValue, setFilter } = column

	let promiseDepartment = Service.get('/company-units/departments')

	promiseDepartment.then((response) => {
		departments = response.data
	})

	return (
		<>
			<CustomReactSelect
				isClearable
				onChange={(d) => {
					return d ? setFilter(d.name) : setFilter('')
				}}
				options={departments}
				getOptionValue={(option) => option.name}
				getOptionLabel={(option) => option.name}
				placeholder="Departman.."
			/>
		</>
	)
}

export const titleFilter = ({ column }) => {
	const { filterValue, setFilter } = column
	let promisetitle = Service.get('/company-units/titles')
	promisetitle.then((response) => {
		titles = response.data
	})

	return (
		<>
			<CustomReactSelect
				isClearable
				onChange={(d) => {
					return d ? setFilter(d.name) : setFilter('')
				}}
				options={titles}
				getOptionValue={(option) => option.name}
				getOptionLabel={(option) => option.name}
				placeholder="Ünvan.."
			/>
		</>
	)
}

export const statusFilter = ({ column }) => {
	const { filterValue, setFilter } = column

	const workingStatuses = [
		{ value: 'working', label: 'Aktif' },
		{ value: 'leaved', label: 'Ayrıldı' }
	]

	return (
		<>
			<CustomReactSelect
				isClearable
				defaultValue={workingStatuses[0]}
				onChange={(d) => {
					return d ? setFilter(d.value) : setFilter('')
				}}
				options={workingStatuses}
				placeholder="Statü.."
			/>
		</>
	)
}
