import { default as lightLogo } from 'assets/svg/logos/meno-light.svg'
import { CloseButton } from 'components/fast-ui'
import { FaIcon } from 'components/ui'
import AuthService from 'global/AuthService'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import md5 from 'md5'
import React, { useEffect, useState } from 'react'
import { Card, Container, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import useAuthStore from 'stores/authStore'
import useOrganizationStore from 'stores/organizationStore'

import { ErrorMessage } from '@hookform/error-message'

const Login = () => {
	const navigate = useNavigate()
	const organizationId = useOrganizationStore((state) => state.organizationId)
	const setAccessToken = useAuthStore((state) => state.setAccessToken)
	const setRefreshToken = useAuthStore((state) => state.setRefreshToken)

	const [passwordVisible, setPasswordVisible] = useState(false)

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState,
		watch,
		formState: { errors }
	} = useForm({ mode: 'onChange' })

	const handleLogin = async (data) => {
		const body = {
			mail: data.mail,
			password: data.password,
			org_id: organizationId
		}
		try {
			const response = await AuthService.post(`/auth/login`, body)

			if (response.data.twoFactorIsActive) {
				navigate('/auth/two-factor', { state: { mail: data.mail, password: data.password, org_id: organizationId } })
				return
			}

			if (response.data.accessToken && response.data.refreshToken) {
				setAccessToken(response.data.accessToken)
				setRefreshToken(response.data.refreshToken)
				sendToast('Giriş başarılı.')
				navigate('/home')
			}
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	return (
		<div className="authentication-wrapper authentication-basic container-p-y">
			<div className="authentication-inner py-4">
				<Card style={{ padding: '40px 30px' }}>
					<Card.Body>
						<Card.Img className="app-brand" variant="top" src={lightLogo} height={30} />

						<Form>
							<Form.Group className="mb-3">
								<Form.Label>E-posta</Form.Label>
								<Form.Control
									type="text"
									placeholder="E-posta adresi"
									{...register('mail', { required: 'Mail alanı boş bırakılamaz.' })}
								/>
								<Form.Control.Feedback type="invalid">
									<ErrorMessage errors={errors} name="mail" />
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>Şifre</Form.Label>
								<div className="input-group input-group-merge">
									<Form.Control
										type={passwordVisible ? 'text' : 'password'}
										placeholder="Şifre"
										{...register('password', { required: 'Şifre boş bırakılamaz.' })}
									/>
									<span
										onClick={() => setPasswordVisible(!passwordVisible)}
										className="input-group-text cursor-pointer"
									>
										<FaIcon icon={passwordVisible ? 'eye-slash' : 'eye'} />
									</span>
								</div>
								<Form.Control.Feedback type="invalid">
									<ErrorMessage errors={errors} name="password" />
								</Form.Control.Feedback>
							</Form.Group>
							<button onClick={handleSubmit((data) => handleLogin(data))} className="btn btn-primary w-100">
								<FaIcon icon="sign-in-alt" className="me-2" />
								Giriş Yap
							</button>
						</Form>
						<Card.Text className="text-center mt-3">
							<Link to="/auth/forgot-password">Şifremi Unuttum</Link>
						</Card.Text>
					</Card.Body>
				</Card>
			</div>
		</div>
	)
}

export default Login
