import Service from 'global/Service'
import { showErrorMessage } from 'global/sendError'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

import MyCompetenceRatingAnswerCard from './components/MyCompetenceRatingAnswerCard'
import MyCompetenceRatingEmployeeCard from './components/MyCompetenceRatingEmployeeCard'

const MyCompetenceRatingDetail = ({ competenceRatingId }) => {
	const params = useParams()
	const { tab_name, module_name, module_id } = params

	const [competenceRating, setCompetenceRating] = useState(null)

	const fetchCompetenceRating = async () => {
		try {
			const response = await Service.get(`/competence-ratings/${competenceRatingId}`)
			const { data } = response
			setCompetenceRating(data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchCompetenceRating()
	}, [])

	return (
		<>
			{competenceRating && (
				<Row>
					<Col md={4} xl={3} className="mb-4">
						<MyCompetenceRatingEmployeeCard {...competenceRating} />
					</Col>
					<Col md={8} xl={9} className="mb-4">
						<MyCompetenceRatingAnswerCard {...competenceRating} />
					</Col>
				</Row>
			)}
		</>
	)
}

export default MyCompetenceRatingDetail
