const RolePermissions = [
	{
		title: 'Döküman',
		permissions: [
			{
				name: 'Döküman Yükleme',
				description: 'Döküman yükleyebilir.',
				value: 'document_upload'
			},
			{
				name: 'Yeni Klasör Oluşturma',
				description: 'Yeni klasör oluşturabilir.',
				value: 'document_create_folder'
			},
			{
				name: 'Kendi Dökümanı Yönetme',
				description: 'Kendi yüklediği dökümanı düzenleyebilir veya silebilir.',
				value: 'document_manage_own'
			},
			{
				name: 'Kendi Dökümanı Revize Etme',
				description: 'Kendi yüklediği dökümanı revize edebilir.',
				value: 'document_revise_own'
			}
		]
	},
	{
		title: 'Diğer',
		permissions: [
			{
				//Takbim
				name: 'Takvim',
				description: 'Takvim sayfasına erişebilir.',
				value: 'calendar_access'
			}
		]
	},
	{
		title: 'Ayarlar',
		permissions: [
			{
				name: 'Şirket Yapısı',
				description: 'Şirket yapısını ve şirket birimlerini düzenleyebilir.',
				value: 'settings_company_structure'
			},
			{
				name: 'Roller ve Yetkiler',
				description: 'Rolleri ve yetkileri düzenleyebilir.',
				value: 'settings_roles'
			},
			{
				name: 'Onay Süreçleri',
				description: 'Onay süreçlerini yönetebilir.',
				value: 'settings_approval_processes'
			},
			{
				name: 'Kullanıcı Rolleri',
				description: 'Kullanıcı rollerini düzenleyebilir.',
				value: 'settings_user_roles'
			},
			{
				name: 'Çalışma Takvimleri',
				description: 'Çalışma takvimlerini ayarlayabilir.',
				value: 'settings_work_calendars'
			},
			{
				name: 'Tatil Takvimleri',
				description: 'Tatil takvimlerini düzenleyebilir.',
				value: 'settings_holiday_calendars'
			}
		]
	}
]

export default RolePermissions
