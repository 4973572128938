import { CloseButton, CreateButton } from 'components/fast-ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Card } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

const WorkspaceList = () => {
	const navigate = useNavigate()
	const params = useParams()
	const { workspace_id: process } = params

	const [data, setData] = useState([])

	const fetchData = async () => {
		try {
			const response = await Service.get('/workspaces')

			setData(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		if (!process) {
			fetchData()
		}
	}, [params])

	const handleClick = () => {
		navigate('/workspaces/add')
	}

	const handleWorkspaceItemClick = (id) => {
		navigate(`/workspaces/${id}`)
	}

	const {
		register,
		handleSubmit,
		control,
		reset,
		formState: { errors }
	} = useForm()

	return (
		<Card className="d-flex flex-column align-items-center p-4">
			<Card.Header className="d-flex flex-column align-items-center">
				<Card.Title className="mb-0" as="h5">
					Çalışma Alanlarım
				</Card.Title>
			</Card.Header>
			<Card.Body>
				<p>Bir çalışma alanı oluşturun veya yönetin.</p>
				<div className="d-flex flex-column gap-3">
					<CreateButton text="Yeni Çalışma Alanı" onClick={handleClick} />
					{data.map((item) => (
						<Card className="bg-light cursor-pointer px-4 py-3" onClick={() => handleWorkspaceItemClick(item._id)}>
							<h6 className="mb-0">{item.name}</h6>
						</Card>
					))}
				</div>
			</Card.Body>
		</Card>
	)
}

export default WorkspaceList
