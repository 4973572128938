import { CustomPopperTooltip, CustomReactTable, FaIcon, TabButton, TabButtonContainer } from 'components/ui'
import { getStatusBadgeClass, getStatusBadgeText } from 'global/functions'
import React, { useEffect, useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const DocumentApprovalHistory = () => {
	const [tableData, setTableData] = useState([])
	const [tableColumns, setTableColumns] = useState([])
	const [filteredData, setFilteredData] = useState([])

	useEffect(() => {
		const columns = [
			{
				Header: 'Türü',
				accessor: 'type',
				Cell: ({ row }) => {
					return row.original.type === 'new' ? (
						<span className="badge bg-info">Yeni</span>
					) : (
						<span className="badge bg-dark">Revize</span>
					)
				}
			},
			{
				Header: 'Doküman Adı',
				accessor: 'name'
			},
			{
				Header: 'Yükleyen Kişi',
				accessor: 'uploader'
			},
			{
				Header: 'Yüklenme Tarihi',
				accessor: 'uploadDate'
			},
			{
				Header: 'Yüklenen Konum',
				accessor: 'location',
				Cell: ({ row }) => {
					return (
						<>
							<CustomPopperTooltip text={row.original.location}>
								<FaIcon icon="fa fa-info-circle me-2" />
							</CustomPopperTooltip>
							<span className="ml-2">{row.original.location}</span>
						</>
					)
				}
			},
			{
				Header: 'Onay Durumu',
				accessor: 'status',
				Cell: ({ row }) => {
					return (
						<span className={`badge ${getStatusBadgeClass(row.original.status)}`}>
							{getStatusBadgeText(row.original.status)}
						</span>
					)
				}
			},
			{
				Header: 'İncele',
				accessor: 'id',
				Cell: ({ row }) => {
					return (
						<Link to={`/document-approval/${row.original.id}/view`} className="text-primary me-3">
							<FaIcon icon="eye" />
						</Link>
					)
				}
			}
		]

		const data = [
			{
				id: 1,
				name: 'Doküman 1',
				uploader: 'Ali',
				uploadDate: '01.01.2021',
				location: 'Konum 1',
				status: 'rejected'
			},
			{
				id: 2,
				name: 'Doküman 2',
				uploader: 'Berkay',
				uploadDate: '01.01.2021',
				location: 'Konum 1',
				status: 'approved'
			}
		]

		setTableData(data)
		setTableColumns(columns)
		setFilteredData(data)
	}, [])

	const handleFilter = (filterValue) => {
		if (filterValue === '') {
			setFilteredData(tableData)
		} else {
			const filteredData = tableData.filter((item) => {
				return item.status === filterValue
			})
			setFilteredData(filteredData)
		}
	}

	const handleDocumentNameFilter = (event) => {
		const filterValue = event.target.value.toLowerCase()
		const filteredData = tableData.filter((item) => {
			return item.name.toLowerCase().includes(filterValue)
		})
		setFilteredData(filteredData)
	}

	return (
		<Card>
			<Card.Header>
				<Card.Title as="h5">Onay / Red Geçmişi</Card.Title>
				<TabButtonContainer>
					<TabButton icon="list" text="Tümü" onClick={() => handleFilter('')} />
					<TabButton icon="check" text="Onaylanan" onClick={() => handleFilter('approved')} />
					<TabButton icon="xmark" text="Reddedilen" onClick={() => handleFilter('rejected')} />
					<Form>
						<Form.Control type="text" placeholder="Ara..." className="mr-sm-2" onChange={handleDocumentNameFilter} />
					</Form>
				</TabButtonContainer>
			</Card.Header>
			<Card.Body>
				<CustomReactTable data={filteredData} columns={tableColumns} />
			</Card.Body>
		</Card>
	)
}

export default DocumentApprovalHistory
