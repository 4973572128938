import { CustomPopperTooltip, FaIcon } from 'components/ui'
import { AvatarGroup } from 'components/ui'
import { formatDate } from 'global/functions/formatDate'
import React from 'react'
import { Button, Card, Dropdown, DropdownButton } from 'react-bootstrap'

const CompetenceRatingCard = ({ employee, raters, ratings, averageScore, createDate }) => {
	return (
		<Card>
			<Card.Body>
				<div className="d-flex align-items-center mb-3">
					<a href="#" className="d-flex align-items-center">
						<div className="avatar avatar-sm me-2">
							{employee.profilePhotoUrl ? (
								<img className="rounded-circle" src={employee.profilePhotoUrl} alt="Avatar" />
							) : (
								<span className="avatar-initial rounded-circle bg-label-primary">
									{`${employee.firstName[0]} ${employee.lastName[0]}`}
								</span>
							)}
						</div>
						<div className="me-2 h5 mb-0">
							<span>{employee.fullName}</span>
						</div>
					</a>
					<div className="ms-auto mb-2">
						<FaIcon icon="search" />
					</div>
				</div>

				<div className="d-flex align-items-center flex-wrap gap-1">
					<AvatarGroup employees={raters} />
					<div className="ms-auto">
						<span title="Oluşturma Tarihi" className="badge bg-label-secondary me-2">
							{formatDate(createDate)} <FaIcon icon="calendar" className="ms-1" />
						</span>

						<span title="Değerlendirme Sayısı" className="badge bg-label-secondary me-2">
							{`${ratings.length}/${raters.length}`} <FaIcon icon="user" />
						</span>

						<span
							title="Ortalama Puan"
							className={`badge bg-label-${
								averageScore >= 6 ? 'success' : averageScore > 0 && averageScore <= 3 ? 'danger' : 'secondary'
							} me-2`}
						>
							{averageScore ? averageScore.toFixed(2) : 0}
							{averageScore === null ? (
								<FaIcon icon="clock" className="ms-1" />
							) : averageScore >= 6 ? (
								<FaIcon icon="smile" className="ms-1" />
							) : averageScore <= 3 ? (
								<FaIcon icon="frown" className="ms-1" />
							) : (
								<FaIcon icon="meh" className="ms-1" />
							)}
						</span>
					</div>
				</div>
			</Card.Body>
		</Card>
	)
}

export default CompetenceRatingCard
