import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import { create } from 'zustand'

const useTaskStore = create((set, get) => ({
	task: null,
	setTask: (task) => set({ task }),
	fetchTask: async (task_id) => {
		try {
			const response = await Service.get(`/tasks/${task_id}`)
			set({ task: response.data })
			console.log('fetch-task-tamamlandi')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	},
	refreshTask: async () => {
		const { task } = get()
		try {
			const response = await Service.get(`/tasks/${task._id}`)
			set({ task: response.data })
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	},
	resetTask: () => set({ task: null })
}))

export default useTaskStore
