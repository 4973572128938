import anonimProfilePhoto from 'assets/img/avatars/anonim-profile-photo.jpg'
import { CloseButton, CreateButton, SaveButton } from 'components/fast-ui'
import { ButtonContainer, CustomDatePicker, CustomReactSelect, FaIcon } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'

const AddTaskModal = ({ onHide }) => {
	const [employees, setEmployees] = useState([])

	const fetchEmployees = async () => {
		try {
			const response = await Service.get('/employees')
			setEmployees(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleClose = () => {
		onHide && onHide()
	}

	const handleSave = async (data) => {
		const { members } = data
		if (members.length > 1) {
			sendToast('Bir göreve en fazla 1 çalışan ekleyebilirsiniz.', false)
			return
		}

		const body = data

		try {
			const response = await Service.post('/tasks', body)
			sendToast('Görev başarıyla oluşturuldu.')
			handleClose()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchEmployees()
	}, [])

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState,
		watch,
		formState: { errors, isDirty }
	} = useForm({ mode: 'onChange' })

	return (
		<Modal show onHide={handleClose} size="md" centered>
			<Modal.Header closeButton>
				<Modal.Title as="h5">
					<FaIcon icon="plus" className="me-2" />
					Yeni Görev
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form className="d-flex flex-column gap-2">
					<Form.Group>
						<Form.Label>Görevi Alan Kişi</Form.Label>
						<Controller
							name="members"
							control={control}
							render={({ field }) => (
								<CustomReactSelect
									value={field.value}
									options={employees}
									onChange={field.onChange}
									placeholder="Çalışan.."
									getOptionLabel={(option) => (
										<div>
											<img
												src={option?.profilePhotoUrl || anonimProfilePhoto}
												alt="avatar"
												className="rounded-circle me-2"
												width="24"
												height="24"
											/>
											<span className="me-1">{option?.fullName}</span>
											<span>
												({option?.position?.department?.name} - {option?.position?.title?.name})
											</span>
										</div>
									)}
									getOptionValue={(option) => option._id}
								/>
							)}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Görev Adı</Form.Label>
						<Form.Control type="text" name="title" {...register('title')} />
					</Form.Group>
					<Form.Group>
						<Form.Label>Görev Açıklaması</Form.Label>
						<Form.Control as="textarea" rows={3} name="description" {...register('description')} />
					</Form.Group>
					<Form.Group>
						<Form.Label>Görevin Bitiş Tarihi</Form.Label>
						<Controller
							name="endDate"
							control={control}
							render={({ field }) => (
								<CustomDatePicker
									{...field}
									selected={field.value}
									onChange={(date) => field.onChange(date)}
									rules={{
										required: 'Bu alan zorunludur.'
									}}
									value={field.value}
								/>
							)}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<ButtonContainer>
					<CloseButton onClick={handleClose} />
					<CreateButton onClick={handleSubmit(handleSave)} disabled={!isDirty} />
				</ButtonContainer>
			</Modal.Footer>
		</Modal>
	)
}

export default AddTaskModal
