import React from 'react'
import { useState } from 'react'
import useProjectTaskStore from 'stores/projectTaskStore'

import TaskCheckListItem from './components/TaskCheckListItem'

const TaskCheckLists = () => {
	const [focusedCheckListId, setFocusedCheckListId] = useState(null)

	const task = useProjectTaskStore((state) => state.projectTask)

	const handleAddNewTaskClick = (checkListId) => {
		setFocusedCheckListId(checkListId)
	}

	const handleCloseClick = () => {
		setFocusedCheckListId(null)
	}

	return (
		<div className="d-flex flex-column gap-4">
			{task?.checkLists
				.sort((a, b) => a.order - b.order)
				.map((checkList) => (
					<TaskCheckListItem
						data={checkList}
						showAddTask={focusedCheckListId === checkList._id}
						onAddNewTaskClick={handleAddNewTaskClick}
						onCloseClick={handleCloseClick}
					/>
				))}
		</div>
	)
}

export default TaskCheckLists
