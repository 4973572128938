import { CreateButton } from 'components/fast-ui'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import PositionHistoryModal from './components/Modal'
import PositionHistoryTable from './components/position-history-table'

const CareerPositionTab = () => {
	const params = useParams()
	const { employee_id, module_name, module_id } = params

	useEffect(() => {}, [])
	return (
		<>
			<div className="card">
				<h5 className="card-header">
					<span className="me-3">Pozisyon</span>
					<Link to={`/employees/${params.employee_id}/career/positions/add`}>
						<CreateButton className="btn btn-primary btn-sm" text="Ekle" />
					</Link>
				</h5>
				<div className="card-body">
					<div className="row">
						<PositionHistoryTable />
					</div>
				</div>
			</div>
			<PositionHistoryModal />
		</>
	)
}

export default CareerPositionTab
