import { TabButton, TabButtonContainer } from 'components/ui'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import CompetenceDefinitionsTab from './tabs/definitions'
import CompetenceRatingsTab from './tabs/ratings'
import CompetenceResultsTab from './tabs/results'

export default function Comptencies() {
	const navigate = useNavigate()
	const params = useParams()
	const { tab_name, module_name: sub_tab_name } = params
	const dispatch = useDispatch()

	const navigateToTab = (tab) => {
		navigate(`/ik-competencies/${tab}`)
	}

	const ikCompetenceAssessment = useSelector((state) => state.ikCompetenceAssessment)

	return (
		<div className="container-xxl flex-grow-1 container-p-y">
			<Row>
				<Col md={12} className="mb-4">
					<TabButtonContainer>
						<TabButton
							onClick={() => navigateToTab('definitions')}
							icon="pen-circle"
							text="Tanımlar"
							active={tab_name === 'definitions'}
						/>
						<TabButton
							onClick={() => navigateToTab('rating')}
							icon="star"
							text="Değerlendirme"
							active={tab_name === 'rating'}
						/>
						<TabButton
							onClick={() => navigateToTab('results/list')}
							icon="search"
							text="Sonuçlar"
							active={tab_name === 'results' && sub_tab_name !== 'details'}
						/>

						{tab_name === 'results' && sub_tab_name === 'details' && (
							<TabButton
								onClick={() => navigateToTab('results/list')}
								icon="arrow-left"
								text="Sonuçlara Dön"
								active={tab_name === 'results' && sub_tab_name === 'details'}
							/>
						)}
					</TabButtonContainer>
				</Col>
				{tab_name === 'definitions' ? <CompetenceDefinitionsTab /> : null}
				{tab_name === 'rating' ? <CompetenceRatingsTab /> : null}
				{tab_name === 'results' ? <CompetenceResultsTab /> : null}
			</Row>
		</div>
	)
}
