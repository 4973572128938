import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

import ProjectModal from './components/ProjectModal'
import WorkspaceList from './components/WorkspaceList'
import WorkspaceModal from './components/WorkspaceModal'
import WorkspaceView from './components/workspace-view'

const Workspaces = () => {
	const params = useParams()
	const navigate = useNavigate()
	const { workspace_id, project_id } = params

	const [tabName, setTabName] = useState('list')
	const [showModal, setShowModal] = useState('')

	useEffect(() => {
		if (workspace_id && project_id) {
			if (project_id === 'edit') {
				setShowModal('workspace')
				setTabName('view')
			} else if (project_id === 'add') {
				setShowModal('project')
			} else {
				setShowModal('')
			}
		} else {
			if (workspace_id) {
				if (workspace_id === 'add') {
					setShowModal('workspace')
				} else {
					setTabName('view')
					setShowModal('')
				}
			} else {
				setTabName('list')
				setShowModal('')
			}
		}
	}, [params])

	const handleCloseModal = () => {
		if (tabName === 'view') {
			navigate(`/workspaces/${workspace_id}`)
		} else {
			navigate(`/workspaces`)
		}
	}

	return (
		<>
			<Container fluid="xxl" className="flex-grow-1 container-p-y">
				<Row className="d-flex justify-content-center align-items-center">
					{tabName === 'list' && (
						<Col md={12} className="d-flex justify-content-center align-items-center">
							<WorkspaceList />
						</Col>
					)}
					{tabName === 'view' && (
						<Col md={12}>
							<WorkspaceView />
						</Col>
					)}
				</Row>
				{showModal === 'workspace' && <WorkspaceModal isNew={workspace_id === 'add'} onHide={handleCloseModal} />}
				{showModal === 'project' && <ProjectModal isNew={project_id === 'add'} onHide={handleCloseModal} />}
			</Container>
		</>
	)
}

export default Workspaces
