import { FaIcon } from 'components/ui'
import { AvatarGroup } from 'components/ui'
import { formatDate } from 'global/functions/formatDate'
import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedRatingID } from 'stores/ik/competence/ikCompetenceRatingSlice'

const CompetenceRatingDetailsCard = ({
	employee,
	competencies,
	raters,
	createDate,
	ratings,
	averageAnswers,
	averageScore,
	isCompleted
}) => {
	const [raterId, setRaterId] = useState(null)

	return (
		<Row>
			<Col md={4} xl={3} className="mb-4">
				<Card className="h-100">
					<Card.Header className="border-bottom">
						<div className="d-flex align-items-center me-3">
							<img src={employee.profilePhotoUrl} alt="Avatar" className="rounded-circle me-3" width={54} />
							<div className="card-title mb-0">
								<h5 className="mb-0">{employee.fullName}</h5>
								<small className="text-muted">Yetkinlik Değerlendirme Formu</small>
							</div>
						</div>
					</Card.Header>

					<Card.Body>
						<div className="d-flex flex-wrap justify-content-center gap-4 py-4 mb-3 border-bottom">
							<div className="text-center">
								<h6 className="mb-2">Ortalama</h6>
								<div className="avatar avatar-xl">
									<span
										className={`avatar-initial rounded-circle bg-label-${
											averageScore >= 6 ? 'success' : averageScore > 0 && averageScore <= 3 ? 'danger' : 'secondary'
										}`}
									>
										{averageScore ? averageScore.toFixed(2) : 0}
									</span>
								</div>
							</div>
							<div className="text-center">
								<h6 className="mb-2">Yetkinlik</h6>
								<div className="avatar avatar-xl">
									<span className="avatar-initial rounded-circle bg-label-info">{competencies.length}</span>
								</div>
							</div>
						</div>

						<div className="info-container border-bottom pb-2 mb-3">
							<small className="text-muted">Hakkında</small>
							<div>
								<ul className="list-unstyled mt-3">
									<li className="d-flex flex-wrap align-items-center mb-3">
										<i className="bx bx-user me-2" />
										<span className="fw-semibold me-2">İşe Başlama Tarihi:</span>{' '}
										<span>{formatDate(employee.hiredDate, false)}</span>
									</li>
									<li className="d-flex flex-wrap align-items-center mb-3">
										<i className="bx bx-flag me-2" />
										<span className="fw-semibold me-2">Lokasyon:</span> <span>{employee.position?.location?.name}</span>
									</li>
									<li className="d-flex flex-wrap align-items-center mb-3">
										<i className="bx bx-briefcase me-2" />
										<span className="fw-semibold me-2">Departman:</span>{' '}
										<span>{employee.position?.department?.name}</span>
									</li>
									<li className="d-flex flex-wrap align-items-center mb-3">
										<i className="bx bx-star me-2" />
										<span className="fw-semibold me-2">Ünvan:</span> <span>{employee.position?.title?.name}</span>
									</li>
								</ul>
							</div>
						</div>

						<div className="info-container">
							<div className="mb-3 d-flex flex-wrap align-items-center justify-content-between">
								<small className="text-muted">Değerlendirenler:</small>

								<AvatarGroup employees={raters} />
							</div>
							<div className="mb-3 d-flex flex-wrap align-items-center justify-content-between">
								<small className="text-muted">Oluşturma Tarihi:</small>
								<span className="badge bg-label-secondary fw-semibold mx-2">
									<FaIcon icon="calendar" className="me-2" />
									{formatDate(createDate)}
								</span>
							</div>
							<div className="mb-3 d-flex flex-wrap align-items-center justify-content-between">
								<small className="text-muted">Durum:</small>
								{isCompleted ? (
									<span className="badge bg-label-success fw-semibold mx-2">
										<FaIcon icon="check" className="me-2" />
										Tamamlandı
									</span>
								) : (
									<span className="badge bg-label-warning fw-semibold mx-2">
										<FaIcon icon="clock" className="me-2" />
										Devam ediyor
									</span>
								)}
							</div>
						</div>
					</Card.Body>
				</Card>
			</Col>
			<Col md={8} xl={9} className="mb-4">
				<Card className="h-100">
					<Card.Header className="border-bottom">
						<Card.Title as="h5" className="mb-0">
							Değerlendirilen Yetkinlikler
						</Card.Title>
					</Card.Header>

					<Card.Body>
						<div className="d-flex align-items-center justify-content-start border-bottom py-4 mb-4">
							<div>
								<span className="badge bg-label-secondary me-2">Değerlendiren Seç:</span>
								<span
									onClick={() => setRaterId(null)}
									className={`badge me-2 ${
										averageScore <= 3
											? 'bg-label-danger'
											: averageScore <= 6
											? 'bg-label-warning'
											: averageScore === 0
											? 'bg-label-secondary'
											: 'bg-label-success'
									}`}
									type="button"
								>
									{' '}
									Tümü ({averageScore ? averageScore : <FaIcon icon="clock" />})
								</span>

								<span>
									{raters.map((rater) => {
										const hasRating = ratings.some((r) => r.rater === rater._id)
										return (
											<span
												onClick={() => {
													hasRating ? setRaterId(rater._id) : setRaterId(null)
												}}
												className={`badge me-2 ${hasRating ? 'bg-label-primary' : 'bg-label-secondary'}`}
												type={hasRating ? 'button' : undefined}
											>
												{rater.fullName}
												{!hasRating && <FaIcon icon="clock" className="ms-2" />}
												{hasRating && <FaIcon icon="check" className="ms-2" />}
											</span>
										)
									})}
								</span>
							</div>
						</div>
						<Row className="g-3">
							{competencies.map((competence, index) => (
								<Col md={6}>
									<Card>
										<Card.Body>
											<div className="d-flex align-items-center justify-content-between">
												<div>
													<h5>{competence.name}</h5>
													<small className="text-muted">{competence.description}</small>
												</div>
											</div>
										</Card.Body>
										<Card.Footer className="border-top">
											<div className="d-flex align-items-center justify-content-between">
												<div className="d-flex flex-wrap gap-2">
													<span className="badge bg-label-info">
														<FaIcon icon="flag" className="me-2" />
														{competence.type.name}
													</span>
													<span className="badge bg-label-secondary">
														<FaIcon icon="chart-mixed" className="me-2" />
														Ortalama: {averageAnswers.find((a) => a.competence._id === competence._id)?.score}
													</span>
													{raterId && (
														<span className="badge bg-label-danger">
															<FaIcon icon="circle-dot" className="me-2" />{' '}
															{ratings
																.find((r) => r.rater === raterId)
																?.answers.find((a) => a.competence === competence._id)?.score ?? 'N/A'}
														</span>
													)}
												</div>
											</div>
										</Card.Footer>
									</Card>
								</Col>
							))}
						</Row>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	)
}

export default CompetenceRatingDetailsCard
