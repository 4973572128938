import Service from 'global/Service'

import { createSlice } from '@reduxjs/toolkit'

export const ikCompetenceDefinitionSlice = createSlice({
	name: 'ikCompetenceDefinition',
	initialState: {
		competence: {
			list: [],
			selected: {
				id: 0,
				name: null,
				competenceType: null,
				competenceTypeID: null,
				competenceTypeName: null
			},
			status: {
				showStatus: false,
				content: {
					statusCode: null,
					statusMessage: null
				}
			}
		},
		competenceTypes: []
	},
	reducers: {
		setCompetenceList: (state, action) => {
			state.competence.list = action.payload
		},
		setSelectedCompetence: (state, action) => {
			state.competence.selected = action.payload
		},
		setSelectedCompetenceName: (state, action) => {
			state.competence.selected.name = action.payload
		},
		setCompetenceStatusContent: (state, action) => {
			state.competence.status.content = action.payload
		},
		setSelectedCompetenceType: (state, action) => {
			state.competence.selected.competenceType = action.payload
			state.competence.selected.competenceTypeID = action.payload.value
			state.competence.selected.competenceTypeName = action.payload.label
		},
		setCompetenceTypeList: (state, action) => {
			state.competence.competenceTypes = action.payload
		}
	}
})

export const {
	setCompetenceList,
	setSelectedCompetence,
	setSelectedCompetenceName,
	setCompetenceStatusContent,
	setSelectedCompetenceType,
	setCompetenceTypeList
} = ikCompetenceDefinitionSlice.actions

export const refreshCompetenceList = () => (dispatch) => {
	let promise = Service.get('https://api.devbont.site/api/competencies')
	promise.then((response) => {
		dispatch(setCompetenceList(response.data))
	})
}

export const refreshCompetenceTypeList = () => (dispatch) => {
	let promise = Service.get('https://api.devbont.site/api/competence-types/combo')
	promise.then((response) => {
		dispatch(setCompetenceTypeList(response.data))
	})
}

export const selectCompetence = (competenceID) => (dispatch) => {
	let promise = Service.get(`https://api.devbont.site/api/competencies/${competenceID}`)
	promise.then((response) => {
		const data = response.data[0]
		dispatch(
			setCompetenceStatusContent({
				status_code: null,
				status_message: null
			})
		)
		dispatch(setSelectedCompetence(data))
		dispatch(setSelectedCompetenceType({ value: data.competenceTypeID, label: data.competenceTypeName }))
	})
}

export const saveCompetence = (competenceDetails) => (dispatch) => {
	const body = competenceDetails
	let promise = Service.post(`https://api.devbont.site/api/competencies`, body)
	promise.then((response) => {
		dispatch(setCompetenceStatusContent(response.data))
		dispatch(refreshCompetenceList())
	})
}

export const deleteCompetence = (competenceID) => (dispatch) => {
	let promise = Service.delete(`https://api.devbont.site/api/competencies/${competenceID}`)
	promise.then((response) => {
		dispatch(setCompetenceStatusContent(response.data))
		dispatch(refreshCompetenceList())
	})
}

export const newCompetence = () => (dispatch) => {
	dispatch(
		setCompetenceStatusContent({
			status_code: null,
			status_message: null
		})
	)
	dispatch(
		setSelectedCompetence({
			id: 0,
			name: '',
			competenceType: null,
			competenceTypeID: '',
			competenceTypeName: ''
		})
	)
}

export default ikCompetenceDefinitionSlice.reducer
