import { SaveButton } from 'components/fast-ui'
import { CustomDropzone, CustomReactSelect, FaIcon, FileIcon } from 'components/ui'
import { dismissalReasons } from 'constants/dismissalReasons'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { createRef, useState } from 'react'
import { useEffect } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import Flatpickr from 'react-flatpickr'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchEmployee } from 'stores/ikEmployeeEditSlice'

import { ErrorMessage } from '@hookform/error-message'

const DismissalModal = () => {
	const params = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { employee_id, module_name } = params
	const { employee } = useSelector((state) => state.ikEmployeeEdit)

	const [show, setShow] = useState(false)
	const [files, setFiles] = useState([])
	const [uploadedFiles, setUploadedFiles] = useState([])
	const inputFileRef = createRef()

	useEffect(() => {
		if (module_name === 'dismissal') {
			setShow(true)
		} else {
			setShow(false)
		}
	}, [module_name])

	const handleClose = () => {
		setShow(false)
		navigate(`/employees/${employee_id}/general`)
	}

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		setValue,
		formState,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	const handleClickAddFile = () => {
		inputFileRef.current.click()
	}

	const handleClickRemoveFile = (willRemovedFile) => {
		const filteredFiles = files.filter((item) => item !== willRemovedFile)
		setFiles(filteredFiles)
	}

	const handleFileSelect = (e) => {
		const formData = new FormData()
		const file = e.target.files[0]
		setFiles([...e.target.files])
	}

	const handleSave = async (data) => {
		try {
			var uploadedFiles2 = []
			await Promise.all(
				files.map(async (file) => {
					const formData = new FormData()
					formData.append('file', file, file.name)

					const newFile = await Service.post(`/files`, formData)
					const file_id = newFile.data._id
					uploadedFiles2 = [...uploadedFiles2, file_id]
				})
			)
			setFiles([])
		} catch (error) {
			sendToast(error.response.data.message, false)
		}

		data.dismissal.files = [...data.dismissal.files, ...uploadedFiles2]
		const body = data

		try {
			const patchedEmployee = await Service.patch(`/employees/${employee_id}`, body)
			dispatch(fetchEmployee(employee_id))
			sendToast('Personel işten çıkarıldı.')
			dispatch(fetchEmployee(employee_id))
			handleClose()
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		reset(employee)
	}, [employee])

	const dismissalFiles = watch('dismissal.files')

	return (
		<Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<div className="content-left">
					<Modal.Title as={'h5'}>
						<FaIcon icon={'user-minus'} className="me-2" /> İşten Çıkart
					</Modal.Title>
				</div>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col xs={12} sm={12} className="mb-3">
						<Form.Label>Ayrılma Tarihi</Form.Label>
						<Controller
							name="dismissal.date"
							control={control}
							defaultValue={new Date()}
							rules={{ required: 'Bu alan gereklidir.' }}
							render={({ field }) => (
								<Flatpickr
									className="form-control"
									value={field.value}
									onChange={field.onChange}
									ref={field.ref}
									options={{ dateFormat: 'd-m-Y', locale: { ...Turkish } }}
								/>
							)}
						/>
						<ErrorMessage
							errors={errors}
							name={'dismissal.date'}
							render={({ message }) => <div className="invalid-feedback">{message}</div>}
						/>
					</Col>

					<Col xs={12} sm={12} className="mb-3">
						<Form.Label>Sebep</Form.Label>
						<Controller
							name="dismissal.reason"
							control={control}
							rules={{ required: 'Bu alan gereklidir.' }}
							render={({ field }) => (
								<CustomReactSelect
									value={dismissalReasons.filter((obj) => obj.value === field.value)}
									onChange={(d) => field.onChange(d?.value)}
									ref={field.ref}
									placeholder=""
									options={dismissalReasons}
								/>
							)}
						/>
						<ErrorMessage
							errors={errors}
							name={'dismissal.reason'}
							render={({ message }) => <div className="invalid-feedback">{message}</div>}
						/>
					</Col>

					<Col xs={12} sm={12} className="mb-3">
						<Form.Label>Detaylar ve Yorumlar</Form.Label>
						<Form.Control as="textarea" rows={3} {...register('dismissal.detail')} />
					</Col>

					<Col xs={12} sm={12} className="mb-3">
						<Form.Label>İlgili Dosyalar</Form.Label>
						<input className="d-none" ref={inputFileRef} onChange={handleFileSelect} type="file" multiple />
						<CustomDropzone files={files} setFiles={setFiles} multiple />

						{dismissalFiles?.map((file) => (
							<div className="d-inline-flex align-items-center border rounded px-3 py-2 me-2 mb-2">
								<div className="d-flex align-items-center me-5" type="button">
									<FileIcon extension={file.extension} className={'me-1'} style={{ width: 'auto', height: 18 }} />
									<a href={`http://localhost:3001/files/${file._id}.${file.extension}`}>{`${file.fileName}`}</a>
								</div>
								<div
									type="button"
									onClick={() => {
										const filteredDismissalFiles = dismissalFiles.filter((item) => item !== file)
										setValue('dismissal.files', [...filteredDismissalFiles])
									}}
								>
									<FaIcon icon="remove" />
								</div>
							</div>
						))}

						{files.map((file) => (
							<div className="d-inline-flex align-items-center border rounded px-3 py-2 me-2 mb-2">
								<div className="d-flex align-items-center me-5">
									<FileIcon
										extension={file.name.split('.').pop()}
										className={'me-1'}
										style={{ width: 'auto', height: 18 }}
									/>
									{`${file.name.substring(0, 10)}.${file.name.split('.').pop()}`}
								</div>
								<div
									type="button"
									onClick={() => {
										handleClickRemoveFile(file)
									}}
								>
									<FaIcon icon="remove" />
								</div>
							</div>
						))}
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<SaveButton onClick={handleSubmit((d) => handleSave(d))} />
			</Modal.Footer>
		</Modal>
	)
}

export default DismissalModal
