import Service from 'global/Service'

import { createSlice } from '@reduxjs/toolkit'

export const ikSlice = createSlice({
	name: 'ik',
	initialState: {
		editor: {
			show: false,
			disabled: false,
			combos: {
				statuses: [],
				collars: [],
				genders: [],
				locations: [],
				departments: [],
				positions: []
			}
		},
		employeeList: [],
		selectedEmployee: {
			id: 0,
			username: null,
			mail: null,
			identity: null,
			name: null,
			surname: null,
			phone: null,
			genderID: null,
			dateOfBirth: null,
			startDateOfWork: null,
			collarID: null,
			locationID: null,
			statusID: null,
			positionID: null,
			profilePhotoURL: null
		},
		selectedEmployeeComboValues: {
			gender: {},
			collar: {},
			department: {},
			location: {},
			position: {},
			status: {}
		}
	},
	reducers: {
		showEditor: (state) => {
			state.editor.show = true
		},
		hideEditor: (state) => {
			state.editor.show = false
		},
		enableReadonlyEditor: (state) => {
			state.editor.disabled = true
		},
		disableReadonlyEditor: (state) => {
			state.editor.disabled = false
		},
		setEmployeeList: (state, action) => {
			state.employeeList = action.payload
		},
		setStatuses: (state, action) => {
			state.editor.combos.statuses = action.payload
		},
		setCollars: (state, action) => {
			state.editor.combos.collars = action.payload
		},
		setGenders: (state, action) => {
			state.editor.combos.genders = action.payload
		},
		setLocations: (state, action) => {
			state.editor.combos.locations = action.payload
		},
		setDepartments: (state, action) => {
			state.editor.combos.departments = action.payload
		},
		setPositions: (state, action) => {
			state.editor.combos.positions = action.payload
		},
		setSelectedEmployee: (state, action) => {
			state.selectedEmployee = action.payload
		},
		setSelectedEmployeeComboValues: (state, action) => {
			state.selectedEmployeeComboValues = action.payload
		},
		setSelectedEmployeeUsername: (state, action) => {
			state.selectedEmployee.username = action.payload
		},
		setSelectedEmployeeMail: (state, action) => {
			state.selectedEmployee.mail = action.payload
		},
		setSelectedEmployeeIdentity: (state, action) => {
			state.selectedEmployee.identity = action.payload
		},
		setSelectedEmployeeName: (state, action) => {
			state.selectedEmployee.name = action.payload
		},
		setSelectedEmployeeSurname: (state, action) => {
			state.selectedEmployee.surname = action.payload
		},
		setSelectedEmployeePhone: (state, action) => {
			state.selectedEmployee.phone = action.payload
		},
		setSelectedEmployeeDateOfBirth: (state, action) => {
			state.selectedEmployee.dateOfBirth = action.payload
		},
		setSelectedEmployeeStartDateOfWork: (state, action) => {
			state.selectedEmployee.startDateOfWork = action.payload
		},
		setSelectedEmployeeGenderID: (state, action) => {
			state.selectedEmployee.genderID = action.payload.value
			state.selectedEmployeeComboValues.gender = { label: action.payload.label, value: action.payload.value }
		},
		setSelectedEmployeeCollarID: (state, action) => {
			state.selectedEmployee.collarID = action.payload.value
			state.selectedEmployeeComboValues.collar = { label: action.payload.label, value: action.payload.value }
		},
		setSelectedEmployeeLocationID: (state, action) => {
			state.selectedEmployee.locationID = action.payload.value
			state.selectedEmployeeComboValues.location = { label: action.payload.label, value: action.payload.value }
		},
		setSelectedEmployeeStatusID: (state, action) => {
			state.selectedEmployee.statusID = action.payload.value
			state.selectedEmployeeComboValues.status = { label: action.payload.label, value: action.payload.value }
		},
		setSelectedEmployeeDepartmentID: (state, action) => {
			state.selectedEmployee.departmentID = action.payload.value
			state.selectedEmployeeComboValues.department = { label: action.payload.label, value: action.payload.value }
		},
		setSelectedEmployeePositionID: (state, action) => {
			state.selectedEmployee.positionID = action.payload.value
			state.selectedEmployeeComboValues.position = { label: action.payload.label, value: action.payload.value }
		}
	}
})

export const {
	showEditor,
	hideEditor,
	setEmployeeList,
	enableReadonlyEditor,
	disableReadonlyEditor,
	setStatuses,
	setCollars,
	setDepartments,
	setGenders,
	setLocations,
	setPositions,
	setSelectedEmployee,
	setSelectedEmployeeName,
	setSelectedEmployeeCollarID,
	setSelectedEmployeeDateOfBirth,
	setSelectedEmployeeGenderID,
	setSelectedEmployeeLocationID,
	setSelectedEmployeePhone,
	setSelectedEmployeePositionID,
	setSelectedEmployeeStartDateOfWork,
	setSelectedEmployeeStatusID,
	setSelectedEmployeeSurname,
	setSelectedEmployeeUsername,
	setSelectedEmployeeIdentity,
	setSelectedEmployeeMail,
	setSelectedEmployeeDepartmentID,
	setSelectedEmployeeComboValues
} = ikSlice.actions

export const refreshEmployeeList = () => (dispatch) => {
	let promise = Service.get('/employees')
	promise.then((response) => {
		dispatch(setEmployeeList(response.data))
	})
}

export const loadCombos = () => (dispatch) => {
	Service.get('https://api.devbont.site/api/employee-statuses').then((response) => {
		dispatch(setStatuses(response.data))
	})

	Service.get('https://api.devbont.site/api/genders').then((response) => {
		dispatch(setGenders(response.data))
	})

	Service.get('/locations').then((response) => {
		dispatch(setLocations(response.data))
	})

	Service.get('/departments').then((response) => {
		dispatch(setDepartments(response.data))
	})

	Service.get('https://api.devbont.site/api/collars').then((response) => {
		dispatch(setCollars(response.data))
	})
}

export const loadPositions = (departmentID) => (dispatch) => {
	const bodyPositions = { departmentID: departmentID }
	Service.post(`/positions`, bodyPositions).then((response) => {
		dispatch(setPositions(response.data))
	})
}

export const selectEmployee = (employeeID) => (dispatch) => {
	/* let promise = Service.get(`/employees/${employeeID}`)
	promise.then((response) => {
		const data = response.data[0]
		dispatch(setSelectedEmployee(data))
		dispatch(loadPositions(data.departmentID))
		dispatch(
			setSelectedEmployeeComboValues({
				gender: { label: data.genderName, value: data.genderID },
				collar: { label: data.collarName, value: data.collarID },
				department: { label: data.departmentName, value: data.departmentID },
				location: { label: data.locationName, value: data.locationID },
				position: { label: data.positionName, value: data.positionID },
				status: { label: data.statusName, value: data.statusID }
			})
		)
	}) */
}

export const saveEmployee = (employeeDetails) => (dispatch) => {
	const body = employeeDetails
	let promise = Service.post(`/employees`, body)
	promise.then((response) => {
		dispatch(refreshEmployeeList())
	})
}

export const newEmployee = () => (dispatch) => {
	dispatch(
		setSelectedEmployee({
			id: 0,
			username: '',
			mail: '',
			identity: '',
			name: '',
			surname: '',
			phone: '',
			genderID: '',
			dateOfBirth: '',
			startDateOfWork: '',
			collarID: '',
			locationID: '',
			statusID: '',
			positionID: '',
			profilePhotoURL: null
		})
	)
	dispatch(
		setSelectedEmployeeComboValues({
			gender: null,
			collar: null,
			department: null,
			location: null,
			position: null,
			status: null
		})
	)
}

export default ikSlice.reducer
