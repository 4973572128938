import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import { Card, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import CompanyUnitCard from './CompanyUnitCard'

const CompanyUnits = () => {
	const params = useParams()
	const navigate = useNavigate()

	const { tab_name, item_id, sub_item_id, process_name } = params

	const { companyStructures } = useSelector((state) => state.companyStructure)

	return (
		<Col md={8}>
			<p className="mb-4">
				<strong>Şirket Birimleri</strong>
				<p>Şirket yapısındaki birimleri düzenleyin ve yeni birimler oluşturun.</p>
			</p>

			{companyStructures
				.filter((companyStructure) => companyStructure.isActive)
				.map((companyStructure) => (
					<CompanyUnitCard companyStructure={companyStructure} key={companyStructure._id} />
				))}
		</Col>
	)
}

export default CompanyUnits
