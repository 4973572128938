import { AddButton, CreateButton } from 'components/fast-ui'
import { TabButton, TabButtonContainer } from 'components/ui'
import React from 'react'
import { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

import VacationRequestes from './tabs/vacation-requestes'
import VacationRules from './tabs/vacation-rules'

const Vacations = () => {
	const navigate = useNavigate()
	const params = useParams()

	const { tab_name } = params

	const handleNewRule = () => {
		navigate('/vacations/rules/add')
	}

	return (
		<Container fluid="xxl" className="flex-grow-1 container-p-y">
			<Row>
				<Col md={12} className="mb-4">
					<TabButtonContainer>
						<TabButton
							icon="calendar"
							text="İzin Talepleri"
							active={tab_name === 'requestes'}
							onClick={() => navigate('/vacations/requestes')}
						/>
						<TabButton
							icon="scale-balanced"
							text="İzin Kuralları"
							active={tab_name === 'rules'}
							onClick={() => navigate('/vacations/rules')}
						/>
						{tab_name === 'rules' && (
							<AddButton text="Yeni Kural" type="outline-secondary" className="ms-auto" onClick={handleNewRule} />
						)}
					</TabButtonContainer>
				</Col>
			</Row>
			<>{tab_name === 'requestes' && <VacationRequestes />}</>
			<>{tab_name === 'rules' && <VacationRules />}</>
		</Container>
	)
}

export default Vacations
