import React from 'react'
import { Row } from 'react-bootstrap'

import CompanyStructureModals from './company-structure-modals'
import CompanyStructures from './company-structures'
import CompanyUnits from './company-units'

const CompanyStructure = () => {
	return (
		<Row>
			<CompanyStructures />
			<CompanyUnits />
			<CompanyStructureModals />
		</Row>
	)
}

export default CompanyStructure
