import { CustomReactTable, FaIcon } from 'components/ui'
import NoData from 'components/ui/NoData'
import statuses from 'constants/statuses'
import Service from 'global/Service'
import { formatDate } from 'global/functions/formatDate'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Card, Col } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'

const columns = [
	{
		Header: 'Tür',
		accessor: 'type.name',
		Cell: (cell) => {
			return <span className="text-truncate d-flex align-items-center">{cell.row.original.type.name}</span>
		}
	},
	{
		Header: 'Başlangıç Tarihi',
		accessor: 'startDate',
		Cell: (cell) => {
			return <span>{formatDate(cell.row.original.startDate, true)}</span>
		}
	},
	{
		Header: 'Bitiş Tarihi',
		accessor: 'endDate',
		Cell: (cell) => {
			return <span>{formatDate(cell.row.original.endDate, true)}</span>
		}
	},
	{
		Header: 'İzin Süresi',
		accessor: 'duration',
		Cell: (cell) => {
			return (
				<span>
					{cell.row.original.startDate &&
						cell.row.original.endDate &&
						Math.ceil(
							(new Date(cell.row.original.endDate) - new Date(cell.row.original.startDate)) / (1000 * 60 * 60 * 24)
						)}{' '}
					gün
				</span>
			)
		}
	},

	{
		Header: 'Açıklama',
		accessor: 'description',
		Cell: (cell) => {
			return <span className="fw-semibold">{cell.row.original.description}</span>
		}
	},
	{
		Header: 'Durum',
		accessor: 'status',
		Cell: (cell) => {
			const badgeStatus =
				cell.row.original.status === 'pending'
					? 'bg-warning'
					: cell.row.original.status === 'approved'
					? 'bg-success'
					: cell.row.original.status === 'rejected'
					? 'bg-danger'
					: 'bg-secondary'

			return (
				<span className={`badge ${badgeStatus}`}>
					{statuses.find((status) => status.value === cell.row.original.status)?.label}
				</span>
			)
		}
	},
	{
		Header: 'Seçenekler',
		accessor: 'options',
		Cell: (cell) => {
			return (
				<div className="d-flex align-items-center">
					<div className="d-flex align-items-center">
						<Link to={`/profile/vacations/list/${cell.row.original._id}/show`} className="text-primary me-3">
							<FaIcon icon="eye" />
						</Link>
						{cell.row.original.status === 'pending' && (
							<Link to={`/profile/vacations/list/${cell.row.original._id}/cancel`} className="text-danger me-2">
								<FaIcon icon="xmark" />
							</Link>
						)}
					</div>
				</div>
			)
		}
	}
]

const MyVacationTable = () => {
	const params = useParams()
	const { module_name, tab_name } = params

	const [vacations, setVacations] = useState([])

	const fetchVacations = async () => {
		try {
			const response = await Service.get(`/employee-vacations/me/requests`)
			setVacations(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchVacations()
	}, [params])

	return (
		<Col lg={12} md={12} sm={12} xs={12}>
			<Card>
				<Card.Header>
					<Card.Title>
						<h4>İzin Taleplerim</h4>
					</Card.Title>
				</Card.Header>
				<Card.Body>
					{vacations.length > 0 ? (
						<CustomReactTable columns={columns} data={vacations} />
					) : (
						<NoData
							title="Henüz izin talebiniz bulunmuyor."
							subTitle="Talep sayfasından yeni bir izin talebi oluşturabilirsiniz."
						/>
					)}
				</Card.Body>
			</Card>
		</Col>
	)
}

export default MyVacationTable
