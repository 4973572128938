const { default: axios } = require('axios')

const downloadFile = (url, file, fileName) => {
	//resim ise yeni sekmede aç
	if (file.mimeType.includes('image')) {
		window.open(url)
		return
	}
	axios({
		url: url,
		method: 'GET',
		responseType: 'blob'
	}).then((response) => {
		// create file link in browser's memory
		const href = URL.createObjectURL(response.data)

		// create "a" HTML element with href to file & click
		const link = document.createElement('a')
		link.href = href
		link.setAttribute('download', fileName)
		link.setAttribute('target', '_blank') // Open in a new tab
		document.body.appendChild(link)
		link.click()

		// clean up "a" element & remove ObjectURL
		document.body.removeChild(link)
		URL.revokeObjectURL(href)
	})
}
