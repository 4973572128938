import { TabButton, TabButtonContainer } from 'components/ui'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

import SchemeEditor from './components/SchemeEditor'
import SchemeList from './components/SchemeList'

const OrganizationSchemes = () => {
	const params = useParams()
	const navigate = useNavigate()

	const [tabName, setTabName] = useState('list')
	const { scheme_id } = params

	useEffect(() => {
		if (scheme_id === undefined || scheme_id === 'add') {
			setTabName('list') //test
		} else {
			setTabName('edit')
		}
	}, [scheme_id])

	return (
		<Container fluid="xxl" className="flex-grow-1 container-p-y">
			<Row>
				{tabName === 'list' ? (
					<SchemeList />
				) : (
					<>
						<Col md={12}>
							<TabButtonContainer className="mb-4">
								<TabButton
									onClick={() => navigate(`/ik-organization-schemes`)}
									icon="sitemap"
									text="Şemalar"
									active={1 === 'general'}
								/>
							</TabButtonContainer>
						</Col>
						<Col md={12}>
							<SchemeEditor />
						</Col>
					</>
				)}
			</Row>
		</Container>
	)
}

export default OrganizationSchemes
