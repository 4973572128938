import { FaIcon, TabButton } from 'components/ui'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import CareerPositionTab from './position-tab'
import CareerSalaryTab from './salary-tab'
import CareerWorkCalendarTab from './work-calendar-tab'

const CareerTab = () => {
	const params = useParams()
	const navigate = useNavigate()
	const { employee_id, tab_name, module_name, module_id } = params

	const [activeTab, setActiveTab] = useState('positions')

	useEffect(() => {
		if (tab_name === 'career' && module_name) {
			setActiveTab(module_name)
		}
	}, [params])

	return (
		<>
			<div className="mb-4">
				<div className="btn-group" role="group">
					<button
						type="button"
						class={`btn btn-outline-secondary ${activeTab === 'positions' ? 'active' : ''}`}
						onClick={() => navigate(`/employees/${employee_id}/career/positions`)}
					>
						<FaIcon icon="briefcase" className="me-2" />
						Pozisyon
					</button>
					<button
						type="button"
						class={`btn btn-outline-secondary ${activeTab === 'salaries' ? 'active' : ''}`}
						onClick={() => navigate(`/employees/${employee_id}/career/salaries`)}
					>
						<FaIcon icon="money-bill-wave" className="me-2" />
						Maaş
					</button>
					<button
						type="button"
						class={`btn btn-outline-secondary ${activeTab === 'work-calendars' ? 'active' : ''}`}
						onClick={() => navigate(`/employees/${employee_id}/career/work-calendars`)}
					>
						<FaIcon icon="calendar" className="me-2" />
						Çalışma Takvimi
					</button>
				</div>
			</div>

			<div className="mb-4">
				{activeTab === 'positions' && <CareerPositionTab />}
				{activeTab === 'salaries' && <CareerSalaryTab />}
				{activeTab === 'work-calendars' && <CareerWorkCalendarTab />}
			</div>
		</>
	)
}

export default CareerTab
