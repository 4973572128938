import { ExamineButton } from 'components/fast-ui'
import { CustomPopperTooltip, CustomReactTable, FaIcon } from 'components/ui'
import Service from 'global/Service'
import { getStatusBadgeClass, getStatusBadgeText } from 'global/functions'
import { formatDate } from 'global/functions/formatDate'
import { sendToast } from 'global/sendToast'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const columns = [
	{
		Header: 'Türü',
		accessor: 'type',
		Cell: ({ row }) => {
			return row.original.isRevise ? (
				<span className="badge bg-dark">Revize</span>
			) : (
				<span className="badge bg-info">Yeni</span>
			)
		}
	},
	{
		Header: 'Doküman Adı',
		accessor: 'digitalName'
	},
	{
		Header: 'Yükleyen Kişi',
		accessor: 'creator.fullName'
	},
	{
		Header: 'Yüklenme Tarihi',
		accessor: 'createDate',
		Cell: ({ row }) => {
			return <span>{formatDate(row.original.createDate)}</span>
		}
	},
	{
		Header: 'Yüklenen Konum',
		accessor: 'path',
		Cell: ({ row }) => {
			return (
				<>
					<CustomPopperTooltip text={row.original.path}>
						<FaIcon icon="fa fa-info-circle me-2" />
					</CustomPopperTooltip>
					<span className="ml-2">{row.original.path.split('/').pop()}</span>
				</>
			)
		}
	},
	{
		Header: 'Onay Durumu',
		accessor: 'status',
		Cell: ({ row }) => {
			return (
				<span className={`badge ${getStatusBadgeClass(row.original.status)}`}>
					{getStatusBadgeText(row.original.status)}
				</span>
			)
		}
	},
	{
		Header: 'İşlem',
		accessor: 'action',
		Cell: ({ row }) => {
			return (
				<>
					<Link to={`/document-approval/${row.original._id}/approve`}>
						<ExamineButton size="sm" />
					</Link>
				</>
			)
		}
	}
]

const exampleData = [
	{
		_id: '2',
		name: 'Doküman 12',
		uploader: 'Nihat',
		uploadDate: '02/01/2023',
		status: 'pending',
		type: 'new',
		location: 'Konum 2'
	},
	{
		_id: '3',
		name: 'Doküman 3',
		uploader: 'Barış',
		uploadDate: '03/01/2023',
		status: 'pending',
		type: 'revise',
		location: 'Konum 3'
	}
]

const DocumentApprovalRequests = () => {
	const [data, setData] = useState([])

	const fetchData = async () => {
		try {
			const response = await Service.get('/documents/my-pending')
			setData(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<>
			<Card>
				<Card.Header>
					<Card.Title as="h5" className="mb-0">
						Onay Bekleyen Dokümanlar
					</Card.Title>
				</Card.Header>
				<Card.Body>
					<CustomReactTable data={data} columns={columns} />
				</Card.Body>
			</Card>
		</>
	)
}

export default DocumentApprovalRequests
