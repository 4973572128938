export const allCountries = [
	{
		name: 'ABD Virgin Adaları',
		dial_code: '+1 340',
		code: 'VI'
	},
	{
		name: 'Aland Adaları',
		dial_code: '+358',
		code: 'AX'
	},
	{
		name: 'Almanya',
		dial_code: '+49',
		code: 'DE'
	},
	{
		name: 'Amerika Birleşik Devletleri',
		dial_code: '+1',
		code: 'US'
	},
	{
		name: 'Andora',
		dial_code: '+376',
		code: 'AD'
	},
	{
		name: 'Angola',
		dial_code: '+244',
		code: 'AO'
	},
	{
		name: 'Anguilla',
		dial_code: '+1 264',
		code: 'AI'
	},
	{
		name: 'Antarktika',
		dial_code: '+672',
		code: 'AQ'
	},
	{
		name: 'Antigua ve Barbuda',
		dial_code: '+1268',
		code: 'AG'
	},
	{
		name: 'Arjantin',
		dial_code: '+54',
		code: 'AR'
	},
	{
		name: 'Arnavutluk',
		dial_code: '+355',
		code: 'AL'
	},
	{
		name: 'Aruba',
		dial_code: '+297',
		code: 'AW'
	},
	{
		name: 'Avustralya',
		dial_code: '+61',
		code: 'AU'
	},
	{
		name: 'Avusturya',
		dial_code: '+43',
		code: 'AT'
	},
	{
		name: 'Azerbaycan',
		dial_code: '+994',
		code: 'AZ'
	},
	{
		name: 'Bahamalar',
		dial_code: '+1 242',
		code: 'BS'
	},
	{
		name: 'Bahreyn',
		dial_code: '+973',
		code: 'BH'
	},
	{
		name: 'Bangladeş',
		dial_code: '+880',
		code: 'BD'
	},
	{
		name: 'Barbados',
		dial_code: '+1 246',
		code: 'BB'
	},
	{
		name: 'Belize',
		dial_code: '+501',
		code: 'BZ'
	},
	{
		name: 'Belçika',
		dial_code: '+32',
		code: 'BE'
	},
	{
		name: 'Benin',
		dial_code: '+229',
		code: 'BJ'
	},
	{
		name: 'Bermuda',
		dial_code: '+1 441',
		code: 'BM'
	},
	{
		name: 'Beyaz Rusya',
		dial_code: '+375',
		code: 'BY'
	},
	{
		name: 'Bhutan',
		dial_code: '+975',
		code: 'BT'
	},
	{
		name: 'Birleşik Arap Emirlikleri',
		dial_code: '+971',
		code: 'AE'
	},
	{
		name: 'Birleşik Krallık',
		dial_code: '+44',
		code: 'GB'
	},
	{
		name: 'Bolivya',
		dial_code: '+591',
		code: 'BO'
	},
	{
		name: 'Bosna Hersek',
		dial_code: '+387',
		code: 'BA'
	},
	{
		name: 'Botsvana',
		dial_code: '+267',
		code: 'BW'
	},
	{
		name: 'Brezilya',
		dial_code: '+55',
		code: 'BR'
	},
	{
		name: 'Brunei',
		dial_code: '+673',
		code: 'BN'
	},
	{
		name: 'Bulgaristan',
		dial_code: '+359',
		code: 'BG'
	},
	{
		name: 'Burkina Faso',
		dial_code: '+226',
		code: 'BF'
	},
	{
		name: 'Burundi',
		dial_code: '+257',
		code: 'BI'
	},
	{
		name: 'Cape Verde',
		dial_code: '+238',
		code: 'CV'
	},
	{
		name: 'Cebelitarık',
		dial_code: '+350',
		code: 'GI'
	},
	{
		name: 'Cezayir',
		dial_code: '+213',
		code: 'DZ'
	},
	{
		name: 'Christmas Adası',
		dial_code: '+61',
		code: 'CX'
	},
	{
		name: 'Cibuti',
		dial_code: '+253',
		code: 'DJ'
	},
	{
		name: 'Cocos Adaları',
		dial_code: '+61',
		code: 'CC'
	},
	{
		name: 'Cook Adaları',
		dial_code: '+682',
		code: 'CK'
	},
	{
		name: 'Çad',
		dial_code: '+235',
		code: 'TD'
	},
	{
		name: 'Çek Cumhuriyeti',
		dial_code: '+420',
		code: 'CZ'
	},
	{
		name: 'Çin',
		dial_code: '+86',
		code: 'CN'
	},
	{
		name: 'Danimarka',
		dial_code: '+45',
		code: 'DK'
	},
	{
		name: 'Dominik',
		dial_code: '+1 767',
		code: 'DM'
	},
	{
		name: 'Dominik Cumhuriyeti',
		dial_code: '+1 849',
		code: 'DO'
	},
	{
		name: 'Doğu Timor',
		dial_code: '+670',
		code: 'TL'
	},
	{
		name: 'Ekvator',
		dial_code: '+593',
		code: 'EC'
	},
	{
		name: 'Ekvator Ginesi',
		dial_code: '+240',
		code: 'GQ'
	},
	{
		name: 'El Salvador',
		dial_code: '+503',
		code: 'SV'
	},
	{
		name: 'Endonezya',
		dial_code: '+62',
		code: 'ID'
	},
	{
		name: 'Eritre',
		dial_code: '+291',
		code: 'ER'
	},
	{
		name: 'Ermenistan',
		dial_code: '+374',
		code: 'AM'
	},
	{
		name: 'Estonya',
		dial_code: '+372',
		code: 'EE'
	},
	{
		name: 'Etiyopya',
		dial_code: '+251',
		code: 'ET'
	},
	{
		name: 'Falkland Adaları (Malvinalar)',
		dial_code: '+500',
		code: 'FK'
	},
	{
		name: 'Faroe Adaları',
		dial_code: '+298',
		code: 'FO'
	},
	{
		name: 'Fas',
		dial_code: '+212',
		code: 'MA'
	},
	{
		name: 'Fiji',
		dial_code: '+679',
		code: 'FJ'
	},
	{
		name: 'Fildişi Sahilleri',
		dial_code: '+225',
		code: 'CI'
	},
	{
		name: 'Filipinler',
		dial_code: '+63',
		code: 'PH'
	},
	{
		name: 'Filistin Bölgesi',
		dial_code: '+970',
		code: 'PS'
	},
	{
		name: 'Finlandiya',
		dial_code: '+358',
		code: 'FI'
	},
	{
		name: 'Fransa',
		dial_code: '+33',
		code: 'FR'
	},
	{
		name: 'Fransız Guyanası',
		dial_code: '+594',
		code: 'GF'
	},
	{
		name: 'Fransız Polinezyası',
		dial_code: '+689',
		code: 'PF'
	},
	{
		name: 'Gabon',
		dial_code: '+241',
		code: 'GA'
	},
	{
		name: 'Gambia',
		dial_code: '+220',
		code: 'GM'
	},
	{
		name: 'Gana',
		dial_code: '+233',
		code: 'GH'
	},
	{
		name: 'Gine',
		dial_code: '+224',
		code: 'GN'
	},
	{
		name: 'Gine-Bissau',
		dial_code: '+245',
		code: 'GW'
	},
	{
		name: 'Granada',
		dial_code: '+1 473',
		code: 'GD'
	},
	{
		name: 'Grönland',
		dial_code: '+299',
		code: 'GL'
	},
	{
		name: 'Guadeloupe',
		dial_code: '+590',
		code: 'GP'
	},
	{
		name: 'Guam',
		dial_code: '+1 671',
		code: 'GU'
	},
	{
		name: 'Guatemala',
		dial_code: '+502',
		code: 'GT'
	},
	{
		name: 'Guernsey',
		dial_code: '+44',
		code: 'GG'
	},
	{
		name: 'Guyana',
		dial_code: '+595',
		code: 'GY'
	},
	{
		name: 'Güney Afrika',
		dial_code: '+27',
		code: 'ZA'
	},
	{
		name: 'Güney Georgia ve Güney Sandwich Adaları',
		dial_code: '+500',
		code: 'GS'
	},
	{
		name: 'Güney Kore',
		dial_code: '+82',
		code: 'KR'
	},
	{
		name: 'Güney Kıbrıs Rum Kesimi',
		dial_code: '+537',
		code: 'CY'
	},
	{
		name: 'Gürcistan',
		dial_code: '+995',
		code: 'GE'
	},
	{
		name: 'Haiti',
		dial_code: '+509',
		code: 'HT'
	},
	{
		name: 'Hindistan',
		dial_code: '+91',
		code: 'IN'
	},
	{
		name: 'Hint Okyanusu İngiliz Bölgesi',
		dial_code: '+246',
		code: 'IO'
	},
	{
		name: 'Hollanda',
		dial_code: '+31',
		code: 'NL'
	},
	{
		name: 'Hollanda Antilleri',
		dial_code: '+599',
		code: 'AN'
	},
	{
		name: 'Honduras',
		dial_code: '+504',
		code: 'HN'
	},
	{
		name: 'Hong Kong SAR - Çin',
		dial_code: '+852',
		code: 'HK'
	},
	{
		name: 'Hırvatistan',
		dial_code: '+385',
		code: 'HR'
	},
	{
		name: 'Irak',
		dial_code: '+964',
		code: 'IQ'
	},
	{
		name: 'İngiliz Virgin Adaları',
		dial_code: '+1 284',
		code: 'VG'
	},
	{
		name: 'İran',
		dial_code: '+98',
		code: 'IR'
	},
	{
		name: 'İrlanda',
		dial_code: '+353',
		code: 'IE'
	},
	{
		name: 'İspanya',
		dial_code: '+34',
		code: 'ES'
	},
	{
		name: 'İsrail',
		dial_code: '+972',
		code: 'IL'
	},
	{
		name: 'İsveç',
		dial_code: '+46',
		code: 'SE'
	},
	{
		name: 'İsviçre',
		dial_code: '+41',
		code: 'CH'
	},
	{
		name: 'İtalya',
		dial_code: '+39',
		code: 'IT'
	},
	{
		name: 'İzlanda',
		dial_code: '+354',
		code: 'IS'
	},
	{
		name: 'Jamaika',
		dial_code: '+1 876',
		code: 'JM'
	},
	{
		name: 'Japonya',
		dial_code: '+81',
		code: 'JP'
	},
	{
		name: 'Jersey',
		dial_code: '+44',
		code: 'JE'
	},
	{
		name: 'Kamboçya',
		dial_code: '+855',
		code: 'KH'
	},
	{
		name: 'Kamerun',
		dial_code: '+237',
		code: 'CM'
	},
	{
		name: 'Kanada',
		dial_code: '+1',
		code: 'CA'
	},
	{
		name: 'Karadağ',
		dial_code: '+382',
		code: 'ME'
	},
	{
		name: 'Katar',
		dial_code: '+974',
		code: 'QA'
	},
	{
		name: 'Kayman Adaları',
		dial_code: '+ 345',
		code: 'KY'
	},
	{
		name: 'Kazakistan',
		dial_code: '+7 7',
		code: 'KZ'
	},
	{
		name: 'Kenya',
		dial_code: '+254',
		code: 'KE'
	},
	{
		name: 'Kiribati',
		dial_code: '+686',
		code: 'KI'
	},
	{
		name: 'Kolombiya',
		dial_code: '+57',
		code: 'CO'
	},
	{
		name: 'Komorlar',
		dial_code: '+269',
		code: 'KM'
	},
	{
		name: 'Kongo',
		dial_code: '+242',
		code: 'CG'
	},
	{
		name: 'Kongo Demokratik Cumhuriyeti',
		dial_code: '+243',
		code: 'CD'
	},
	{
		name: 'Kosta Rika',
		dial_code: '+506',
		code: 'CR'
	},
	{
		name: 'Kuveyt',
		dial_code: '+965',
		code: 'KW'
	},
	{
		name: 'Kuzey Kore',
		dial_code: '+850',
		code: 'KP'
	},
	{
		name: 'Kuzey Mariana Adaları',
		dial_code: '+1 670',
		code: 'MP'
	},
	{
		name: 'Küba',
		dial_code: '+53',
		code: 'CU'
	},
	{
		name: 'Kırgızistan',
		dial_code: '+996',
		code: 'KG'
	},
	{
		name: 'Laos',
		dial_code: '+856',
		code: 'LA'
	},
	{
		name: 'Lesotho',
		dial_code: '+266',
		code: 'LS'
	},
	{
		name: 'Letonya',
		dial_code: '+371',
		code: 'LV'
	},
	{
		name: 'Liberya',
		dial_code: '+231',
		code: 'LR'
	},
	{
		name: 'Libya',
		dial_code: '+218',
		code: 'LY'
	},
	{
		name: 'Liechtenstein',
		dial_code: '+423',
		code: 'LI'
	},
	{
		name: 'Litvanya',
		dial_code: '+370',
		code: 'LT'
	},
	{
		name: 'Lübnan',
		dial_code: '+961',
		code: 'LB'
	},
	{
		name: 'Lüksemburg',
		dial_code: '+352',
		code: 'LU'
	},
	{
		name: 'Macaristan',
		dial_code: '+36',
		code: 'HU'
	},
	{
		name: 'Madagaskar',
		dial_code: '+261',
		code: 'MG'
	},
	{
		name: 'Makao S.A.R. Çin',
		dial_code: '+853',
		code: 'MO'
	},
	{
		name: 'Makedonya',
		dial_code: '+389',
		code: 'MK'
	},
	{
		name: 'Malavi',
		dial_code: '+265',
		code: 'MW'
	},
	{
		name: 'Maldivler',
		dial_code: '+960',
		code: 'MV'
	},
	{
		name: 'Malezya',
		dial_code: '+60',
		code: 'MY'
	},
	{
		name: 'Mali',
		dial_code: '+223',
		code: 'ML'
	},
	{
		name: 'Malta',
		dial_code: '+356',
		code: 'MT'
	},
	{
		name: 'Man Adası',
		dial_code: '+44',
		code: 'IM'
	},
	{
		name: 'Marshall Adaları',
		dial_code: '+692',
		code: 'MH'
	},
	{
		name: 'Martinik',
		dial_code: '+596',
		code: 'MQ'
	},
	{
		name: 'Mauritius',
		dial_code: '+230',
		code: 'MU'
	},
	{
		name: 'Mayotte',
		dial_code: '+262',
		code: 'YT'
	},
	{
		name: 'Meksika',
		dial_code: '+52',
		code: 'MX'
	},
	{
		name: 'Mikronezya Federal Eyaletleri',
		dial_code: '+691',
		code: 'FM'
	},
	{
		name: 'Moldovya Cumhuriyeti',
		dial_code: '+373',
		code: 'MD'
	},
	{
		name: 'Monako',
		dial_code: '+377',
		code: 'MC'
	},
	{
		name: 'Montserrat',
		dial_code: '+1664',
		code: 'MS'
	},
	{
		name: 'Moritanya',
		dial_code: '+222',
		code: 'MR'
	},
	{
		name: 'Mozambik',
		dial_code: '+258',
		code: 'MZ'
	},
	{
		name: 'Moğolistan',
		dial_code: '+976',
		code: 'MN'
	},
	{
		name: 'Myanmar',
		dial_code: '+95',
		code: 'MM'
	},
	{
		name: 'Mısır',
		dial_code: '+20',
		code: 'EG'
	},
	{
		name: 'Namibya',
		dial_code: '+264',
		code: 'NA'
	},
	{
		name: 'Nauru',
		dial_code: '+674',
		code: 'NR'
	},
	{
		name: 'Nepal',
		dial_code: '+977',
		code: 'NP'
	},
	{
		name: 'Nijer',
		dial_code: '+227',
		code: 'NE'
	},
	{
		name: 'Nijerya',
		dial_code: '+234',
		code: 'NG'
	},
	{
		name: 'Nikaragua',
		dial_code: '+505',
		code: 'NI'
	},
	{
		name: 'Niue',
		dial_code: '+683',
		code: 'NU'
	},
	{
		name: 'Norfolk Adası',
		dial_code: '+672',
		code: 'NF'
	},
	{
		name: 'Norveç',
		dial_code: '+47',
		code: 'NO'
	},
	{
		name: 'Orta Afrika Cumhuriyeti',
		dial_code: '+236',
		code: 'CF'
	},
	{
		name: 'Özbekistan',
		dial_code: '+998',
		code: 'UZ'
	},
	{
		name: 'Pakistan',
		dial_code: '+92',
		code: 'PK'
	},
	{
		name: 'Palau',
		dial_code: '+680',
		code: 'PW'
	},
	{
		name: 'Panama',
		dial_code: '+507',
		code: 'PA'
	},
	{
		name: 'Papua Yeni Gine',
		dial_code: '+675',
		code: 'PG'
	},
	{
		name: 'Paraguay',
		dial_code: '+595',
		code: 'PY'
	},
	{
		name: 'Peru',
		dial_code: '+51',
		code: 'PE'
	},
	{
		name: 'Pitcairn',
		dial_code: '+872',
		code: 'PN'
	},
	{
		name: 'Polonya',
		dial_code: '+48',
		code: 'PL'
	},
	{
		name: 'Portekiz',
		dial_code: '+351',
		code: 'PT'
	},
	{
		name: 'Porto Riko',
		dial_code: '+1 939',
		code: 'PR'
	},
	{
		name: 'Reunion',
		dial_code: '+262',
		code: 'RE'
	},
	{
		name: 'Romanya',
		dial_code: '+40',
		code: 'RO'
	},
	{
		name: 'Ruanda',
		dial_code: '+250',
		code: 'RW'
	},
	{
		name: 'Rusya Federasyonu',
		dial_code: '+7',
		code: 'RU'
	},
	{
		name: 'Samoa',
		dial_code: '+685',
		code: 'WS'
	},
	{
		name: 'San Marino',
		dial_code: '+378',
		code: 'SM'
	},
	{
		name: 'Sao Tome ve Principe',
		dial_code: '+239',
		code: 'ST'
	},
	{
		name: 'Senegal',
		dial_code: '+221',
		code: 'SN'
	},
	{
		name: 'Seyşeller',
		dial_code: '+248',
		code: 'SC'
	},
	{
		name: 'Sierra Leone',
		dial_code: '+232',
		code: 'SL'
	},
	{
		name: 'Singapur',
		dial_code: '+65',
		code: 'SG'
	},
	{
		name: 'Slovakya',
		dial_code: '+421',
		code: 'SK'
	},
	{
		name: 'Slovenya',
		dial_code: '+386',
		code: 'SI'
	},
	{
		name: 'Solomon Adaları',
		dial_code: '+677',
		code: 'SB'
	},
	{
		name: 'Somali',
		dial_code: '+252',
		code: 'SO'
	},
	{
		name: 'Sri Lanka',
		dial_code: '+94',
		code: 'LK'
	},
	{
		name: 'Sudan',
		dial_code: '+249',
		code: 'SD'
	},
	{
		name: 'Surinam',
		dial_code: '+597',
		code: 'SR'
	},
	{
		name: 'Suriye',
		dial_code: '+963',
		code: 'SY'
	},
	{
		name: 'Suudi Arabistan',
		dial_code: '+966',
		code: 'SA'
	},
	{
		name: 'Svalbard ve Jan Mayen',
		dial_code: '+47',
		code: 'SJ'
	},
	{
		name: 'Svaziland',
		dial_code: '+268',
		code: 'SZ'
	},
	{
		name: 'Sırbistan',
		dial_code: '+381',
		code: 'RS'
	},
	{
		name: 'Şili',
		dial_code: '+56',
		code: 'CL'
	},
	{
		name: 'Tacikistan',
		dial_code: '+992',
		code: 'TJ'
	},
	{
		name: 'Tanzanya',
		dial_code: '+255',
		code: 'TZ'
	},
	{
		name: 'Tayland',
		dial_code: '+66',
		code: 'TH'
	},
	{
		name: 'Tayvan',
		dial_code: '+886',
		code: 'TW'
	},
	{
		name: 'Togo',
		dial_code: '+228',
		code: 'TG'
	},
	{
		name: 'Tokelau',
		dial_code: '+690',
		code: 'TK'
	},
	{
		name: 'Tonga',
		dial_code: '+676',
		code: 'TO'
	},
	{
		name: 'Trinidad ve Tobago',
		dial_code: '+1 868',
		code: 'TT'
	},
	{
		name: 'Tunus',
		dial_code: '+216',
		code: 'TN'
	},
	{
		name: 'Turks ve Caicos Adaları',
		dial_code: '+1 649',
		code: 'TC'
	},
	{
		name: 'Tuvalu',
		dial_code: '+688',
		code: 'TV'
	},
	{
		name: 'Türkiye',
		dial_code: '+90',
		code: 'TR'
	},
	{
		name: 'Türkmenistan',
		dial_code: '+993',
		code: 'TM'
	},
	{
		name: 'Uganda',
		dial_code: '+256',
		code: 'UG'
	},
	{
		name: 'Ukrayna',
		dial_code: '+380',
		code: 'UA'
	},
	{
		name: 'Umman',
		dial_code: '+968',
		code: 'OM'
	},
	{
		name: 'Uruguay',
		dial_code: '+598',
		code: 'UY'
	},
	{
		name: 'Ürdün',
		dial_code: '+962',
		code: 'JO'
	},
	{
		name: 'Vanuatu',
		dial_code: '+678',
		code: 'VU'
	},
	{
		name: 'Vatikan',
		dial_code: '+379',
		code: 'VA'
	},
	{
		name: 'Venezuela',
		dial_code: '+58',
		code: 'VE'
	},
	{
		name: 'Vietnam',
		dial_code: '+84',
		code: 'VN'
	},
	{
		name: 'Wallis ve Futuna',
		dial_code: '+681',
		code: 'WF'
	},
	{
		name: 'Yemen',
		dial_code: '+967',
		code: 'YE'
	},
	{
		name: 'Yeni Kaledonya',
		dial_code: '+687',
		code: 'NC'
	},
	{
		name: 'Yeni Zelanda',
		dial_code: '+64',
		code: 'NZ'
	},
	{
		name: 'Yunanistan',
		dial_code: '+30',
		code: 'GR'
	},
	{
		name: 'Zambiya',
		dial_code: '+260',
		code: 'ZM'
	},
	{
		name: 'Zimbabve',
		dial_code: '+263',
		code: 'ZW'
	}
]
