import { FaIcon } from 'components/ui'
import React from 'react'

const DocumentTag = (props) => {
	return (
		<>
			{
				<span className="ms-n1" style={{ color: props.hexColor }}>
					<FaIcon type="solid" icon="circle-small" size="xs" /> {props.showName && props.name}
				</span>
			}
		</>
	)
}

export default DocumentTag
