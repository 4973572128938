import 'react-phone-input-2/lib/style.css'

import chroma from 'chroma-js'
import { CreateButton, SaveButton } from 'components/fast-ui'
import { FaIcon } from 'components/ui'
import { CustomReactSelect, FormInput } from 'components/ui'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React, { useEffect, useState } from 'react'
import Flatpickr from 'react-flatpickr'
import { Controller, useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchEmployee } from 'stores/ikEmployeeEditSlice'
import useNewEmployeeStore from 'stores/newEmployeeStore'

import { ErrorMessage } from '@hookform/error-message'

import Inputs from './components/Inputs'

const contractTypes = [
	{ value: 'timed', label: 'Süreli' },
	{ value: 'indefinite', label: 'Süresiz' }
]

const GeneralTab = () => {
	const { setFirstName, setLastName } = useNewEmployeeStore((state) => state)
	const params = useParams()

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [isNew, setIsNew] = useState(false)
	const [roles, setRoles] = useState([])

	const fetchRoles = async () => {
		try {
			const roles = await Service.get('/roles')
			setRoles(roles.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchRoles()
	}, [])

	const colourStyles = {
		control: (styles) => ({ ...styles, backgroundColor: 'white' }),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			const color = chroma(data.hexColor)
			return {
				...styles,
				backgroundColor: isDisabled
					? undefined
					: isSelected
					? data.hexColor
					: isFocused
					? color.alpha(0.1).css()
					: undefined,
				color: isDisabled
					? '#ccc'
					: isSelected
					? chroma.contrast(color, 'white') > 2
						? 'white'
						: 'black'
					: data.hexColor,
				cursor: isDisabled ? 'not-allowed' : 'default',

				':active': {
					...styles[':active'],
					backgroundColor: !isDisabled ? (isSelected ? data.hexColor : color.alpha(0.3).css()) : undefined
				}
			}
		},
		multiValue: (styles, { data }) => {
			const color = chroma(data.hexColor)
			return {
				...styles,
				backgroundColor: color.alpha(0.1).css()
			}
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: data.hexColor
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: data.hexColor,
			':hover': {
				backgroundColor: data.hexColor,
				color: 'white'
			}
		})
	}

	const { employee } = useSelector((state) => state.ikEmployeeEdit)
	const { workingStatus } = employee
	const { employee_id } = params

	useEffect(() => {
		if (employee._id !== undefined) {
			reset(employee)
			setIsNew(false)
		}
	}, [employee])

	useEffect(() => {
		if (employee_id === 'new') {
			reset({
				firstName: null,
				lastName: null,
				mail: null,
				workMail: null,
				phone: null,
				workPhone: null,
				hiredDate: undefined,
				accrualStartDate: undefined,
				contractType: null,
				contractFinishDate: undefined
			})
			setIsNew(true)
		}
	}, [employee_id])

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		control,
		reset,
		methods,
		formState,
		setValue,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	const firstName = watch('firstName')
	const lastName = watch('lastName')

	useEffect(() => {
		if (firstName || lastName) {
			setFirstName(firstName)
			setLastName(lastName)
		} else {
			setFirstName('Yeni')
			setLastName('Personel')
		}
	}, [firstName, lastName])

	const handleCreate = async (data) => {
		const body = data
		try {
			const createdEmployee = await Service.post(`/employees`, body)
			sendToast('Personel oluşturuldu.')
			navigate(`/employees/${createdEmployee.data._id}`)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleSave = async (data) => {
		const body = data

		try {
			const patchedEmployee = await Service.patch(`/employees/${data._id}`, body)
			dispatch(fetchEmployee(employee_id))
			sendToast('Personel kaydedildi.')
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	const handleClickDismissalInfo = () => {
		navigate(`/employees/${employee_id}/general/dismissal`)
	}

	const selectedContractType = watch('contractType') // watch if the name is checked

	return (
		<div className="card mb-4">
			<h5 className="card-header">Genel Bilgiler</h5>
			<div className="card-body">
				<form className="row">
					{Inputs.map(({ name, label, placeholder, options }) => (
						<div className="mb-3 col-12 col-sm-6">
							<FormInput
								register={register(name, options)}
								defaultValue={employee?.[name]}
								label={label}
								disabled={isNew ? false : !workingStatus}
								placeholder={placeholder}
							/>
							<ErrorMessage
								errors={errors}
								name={name}
								render={({ message }) => <div className="invalid-feedback">{message}</div>}
							/>
						</div>
					))}

					<div className="mb-3 col-12 col-sm-6">
						<div className="form-input">
							<label className="form-label">Telefon (İş)</label>
							<PhoneInput
								country="tr"
								onChange={(value) => setValue('workPhone', value)}
								value={getValues('workPhone')}
								defaultValue={employee?.workPhone}
								disabled={isNew ? false : !workingStatus}
								inputClass="form-control w-100"
								inputProps={{
									name: 'workPhone',
									required: true
								}}
							/>
							<ErrorMessage
								errors={errors}
								name={'workPhone'}
								render={({ message }) => <div className="invalid-feedback">{message}</div>}
							/>
						</div>
					</div>

					<div className="mb-3 col-12 col-sm-6">
						<div className="form-input">
							<label className="form-label">Telefon (Kişisel)</label>
							<PhoneInput
								country="tr"
								onChange={(value) => setValue('phone', value)}
								value={getValues('phone')}
								defaultValue={employee?.phone}
								disabled={isNew ? false : !workingStatus}
								inputClass="form-control w-100"
								inputProps={{
									name: 'phone',
									required: true
								}}
							/>
							<ErrorMessage
								errors={errors}
								name={'phone'}
								render={({ message }) => <div className="invalid-feedback">{message}</div>}
							/>
						</div>
					</div>

					<div className="mb-3 col-12 col-sm-6">
						<div className="form-input">
							<label className="form-label">İşe Başlama Tarihi</label>
							<Controller
								name="hiredDate"
								control={control}
								render={({ field }) => (
									<Flatpickr
										className="form-control"
										value={field.value}
										onChange={field.onChange}
										ref={field.ref}
										disabled={isNew ? false : !workingStatus}
										options={{ dateFormat: 'd-m-Y', locale: { ...Turkish } }}
									/>
								)}
							/>
						</div>
					</div>

					<div className="mb-3 col-12 col-sm-6">
						<div className="form-input">
							<label className="form-label">İzne Esas Hakediş Tarihi</label>
							<Controller
								name="accrualStartDate"
								control={control}
								render={({ field }) => (
									<Flatpickr
										className="form-control"
										value={field.value}
										onChange={field.onChange}
										ref={field.ref}
										disabled={isNew ? false : !workingStatus}
										options={{ dateFormat: 'd-m-Y', locale: { ...Turkish } }}
									/>
								)}
							/>
						</div>
					</div>

					<div className="mb-3 col-12 col-sm-6">
						<div className="form-input">
							<label className="form-label">Roller</label>
							<Controller
								name="roles"
								control={control}
								render={({ field }) => (
									<CustomReactSelect
										isMulti
										isClearable
										value={field.value}
										onChange={field.onChange}
										ref={field.ref}
										isDisabled={isNew ? false : !workingStatus}
										placeholder="Roller.."
										options={roles}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option._id}
										styles={colourStyles}
									/>
								)}
							/>
						</div>
					</div>

					<div className="mb-3 col-12 col-sm-6">
						<label className="form-label">Sözleşme Türü</label>
						<Controller
							name="contractType"
							control={control}
							render={({ field }) => (
								<CustomReactSelect
									value={contractTypes.filter((obj) => obj.value === field.value)}
									onChange={(d) => field.onChange(d.value)}
									ref={field.ref}
									isDisabled={isNew ? false : !workingStatus}
									options={contractTypes}
									placeholder="Sözleşme türü.."
								/>
							)}
						/>
					</div>

					<div className="mb-3 col-12 col-sm-6">
						<label className="form-label">Sözleşme Bitiş Tarihi</label>

						<Controller
							name="contractFinishDate"
							control={control}
							rules={{ required: selectedContractType === 'timed' && 'Bu alan gereklidir.' }}
							render={({ field }) => (
								<Flatpickr
									className="form-control"
									value={field.value}
									disabled={isNew ? false : !workingStatus || selectedContractType === 'indefinite'}
									onChange={field.onChange}
									ref={field.ref}
									placeholder="GG-AA-YYYY"
									options={{ dateFormat: 'd-m-Y', locale: { ...Turkish } }}
								/>
							)}
						/>
						<ErrorMessage
							errors={errors}
							name={'contractFinishDate'}
							render={({ message }) => <div className="invalid-feedback">{message}</div>}
						/>
					</div>

					{isNew ? (
						false
					) : !workingStatus ? (
						<div className="mb-3 col-12 col-sm-6">
							<div className="d-flex justify-content-between">
								<label className="form-label">İşten Ayrılma Tarihi</label>
								<label onClick={handleClickDismissalInfo} className="form-label text-primary" type="button">
									<FaIcon icon="circle-info" /> Detay
								</label>
							</div>

							<Controller
								name="dismissal.date"
								control={control}
								render={({ field }) => (
									<Flatpickr
										className="form-control"
										value={field.value}
										disabled={isNew ? false : !workingStatus}
										ref={field.ref}
										placeholder="GG-AA-YYYY"
										options={{ dateFormat: 'd-m-Y', locale: { ...Turkish } }}
									/>
								)}
							/>
						</div>
					) : null}
				</form>
				{isNew ? <CreateButton onClick={handleSubmit((d) => handleCreate(d))} /> : null}
				{isNew === false && workingStatus === true ? <SaveButton onClick={handleSubmit((d) => handleSave(d))} /> : null}
			</div>
		</div>
	)
}

export default GeneralTab
