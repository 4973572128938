import { GlobalFilter } from 'components/ui'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { refreshEmployeeList } from 'stores/ikSlice'

import COLUMNS from './Columns'
import ImportEmployees from './ImportEmployees'

/* const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn */

export const EmployeeList = (props) => {
	const { employeeList } = useSelector((state) => state.ik)

	const data = useMemo(() => employeeList)
	const columns = useMemo(() => COLUMNS, [])
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(refreshEmployeeList())
	}, [])

	const initialState = { hiddenColumns: ['lastName', 'workMail'] }

	const tableInstance = useTable(
		{
			columns,
			data,
			initialState: {
				filters: [
					{
						id: 'workingStatus',
						value: 'working' // Varsayılan filtre değeri
					}
				]
			}
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		flatRows,
		prepareRow,
		page,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		pageOptions,
		gotoPage,
		pageCount,
		setPageSize,
		state,
		setGlobalFilter
	} = tableInstance

	const { globalFilter, pageIndex, pageSize } = state

	const addEmployee = () => {
		/* dispatch(newEmployee())
		dispatch(disableReadonlyEditor())
		dispatch(showEditor()) */
	}

	return (
		<div className="col-12">
			<div className="card">
				<div className="card-header border-bottom">
					<h4 className="card-title mb-0">Personel Listesi</h4>
					<small>Çalışanlarınızı filtreleyin, düzenleyin, kontrol edin.</small>
					<div className="d-flex justify-content-between align-items-center row py-3 gap-3 gap-md-0">
						<div className="col-md-3">
							{headerGroups.map((headerGroup) =>
								headerGroup.headers.map((column) =>
									column.id === 'position.location.name' ? (
										<div>{column.canFilter ? column.render('Filter') : null}</div>
									) : null
								)
							)}
						</div>
						<div className="col-md-3">
							{headerGroups.map((headerGroup) =>
								headerGroup.headers.map((column) =>
									column.id === 'position.department.name' ? (
										<div>{column.canFilter ? column.render('Filter') : null}</div>
									) : null
								)
							)}
						</div>
						<div className="col-md-3 user_plan">
							{headerGroups.map((headerGroup) =>
								headerGroup.headers.map((column) =>
									column.id === 'position.title.name' ? (
										<div>{column.canFilter ? column.render('Filter') : null}</div>
									) : null
								)
							)}
						</div>
						<div className="col-md-3 user_status">
							{headerGroups.map((headerGroup) =>
								headerGroup.headers.map((column) =>
									column.id === 'workingStatus' ? <div>{column.canFilter ? column.render('Filter') : null}</div> : null
								)
							)}
						</div>
					</div>
				</div>
				<div className="card-datatable table-responsive">
					<div className="dataTables_wrapper dt-bootstrap5 no-footer">
						<div className="row mx-2">
							<div className="col-md-2">
								<div className="me-3">
									<div className="dataTables_length" id="DataTables_Table_0_length">
										<label>
											<select
												className="form-select"
												value={pageSize}
												onChange={(e) => setPageSize(Number(e.target.value))}
											>
												{[10, 25, 50, 100].map((pageSize) => (
													<option key={pageSize} value={pageSize}>
														{pageSize}
													</option>
												))}
											</select>
										</label>
									</div>
								</div>
							</div>
							<div className="col-md-10">
								<div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0">
									<div id="DataTables_Table_0_filter" className="dataTables_filter me-3">
										<label>
											<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
										</label>
									</div>
									<div className="dt-action-buttons d-flex align-items-center justify-content-end flex-md-row flex-column me-3">
										<ImportEmployees />
									</div>
									<div className="dt-buttons">
										<Link to="/employees/new">
											<button
												onClick={() => {
													addEmployee()
												}}
												className="btn btn-primary btn-add-new"
											>
												<span>
													<i className="fa-regular fa-plus me-2"></i>
													<span className="align-items-center">Yeni Personel</span>
												</span>
											</button>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<table
							{...getTableProps()}
							className="datatables-users table border-top dataTable table-striped no-footer dtr-column"
							style={{ width: 1382 }}
						>
							<thead>
								{headerGroups.map((headerGroup) => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map((column) => (
											<th
												className="control sorting_disabled"
												{...column.getHeaderProps(column.getSortByToggleProps())}
											>
												{column.render('Header')}
												<span>{column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}</span>
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()}>
								{page.map((row) => {
									prepareRow(row)
									return (
										<tr {...row.getRowProps()}>
											{row.cells.map((cell) => {
												return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
											})}
										</tr>
									)
								})}
							</tbody>
						</table>
						<div className="row mx-2">
							<div className="col-sm-12 col-md-6">
								<div className="dataTables_info" role="status" aria-live="polite">
									Toplam {employeeList.length} personel arasından {rows.length} sonuç
								</div>
							</div>
							<div className="col-sm-12 col-md-6">
								<div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
									<ul className="pagination">
										<li
											className={'paginate_button page-item previous ' + (canPreviousPage ? '' : 'disabled')}
											onClick={() => previousPage()}
										>
											<a href="#" className="page-link">
												Önceki
											</a>
										</li>
										<li
											className={'paginate_button page-item next ' + (canNextPage ? '' : 'disabled')}
											onClick={() => nextPage()}
										>
											<a href="#" className="page-link">
												Sonraki
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
