import anonimProfilePhoto from 'assets/img/avatars/anonim-profile-photo.jpg'
import { FaIcon, Timeago } from 'components/ui'
import Service from 'global/Service'
import { sendToast } from 'global/sendToast'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const WaitingTasks = () => {
	const [data, setData] = useState([])

	const fetchData = async () => {
		try {
			const response = await Service.get('/tasks/assigned')
			setData(response.data)
		} catch (error) {
			sendToast(error.response.data.message, false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])
	return (
		<div className="card card-action mb-4" style={{ height: 300 }}>
			<div className="card-header align-items-center mb-2">
				<h5 className="card-action-title mb-0">Bekleyen görevler</h5>
			</div>
			<div
				className="card-body pb-0"
				style={{
					overflow: 'auto'
				}}
			>
				<ul className="list-unstyled mb-0">
					<li className="mb-3">
						<>
							{data
								.filter((item) => item.status === 'waiting')
								.map((item, index) => (
									<div className="d-flex align-items-start border-bottom pb-2 mb-3" key={index}>
										<div className="d-flex align-items-start">
											<div className="avatar me-3">
												<img
													src={item?.creator?.profilePhotoUrl || anonimProfilePhoto}
													alt="Avatar"
													className="rounded-circle"
												/>
											</div>
											<div className="me-2">
												<h6 className="mb-0">{item?.title}</h6>
												<small className="text-muted">
													{item?.creator?.fullName} - {<Timeago date={item.createDate} />}
												</small>
											</div>
										</div>
										<div className="ms-auto">
											<Link to={`/tasks/${item._id}`} className="text-decoration-none">
												<span className="badge bg-label-info">
													<FaIcon icon="arrow-right" />
												</span>
											</Link>
										</div>
									</div>
								))}
						</>
					</li>
				</ul>
			</div>
			<div className="card-footer pt-0 pb-0">
				<p className="text-center pb-0">
					<Link to="/tasks/assigned-tasks">Tüm görevler</Link>
				</p>
			</div>
		</div>
	)
}

export default WaitingTasks
